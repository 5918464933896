import { RegionList } from 'components/Forms/constants';
import {
  SORT_TYPES,
  TEAM_MEMBERS_TABLE_COLUMN_HEADERS,
  getPermissionOptionDisplayTextFromBoolean,
  getPermissionBooleanFromPermissionType,
} from 'constants/app';
import { StandardCompare, getName } from '../Utilities';

export const sortTeamMembersIds = (
  sorts = {
    sortBy: TEAM_MEMBERS_TABLE_COLUMN_HEADERS.NAME,
    sortDirection: SORT_TYPES.ASC,
  },
  tMIds = [],
  teamMembersById = {},
) => {
  const { sortBy, sortDirection } = sorts;
  const teamMemberIds = [...tMIds];
  let sortedTeamMemberIds = teamMemberIds;

  if (teamMemberIds) {
    switch (sortBy) {
      case TEAM_MEMBERS_TABLE_COLUMN_HEADERS.NAME:
        sortedTeamMemberIds = teamMemberIds.sort((a, b) => {
          const teamMember1 = teamMembersById[a];
          const teamMember2 = teamMembersById[b];
          const teamMember1Name = getName(teamMember1.firstName, teamMember1.lastName, '');
          const teamMember2Name = getName(teamMember2.firstName, teamMember2.lastName, '');

          return StandardCompare.strings(teamMember1Name, teamMember2Name, sortDirection);
        });
        break;
      case TEAM_MEMBERS_TABLE_COLUMN_HEADERS.EMAIL:
        sortedTeamMemberIds = teamMemberIds.sort((a, b) =>
          StandardCompare.strings(
            teamMembersById[a].email,
            teamMembersById[b].email,
            sortDirection,
          ),
        );
        break;
      case TEAM_MEMBERS_TABLE_COLUMN_HEADERS.COUNTRY:
        sortedTeamMemberIds = teamMemberIds.sort((a, b) =>
          StandardCompare.strings(
            teamMembersById[a].country,
            teamMembersById[b].country,
            sortDirection,
          ),
        );
        break;
      case TEAM_MEMBERS_TABLE_COLUMN_HEADERS.REGION:
        sortedTeamMemberIds = teamMemberIds.sort((a, b) =>
          StandardCompare.strings(
            teamMembersById[a].regions?.join(' ') || '',
            teamMembersById[b].regions?.join(' ') || '',
            sortDirection,
          ),
        );
        break;
      case TEAM_MEMBERS_TABLE_COLUMN_HEADERS.ADMIN_PERMISSION:
        sortedTeamMemberIds = teamMemberIds.sort((a, b) => {
          const teamMember1 = teamMembersById[a];
          const teamMember2 = teamMembersById[b];
          const permission1 = getPermissionOptionDisplayTextFromBoolean(
            teamMember1.permissions.isAdmin,
          );
          const permission2 = getPermissionOptionDisplayTextFromBoolean(
            teamMember2.permissions.isAdmin,
          );

          return StandardCompare.strings(permission1, permission2, sortDirection);
        });
        break;
      case TEAM_MEMBERS_TABLE_COLUMN_HEADERS.PHYSICIAN_PERMISSION:
        sortedTeamMemberIds = teamMemberIds.sort((a, b) => {
          const teamMember1 = teamMembersById[a];
          const teamMember2 = teamMembersById[b];
          const permission1 = getPermissionOptionDisplayTextFromBoolean(
            teamMember1.permissions.isPhysician,
          );
          const permission2 = getPermissionOptionDisplayTextFromBoolean(
            teamMember2.permissions.isPhysician,
          );

          return StandardCompare.strings(permission1, permission2, sortDirection);
        });
        break;
      default:
        // No sort, keep previous sort
        break;
    }
  }

  return sortedTeamMemberIds;
};

export const filterTeamMembersIds = (
  filters = {
    selectedId: undefined,
    selectedEmail: undefined,
    selectedCountry: undefined,
    selectedRegion: undefined,
    selectedAdminPermissionId: undefined,
    selectedPhysicianPermissionId: undefined,
    selectedTeamMembersManagesAvailability:undefined,
  },
  teamMembersIds = [],
  teamMembersById = {},
) => {
  const {
    selectedId,
    selectedEmail,
    selectedCountry,
    selectedRegion,
    selectedAdminPermissionId,
    selectedPhysicianPermissionId,
    selectedTeamMembersManagesAvailability
  } = filters;
  let filteredTeamMembersIds = teamMembersIds;

  if (teamMembersIds) {
    if (selectedId) {
      filteredTeamMembersIds = filteredTeamMembersIds.filter((id) => {
        const teamMember = teamMembersById[id];
        return teamMember.id === selectedId;
      });
    }

    if (selectedEmail) {
      filteredTeamMembersIds = filteredTeamMembersIds.filter((id) => {
        const teamMember = teamMembersById[id];
        const text = teamMember.email || '';
        return text.includes(selectedEmail);
      });
    }
    if (selectedCountry) {
      filteredTeamMembersIds = filteredTeamMembersIds.filter((id) => {
        const teamMember = teamMembersById[id];
        const text = teamMember.country || '';
        return text.toLowerCase().includes(selectedCountry?.value?.toLowerCase());
      });
    }
    if (selectedRegion) {
      filteredTeamMembersIds = filteredTeamMembersIds.filter((id) => {
        const teamMember = teamMembersById[id];
        const text = (teamMember.regions?.length && teamMember.regions[0]) || '';
        // const text = RegionList[regionCode]?.name || '';
        return text.toLowerCase().includes(selectedRegion?.value?.toLowerCase());
      });
    }

    if (selectedAdminPermissionId) {
      filteredTeamMembersIds = filteredTeamMembersIds.filter((id) => {
        const teamMember = teamMembersById[id];
        const { isAdmin } = teamMember.permissions;
        const selectedAdminPermission =
          getPermissionBooleanFromPermissionType(selectedAdminPermissionId);
        return isAdmin === selectedAdminPermission;
      });
    }
    if (selectedTeamMembersManagesAvailability) {
      filteredTeamMembersIds = filteredTeamMembersIds.filter((id) => {
        const teamMember = teamMembersById[id];
        const { canManageAvailability } = teamMember;
        const selectedAdminPermission =
          getPermissionBooleanFromPermissionType(selectedTeamMembersManagesAvailability);
        return canManageAvailability === selectedAdminPermission;
      });
    }

    if (selectedPhysicianPermissionId) {
      filteredTeamMembersIds = filteredTeamMembersIds.filter((id) => {
        const teamMember = teamMembersById[id];
        const { isPhysician } = teamMember.permissions;
        const selectedPhysicianPermission = getPermissionBooleanFromPermissionType(
          selectedPhysicianPermissionId,
        );
        return isPhysician === selectedPhysicianPermission;
      });
    }
  }

  return filteredTeamMembersIds;
};
