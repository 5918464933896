import React, { useState, useContext, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import styles from './chat.module.css';
import { Strophe } from 'strophe.js';
import { withStyles } from '@material-ui/core/styles';
// import { Chat } from '../../providers';
import ListItem from './ListItem';
import EcgLoader from 'components/Shared/EcgLoader';
import { Container } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ChatContext, getFullJid } from '@alivecor/strophe2.0';

export const TableCell = withStyles({
  root: {
    borderBottom: 'none',
    fontSize: 14,
    padding: 8,
  },
})(MuiTableCell);

const ChatMemberList = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [memberList, setMemberList] = useState([]);
  const { status, allMessages, unreadMsgCount, fetchAllChatHistory, setUnreadMsgCount } =
    useContext(ChatContext);
  const { profileId, chat, getChatUserAction, getLastMessageChatUser } = props || {};
  const { data, loading } = chat || {};

  useEffect(() => {
    if (status === Strophe.Status.CONNECTED) {
      getChatUserAction(profileId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, profileId]);

  useEffect(() => {
    if (data.length) {
      setMemberList(data);
      getLastMessageChatUser(fetchAllChatHistory);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <Container maxWidth="xl">
      {status !== 5 || loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <EcgLoader text={status !== 5 ? 'Connecting...' : 'Loading chats'} />
        </div>
      ) : (
        <>
          <TableContainer className={styles.tableContainer} component={Paper}>
            <Table className={styles.table} aria-label="simple table">
              <TableHead>
                <TableRow className={styles.tableRowHeading}>
                  <TableCell style={{ width: 300 }}>
                    <Typography
                      style={{ fontFamily: 'Work Sans', fontSize: '16px', fontWeight: 600 }}
                    >
                      {t('Member Name')}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      style={{ fontFamily: 'Work Sans', fontSize: '16px', fontWeight: 600 }}
                    >
                      {t('Message')}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={styles.tableBody}>
                {!!memberList.length
                  ? memberList.map((row, index) => (
                      <ListItem
                        onClick={() => {
                          unreadMsgCount[getFullJid(row.chatUserId)] &&
                            setUnreadMsgCount((d) => (d[getFullJid(row.chatUserId)] = 0));
                          history.push(`/chat-inbox/${row.chatUserId}`);
                        }}
                        key={index}
                        item={row}
                        allMessages={allMessages}
                        unreadMsgCount={unreadMsgCount}
                        chat={chat}
                      />
                    ))
                  : null}
              </TableBody>
            </Table>
            {!memberList.length && (
              <div className={styles.notFound}>
                <Typography variant="h6">{t('No members found')}</Typography>
              </div>
            )}
          </TableContainer>
        </>
      )}
    </Container>
  );
};
export default ChatMemberList;
