import React, { useState, useContext, useEffect, useCallback } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import styles from './chat.module.css';
import { Strophe } from 'strophe.js';
// import { Chat } from '../../providers';
import ListItem from './ListItem';
import AsyncChat from 'components/Chat/AsyncChat';
import EcgLoader from 'components/Shared/EcgLoader';
import { Container, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ChatContext } from '@alivecor/strophe2.0';
import { getFullJid } from 'utilities/Utilities';

const ChatInboxDetails = (props) => {
  const { t } = useTranslation();
  const { chatUserId } = props.match.params || {};
  const [memberList, setMemberList] = useState([]);
  const [memberName, setMemberName] = useState('');
  const { status, allMessages, unreadMsgCount, setUnreadMsgCount } = useContext(ChatContext);
  const { profileId, chat, getChatUserAction, getS3BucketData, upLoadFiles,uploadFileSuccess } = props || {};
  const { data, loading } = chat || {};
  const length = data?.length;

  useEffect(() => {
    if (status === Strophe.Status.CONNECTED && !length) {
      getChatUserAction(profileId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, profileId, length]);

  useEffect(() => {
    if (data.length) {
      setMemberList(data);
    } else {
      setMemberList([]);
    }
    setMemberName(getName(data, chatUserId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, chatUserId]);

  const getName = useCallback((data, chatUserId) => {
    const { name } = data.length && data.find((member) => member.chatUserId === chatUserId);
    // const name = data.length && data[0].name
    return name || chatUserId;
  }, []);

  return (
    <Container maxWidth="xl">
      {status !== 5 || loading ? (
        <EcgLoader text={status !== 5 ? 'Connecting...' : 'Loading chat'} />
      ) : (
        <>
          <Paper className={styles.tableContainer}>
            <Grid container className={styles.tableRowHeading}>
              <Grid item lg={3} md={4}>
                <Typography style={{ fontFamily: 'Work Sans', fontSize: '16px', fontWeight: 600 }}>
                  {t('Member')}
                </Typography>
              </Grid>
              <Grid item lg={9} md={8}>
                <Typography style={{ fontFamily: 'Work Sans', fontSize: '16px', fontWeight: 600 }}>
                  {memberName}
                </Typography>
              </Grid>
            </Grid>
            {memberList.length ? (
              <Grid container>
                <Grid item lg={3} md={4} className={styles.tableBody}>
                  {!!memberList.length
                    ? memberList.map((row, index) => (
                        <ListItem
                          onClick={() => {
                            unreadMsgCount[getFullJid(row.chatUserId)] &&
                              setUnreadMsgCount((d) => (d[getFullJid(row.chatUserId)] = 0));
                            props.history.push(`/chat-inbox/${row.chatUserId}`);
                          }}
                          key={index}
                          item={row}
                          selected={row.chatUserId === chatUserId}
                          allMessages={allMessages}
                          unreadMsgCount={unreadMsgCount}
                          chat={chat}
                        />
                      ))
                    : null}
                </Grid>
                <Grid item lg={9} md={8} style={{ borderLeft: '1px solid #e9e9e9' }}>
                  {chatUserId && (
                    <AsyncChat
                      memberName={memberName}
                      chatUserId={chatUserId}
                      getS3BucketData={getS3BucketData}
                      upLoadFiles={upLoadFiles}
                      uploadFileSuccess={uploadFileSuccess}
                    />
                  )}
                </Grid>
              </Grid>
            ) : (
              <div className={styles.notFound}>
                <Typography variant="h6">{t('No members found')}</Typography>
              </div>
            )}
          </Paper>
        </>
      )}
    </Container>
  );
};
export default ChatInboxDetails;
