/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'assets/styles/bootstrap.scss';
import swal from 'sweetalert';
import { Popup } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import 'assets/styles/PopOver.scss';
import * as actions from 'store/actions';
import moment from 'moment';
import AvailabilityForm from '../../Appointments/Calendar/AvailabilityForm';
import Details from '../../Appointments/Calendar/Details';
import Doctor_Details from '../../Appointments/Calendar/DoctorDetails';
import Modal from '../../Appointments/Calendar/AppointmentDetailsModal';
import CachedSharpIcon from '@material-ui/icons/CachedSharp';
import * as Constants from 'utilities/Constants';
import ReAssignAppointment from '../../Dashboard/ReAssignAppointment';
import * as API from '../../../Services/API/actions';
import { withTranslation } from 'react-i18next';
import { getTimezoneName, toPascalCase } from 'utilities/Utilities';
import Authorize from '../../Authorize';
import DeleteIcon from '@material-ui/icons/Delete';
import PersonIcon from '@material-ui/icons/Person';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import './popover.css';
import { AUTH_TYPE } from 'constants/app';

const pattern = 'YYYY-MM-DDTHH:mm:ss.SSS';

class PopOver extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      alert: null,
      isModalOpen: false,
      isappointmentDetailModalOpen: false,
      isoverlayModalClosetime: 1000,
      startDate: this.props.event.isSplit
        ? this.props.event.splitStartDateTime
        : this.props.event.start,
      endDate: this.props.event.isSplit ? this.props.event.splitEndDateTime : this.props.event.end,
      startTime: this.props.event.isSplit
        ? this.props.event.splitStartDateTime
        : this.props.event.start,
      endTime: this.props.event.isSplit ? this.props.event.splitEndDateTime : this.props.event.end,
      seriesConfirm: null,
      reassignmodalOpen: false,
      showpopover: false,
      consultationNote: null,
      tabvalue: 0,
    };
    this.baseState = this.state;
  }
  componentWillUnmount() {
    this.setState({
      isModalOpen: false,
      isappointmentDetailModalOpen: false,
    });
  }

  getAppointmentCount = () => {
    const appointmentCount = this.props.events.reduce((prev, event) => {
      var startDate = moment(event.start, 'DD/MM/YYYY');
      var endDate = moment(event.end, 'DD/MM/YYYY');
      var currentDate = moment(this.props.event.start, 'DD/MM/YYYY');
      if (
        this.props.event.custom !== 'patient' &&
        event.custom === 'patient' &&
        currentDate.isBetween(startDate, endDate, 'days', true)
      ) {
        return prev + 1;
      } else {
        return prev;
      }
    }, 0);
    return appointmentCount;
  };

  isAnyActiveSession = (events) => {
    return events.filter(
      (item) =>
        item.eventType === 'appointment' &&
        (item.status === 1 || item.status === 3 || item.status === 12),
    );
  };
  deleteHandler = () => {
    document.body.click();
    let isAnyActiveSession =
      this.props.event.custom === 'patient' ? 0 : this.isAnyActiveSession(this.props.events);
    // let appointmentCount = this.props.event.custom === 'patient' ? 0 : this.getAppointmentCount();
    let cancelText = this.props.event.custom === 'patient' ? 'DO NOT CANCEL' : 'DO NOT DELETE';
    let confirmText = this.props.event.custom === 'patient' ? 'YES, CANCEL' : 'YES, DELETE';
    let message =
      this.props.event.custom === 'patient'
        ? `Are you sure you want to cancel the session ? This will prompt your member to reschedule via the app.`
        : 'Are you sure you want to delete the Availability?';

    const getDeleteAvailabilityAlert = () => {
      this.hideAlert();
      swal({
        closeOnClickOutside: false,
        title: 'Delete Availability',
        text: `${isAnyActiveSession.length} active ${
          isAnyActiveSession.length > 1 ? 'sessions' : 'session'
        } already booked during your availability. Please cancel the sessions to delete availability.`,
        className: 'telekardia',
        buttons: {
          confirm: {
            text: 'OKAY',
            visible: true,
            className: 'swal_ok swal_green',
            closeModal: true,
          },
        },
      }).then(() => {
        this.setShowfunction();
      });
    };

    const getAlerts = () =>
      swal({
        closeOnClickOutside: false,
        title: 'Recurring Availability',
        text: 'This is one availability in a series. What do you want to delete?',
        className: 'telekardia',
        buttons: {
          close: {
            text: '',
            visible: true,
            value: 'close',
            className: 'swal_black swal_close',
            closeModal: true,
          },
          Single: {
            text: 'THIS EVENT',
            visible: true,
            value: 'single',
            className: 'swal_ok',
            closeModal: true,
          },
          Multi: {
            text: 'THIS EVENT AND ALL FUTURE EVENTS',
            visible: true,
            value: 'multi',
            className: 'swal_ok',
            closeModal: true,
          },
        },
      }).then((value) => {
        if (value === 'single' || value === 'multi') {
          swal({
            closeOnClickOutside: false,
            text: message,
            className: 'telekardia',
            buttons: {
              cancel: {
                text: '',
                visible: true,
                className: 'swal_close swal_black',
                closeModal: true,
              },
              confirm: {
                text: 'Submit',
                visible: true,
                value: 'delete',
                className: 'swal_ok',
                closeModal: true,
              },
            },
          }).then((confirm) => {
            if (confirm === 'delete') this.deleteEvent(value);
          });
        }
      });
    const getAlert = () => {
      swal({
        closeOnClickOutside: false,
        title: this.props.event.custom === 'patient' ? `Cancel Session` : 'Delete Availability',
        text: message,
        className: 'telekardia',
        buttons: {
          cancel: {
            text: cancelText,
            visible: true,
            className: 'swal_cancel swal_black',
            closeModal: true,
          },
          confirm: {
            text: confirmText,
            visible: true,
            className: 'swal_ok swal_green',
            closeModal: true,
          },
        },
      }).then((willDelete) => {
        if (willDelete) this.deleteEvent();
        else this.setShowfunction();
      });
    };
    this.setState({
      alert:
        isAnyActiveSession.length > 0
          ? getDeleteAvailabilityAlert()
          : this.props.event.isRecurring
          ? getAlerts()
          : getAlert(),
    });
  };
  deleteEvent = (prps) => {
    this.setState({
      alert: null,
    });
    let queryParams = {
      endDate: moment(this.props.eRange).format(pattern),
      startDate: moment(this.props.sRange).format(pattern),
      offsetInMinutes: new Date().getTimezoneOffset(),
      timeZone: getTimezoneName(),
      splitBasedOnDays: true,
    };
    let deleteparams = {
      version: this.props.event.version,
    };
    let appointmentqueryParams = {
      reason: 'string',
    };
    let params = {
      timeZoneOffset: new Date().getTimezoneOffset(),
      timeZone: getTimezoneName(),
      startTime: moment(this.props.event.instanceDateTime).format('HH:mm'),
      instanceDate: moment(this.props.event.instanceDateTime).format('YYYY-MM-DD'),
      version: this.props.event.version,
    };
    let url = this.props.event.hasOwnProperty('custom')
      ? API.physicianCalendardeleteappointment(
          this.props.teamId,
          this.props.profileId,
          this.props.event.id,
          deleteparams,
        )
      : prps === 'single'
      ? API.deleteAvailabilityRecursive(
          this.props.teamId,
          this.props.profileId,
          this.props.event.id,
          params,
        )
      : API.deleteAvailabilityNonRecursive(
          this.props.teamId,
          this.props.profileId,
          this.props.event.id,
          deleteparams,
        );
    url.subscribe((res) => {
      if ('detailed_error' in res.data) {
        this.props.onSubmit({
          type: this.props.event.hasOwnProperty('custom') ? 'appointment' : 'delete',
          error: res.data['detailed_error'],
        });
      } else {
        this.props.onSubmit({
          type: this.props.event.hasOwnProperty('custom') ? 'appointment' : 'delete',
        });
      }
    }),
      (error) => {
        this.props.onSubmit({
          type: 'delete',
          error:
            'detailed_error' in error.response
              ? error.response['detailed_error']
              : 'Unable to process this request',
        });
      };
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
  modalOpenHandler = () => {
    document.body.click();
    this.setState({
      isModalOpen: true,
    });
  };
  modalCloseHandler = () => {
    // this.setState({ isModalOpen: false });
    this.setState(this.baseState);
  };
  appointmentDetailmodalOpenHandler = () => {
    document.body.click();

    this.setState({
      isappointmentDetailModalOpen: true,
    });
    //this.getPatientDetails()
  };
  appointmentDetailmodalCloseHandler = () => {
    this.setState({
      isappointmentDetailModalOpen: false,
    });
  };
  startDate_handleChange = (date) => {
    this.setState({
      startDate: date,
    });
  };
  endDate_handleChange = (date) => {
    this.setState({
      endDate: date,
    });
  };
  startTime_handleChange = (date) => {
    this.setState({
      startTime: date,
    });
  };
  endTime_handleChange = (date) => {
    this.setState({
      endTime: date,
    });
  };
  submitHandler = (prps) => {
    this.setState({ isModalOpen: false });
    let queryParams = {
      endDate: moment(this.props.eRange).format(pattern),
      startDate: moment(this.props.sRange).format(pattern),
      offsetInMinutes: new Date().getTimezoneOffset(),
      timeZone: getTimezoneName(),
      splitBasedOnDays: true,
    };

    this.props.onSubmit(prps);
    this.props.onFetchEvents(this.props.profileId, this.props.teamId, queryParams);
  };
  editConfirmationHandler = () => {
    const getAlert = () =>
      swal({
        closeOnClickOutside: false,
        title: 'Recurring Availability',
        text: 'This is one availability in a series. What do you want to edit?',
        className: 'telekardia',
        buttons: {
          Close: {
            text: '',
            visible: true,
            value: 'close',
            className: 'swal_black swal_close',
            closeModal: true,
          },
          Single: {
            text: 'THIS EVENT',
            visible: true,
            value: 'single',
            className: 'swal_ok',
            closeModal: true,
          },
          Multi: {
            text: 'THIS EVENT AND ALL FUTURE EVENTS',
            visible: true,
            value: 'multi',
            className: 'swal_ok',
            closeModal: true,
          },
        },
      }).then((value) => {
        if (value === 'multi') {
          this.setState({
            startDate: this.props.event.RecurrsiveStart,
            startTime: this.props.event.RecurrsiveStart,
            endDate: this.props.event.RecurrsiveEnd,
            endTime: this.props.event.RecurrsiveEnd,
            seriesConfirm: value,
          });
          this.modalOpenHandler();
        } else if (value === 'single') {
          this.setState({
            startDate: this.props.event.isSplit ? this.state.startDate : this.props.event.start,
            startTime: this.props.event.isSplit ? this.state.startTime : this.props.event.start,
            endDate: this.props.event.isSplit ? this.state.endDate : this.props.event.end,
            endTime: this.props.event.isSplit ? this.state.endTime : this.props.event.end,
            seriesConfirm: value,
          });
          this.modalOpenHandler();
        }
      });
    this.setState({ alert: getAlert() });
  };
  convertMStoHM = (ms) => {
    let h, m;
    h = Math.floor(ms / 1000 / 60 / 60);
    m = Math.floor((ms / 1000 / 60 / 60 - h) * 60);
    let duration = '';
    if (h) duration += `${h}`;
    if (h && m) duration += '.';
    if (m) duration += `${m}`;
    return duration;
    return h + '.' + m + '     '; //" mins ";
  };
  convertMtoHM = (ms) => {
    ms = ms * 60;
    let h, m;
    h = Math.floor(ms / 60);
    m = Math.floor(ms % 60);
    let duration = '';
    if (h) duration += `${h} Hours`;
    if (h && m) duration += ', ';
    if (m) duration += `${m} Minutes`;
    return duration;
    // return h + '.' + m + 'Hours'; //" mins ";
  };
  reassignmodalOpenHandler = () => {
    this.setState({
      reassignmodalOpen: true,
    });
  };
  reassignmodalCloseHandler = () => {
    this.setState({
      reassignmodalOpen: false,
    });
  };
  getEvents = () => {
    onFetchEvents();
  };
  getPatientRecordingDetails = (event) => {
    if (event.eventType === 'appointment' && event.id) {
      this.props.getRecordingSamples(
        event.ekgId,
        this.props.teamId,
        this.props.profileId,
        event.id,
        { timeZoneOffset: new Date().getTimezoneOffset(), timeZone: getTimezoneName() },
      );
    }
  };

  setShowfunction = () => {
    this.setState({
      showpopover: !this.state.showpopover,
    });
  };

  saveConsultationNoteToAPI = (note) => {
    API.setAppointmentNotes(
      this.props.teamId,
      this.props.profileId,
      this.props.event.id,
      note,
      // decodeURI(note),
    ).subscribe(
      (res) => {
        console.log('res');
      },
      (error) => {
        console.log('error');
      },
    );
  };
  setConsultationNote = (note) => {
    this.setState({
      consultationNote: note,
    });
    this.saveConsultationNoteToAPI(note);
  };
  changetabvalue = (event, newValue) => {
    this.setState({
      tabvalue: newValue,
    });
  };
  createContextFromEvent(e) {
    const left = e.clientX;
    const top = e.clientY;
    const right = left + 1;
    const bottom = top + 1;

    return {
      getBoundingClientRect: () => ({
        left,
        top,
        right,
        bottom,

        height: 0,
        width: 0,
      }),
    };
  }
  render() {
    const { t } = this.props;
    let current_time = moment().format(pattern);
    let event_time = moment(this.props.event.start).format(pattern);
    let event_endtime = moment(this.props.event.end).format(pattern);
    let status = 'badge br_rad px-2';
    if (this.props.event.status === 1 || this.props.event.status === 6) {
      status = 'badge br_rad px-2 status_green_scheduled';
    } else if (this.props.event.status === 4) {
      status = 'badge br_rad px-2 status_green_completed';
    } else if (this.props.event.status === 3) {
      status = 'badge br_rad px-2 status_inprogress_dotted';
    } else if (
      this.props.event.status === 5 ||
      this.props.event.status === 8 ||
      this.props.event.statusName === 'Declined'
    ) {
      status = 'badge br_rad px-2 status_red_canceled';
    } else if (this.props.event.status === 10) {
      status = 'badge br_rad px-2 status_orange_closed';
    } else if (this.props.event.status === 7) {
      status = 'badge br_rad px-2 status_gray_reportgenerated';
    } else if (this.props.event.status === 2) {
      status = 'badge br_rad px-2 status_gray_doctorreviewstarted';
    }
    let popoverClickRootClose = (
      <div className="telekardia">
        {this.props.event.hasOwnProperty('custom') ? (
          <>
            <div className="row align-center justify-content-between">
              <label
                className="col-10 col-form-label p-0 f10  font-weight-bold"
                style={{ color: 'rgb(136, 95, 133)' }}
              >
                {`${t(`SESSION + DETAILS`)} (${moment(this.state.startTime).format(
                  'hh:mm A',
                )} - ${moment(this.state.endTime).format('hh:mm A')})`}
              </label>
              <IconButton
                aria-label="close"
                className={'p-0'}
                style={{ color: '#000', background: 'transparent' }}
                onClick={() => {
                  document.body.click();
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className="row my-2">
              <div className="col-12 p-0">
                <a
                  onClick={this.appointmentDetailmodalOpenHandler}
                  style={{ textDecoration: 'underline' }}
                >
                  <h5 className="name_caption">
                    {this.props.event.patient.lastName
                      ? this.props.event.patient.lastName + ' ' + this.props.event.patient.firstName
                      : ' -,- '}
                  </h5>
                </a>
                {/* {this.props.userType === AUTH_TYPE.COACH ? (
                  <></>
                ) : (
                  <h5 className="name_caption">
                    {this.props.event.patient.lastName
                      ? this.props.event.patient.lastName + ' ' + this.props.event.patient.firstName
                      : ' -,- '}
                  </h5>
                )} */}
              </div>
            </div>

            <div className="row">
              <label className="col-12 col-form-label p-0">
                <b>{t('Age')} : </b>{' '}
                {this.props.event.patient.dob
                  ? new Date().getFullYear() -
                    new Date(this.props.event.patient.dob).getFullYear() +
                    ' years'
                  : '-'}
              </label>
            </div>
            <div className="row">
              <label className="col-12 col-form-label p-0">
                <b>{t('Gender')} : </b> {this.props.event.patient.gender}
              </label>
            </div>
            <div className="row">
              <label className="col-12 col-form-label p-0">
                <b>{t('Program')} : </b>
                {Constants.programMap[this.props.event?.patient?.program] || ''}
                {/* {this.props.event.visitReason &&
                  this.props.event.visitReason.length > 0
                  ? this.props.event.visitReason
                  : "-"} */}
              </label>
            </div>
            <div className="row">
              <label className="col-12 col-form-label p-0">
                <b>{t('Follow up')} : </b>
                {this.props.event.type == 2 ? 'True' : 'False'}
              </label>
            </div>
            <div className="row">
              <label className="col-12 col-form-label p-0">
                <b>Type of session : </b> {this.props.event.appointmentChannelType}
              </label>
            </div>
            <div className="row">
              <label className="col-12 col-form-label p-0">
                <b>Session : </b>
                {'0/0'}
              </label>
            </div>
            <div className="row">
              <label className="col-12 col-form-label p-0">
                <b>{t('Company')} : </b>
                {this.props.event?.patient?.company || ''}
              </label>
            </div>
            {/* <div className="row">
              <label className="col-12 col-form-label p-0">
                <b>{t('Status')} : </b>{' '}
                <span className={status}>{this.props.event.statusName}</span>
              </label>
            </div> */}
            <div className="row mt-4">
              {[
                // Constants.AppointmentStatusTypes.Scheduled,
                Constants.AppointmentStatusTypes.Physician_Review,
                Constants.AppointmentStatusTypes.InProgress,
                Constants.AppointmentStatusTypes.MemberInWaitingRoom,
                Constants.AppointmentStatusTypes.Encounter_Summary_Preparation,
                // Constants.AppointmentStatusTypes.Cancelled,
                // Constants.AppointmentStatusTypes.Rescheduled,
                // Constants.AppointmentStatusTypes.Encounter_Summary_Preparation,
                // Constants.AppointmentStatusTypes.Cancel_For_Reschedule,
              ].includes(this.props.event.status) ? (
                <div className="col-12 col-form-label mt-4 text-center">
                  <button
                    onClick={() => {
                      this.getPatientRecordingDetails(this.props.event);
                      this.props.handleStartConsultation(this.props.event);
                    }}
                    className="btn btn-outline-primary text-uppercase h40 d-flex align-items-center"
                    style={{ width: '100%' }}
                  >
                    <PersonIcon className="mr-4" style={{ width: 60, flex: '0 1 60px' }} />
                    <span className="text-center" style={{ flex: 1 }}>
                      {this.props.event.status === Constants.AppointmentStatusTypes.InProgress ||
                      this.props.event.status ===
                        Constants.AppointmentStatusTypes.Encounter_Summary_Preparation
                        ? t(`Continue Session`)
                        : t(`Review And Start Session`)}
                    </span>
                  </button>
                </div>
              ) : null}
              {[
                Constants.AppointmentStatusTypes.Scheduled,
                Constants.AppointmentStatusTypes.Rescheduled,
              ].includes(this.props.event.status) ? (
                <div className="col-12 col-form-label text-center">
                  <button
                    onClick={() => this.deleteHandler()}
                    className="btn btn-outline-primary text-uppercase h40 d-flex align-items-center"
                    style={{ width: '100%' }}
                  >
                    <DeleteIcon className="mr-4" style={{ width: 60, flex: '0 1 60px' }} />
                    <span className="text-center" style={{ flex: 1 }}>
                      {t(`Cancel Session`)}
                    </span>
                  </button>
                </div>
              ) : null}
              {/* {(current_time >= event_time &&
                current_time <= event_endtime &&
                this.props.event.status !== Constants.AppointmentStatusTypes.Completed &&
                this.props.event.status !== Constants.AppointmentStatusTypes.closed) ||
              (current_time < event_time &&
                this.props.event.status !== Constants.AppointmentStatusTypes.Completed &&
                this.props.event.status !== Constants.AppointmentStatusTypes.closed) ? (
                <div className="col-12 col-form-label text-center">
                  <button
                    type="button"
                    className="btn px-4 btn-outline-secondary text-uppercase h40 ml-3"
                    onClick={this.appointmentDetailmodalOpenHandler}
                  >
                    {t('View Details')}
                  </button>
                </div>
              ) : null} */}
            </div>
          </>
        ) : (
          // availability popover content
          <>
            <div className="row align-center justify-content-between mb-2">
              <div className="col-10 p-0">
                <h5 className="name_caption">{t('Availability')}</h5>
              </div>
              <IconButton
                aria-label="close"
                className={'p-0'}
                style={{ color: '#000', background: 'transparent' }}
                onClick={() => {
                  document.body.click();
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className="row">
              <label className="col-12 col-form-label p-0">
                <b>{this.props.event.isSplit ? 'Start Date' : 'Start Time'}: </b>{' '}
                {this.props.event.isSplit
                  ? moment(this.state.startDate).format('MM/DD/YYYY') +
                    ',' +
                    ' ' +
                    moment(this.state.startTime).format('hh:mm A')
                  : moment(this.props.event.start).format('LT')}
              </label>
            </div>
            <div className="row">
              <label className="col-12 col-form-label p-0">
                <b>{this.props.event.isSplit ? 'End Date' : 'End Time'} : </b>{' '}
                {this.props.event.isSplit
                  ? moment(this.state.endDate).format('MM/DD/YYYY') +
                    ',' +
                    ' ' +
                    moment(this.state.endTime).format('hh:mm A')
                  : moment(this.props.event.end).format('LT')}
              </label>
            </div>
            <div className="row">
              <label className="col-12 col-form-label p-0">
                <b>{t('Duration')} :</b>{' '}
                {this.convertMtoHM(
                  moment
                    .duration(moment(this.state.endDate).diff(moment(this.state.startDate)))
                    .asHours(),
                )}
              </label>
            </div>
            {(current_time >= event_time && current_time <= event_endtime) ||
            current_time < event_time ? (
              <Authorize>
                <div className="row">
                  <div className="col-12 col-form-label mt-4 ml-auto text-right">
                    <button
                      type="button"
                      className="btn px-4 btn-outline-primary text-uppercase h40"
                      onClick={() => this.deleteHandler()}
                      // disabled={
                      //   current_time >= event_time &&
                      //     current_time <= event_endtime
                      //     ? true
                      //     : false
                      // }
                    >
                      {t('Delete')}
                    </button>
                    <button
                      type="button"
                      className="btn px-4 btn_teal text-uppercase h40 ml-3"
                      onClick={() =>
                        this.props.event.isRecurring
                          ? this.editConfirmationHandler()
                          : this.modalOpenHandler()
                      }
                    >
                      {t('Edit')}
                    </button>
                  </div>
                </div>
              </Authorize>
            ) : null}
          </>
        )}
      </div>
    );
    let content = <div></div>;

    if (this.props.event.hasOwnProperty('custom')) {
      // appintment content display for Day view
      if (this.props.view === 'day') {
        content = (
          <div className={'popover_title occupy'}>
            <div className="d-flex flex-row m-2 l_15">
              <div className="text-truncate max_width250">
                {this.props.event.patient.lastName
                  ? this.props.event.patient.lastName + ' ' + this.props.event.patient.firstName
                  : ' -,- '}
              </div>
              <div className="pl-3 f600">
                {moment(this.props.event.start).format('hh:mm A') +
                  '-' +
                  moment(this.props.event.end).format('hh:mm A')}
              </div>
            </div>
          </div>
        );
      } else
        content = (
          <div className={'popover_title occupy'}>
            <div className="appt_content line16">
              <div className="text-truncate">
                {this.props.event.patient.lastName
                  ? this.props.event.patient.lastName + ' ' + this.props.event.patient.firstName
                  : ' -,- '}
              </div>
              <div className="time_content">{moment(this.props.event.start).format('hh:mm A')}</div>
            </div>
          </div>
        );
    } else if (this.props.event.isRecurring) {
      content = (
        <div className={'recurrance_icon popover_title ' + 'occupy ' + 'recurrsiveIcon'}>
          <CachedSharpIcon />
        </div>
      );
    } else {
      //
      if (this.props.view === 'month') {
        content = (
          <div className={'popover_title ' + 'occupy'}>
            <div className="monthview_content d-flex align-content-stretch">
              <div className="d-flex flex-column col availability p-0 p-1 py-2 monthView">
                <div style={{ fontSize: '14px' }}>{t('Availability')}</div>
                <div className="mt-auto">
                  <h5
                    className="m-0 count"
                    style={{
                      color: 'inherit',
                      whiteSpace: 'pre-wrap',
                      fontSize: '34px',
                      fontWeight: '500',
                    }}
                  >
                    {this.convertMStoHM(this.props.event.availableMinutes)}
                  </h5>
                  <div style={{ fontSize: '14px' }}>{t('Hours')}</div>
                </div>
              </div>
              {this.props.event.plannedApps > 0 && (
                <div className="appointment d-flex flex-column p-1 py-2 monthView">
                  <div style={{ fontSize: '14px' }}>{t('Consultations')}</div>
                  <div className="mt-auto">
                    <h5 className="m-0 count" style={{ color: 'inherit', fontSize: '34px' }}>
                      {this.props.event.plannedApps}
                    </h5>
                    <div style={{ fontSize: '14px' }}>{t('Booked')}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      } else content = <div className={'popover_title ' + 'occupy'}></div>;
    }
    let form = null;
    if (this.state.isModalOpen) {
      form = (
        <AvailabilityForm
          {...this.props}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          startTime={this.state.startTime}
          endTime={this.state.endTime}
          show={this.state.isModalOpen}
          disabled={this.props.event.isRecurring ? false : true}
          recurrsivepattern={this.props.event.RecurrencePattern}
          seriesConfirm={this.state.seriesConfirm}
          handleClose={this.modalCloseHandler}
          onSubmit={(e) => this.submitHandler(e)}
          title={
            this.state.seriesConfirm === 'multi'
              ? 'Edit Availability (Series)'
              : 'Edit Availability'
          }
          component="popover"
        ></AvailabilityForm>
      );
    }
    let viewDetailsModal = null;
    if (this.props.event.eventType === 'appointment' && this.state.isappointmentDetailModalOpen) {
      viewDetailsModal = (
        <Modal
          show={this.state.isappointmentDetailModalOpen}
          handleClose={this.appointmentDetailmodalCloseHandler}
          onSubmit={this.reassignmodalOpenHandler}
          component={'popover'}
          disable={new Date() > new Date(event_time) ? true : false}
        >
          {/* {this.props.loading?
        <div className={this.props.isLoading ? 'spinner-border' : null}></div> */}
          {this.props.userType === AUTH_TYPE.COACH ? (
            <Details
              {...this.props}
              ekgId={this.props.event.ekgId}
              appointmentId={this.props.event.id}
              component="popover"
              handleClose={this.appointmentDetailmodalCloseHandler}
              onSubmit={this.reassignmodalOpenHandler}
              setConsultationNote={this.setConsultationNote}
              consultationNote={this.state.consultationNote}
              tabvalue={this.state.tabvalue}
              changetabvalue={this.changetabvalue}
              disable={new Date() > new Date(event_time) ? true : false}
            />
          ) : (
            <Doctor_Details
              {...this.props}
              ekgId={this.props.event.ekgId}
              appointmentId={this.props.event.id}
              component="popover"
              handleClose={this.appointmentDetailmodalCloseHandler}
              onSubmit={this.reassignmodalOpenHandler}
              setConsultationNote={this.setConsultationNote}
              consultationNote={this.state.consultationNote}
              tabvalue={this.state.tabvalue}
              changetabvalue={this.changetabvalue}
              disable={new Date() > new Date(event_time) ? true : false}
            />
          )}
        </Modal>
      );
    }
    return (
      <React.Fragment>
        <Popup
          open={this.state.showpopover}
          onClose={this.setShowfunction}
          closeOnPortalMouseLeave={false}
          context={this.myRef}
          content={popoverClickRootClose}
          className={'popups'}
          trigger={
            <div
              className={'custom_popover ' + 'occupy'}
              ref={this.myRef}
              onClick={(e) => {
                e.preventDefault();
                this.myRef.current = this.createContextFromEvent(e);
              }}
            >
              <div className={'occupy' + ' custom_align'} onClick={this.setShowfunction}>
                {content}
              </div>
            </div>
          }
          disabled={this.props.view === 'month' ? true : false}
          flowing={true}
          on={['click']}
          hoverable={true}
          // positionFixed={true}
          hideOnScroll={true}
          style={{
            background: 'rgba(249, 249, 249, 0.78)',
            backdropFilter: 'blur(54.3656px)',
            borderRadius: '13px',
            padding: '1em 2em 2em',
            maxWidth: '400px',
            minWidth: '350px',
            marginTop: '-10px',
          }}
          size={'small'}
          position={'bottom left'}
        />
        {form}
        {viewDetailsModal}
        {this.state.reassignmodalOpen === true && (
          <ReAssignAppointment
            show={this.state.reassignmodalOpen}
            handleClose={this.reassignmodalCloseHandler}
            onCancel={this.deleteEvent}
            component="popover"
            onSubmit={this.submitHandler}
            patientDetails={this.props.event}
            {...this.props}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    events: state.events.events,
    profileId: state.user.profile.id,
    teamId: state.user.team.id,
    isLoading: state.events.loading,
    userType: state.user.chatProfile.userType,
    // sessionText: state.user.chatProfile.sessionText,
  };
};

export default connect(mapStateToProps)(withTranslation()(PopOver));
