import React from 'react';
import classes from './EKGPreview.scss';
import Grid from '../Grid';
import Waveform from '../Waveform';
import * as Lib from 'constants/RecordingConstants';

export const EKGPreview = (props) => {
  const {
    inverted,
    previewData: {
      // eslint-disable-next-line no-unused-vars
      frequency,
      samples: { leadI },
    },
    // eslint-disable-next-line no-unused-vars
    height: h,
  } = props;

  const seconds = 6;
  const width = seconds * Lib.PX_PER_SECOND;
  const height = props.height || 200;

  return (
    <div className={classes.eKGPreview}>
      <svg width={width} height={height} className={classes.previewGrid}>
        <g transform="translate(0,0)">
          <Grid renderSmallGrid={false} rowSeconds={seconds} stripWidthPx={width} height={height} />
          <Waveform height={height} samples={leadI} inverted={inverted} mainsFreq={60} />
        </g>
      </svg>
    </div>
  );
};

export default EKGPreview;
