import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { AUTH_TYPE, COACH_ROUTES, DOCTOR_ROUTES, ROUTES } from 'constants/app';
import withStartConsultation from '../Dashboard/withStartConsultation';

const ToastButton = ({
  checkStatus,
  patientDetails,
  patientDetailsLoader,
  id,
  alertHandler,
  buttonProps,
  reviewSession,
  memberInWaitingRoom,
  alertCloseFlag,
  hideToast,
  reviewProfile,
  waitingroomAlert,
}) => {
  const [clicked, setClicked] = useState(false);
  const userType = useSelector((state) => state?.user.chatProfile.userType);
  useEffect(() => {
    if (clicked && patientDetails && patientDetails.appointmentId && id && !patientDetailsLoader) {
      userType === AUTH_TYPE.COACH && alertHandler();
      userType === AUTH_TYPE.DOCTOR && waitingroomAlert();
      setClicked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clicked, patientDetails, patientDetailsLoader, id]);

  useEffect(() => {
    if (alertCloseFlag) {
      console.log('in toast alert... ');
      hideToast();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertCloseFlag]);

  const history = useHistory();
  const toastButtonClick = () => {
    if (memberInWaitingRoom) {
      setClicked(true);
    } else if (reviewSession) {
      history.push(
        AUTH_TYPE.COACH === userType
          ? `${COACH_ROUTES.MEMBER_PROFILE}/${patientDetails?.patient?.patientId}`
          : `${DOCTOR_ROUTES.TELEKARDIA_PATIENT_PROFILE}/${patientDetails?.patient?.patientIdpatientDetails?.appointmentId}`,
      );
    }
  };
  return (
    <Button
      onClick={toastButtonClick}
      style={{ marginLeft: '20px', color: '#fff', fontWeight: 'bold' }}
      {...buttonProps}
    >
      {memberInWaitingRoom ? 'JOIN CALL' : reviewSession ? 'REVIEW PROFILE' : 'REJOIN'}
    </Button>
  );
};

export default withStartConsultation(ToastButton);
