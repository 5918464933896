import { makeStyles } from '@material-ui/core/styles';
export const navStyles = makeStyles((theme) => ({
  customTabRoot: {
    color: '#142A39',
    '& .Mui-selected': {
      color: '#2D9F86',
    },
  },
  customTabIndicator: {
    backgroundColor: '#2d9f86',
    height: '4px',
  },
  tabRoot: {
    fontSize: '14px !important',
    minWidth: 166,
    fontWeight: '700',
    fontFamily: ['Work Sans'].join(','),
  },
}));

export const commonStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
    margin: '26px',
    '& .MuiTypography-root': {
      fontFamily: 'Work Sans',
    },
  },
  divWrapper: {
    position: 'relative',
    background: '#FFFFFF',
    boxShadow:
      '0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1)',
    marginTop: '24px',
    marginBottom: '24px',
    // padding: '16px',
    paddingBottom: '16px',
    '& .MuiGrid-item': {
      // marginTop: '32px',
      // marginBottom: '16px',
      padding: '20px 10px',
    },
    '& .MuiTypography-body1': {
      fontWeight: 600,
    },
    '& .MuiTypography-h6': {
      fontWeight: 600,
    },
  },
  divContentWrapper: {
    // paddingLeft: '16px',
    // paddingRight: '16px',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: '100%',
    width: '100%',
    // opacity: 0,
    borderRadius: '16px',
    transition: '.5s ease',
    backgroundColor: '#f5f5f5',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',
    fontFamily: 'Work Sans',
    letterSpacing: '.12em',
    fontSize: '1.2rem',
    fontWeight: 700,
    opacity: 0.7,
    '&:hover': {
      opacity: 0,
    },
  },
}));
