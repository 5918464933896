import React from 'react';
import styles from './PageTab.module.css';

const PageTab = (props) => {
  const { dataAction, getLabel, label, isActive, handleOnClick } = props;

  const cn = isActive ? styles.activePageTab : styles.pageTab;
  let btnLabel = label;
  if (getLabel) {
    btnLabel = getLabel();
  }

  let dataActionValue = dataAction;
  if (!dataActionValue && typeof btnLabel === 'string') {
    dataActionValue = btnLabel;
  } else if (!dataAction) {
    dataActionValue = 'select';
  }
  dataActionValue = dataActionValue.replace(/\s/g, '-');

  return (
    <button
      className={cn}
      onClick={handleOnClick}
      style={{ borderBottom: isActive ? '4px solid #067F6F' : 'none' }}
      data-action="tab-select"
      data-label={dataActionValue}
    >
      {btnLabel.toUpperCase()}
    </button>
  );
};

export default PageTab;
