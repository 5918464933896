/* eslint-disable */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Input, TimePicker } from 'antd';
import { AUTH_TYPE, DATASOURCES } from 'constants/app';
import { getDataSource } from 'store/reducers/app';
import EcgLoader from '../Shared/EcgLoader';
import styles from './Forms.module.css';
import Loading from '../Loading';
import {
  handleAppointmentNotifications,
  fetchAppointmentNotifications,
  fetchBusinessHours,
  postBusinessHours,
} from '../TeamSettings/TeamSettingActions';
import 'antd/dist/antd.css';
import { getTimezoneName } from 'utilities/Utilities';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
  },
  button: {
    textTransform: 'none',
  },
}));

const CustomSwitch = withStyles({
  switchBase: {
    // color: '#2D9F86',
    '&$checked': {
      color: '#2D9F86',
    },
    '&$checked + $track': {
      backgroundColor: '#2D9F86',
    },
  },
  checked: {},
  track: {},
})(Switch);

const CustomCheckbox = withStyles({
  root: {
    color: '#2D9F86',
    '&$checked': {
      color: '#2D9F86',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

// get the business hours object from the response
const setBusinessHourInitState = (data) => {
  const tempData = [];
  Object.keys(data).forEach((key) => {
    const obj = data[key];
    console.log(obj);
    let tempObject = {};
    tempObject = Object.assign(
      {},
      {
        selectTime: {
          businessHourStart: new Date(),
          businessHourEnd: new Date(),
          timeZone: obj[0].timeZone,
          timeZoneId: obj[0].timeZoneId,
        },
        selectDays: [
          { day: 'sunday', selectable: false, checked: false },
          { day: 'monday', selectable: false, checked: false },
          { day: 'tuesday', selectable: false, checked: false },
          { day: 'wednesday', selectable: false, checked: false },
          { day: 'thursday', selectable: false, checked: false },
          { day: 'friday', selectable: false, checked: false },
          { day: 'saturday', selectable: false, checked: false },
        ],
        ids: [],
        id: [],
      },
    );
    obj.forEach((item) => {
      const bhs = item.businessHourStart;
      const bhe = item.businessHourEnd;
      tempObject.selectTime.businessHourStart = bhs;
      tempObject.selectTime.businessHourEnd = bhe;
      tempObject.selectDays[item.dayOfWeek] = getSelectedDay(
        tempObject.selectDays[item.dayOfWeek],
        item.dayOfWeek,
      );
      // tempObject.id.push(item.id)
      tempObject.ids.push([{ day: item.dayOfWeek, id: item.id }]);
    });
    tempData.push(tempObject);
  });

  // For enabling bthe selected days
  tempData.forEach((item) => {
    item.selectDays.map((d) => {
      if (d.checked === true) {
        d.selectable = true;
      }
    });
  });

  //For disabling/enabling days initially
  for (let i = 0; i < 7; i++) {
    let set = true;
    tempData.forEach((item) => {
      if (item.selectDays[i].checked === true) set = false;
    });
    set ? tempData.forEach((item) => (item.selectDays[i].selectable = true)) : '';
  }
  return tempData;
};

// Function to check or disable days according to fetched data from API
const getSelectedDay = (data, dayOfWeek) => {
  let retData = data;
  switch (dayOfWeek) {
    case 0:
      retData.selectable = true;
      retData.checked = true;
      break;
    case 1:
      retData.selectable = true;
      retData.checked = true;
      break;
    case 2:
      retData.selectable = true;
      retData.checked = true;
      break;
    case 3:
      retData.selectable = true;
      retData.checked = true;
      break;
    case 4:
      retData.selectable = true;
      retData.checked = true;
      break;
    case 5:
      retData.selectable = true;
      retData.checked = true;
      break;
    case 6:
      retData.selectable = true;
      retData.checked = true;
      break;
    default:
      break;
  }
  return retData;
};

const TeamSettingsForm = (props) => {
  const classes = useStyles();
  const teamSettingsData = useSelector(
    (state) =>
      state.teamSettings.appointmentNotifications.appointmentInfo[0] &&
      state.teamSettings.appointmentNotifications.appointmentInfo[0].result,
  );
  const businessHoursData = useSelector(
    (state) =>
      state.teamSettings.appointmentNotifications.businessHours[0] &&
      state.teamSettings.appointmentNotifications.businessHours[0].result,
  );
  // Appointment Notification
  const [appointmentNotificationState, setAppointmentNotificationState] = useState({
    adminInDND: teamSettingsData ? teamSettingsData.adminInDND : false,
    adminDNDTimePeriodInHrs: teamSettingsData ? teamSettingsData.adminDNDTimePeriodInHrs : 0,
    providerInDND: teamSettingsData ? teamSettingsData.providerInDND : false,
    providerDNDTimePeriodInHrs: teamSettingsData ? teamSettingsData.providerDNDTimePeriodInHrs : 0,
    providerFirstReminderEnabled: teamSettingsData
      ? teamSettingsData.providerFirstReminderEnabled
      : false,
    providerFirstReminderInMins: teamSettingsData
      ? teamSettingsData.providerFirstReminderInMins
      : 0,
    providerSecondReminderEnabled: teamSettingsData
      ? teamSettingsData.providerSecondReminderEnabled
      : false,
    providerSecondReminderInMins: teamSettingsData
      ? teamSettingsData.providerSecondReminderInMins
      : 0,
    teamId: props.me.data.teams[0].id,
  });

  // business hours state
  const [businessHourStateI, setBusinessHourStateI] = useState([
    {
      selectTime: {
        businessHourStart: new Date(),
        businessHourEnd: new Date(),
      },
      selectDays: [
        { day: 'sunday', selectable: true, checked: false },
        { day: 'monday', selectable: true, checked: false },
        { day: 'tuesday', selectable: true, checked: false },
        { day: 'wednesday', selectable: true, checked: false },
        { day: 'thursday', selectable: true, checked: false },
        { day: 'friday', selectable: true, checked: false },
        { day: 'saturday', selectable: true, checked: false },
      ],
    },
  ]);

  // For disabling the save button if no value is changed
  const [disableSave, setDisableSave] = useState(true);

  // Start/End Time change handlers
  const handleStartTimeChange = (index, e) => {
    // console.log(moment(e._d).tz(tzz).format('HH:mm a z'));
    let tempArr = businessHourStateI.slice();
    tempArr[index].selectTime.businessHourStart = moment(e && e._d).format('HH:mm a');
    setBusinessHourStateI(tempArr);
    setDisableSave(false);
  };
  const handleEndTimeChange = (index, e) => {
    let tempArr = businessHourStateI.slice();
    tempArr[index].selectTime.businessHourEnd = moment(e && e._d).format('HH:mm a');
    setBusinessHourStateI(tempArr);
    setDisableSave(false);
  };

  const [day, setDay] = useState({
    sunday: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
  });
  const [timeZone, setTimeZone] = useState(getTimezoneName());
  useEffect(() => {
    if (businessHourStateI.length > 0) {
      setTimeZone(businessHourStateI[0].selectTime.timeZoneId);
    }
  }, [businessHourStateI]);
  // get all the fetch API data
  useEffect(() => {
    return props.fetchAppointmentNotifications(), props.fetchBusinessHours();
  }, []);

  const handleSelectDay = (e, item, indx) => {
    const checked = e.target.checked;
    // check section
    let tempState = businessHourStateI.slice();
    tempState.forEach((d, idx) => {
      // indx - chose section index
      if (indx !== idx) {
        switch (e.target.name) {
          case 'sunday':
            d.selectDays[0].selectable = !checked;
            break;
          case 'monday':
            d.selectDays[1].selectable = !checked;
            break;
          case 'tuesday':
            d.selectDays[2].selectable = !checked;
            break;
          case 'wednesday':
            d.selectDays[3].selectable = !checked;
            break;
          case 'thursday':
            d.selectDays[4].selectable = !checked;
            break;
          case 'friday':
            d.selectDays[5].selectable = !checked;
            break;
          case 'saturday':
            d.selectDays[6].selectable = !checked;
            break;
          default:
            break;
        }
      } else {
        switch (e.target.name) {
          case 'sunday':
            d.selectDays[0].selectable = true;
            d.selectDays[0].checked = e.target.checked;
            break;
          case 'monday':
            d.selectDays[1].selectable = true;
            d.selectDays[1].checked = e.target.checked;
            break;
          case 'tuesday':
            d.selectDays[2].selectable = true;
            d.selectDays[2].checked = e.target.checked;
            break;
          case 'wednesday':
            d.selectDays[3].selectable = true;
            d.selectDays[3].checked = e.target.checked;
            break;
          case 'thursday':
            d.selectDays[4].selectable = true;
            d.selectDays[4].checked = e.target.checked;
            break;
          case 'friday':
            d.selectDays[5].selectable = true;
            d.selectDays[5].checked = e.target.checked;
            break;
          case 'saturday':
            d.selectDays[6].selectable = true;
            d.selectDays[6].checked = e.target.checked;
            break;
          default:
            break;
        }
      }
    });
    setBusinessHourStateI(tempState);
    setDisableSave(false);
  };

  // remove the added Business hours section
  const handleRemoveSection = (indx) => {
    let temp = businessHourStateI[indx].selectDays;
    let resetValues = { 0: false, 1: false, 2: false, 3: false, 4: false, 5: false, 6: false };
    temp.map((t, i) => {
      if (t.checked) {
        resetValues[i] = true;
      }
    });
    let tempState = businessHourStateI.slice();

    tempState.splice(indx, 1);
    tempState.map((t, i) => {
      t.selectDays.map((s, j) => {
        if (resetValues[j]) {
          s.checked = false;
          s.selectable = true;
        }
      });
    });
    setBusinessHourStateI(tempState);
    setDisableSave(false);
  };

  // Function for adding a new business hour section
  const addNewHours = (indx) => {
    const INDEX = indx === null ? 0 : indx + 1;
    let tempState = businessHourStateI.slice();
    // check if particular day is already selected ====
    let sunday = tempState.map((d) => {
      return d.selectDays[0].checked ? true : false;
    });
    sunday = sunday.find((d) => {
      return d ? true : false;
    });
    let monday = tempState.map((d) => {
      return d.selectDays[1].checked ? true : false;
    });
    monday = monday.find((d) => {
      return d ? true : false;
    });
    let tuesday = tempState.map((d) => {
      if (d.selectDays[2].checked === true) {
        return true;
      } else return false;
    });
    tuesday = tuesday.find((d) => {
      if (d === true) {
        return true;
      } else return false;
    });
    let wednesday = tempState.map((d) => {
      if (d.selectDays[3].checked === true) {
        return true;
      } else return false;
    });
    wednesday = wednesday.find((d) => {
      if (d === true) {
        return true;
      } else return false;
    });
    let thursday = tempState.map((d) => {
      if (d.selectDays[4].checked === true) {
        return true;
      } else return false;
    });
    thursday = thursday.find((d) => {
      if (d === true) {
        return true;
      } else return false;
    });
    let friday = tempState.map((d) => {
      if (d.selectDays[5].checked === true) {
        return true;
      } else return false;
    });
    friday = friday.find((d) => {
      if (d === true) {
        return true;
      } else return false;
    });
    let saturday = tempState.map((d) => {
      if (d.selectDays[6].checked === true) {
        return true;
      } else return false;
    });
    saturday = saturday.find((d) => {
      if (d === true) {
        return true;
      } else return false;
    });

    // add the default object to the array
    tempState[INDEX] = Object.assign(
      {},
      {
        selectTime: {
          businessHourStart: new Date(),
          businessHourEnd: new Date(),
        },
        selectDays: [
          { day: 'sunday', selectable: true, checked: true },
          { day: 'monday', selectable: true, checked: false },
          { day: 'tuesday', selectable: true, checked: true },
          { day: 'wednesday', selectable: true, checked: false },
          { day: 'thursday', selectable: true, checked: false },
          { day: 'friday', selectable: true, checked: false },
          { day: 'saturday', selectable: true, checked: false },
        ],
      },
    );
    // check days is not undefined
    // if specific day is undefined then not selected in other
    tempState[INDEX].selectDays.map((d) => {
      // console.log('d', d.day)
      switch (d.day) {
        case 'sunday':
          d.selectable = sunday === undefined ? true : false;
          d.checked = false;
          break;
        case 'monday':
          d.selectable = monday === undefined ? true : false;
          d.checked = false;
          break;
        case 'tuesday':
          d.selectable = tuesday === undefined ? true : false;
          d.checked = false;
          break;
        case 'wednesday':
          d.selectable = wednesday === undefined ? true : false;
          d.checked = false;
          break;
        case 'thursday':
          d.selectable = thursday === undefined ? true : false;
          d.checked = false;
          break;
        case 'friday':
          d.selectable = friday === undefined ? true : false;
          d.checked = false;
          break;
        case 'saturday':
          d.selectable = saturday === undefined ? true : false;
          d.checked = false;
          break;
        default:
          break;
      }
    });
    setBusinessHourStateI(tempState);
  };

  // useEffect for setting the appointment notifications Fetched data
  useEffect(() => {
    if (teamSettingsData) {
      setAppointmentNotificationState({
        adminInDND: teamSettingsData.adminInDND,
        adminDNDTimePeriodInHrs: teamSettingsData.adminDNDTimePeriodInHrs,
        providerInDND: teamSettingsData.providerInDND,
        providerDNDTimePeriodInHrs: teamSettingsData.providerDNDTimePeriodInHrs,
        providerFirstReminderEnabled: teamSettingsData.providerFirstReminderEnabled,
        providerFirstReminderInMins: teamSettingsData.providerFirstReminderInMins,
        providerSecondReminderEnabled: teamSettingsData.providerSecondReminderEnabled,
        providerSecondReminderInMins: teamSettingsData.providerSecondReminderInMins,
        teamId: props.me.data.teams[0].id,
      });
    }
  }, [teamSettingsData]);

  //useEffect for setting Business Hours fetched data
  useEffect(() => {
    if (businessHoursData) {
      // console.log(businessHoursData);
      // Group the fetched data according to start time
      const result = _.groupBy(businessHoursData, (BHD) => BHD.businessHourStart);
      let tempData = setBusinessHourInitState(result);
      // console.log(tempData);
      setBusinessHourStateI(tempData.slice());
    }
  }, [businessHoursData]);
  // console.log(businessHourStateI)
  useEffect(() => {
    const {
      adminDNDTimePeriodInHrs,
      providerDNDTimePeriodInHrs,
      providerFirstReminderInMins,
      providerSecondReminderInMins,
    } = appointmentNotificationState;
    if (
      !adminDNDTimePeriodInHrs ||
      !providerDNDTimePeriodInHrs ||
      !providerFirstReminderInMins ||
      !providerSecondReminderInMins
    ) {
      setDisableSave(true);
    }
  }, [appointmentNotificationState]);

  // onChange handlers for Appointment Notifications section
  const handleToggleChange = (event) => {
    setAppointmentNotificationState({
      ...appointmentNotificationState,
      [event.target.name]: event.target.checked,
    });
    setDisableSave(false);
  };
  const handleadminDNDChange = (event) => {
    const { value } = event.target;
    if (value > 0 || value == '') {
      setAppointmentNotificationState({
        ...appointmentNotificationState,
        adminDNDTimePeriodInHrs: event.target.value,
      });
      setDisableSave(false);
    } else {
      setDisableSave(true);
    }
    // value > 0 ? setDisableSave(false) : setDisableSave(true);
  };
  const handleproviderDNDChange = (event) => {
    const { value } = event.target;

    if (value > 0 || value == '') {
      setAppointmentNotificationState({
        ...appointmentNotificationState,
        providerDNDTimePeriodInHrs: event.target.value,
      });
      setDisableSave(false);
    } else {
      setDisableSave(true);
    }
  };
  const handleproviderFirstReminderChange = (event) => {
    const { value } = event.target;

    if (value > 0 || value == '') {
      setAppointmentNotificationState({
        ...appointmentNotificationState,
        providerFirstReminderInMins: event.target.value,
      });
      setDisableSave(false);
    } else {
      setDisableSave(true);
    }
  };
  const handleproviderSecondReminderChange = (event) => {
    const { value } = event.target;

    if (value > 0 || value == '') {
      setAppointmentNotificationState({
        ...appointmentNotificationState,
        providerSecondReminderInMins: event.target.value,
      });
      setDisableSave(false);
    } else {
      setDisableSave(true);
    }
  };

  // Formatting the data to be sent as POST according to our requirement
  let businessHourPostData = [
    {
      businessHourStart: '',
      businessHourEnd: '',
      dayOfWeek: [],
    },
  ];
  businessHourPostData = businessHourStateI.map((item) => {
    let daysOfWeek = [];
    item.selectDays[0].checked === true ? daysOfWeek.push('0') : '';
    item.selectDays[1].checked === true ? daysOfWeek.push('1') : '';
    item.selectDays[2].checked === true ? daysOfWeek.push('2') : '';
    item.selectDays[3].checked === true ? daysOfWeek.push('3') : '';
    item.selectDays[4].checked === true ? daysOfWeek.push('4') : '';
    item.selectDays[5].checked === true ? daysOfWeek.push('5') : '';
    item.selectDays[6].checked === true ? daysOfWeek.push('6') : '';
    return {
      businessHourStart: moment(item.selectTime.businessHourStart, 'hh:mm a').format('HH:mm'),
      businessHourEnd: moment(item.selectTime.businessHourEnd, 'hh:mm a').format('HH:mm'),
      dayOfWeek: daysOfWeek,
      id: [],
      ids: item.ids,
    };
  });

  businessHourPostData.map((item, index) => {
    item.dayOfWeek.forEach((d) => {
      item.ids &&
        item.ids.forEach((i) => {
          if (d == i[0].day) {
            item.id.push(i[0].id);
          }
        });
    });
  });

  businessHourPostData.map((item, index) => {
    if (item.dayOfWeek.length === 0) {
      businessHourPostData.splice(index, 1);
    }
  });

  const [text, setText] = useState(false);
  const TimeError = (start, end) => {
    if (moment(start) >= moment(end)) {
      setText(true);
      setDisableSave(true);
    } else {
      setText(false);
      setDisableSave(false);
    }
  };
  {
    // console.log('props ', props.APPOINTMENT_NOTIFICATIONS_STATE);
  }
  useEffect(() => {
    moment.tz.zone('America/Phoenix');
  }, []);

  const userType = useSelector((state) => state.user.chatProfile.userType);
  return props.APPOINTMENT_NOTIFICATIONS_STATE.isRequesting ? (
    <EcgLoader />
  ) : (
    <form>
      <div>
        <h2 className={styles.formTitle}>Appointment Notifications</h2>
        <div className={styles.formSection}>
          <div className={styles.toggleSection}>
            <div className={styles.toggleSection}>
              <div>
                <h2 className={styles.toggleInfo}>Admin</h2>
                <div>
                  <p className={styles.dnd}>Do not disturb</p>
                </div>
                <div>
                  <p className={styles.notificationWarning}>
                    Notifications will be silenced before this time period
                  </p>
                </div>
                <div>
                  <span style={{ display: '-webkit-inline-box', width: '50px', marginTop: '10px' }}>
                    <Input
                      defaultValue="24"
                      value={appointmentNotificationState.adminDNDTimePeriodInHrs}
                      onChange={(e) => handleadminDNDChange(e)}
                    />
                  </span>{' '}
                  <span style={{ marginLeft: '10px' }}> hours prior to appointment </span>
                </div>
              </div>
              <div>
                <CustomSwitch
                  checked={appointmentNotificationState.adminInDND}
                  onChange={handleToggleChange}
                  name="adminInDND"
                />
              </div>
            </div>
            <div className={styles.toggleSection}>
              <div>
                <h2 className={styles.toggleInfo}>
                  {userType === AUTH_TYPE.COACH ? 'Coach' : 'Provider'}
                </h2>
                <div>
                  <p className={styles.dnd}>Do not disturb</p>
                </div>
                <div>
                  <p className={styles.notificationWarning}>
                    Notifications will be silenced before this time period
                  </p>
                </div>
                <div>
                  <span style={{ display: '-webkit-inline-box', width: '50px', marginTop: '10px' }}>
                    <Input
                      defaultValue="24"
                      value={appointmentNotificationState.providerDNDTimePeriodInHrs}
                      onChange={(e) => handleproviderDNDChange(e)}
                    />
                  </span>{' '}
                  <span style={{ marginLeft: '10px' }}> hours prior to appointment </span>
                </div>
              </div>
              <div>
                <CustomSwitch
                  checked={appointmentNotificationState.providerInDND}
                  onChange={handleToggleChange}
                  name="providerInDND"
                />
              </div>
            </div>
          </div>
          <div style={{ marginTop: '20px' }} className={styles.toggleSection}>
            <div className={styles.toggleSection}>
              <div style={{ marginRight: '65px' }}>
                <h2 className={styles.toggleInfo}>
                  {userType === AUTH_TYPE.COACH ? 'Coach' : 'Provider'}
                </h2>
                <div>
                  <p className={styles.dnd}>Upcoming appointment Reminder 1</p>
                </div>
                <div>
                  <p className={styles.notificationWarning}>A reminder will be sent at this time</p>
                </div>
                <div>
                  <span style={{ display: '-webkit-inline-box', width: '50px', marginTop: '10px' }}>
                    <Input
                      defaultValue="50"
                      value={appointmentNotificationState.providerFirstReminderInMins}
                      onChange={(e) => handleproviderFirstReminderChange(e)}
                    />
                  </span>
                  <span style={{ marginLeft: '10px' }}> minutes prior to appointment </span>
                </div>
              </div>
              <div>
                <CustomSwitch
                  checked={appointmentNotificationState.providerFirstReminderEnabled}
                  onChange={handleToggleChange}
                  name="providerFirstReminderEnabled"
                />
              </div>
            </div>
            <div className={styles.toggleSection}>
              <div style={{ marginRight: '65px' }}>
                <h2 className={styles.toggleInfo}>
                  {userType === AUTH_TYPE.COACH ? 'Coach' : 'Provider'}
                </h2>
                <div>
                  <p className={styles.dnd}>Upcoming appointment Reminder 2</p>
                </div>
                <div>
                  <p className={styles.notificationWarning}>A reminder will be sent at this time</p>
                </div>
                <div>
                  <span style={{ display: '-webkit-inline-box', width: '50px', marginTop: '10px' }}>
                    <Input
                      defaultValue="50"
                      value={appointmentNotificationState.providerSecondReminderInMins}
                      onChange={(e) => handleproviderSecondReminderChange(e)}
                    />
                  </span>
                  <span style={{ marginLeft: '10px' }}> minutes prior to appointment </span>
                </div>
              </div>
              <div>
                <CustomSwitch
                  checked={appointmentNotificationState.providerSecondReminderEnabled}
                  onChange={handleToggleChange}
                  name="providerSecondReminderEnabled"
                />
              </div>
            </div>
          </div>
        </div>
        <h2 className={styles.formTitle}>Business Hours</h2>
        <div style={{ height: '380px', overflow: 'auto' }} className={styles.formSection}>
          {businessHourStateI.length === 0 && (
            <div style={{ marginLeft: '20px', marginTop: '25px' }}>
              <Button className={classes.button} onClick={() => addNewHours(null)}>
                <AddCircleOutlineIcon style={{ marginRight: '5px', height: '22px' }} />
                <u>Add New Hours</u>
              </Button>
            </div>
          )}
          {businessHourStateI &&
            businessHourStateI.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    borderTop: index > 0 ? '1px  lightgrey solid' : '',
                    paddingTop: index > 0 ? '20px' : '',
                  }}
                >
                  <h2 className={styles.toggleInfo}>Select Time</h2>
                  <div className={styles.businessTime}>
                    <div>
                      <div>
                        <p className={styles.time}>Start Time {timeZone && `(${timeZone})`}</p>
                      </div>
                      <div>
                        <TimePicker
                          style={{ width: '250px', height: '40px' }}
                          value={moment(item.selectTime.businessHourStart, 'hh:mm a z')}
                          //  value={item.selectTime.businessHourStart.format('hh:mm a z')}
                          onChange={(e) => {
                            if (e) {
                              handleStartTimeChange(index, e);
                              TimeError(
                                moment(item.selectTime.businessHourStart, 'hh:mm a z'),
                                moment(item.selectTime.businessHourEnd, 'hh:mm a z'),
                              );
                            }
                          }}
                          use12Hours
                          format="hh:mm a z"
                        />
                      </div>
                    </div>
                    <div style={{ marginLeft: '50px' }}>
                      <div>
                        <p className={styles.time}>End Time {timeZone && `(${timeZone})`}</p>{' '}
                      </div>
                      <div>
                        <TimePicker
                          style={{ width: '250px', height: '40px' }}
                          value={moment(item.selectTime.businessHourEnd, 'hh:mm a')}
                          onChange={(e) => {
                            if (e) {
                              handleEndTimeChange(index, e);
                              TimeError(
                                moment(item.selectTime.businessHourStart, 'hh:mm a'),
                                moment(item.selectTime.businessHourEnd, 'hh:mm a'),
                              );
                            }
                          }}
                          use12Hours
                          format="hh:mm a"
                        />
                      </div>
                    </div>
                    {index === businessHourStateI.length - 1 && (
                      <div style={{ marginLeft: '20px', marginTop: '25px' }}>
                        <Button className={classes.button} onClick={() => addNewHours(index)}>
                          <AddCircleOutlineIcon style={{ marginRight: '5px', height: '22px' }} />
                          <u>Add New Hours</u>
                        </Button>
                      </div>
                    )}
                  </div>
                  {text ? (
                    <div style={{ color: '#F15757' }}>
                      * Start Time should be less than End Time
                    </div>
                  ) : (
                    ''
                  )}
                  <h2 className={styles.days}>Select Day(s)</h2>
                  <FormControl component="fieldset" className={classes.formControl}>
                    <FormGroup style={{ display: 'block' }}>
                      {/* sunday */}
                      <FormControlLabel
                        style={{ width: '170px' }}
                        control={
                          <CustomCheckbox
                            checked={item.selectDays[0].checked}
                            disabled={!item.selectDays[0].selectable}
                            onChange={(e) => handleSelectDay(e, item, index)}
                            name="sunday"
                          />
                        }
                        label="Sunday"
                      />
                      {/* Monday */}
                      <FormControlLabel
                        style={{ width: '170px' }}
                        control={
                          <CustomCheckbox
                            checked={item.selectDays[1].checked}
                            disabled={!item.selectDays[1].selectable}
                            onChange={(e) => handleSelectDay(e, item, index)}
                            name="monday"
                          />
                        }
                        label="Monday"
                      />
                      {/* Tuesday */}
                      <FormControlLabel
                        style={{ width: '170px' }}
                        control={
                          <CustomCheckbox
                            checked={item.selectDays[2].checked}
                            disabled={!item.selectDays[2].selectable}
                            onChange={(e) => handleSelectDay(e, item, index)}
                            name="tuesday"
                          />
                        }
                        label="Tuesday"
                      />
                      {/* Wednesday */}
                      <FormControlLabel
                        style={{ width: '170px' }}
                        control={
                          <CustomCheckbox
                            checked={item.selectDays[3].checked}
                            disabled={!item.selectDays[3].selectable}
                            onChange={(e) => handleSelectDay(e, item, index)}
                            name="wednesday"
                          />
                        }
                        label="Wednesday"
                      />
                      {/* Thursday */}
                      <FormControlLabel
                        style={{ width: '170px' }}
                        control={
                          <CustomCheckbox
                            checked={item.selectDays[4].checked}
                            disabled={!item.selectDays[4].selectable}
                            onChange={(e) => handleSelectDay(e, item, index)}
                            name="thursday"
                          />
                        }
                        label="Thursday"
                      />
                      {/* Friday */}
                      <FormControlLabel
                        style={{ width: '170px' }}
                        control={
                          <CustomCheckbox
                            checked={item.selectDays[5].checked}
                            disabled={!item.selectDays[5].selectable}
                            onChange={(e) => handleSelectDay(e, item, index)}
                            name="friday"
                          />
                        }
                        label="Friday"
                      />
                      {/* Saturday */}
                      <FormControlLabel
                        style={{ width: '170px' }}
                        control={
                          <CustomCheckbox
                            checked={item.selectDays[6].checked}
                            disabled={!item.selectDays[6].selectable}
                            onChange={(e) => handleSelectDay(e, item, index)}
                            name="saturday"
                          />
                        }
                        label="Saturday"
                      />
                      {index !== 0 && (
                        <Button
                          className={classes.button}
                          style={{ float: 'right' }}
                          onClick={() => handleRemoveSection(index)}
                        >
                          <u>Remove</u>
                        </Button>
                      )}
                    </FormGroup>
                  </FormControl>
                </div>
              );
            })}
        </div>
        <div style={{ marginBottom: '50px' }} className={styles.appointmentbuttonWrapper}>
          {props.APPOINTMENT_NOTIFICATIONS_STATE.isRequesting ? (
            <button disabled className={styles.submitLoading}>
              <Loading />
            </button>
          ) : (
            <button
              disabled={disableSave}
              style={{ width: 'auto' }}
              className={styles.submit}
              onClick={() => {
                props.handleAppointmentNotifications(appointmentNotificationState);
                props.postBusinessHours(businessHourPostData, businessHoursData, day);
              }}
            >
              Save Changes
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => {
  const me = getDataSource(state, DATASOURCES.ME);
  const APPOINTMENT_NOTIFICATIONS_STATE = state.teamSettings.appointmentNotifications;
  return {
    me,
    APPOINTMENT_NOTIFICATIONS_STATE,
  };
};

const mapActionCreators = {
  handleAppointmentNotifications,
  fetchAppointmentNotifications,
  fetchBusinessHours,
  postBusinessHours,
};

export default connect(mapStateToProps, mapActionCreators)(injectIntl(TeamSettingsForm));
