import * as actions from '../actions/actionTypes';

const initialState = {
  isFloatingWindow: false,
  callStatus: '',
  noteId: '',
  memberId: null,
};

export function AdHocReducer(state = initialState, action) {
  switch (action.type) {
    case actions.START_PHONE_CALL:
      return {
        ...state,
        isFloatingWindow: true,
        callStatus: action.payload,
        memberId: action.member,
      };
    case actions.CLOSE_PHONE_CALL:
      return {
        ...state,
        isFloatingWindow: false,
      };
    case actions.CHANGE_CALL_STATUS:
      return {
        ...state,
        callStatus: action.payload,
      };
    case actions.NOTES_UPDATE:
      return {
        ...state,
        noteId: action.payload,
      };

    default:
      return state;
  }
}
