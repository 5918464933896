import { Button, FormControl, Grid, Paper, Select, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { colors, fonts, fontSizes } from 'style/styleSettings';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  editNote,
  getNotesMetadata,
  postNote,
  uploadNoteAttachment,
} from 'store/actions/memberProfile';
import DocumentationFileUpload from './Upload';
import * as constant from 'constants/app';
import styles from './documentation.module.css';
import moment from 'moment';

const useStyles = makeStyles({
  root: {
    fontFamily: fonts.SESSION,
    '& .MuiButton-root:hover': {
      backgroundColor: `lightgray !important`,
    },
    '& .MuiTypography-h5': {
      fontSize: `${fontSizes.HEADING} !important`,
    },
    '& .MuiInputBase-input': {
      border: '1px solid lightgrey',
    },
    '& .MuiButton-label': {
      fontFamily: 'Work Sans',
    },
  },
});
function AddEditNotes({
  callProgress = '',
  type = '',
  row = null,
  memberId,
  snackBarDataHandler,
  onClose,
  setnotePostMessage,
  noteId = '',
  file,
  alertId,
  ...other
}) {
  const classes = useStyles();
  const teamId = useSelector((state) => state.user.team.id);
  const coachId = useSelector((state) => state.user.profile.id);
  const userType = useSelector((state) => state.user.chatProfile.userType);
  const profile = useSelector((state) => state.user.profile);
  const noteMetaData = useSelector((state) => state.memberProfile.noteMetaData);

  const [metadata, setMetadata] = useState(null);
  const [attachments, setAttachements] = useState([]);
  const [cachedUploads, setCachedUploads] = useState([]);
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    tag: '',
    description: '',
  });

  useEffect(() => {
    if (noteMetaData === null || noteMetaData?.data === null) {
      dispatch(getNotesMetadata(teamId, coachId, false)); //notesmetadata
      dispatch(getNotesMetadata(teamId, coachId, true));
    }

    // file !== undefined && setCachedUploads((s) => [...s, file]);
  }, []);

  useEffect(() => {
    if (row !== null && Object.keys(row).length > 0) {
      setState({
        ...state,
        tag: row.tag,
        description: row.description,
      });
      //check for attachments
      let att = [];
      if (row.attachmentDetails.length > 0) {
        row.attachmentDetails.map((item) => {
          att.push({
            name: item.filename,
            size: item.fileLength,
            uploaded: true,
            success: true,
            error: false,
            ...item,
          });
        });
      }
      setAttachements(att);
    }
  }, [row]);
  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  useEffect(() => {
    if (noteMetaData && noteMetaData?.data !== null) {
      noteMetaData.data.tag = {
        ...noteMetaData?.data?.tag,
        0: 'Select Tag',
      };
      setMetadata(noteMetaData?.data);
    }
  }, [noteMetaData]);

  const handleSubmitNote = (status) => {
    const data = {
      ...state,
      status,
      type: type === constant.ADHOC_CALL ? 1 : 2,
      patientId: memberId,
    };
    if (status == 102) {
      data.signedBy = coachId;
    }
    if (type === constant.ADHOC_CALL || row) {
      delete data.type;
      delete data.patientId;
      if (type == constant.ADHOC_CALL) {
        editNote(teamId, coachId, data, noteId)
          .then((res) => {
            setnotePostMessage(res);
          })
          .catch((err) => console.log(err));
      } else {
        editNote(teamId, coachId, data, row.id)
          .then((res) => {
            snackBarDataHandler({
              open: true,
              text: 'Note edited successfully',
              severity: 'success',
            });
            setTimeout(() => onClose(), 2000);
          })
          .catch((err) => console.log(err));
      }
    } else {
      // so we save as draft -> upload attchments -> save as signed
      if (status == 102) {
        data.status = 101;
      }
      postNote(teamId, coachId, data, type, alertId).then((res) => {
        if (res.success) {
          snackBarDataHandler({
            open: true,
            text: 'Saving attachments',
            severity: 'success',
          });
          let promises = [];
          if (cachedUploads.length > 0) {
            cachedUploads.map((c) => {
              promises.push(
                uploadNoteAttachment(teamId, coachId, res.result, c).then((res) =>
                  console.log(res),
                ),
              );
            });
          }
          Promise.all(promises).then(
            function () {
              // returned data is in arguments[0], arguments[1], ... arguments[n]
              // you can process it here
              if (status == 102) {
                data.status = 102;
                delete data.type; //type is not needed for edit
                delete data.patientId;
                editNote(teamId, coachId, data, res.result).then((res) => {});
              }
              snackBarDataHandler({
                open: true,
                text: 'Note created successfully!',
                severity: 'success',
              });
              setCachedUploads([]);
              setTimeout(() => onClose(), 2000);
            },
            function (err) {
              // error occurred
              console.log(err);
            },
          );
        }
      });
    }
  };
  // console.log('noteMetaData', noteMetaData);
  const cacheUploadHander = async (file) => {
    setCachedUploads((s) => [...s, file]);
    return { status: 200 };
  };

  //time difference calculator
  const calculateTimeDiff = (end, start) => {
    let endTime = moment(end);
    let startTime = moment(start);
    let diff = endTime.diff(startTime);
    return moment.utc(diff).format('mm:ss');
  };

  return (
    <Grid container spacing={2} className={classes.root}>
      {/* status */}
      {(type === constant.ADHOC_CALL ||
        type === constant.ALERT_NOTE ||
        (row !== null && Object.keys(row).length > 0)) && (
        <Grid item xs={12}>
          <Paper
            style={{
              backgroundColor: 'white',
              borderRadius: 5,
              boxShadow: '10px, 5px #c1c1c1',
              padding: '0 20px',
              textAlign: 'left',
            }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
              >
                <Typography variant="h5">Status</Typography>
                <div
                  style={{
                    marginLeft: '20px',
                    border:
                      type === constant.ADHOC_CALL
                        ? `2px solid ${colors.SPRUCE}`
                        : row?.status == 'Draft'
                        ? `2px solid ${colors.SPRUCE}`
                        : `2px solid black`,
                    borderRadius: '100px',
                    padding: '8px 12px 8px 12px',
                  }}
                >
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: 600,
                      textTransform: 'uppercase',
                      fontSize: 11,
                      letterSpacing: '1.5px',
                      lineHeight: '13px',
                    }}
                  >
                    {type === constant.ADHOC_CALL || type === constant.ALERT_NOTE
                      ? `Draft`
                      : row?.status}
                  </Typography>
                </div>
              </Grid>

              {/* this section is common irrespective trigger point */}
              <Grid item xs={6}>
                <Typography variant="body1">
                  {`Provider: ${profile.permissions?.isAdmin ? `Admin` : userType}`}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {`Name: ${profile.firstName} ${profile.lastName}`}
                </Typography>
              </Grid>

              {/* if trigger point is from Documentation table */}
              {row !== null && Object.keys(row).length > 0 && (
                <>
                  <Grid item xs={6}>
                    {row.type === constant.ALERT_NOTE ? (
                      <Typography variant="body1">Alert Type: {row?.subTypeName}</Typography>
                    ) : row.type === 'AdHoc Call' ? (
                      <Typography variant="body1">
                        Call Duration:{' '}
                        {calculateTimeDiff(row.adHocCallActualEnd, row.adHocCallActualStart)}
                      </Typography>
                    ) : (
                      <Typography variant="body1">Type: {row?.type}</Typography>
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    {row.type === constant.ALERT_NOTE || row.type === 'AdHoc Call' ? (
                      <Typography variant="body1">
                        Date: {moment(row?.createdDate).format('MM-DD-YYYY')}
                      </Typography>
                    ) : (
                      <Typography variant="body1">Tag: {row?.tagName}</Typography>
                    )}
                  </Grid>
                </>
              )}

              {/* if trigger point is from alert table */}
              {type === constant.ALERT_NOTE && row == null && (
                <>
                  <Grid item xs={6}>
                    <Typography variant="body1">{`Alert Type: ${other.alertSubType}`}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">{`Date: ${other.alertDate}`}</Typography>
                  </Grid>
                </>
              )}

              {/* if trigger point is from adhoc call window */}
              {type === constant.ADHOC_CALL && (
                <>
                  <Grid item xs={6}>
                    <Typography variant="body1">{`Call Duration: ${other.callDuration}`}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">{`Date: ${moment(new Date()).format(
                      'MM-DD-YYYY',
                    )}`}</Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Paper>
        </Grid>
      )}
      <Grid item xs={12}>
        <Paper
          style={{
            backgroundColor: 'white',
            borderRadius: 5,
            boxShadow: '10px, 5px #c1c1c1',
            padding: '0 20px',
            textAlign: 'left',
            marginTop: '10px',
          }}
        >
          <Grid container spacing={2}>
            {/* adhoc call notes head */}
            {type === constant.ADHOC_CALL && (
              <Grid item xs={12}>
                <Typography style={{ fontSize: fontSizes.BASE }}>
                  {callProgress === constant.CALL_IN_PROGRESS
                    ? `Notes will automatically save when you hang up`
                    : `Enter your notes below`}
                </Typography>
              </Grid>
            )}

            {/* tags & inputs */}
            <Grid item xs={12}>
              <Typography style={{ fontSize: fontSizes.BASE, fontFamily: 'Work Sans' }}>
                Tag <span style={{ color: 'red' }}>*</span>
              </Typography>

              <select
                name="tag"
                id="tag"
                className={styles.selectStyle}
                value={state.tag}
                onChange={handleChange}
              >
                {metadata &&
                  Object.entries(metadata.tag).map((item, i) => {
                    return (
                      <option key={i} value={item[0]}>
                        {item[1]}
                      </option>
                    );
                  })}
              </select>
              <textarea
                style={{
                  width: '100%',
                  padding: '10px',
                  fontFamily: "'proxima-nova', 'Helvetica Neue', helvetica, arial, sans-serif",
                  border: '2px solid lightgrey',
                  outline: 'none',
                  borderRadius: 6,
                }}
                name="description"
                rows="5"
                placeholder={type === constant.ADHOC_CALL ? 'Enter notes here.' : 'Notes Goes here'}
                value={state.description}
                onChange={handleChange}
              ></textarea>
            </Grid>

            {type !== constant.ADHOC_CALL && (
              <DocumentationFileUpload
                data={attachments}
                snackBarDataHandler={snackBarDataHandler}
                onUpload={(file) => {
                  if (row && row.id) {
                    //if data is already present , we can use id to upload the attachment
                    return uploadNoteAttachment(teamId, coachId, row.id, file).then((res) => res);
                  } else {
                    //if not, the system caches the attachment and finally uploads it at the time
                    return cacheUploadHander(file).then((res) => res);
                  }
                }}
                file={file}
              />
            )}
            {/* action button */}
            {/* {(type === constant.ADHOC_CALL || type === 'Addhoc Notes') && ( */}
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <>
                <Button
                  style={{
                    color: '#067F6F',
                    border: '2px solid #067F6F',
                    padding: '10px 20px',
                    fontWeight: '800',
                    fontFamily: 'Work Sans',
                    lineHeight: '18px',
                  }}
                  disabled={callProgress === constant.CALL_IN_PROGRESS || state.tag === 0}
                  onClick={() => handleSubmitNote(101)}
                >{`Save Draft`}</Button>
                <Button
                  style={{
                    backgroundColor: '#067F6F',
                    color: 'white',
                    marginLeft: '20px',
                    border: '2px solid #067F6F',
                    padding: '10px 20px',
                    fontWeight: '800',
                    fontFamily: 'Work Sans',
                    lineHeight: '18px',
                  }}
                  disabled={callProgress === constant.CALL_IN_PROGRESS || state.tag === 0}
                  onClick={() => handleSubmitNote(102)}
                >
                  {`Save And Sign`}
                </Button>
              </>
            </Grid>
            {/* )} */}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default AddEditNotes;
