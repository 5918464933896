import React from 'react';

import SignOutIcon from '@material-ui/icons/ExitToApp';
import styles from '../NavigationSidebar.module.css';
import NavLink from './NavLink';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@material-ui/lab';

const NavigationBuilder = ({ main, footer, updatePrevLocation, handleLogout, isLoading }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {isLoading ? (
        <ul className={styles.navList}>
          {'loader'.split('').map((m, i) => {
            return (
              <React.Fragment key={i}>
                <li data-action="page-navigation">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-around',
                      padding: 10,
                    }}
                  >
                    <Skeleton animation="wave" variant="circle" width={30} height={30} />
                    <Skeleton animation="wave" variant="rect" width={'70%'} height={40} />
                  </div>
                </li>
                <div className={styles.spacing}></div>
              </React.Fragment>
            );
          })}
        </ul>
      ) : (
        <ul className={styles.navList}>
          {main.map((m, i) => {
            if (m.condition) {
              return (
                <React.Fragment key={i}>
                  <li data-action="page-navigation" data-label={m.title}>
                    <NavLink
                      className={styles.sidebarItem}
                      activeClassName={styles.sidebarItemActive}
                      to={m.navigateTo} //prop
                      Icon={m.icon} //prop
                      href={m.href}
                      text={m.title} //prop
                      style={{ ...m.style }} //prop
                      status={m.status} //prop
                      unreadCount={m.unreadCount} //prop
                      target={m.target} //prop
                      alertCount={m.alertCount}
                    />
                  </li>
                  <div className={styles.spacing}></div>
                </React.Fragment>
              );
            }
          })}
        </ul>
      )}

      {/* {(process.env.NODE_ENV !== 'production' && !sidebarIsCollapsed) && (
        <div className={styles.buildInfo}>
          Build Info: <br />
          V: {getBuildVersion()}<br />
          D: {getBuildDateTime()}
        </div>
      )} */}
      <div className={styles.sidebarFooter}>
        {isLoading ? (
          <div>
            {[1, 1].map((f, i) => (
              <div
                key={i}
                className={styles.sidebarFooterItem}
                data-action="page-navigation"
                data-label="Coach Resources"
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    padding: 10,
                  }}
                >
                  <Skeleton animation="wave" variant="circle" width={30} height={30} />
                  <Skeleton animation="wave" variant="rect" width={'70%'} height={40} />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div>
            {footer.map((f, i) => (
              <div
                key={i}
                className={styles.sidebarFooterItem}
                data-action="page-navigation"
                data-label="Coach Resources"
              >
                <NavLink
                  className={styles.settings}
                  activeClassName={styles.sidebarItemActive}
                  to={f.navigateTo}
                  Icon={f.icon}
                  text={f.title}
                />
              </div>
            ))}
          </div>
        )}

        <button
          onClick={() => {
            updatePrevLocation(null);
            handleLogout();
            // history.push(ROUTES.LOGIN);
          }}
          className={styles.logout}
          data-action="signOut"
        >
          <div className={styles.navIcon}>
            <SignOutIcon style={{ fill: '#2D9F86' }} />
          </div>
          <span className={styles.navText}>{t('Sign Out')}</span>
        </button>
        <div className={styles.navIcon} style={{ position: 'absolute', bottom: 0 }}></div>
      </div>
    </React.Fragment>
  );
};

export default NavigationBuilder;
