import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './NavigationSidebar.module.css';
import { useSelector } from 'react-redux';
import { ChatContext } from '@alivecor/strophe2.0';
import { useTranslation } from 'react-i18next';
import TeamProfile from './components/TeamProfile';
import NavigationBuilder from './components/NavigationBuilder';
import { fetchConfigs } from './config';
import { AUTH_TYPE } from 'constants/app';

const Navigation = (props) => {
  const { status } = useContext(ChatContext);
  const { me, updatePrevLocation, handleLogout, userType } = props;
  const history = useHistory();
  const { t } = useTranslation();

  const { unreadMsgCount } = useContext(ChatContext);
  const [unreadCount, setunreadCount] = useState(0);
  const alertCount = useSelector((state) => state.escalation?.alertCount);

  useEffect(() => {
    setunreadCount(
      Object.values(unreadMsgCount).reduce((acc, cur) => {
        return acc + cur;
      }, 0),
    );
  }, [unreadMsgCount]);

  const isAdmin = me.data ? me.data.profile.permissions.isAdmin : false;
  const isPhysician = me.data ? me.data.profile.permissions.isPhysician : false;
  const [config, setConfig] = useState({ main: [], footer: [] });
  useEffect(() => {
    let data = null;
    if (userType) {
      //fetches role configs and populates sidebar based on the user type
      data = fetchConfigs(isAdmin, isPhysician, history, unreadCount, status, alertCount);
      if (data) {
        switch (userType) {
          case AUTH_TYPE.DOCTOR:
            setConfig(data.doctorConfig);
            break;
          case AUTH_TYPE.COACH:
            setConfig(data.coachConfig);
            break;
          default:
            break;
        }
      }
    }
    return () => setConfig({ main: [], footer: [] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType, status, unreadCount, alertCount]);
  return (
    <React.Fragment>
      <NavigationBuilder
        main={config.main}
        footer={config.footer}
        updatePrevLocation={updatePrevLocation}
        handleLogout={handleLogout}
        isLoading={config.main.length == 0 && config.footer.length == 0}
      />
    </React.Fragment>
  );
};

export const NavigationSidebar = (props) => {
  const data = useSelector((state) => state.user);
  const {
    chatProfile: { userType },
  } = data;

  const { sidebarIsCollapsed, handleToggleNavigationSidebar } = props;

  const sidebarClassName = sidebarIsCollapsed ? 'sidebarCollapsed' : 'sidebar';
  return (
    <React.Fragment>
      <aside data-page="navigation-sidebar" className={styles[sidebarClassName]}>
        <TeamProfile
          me={{ isFetching: false, data: data }}
          sidebarIsCollapsed={sidebarIsCollapsed}
          handleToggleNavigationSidebar={handleToggleNavigationSidebar}
        />
        <Navigation userType={userType} me={{ isFetching: false, data: data }} {...props} />
      </aside>
    </React.Fragment>
  );
};

export default NavigationSidebar;
