/* eslint-disable react/jsx-key */
import * as React from 'react';
import { useHistory } from 'react-router';
import { GridActionsCellItem } from '@mui/x-data-grid-premium';
import { CustomText, Pills, MuiTableComponent } from '../CustomUI';
import { ALERT_NOTE, ALERT_SUBTYPE, COACH_ROUTES } from 'constants/app';
import { useDispatch, useSelector } from 'react-redux';
import { startAdhocCall } from 'store/actions';
import { getMemberProfileDetails, getNote } from 'store/actions/memberProfile';
import Documentation from 'components/Common/ModalView/Documentation';
import { useParams } from 'react-router';
import moment from 'moment';
import {
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
  DialogContent,
  Button,
  Paper,
  Grid,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  alertsTableInfo,
  alertStatusChange,
  dashboardKpiCount,
  deleteAlert,
  getChatUserId,
} from 'store/actions/escalations';
import { programMap } from 'utilities/Constants';

export default function Alert() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { alertType } = useParams();
  const profileId = useSelector((state) => state.user.profile.id);
  const teamId = useSelector((state) => state.user.team.id);

  // ========== x-data-grid column definition =========//

  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      filterable: false,
      // hide: true,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 220,
      // showMultiFilterOperators: false,
      editable: false,
      type: 'string',
      renderCell: (params) => <CustomText params={params} color="#067F6F" weight={800} />,
    },
    {
      field: 'createdDate',
      headerName: 'Date',
      width: 180,
      // editable: false,
      type: 'date',
      renderCell: (params) => (
        <p style={{ color: params.row.status === 3 ? 'lightgray' : '#142A39' }}>
          {moment(params?.row?.createdDate).format('MM-DD-YYYY')}
        </p>
      ),
    },
    {
      field: 'subTypeName',
      headerName: 'Alert Type',
      width: 160,
      editable: false,
      type: 'string',
      renderCell: (params) => (
        <p style={{ color: params.row.status === 3 ? 'lightgray' : '#142A39' }}>
          {params.row.subTypeName}
        </p>
      ),
    },
    {
      field: 'bloodPressure',
      headerName: 'Blood Pressure',
      width: 160,
      filterable: false,
      type: 'string',
      renderCell: (params) => (
        <p style={{ color: params.row.status === 3 ? 'lightgray' : '#142A39' }}>
          {params.row.bloodPressure}
        </p>
      ),
    },
    {
      field: 'ekg',
      headerName: 'EKG',
      width: 180,
      editable: false,
      type: 'string',
      sortable: false,
      renderCell: (params) => <Pills params={params} color="#067F6F" column="ekg" />,
    },
    {
      field: 'program',
      headerName: 'Program',
      width: 150,
      editable: false,
      type: 'string',
      sorting: false,
      renderCell: (params) => (
        <p style={{ color: params.row.status === 3 ? 'lightgray' : '#142A39' }}>
          {params.row.program}
        </p>
      ),
    },
    {
      field: 'statusName',
      headerName: 'Status',
      width: 150,
      editable: false,
      type: 'string',
      sorting: false,
      renderCell: (params) => <Pills params={params} color="#c1c1c1" column="status" />,
    },
    {
      field: 'action',
      headerName: '',
      width: 50,
      sorting: false,
      hide: true,
      type: 'actions',
      getActions: (params) => [
        <GridActionsCellItem
          // icon={<SecurityIcon />}
          label="View Profile"
          onClick={viewProfile(params.row)}
          // disabled={params.row.status === 3}
          showInMenu
        />,
        <GridActionsCellItem
          label="Message Member"
          onClick={messageMember(params.row)}
          disabled={params.row.status === 3}
          showInMenu
        />,
        <GridActionsCellItem
          label="Call Member"
          onClick={callMember(params.row)}
          disabled={params.row.status === 3}
          showInMenu
        />,
        <GridActionsCellItem
          label={params.row.noteId === null ? `Add Notes` : `Review Note`}
          onClick={addNotes(params.row)}
          disabled={params.row.status === 3}
          showInMenu
        />,
        <GridActionsCellItem
          label="Dismiss Alert"
          onClick={dismissAlert(params.row)}
          disabled={params.row.status === 3 || params.row.noteId === null}
          showInMenu
        />,
      ],
    },
  ];

  //========== end =============//

  //========== table action callbacks ========//
  // view profile
  const viewProfile = React.useCallback(
    (params) => () => {
      // change alert status to pending if its in open state
      params.status === 1 && alertStatusChange(teamId, profileId, params.id);
      history.push(`${COACH_ROUTES.MEMBER_PROFILE}/${params?.patientId}`);
    },
    [],
  );

  // message member
  const messageMember = React.useCallback(
    (params) => () => {
      // change alert status to pending if its in open state
      params.status === 1 && alertStatusChange(teamId, profileId, params.id);
      getChatUserId(profileId, params.patientId).then((res) => {
        history.push(`/chat-inbox/${res?.chatUserID}`);
      });
    },
    [],
  );

  // call member
  const callMember = React.useCallback(
    (params) => () => {
      setLoading(true);
      // change alert status to pending if its in open state
      params.status === 1 &&
        alertStatusChange(teamId, profileId, params.id).then((res) => {
          res.data.result &&
            alertsTableInfo(
              teamId,
              profileId,
              page,
              pageSize,
              patientSearch,
              alertSubTypeSearch,
            ).then((res) => {
              setFilteredRow(
                res.map((item) => {
                  return {
                    ...item,
                    program: programMap[item.programId],
                    name: `${item.lastName}, ${item.firstName}`,
                  };
                }),
              );
              settotalRowCount(res.length);
              setLoading(false);
            });
        });

      dispatch(getMemberProfileDetails(params.patientId)).then(() => {
        dispatch(startAdhocCall('Call Member', params.patientId));
      });
    },
    [],
  );

  //======= add note and review note callbacks ===========//
  const [openNotes, setopenNotes] = React.useState({
    open: false,
    params: null,
  });
  const addNotes = React.useCallback(
    (params) => () => {
      handleClickOpen(false); // to replace dismiss modal with add/view notes
      setopenNotes({
        open: true,
        params: params,
      });
    },
    [],
  );
  const toggleModal = () => {
    setopenNotes({
      open: !openNotes.open,
    });
    setLoading(true);
    alertsTableInfo(teamId, profileId, page, pageSize, patientSearch, alertSubTypeSearch).then(
      (res) => {
        setFilteredRow(
          res.map((item) => {
            return {
              ...item,
              program: programMap[item.programId],
              name: `${item.lastName}, ${item.firstName}`,
            };
          }),
        );
        settotalRowCount(res.length);
        setLoading(false);
      },
    );
  };
  //======== end ===========//

  //=======dismiss alert callback function=======//
  const [dismissWindow, setDismissWindow] = React.useState({
    status: null,
    params: null,
  });
  const dismissAlert = React.useCallback(
    (params) => () => {
      setLoading(true);
      getNote(teamId, profileId, params.noteId)
        .then((res) => {
          setLoading(false);
          setDismissWindow({
            status: res.result.status,
            params: params,
          });
          handleClickOpen(true);
        })
        .catch((err) => console.log(err));
    },
    [],
  );

  // dismissing the alert if note is already signed.
  const dismissAlertConfirm = React.useCallback(
    (params) => () => {
      deleteAlert(teamId, profileId, params).then((res) => {
        if (res) {
          handleClickOpen(false);
          dispatch(dashboardKpiCount(teamId, profileId));
        }
      });
    },
    [],
  );

  const [openDismissModal, setOpenDismissModal] = React.useState(false);

  const handleClickOpen = (flag) => {
    setOpenDismissModal(flag);
    if (!flag) {
      setLoading(true);
      alertsTableInfo(teamId, profileId, page, pageSize, patientSearch, alertSubTypeSearch).then(
        (res) => {
          setFilteredRow(
            res.map((item) => {
              return {
                ...item,
                program: programMap[item.programId],
                name: `${item.lastName}, ${item.firstName}`,
              };
            }),
          );
          settotalRowCount(res.length);
          setLoading(false);
        },
      );
    }
  };

  const handleClose = (e, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    setOpenDismissModal(false);
  };
  // ========= end ==========//

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    id: false,
  });

  //==================pagination server logic & filter logic =================//

  const [loading, setLoading] = React.useState(false);
  const [filteredRow, setFilteredRow] = React.useState([]);
  const [patientSearch, setPatientSearch] = React.useState('');
  const [alertSubTypeSearch, setalertSubTypeSearch] = React.useState('');

  const [totalRowCount, settotalRowCount] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const perPageOptions = [10, 20, 50];

  const setFilterModel = (val) => {
    if (
      val.items.length === 0 ||
      (val.items.length > 0 && (val.items[0].value === '' || val.items[0].value === undefined))
    ) {
      setLoading(true);
      // if filter value is empty or filter is cancelled
      alertsTableInfo(teamId, profileId, page, pageSize).then((res) => {
        setFilteredRow(
          res.map((item) => {
            return {
              ...item,
              program: programMap[item.programId],
              name: `${item.lastName}, ${item.firstName}`,
            };
          }),
        );
        settotalRowCount(res.length);
        setLoading(false);
      });
    } else {
      val.items[0].columnField === 'firstName' && setPatientSearch(`&name=${val.items[0].value}`);
      // val.items[0].columnField === 'subTypeName' &&
      //   setalertSubTypeSearch(`&subType=${ALERT_SUBTYPE[val.items[0].value]}`);
    }
  };

  React.useEffect(() => {
    if (alertType !== 'list') {
      // showing filtered alert table on click of dashbpard tiles
      setalertSubTypeSearch(`&subType=${ALERT_SUBTYPE[alertType]}`);
    }
  }, [alertType]);

  const asyncTimer = React.useRef(null);
  React.useEffect(() => {
    setLoading(true);
    //timeout to trigger after 2 src of typing the name filter
    asyncTimer.current = setTimeout(() => {
      alertsTableInfo(teamId, profileId, page, pageSize, patientSearch, alertSubTypeSearch).then(
        (res) => {
          setFilteredRow(
            res.map((item) => {
              return {
                ...item,
                program: programMap[item.programId],
                name: `${item.lastName}, ${item.firstName}`,
              };
            }),
          );
          settotalRowCount(res.length);
          setLoading(false);
          dispatch(dashboardKpiCount(teamId, profileId));
        },
      );
    }, 2000);
    return () => clearTimeout(asyncTimer.current);
  }, [page, pageSize, patientSearch, alertSubTypeSearch]);

  // initial filter if naviagted from dahsboard kpis based on alert type
  // const initialFilter = {
  //   items: [{ id: 1, columnField: 'subTypeName', operatorValue: 'contains', value: alertType }],
  //   // linkOperator: 'or',
  // };
  //= ======= end =========== //

  return (
    <>
      <MuiTableComponent
        columns={columns}
        rows={filteredRow}
        // initialState={{
        //   filter: {
        //     filterModel: alertType === 'list' ? null : initialFilter,
        //   },
        // }}
        loading={loading}
        columnToolbar={true}
        filterToolbar={true}
        densityToolbar={true}
        exportToolbar={false}
        pagination
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        page={page}
        onPageChange={(newPage) => setPage(newPage)}
        rowsPerPageOptions={perPageOptions}
        paginationMode="server"
        rowCount={100}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
        ColumnResizeIcon={null}
        setFilterModel={(val) => setFilterModel(val)}
      />
      {openNotes.open && (
        <Documentation
          onClose={toggleModal}
          noteId={openNotes.params.noteId === null ? '' : openNotes.params.noteId}
          setopenNotesModal={setopenNotes}
          memberId={openNotes.params.patientId}
          openNotesModal={openNotes.open}
          type={ALERT_NOTE}
          alertSubType={openNotes.params.subTypeName}
          alertId={openNotes.params.id}
          alertDate={moment(openNotes.params.createdDate).format('MM-DD-YYY')}
        />
      )}

      {openDismissModal && (
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={openDismissModal}
        >
          <DialogTitle sx={{ m: 0, p: 2, backgroundColor: '#F1F2F3' }} id="customized-dialog-title">
            <Typography variant={'h6'}>Dismiss Alert</Typography>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers sx={{ backgroundColor: '#F1F2F3' }}>
            <Paper
              style={{
                borderRadius: 5,
                padding: '10px 20px',
                textAlign: 'center',
                backgroundColor: 'white !important',
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant={'h6'}>
                    {dismissWindow &&
                      (dismissWindow.status === 'Draft'
                        ? `You need to sign your note before dismissing this alert.`
                        : `Are you sure you want to dismiss this alert?`)}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                  }}
                >
                  {dismissWindow && dismissWindow.status === 'Signed' && (
                    <Button
                      style={{ color: '#067F6F', border: '1px solid #067F6F', padding: '5px 20px' }}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  )}

                  <Button
                    style={{
                      backgroundColor: '#067F6F',
                      color: 'white',
                      marginLeft: '20px',
                      borderColor: '#067F6F',
                      padding: '5px 20px',
                    }}
                    onClick={
                      dismissWindow.status === 'Signed'
                        ? dismissAlertConfirm(dismissWindow.params)
                        : addNotes(dismissWindow.params)
                    }
                  >
                    {dismissWindow && dismissWindow.status === 'Signed' ? `Yes, Dismiss` : `Okay`}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
