import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
// import { Link } from 'react-router-dom';
import { getTimezoneName, streamToPromise } from 'utilities/Utilities';
import { AppointmentStatusTypes } from 'utilities/Constants';
import { useSelector, useDispatch } from 'react-redux';
import apiservice from 'api/services/apiservice.service';
import moment from 'moment';
import { useHistory } from 'react-router';
import { Typography } from '@material-ui/core';
import { AUTH_TYPE, COACH_ROUTES, DOCTOR_ROUTES } from 'constants/app';
import EcgLoader from 'components/Shared/EcgLoader';
import { StatusColumn } from 'screens/coach/MemberList/components/StatusColumn';
// import * as action from 'store/actions';

// import DoctorDetails from 'components/Appointments/Calendar/DoctorDetails';
// import Modal from '../../../components/Appointments/Calendar/AppointmentDetailsModal';
// import * as API from '../../../Services/API/actions';
// import { getToken } from 'auth/AuthUtilities';
// import axios from 'axios';
// import { GoAPI } from 'Axios/axios';

const useStyles = makeStyles({
  root: {
    minWidth: 650,
    '& .MuiTableCell-root': {
      fontFamily: 'Work Sans !important',
      fontSize: '16px',
    },
    '& .MuiTableHead-root': {
      '& .MuiTableCell-root': {
        fontWeight: 700,
      },
    },
    '& .MuiTableBody-root': {
      '& .MuiTableCell-root': {
        fontWeight: 400,
      },
    },
    '& .MuiTypography-h5': {
      fontSize: '20px',
      lineHeight: '23px',
      fontFamily: 'Work Sans',
      fontWeight: 500,
      margin: '20px',
    },
    '& .MuiTypography-h6': {
      fontSize: '16px',
      lineHeight: '20px',
      fontFamily: 'Work Sans',
      fontWeight: 400,
      margin: '20px',
    },
  },
  head: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  container: {
    padding: '20px',
  },
  tableCell_Member: {
    color: '#067F6F',
    fontSize: '16px',
    fontWeight: 600,
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  noData: {
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: 600,
    fontColor: 'grey',
    fontFamily: 'Work Sans',
    // marginTop: '40px',
    // marginBottom: '40px',
    // marginLeft: '24px',
  },
  tableRowStyle: {
    '&:nth-of-type(even)': {
      backgroundColor: '#F0F8F5 !important',
    },
    '&:hover': {
      backgroundColor: '#F5F5F5 !important',
    },
  },
  scheduleRow: {
    margin: '24px !important',
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiButton-root': {
      border: '1px solid #067F6F',
      borderRadius: '4px',
      fontFamily: 'Work Sans',
      color: '#067F6F',
      backgroundColor: 'white',
      fontWeight: 600,
    },
    '& .MuiTypography-h5': {
      fontFamily: 'Work Sans',
      fontSize: '20px',
      fontWeight: 500,
    },
  },
  tableHeader: {
    background: '#F1F2F3',
    borderBottom: '1px solid #B4BBC0',
    alignSelf: 'stretch',
  },
});

const DashboardTable = (props) => {
  const { userType } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [sessionData, setSessionData] = useState([]);
  // const [userRoute, setUserRoute] = useState(null);

  const clinicianId = useSelector((state) => state.user.profile.id);
  const teamId = useSelector((state) => state.user.team.id);
  const isLoading = useSelector((state) => state.appointments.loading);
  // const sessionText = useSelector((state) => state.user.chatProfile.sessionText);
  let offsetInMinutes = new Date().getTimezoneOffset();
  // let skip = '0';
  // let take = '10';
  // const localfilterstatusfinal = '';
  // const sortordertypenew = 'asc';
  // const searchInput = '';
  // const sortpropsnamenew = 'Date';
  // let develop = false;
  const timezoneValue = getTimezoneName();
  const now = moment();

  const startDateformatted = now.startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS');
  const endDateformatted = now.endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS');

  const getDataForTodaySession = async () => {
    const appointmentRequest = streamToPromise(
      apiservice.getapiservice({
        baseUrl: `api/v1/teams/${teamId}/clinicians/${clinicianId}/calendar/?endDate=${endDateformatted}&startDate=${startDateformatted}&offsetInMinutes=${offsetInMinutes}&timeZone=${timezoneValue}&splitBasedOnDays=true`,
        // baseUrl: `api/v1.1/teams/${teamId}/clinicians/${clinicianId}/appointments?offsetInMinutes=${offsetInMinutes}&pageNumber=${skip}&pageSize=${take}&appointmentStatus=${localfilterstatusfinal}&sortOrder=${sortordertypenew}&searchString=${searchInput}&sortParam=${sortpropsnamenew}&timeZone=${timezoneValue}&startDateTime=${startDateformatted}&endDateTime=${endDateformatted}`,
      }),
    );
    const appointments = await appointmentRequest;
    if (appointments) {
      // console.log(appointments);
      return appointments.data.appointments;
      // return appointments.data.result.appointments;
    }
  };
  useEffect(() => {
    getDataForTodaySession().then((data) => {
      setSessionData(data);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const classes = useStyles();

  // const [openDetailModal, setOpenDetailModal] = useState(false);
  const navigate = (pId, appId, appStatus) => {
    if (userType === AUTH_TYPE.COACH) {
      history.push(`${COACH_ROUTES.MEMBER_PROFILE}/${pId}`);
    } else {
      // dispatch(
      //   action.getRecordingSamples('', teamId, clinicianId, appId, {
      //     timeZoneOffset: new Date().getTimezoneOffset(),
      //     timeZone: getTimezoneName(),
      //   }),
      // );
      // setAppid(appId);
      // setEkgId(ekgId);
      // setOpenDetailModal(true);
      if (
        [
          AppointmentStatusTypes.Scheduled,
          AppointmentStatusTypes.InProgress,
          AppointmentStatusTypes.MemberInWaitingRoom,
          AppointmentStatusTypes.Encounter_Summary_Preparation,
        ].includes(appStatus)
      ) {
        history.push(`${DOCTOR_ROUTES.PATIENT_PROFILE}/${pId}/${appId}`);
      } else {
        history.push(`${DOCTOR_ROUTES.PATIENT_PROFILE}/${pId}/${' '}`);
      }
    }
  };

  // const [tabvalue, setTabvalue] = useState(0);
  // const [ekgId, setEkgId] = useState('');
  // const [appId, setAppid] = useState('');
  // const [consultationNote, setconsultationNote] = useState('');

  // const changetabvalue = (event, newValue) => {
  //   setTabvalue(newValue);
  // };
  // const appointmentDetailmodalCloseHandler = () => {
  //   setOpenDetailModal(false);
  // };
  // const setConsultationNote = useCallback(
  //   (note) => {
  //     setconsultationNote(note);
  //     saveConsultationNoteToAPI(note);
  //   },
  //   [appId],
  // );
  // const saveConsultationNoteToAPI = (note) => {
  //   API.setAppointmentNotes(
  //     teamId,
  //     clinicianId,
  //     appId,
  //     note,
  //     // decodeURI(note),
  //   ).subscribe(
  //     (res) => {
  //       console.log('res');
  //     },
  //     (error) => {
  //       console.log('error');
  //     },
  //   );
  // };
  return (
    <>
      <TableContainer component={Paper} className={classes.container}>
        {isLoading ? (
          <EcgLoader />
        ) : (
          <>
            <div className={classes.head}>
              <Typography variant="h5" gutterBottom>
                Today's Session
              </Typography>
              {/* <Typography variant="h6">{moment(new Date()).format('MMM Do YYYY')}</Typography> */}
            </div>
            <Table className={classes.root} aria-label="simple table">
              {sessionData.length > 0 && (
                <TableHead className={classes.tableHeader}>
                  <TableRow hover>
                    <TableCell>
                      <b>Time</b>
                    </TableCell>
                    <TableCell>
                      <b>{userType === AUTH_TYPE.COACH ? `Member` : `Patient`}</b>
                    </TableCell>
                    <TableCell>
                      <b>Status</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
              )}
              <TableBody>
                {sessionData.reverse().map((row, index) => (
                  <TableRow key={index} className={classes.tableRowStyle}>
                    <TableCell>
                      {`${moment(new Date(row.scheduledStartTime)).format('LT')} -
                        ${moment(new Date(row.scheduledEndTime)).format('LT')}`}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {/* <Link
                        className={classes.tableCell_Member}
                        to={`${userRoute}/${row.patient.patientId}`}
                      >
                      </Link> */}
                      <span
                        className={classes.tableCell_Member}
                        onClick={() =>
                          navigate(
                            row.patient.patientId,
                            row.appointmentId,
                            row.appointmentStatusId,
                          )
                        }
                      >
                        {row.patient.lastName} {row.patient.firstName}
                      </span>
                    </TableCell>
                    <TableCell>
                      {' '}
                      <StatusColumn data={row.appointmentStatus} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        )}
        {!isLoading && sessionData.length === 0 && (
          <Typography className={classes.noData}>0 session scheduled for today</Typography>
        )}
      </TableContainer>
      {/* {openDetailModal && (
        <Modal
          show={openDetailModal}
          handleClose={appointmentDetailmodalCloseHandler}
          component={'popover'}
          disable={new Date() > new Date() ? true : false}
        >
          <DoctorDetails
            tabvalue={tabvalue}
            changetabvalue={changetabvalue}
            ekgId={ekgId}
            appointmentId={appId}
            component="popover"
            handleClose={appointmentDetailmodalCloseHandler}
            setConsultationNote={setConsultationNote}
            consultationNote={consultationNote}
            disable={new Date() > new Date() ? true : false}
          />
        </Modal>
      )} */}
    </>
  );
};

export default DashboardTable;
