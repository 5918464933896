/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useEffect, useRef, useState } from 'react';
import { Box, IconButton, Input } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import SendIcon from '@material-ui/icons/Send';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { Flex } from '../Shared/Flex';
import FlexItem from '../Shared/FlexItem';
import Loader from '../Shared/Loader';
import classes from './Chat.module.css';
import MessageBox from './MessageBox';
import withChat from './withChat';
import { colors } from '../../style/styleSettings';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';

// const styles = (theme) => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(2),
//   },
//   closeButton: {
//     position: 'absolute',
//     right: theme.spacing(1),
//     top: theme.spacing(1),
//     color: theme.palette.grey[500],
//   },
// });

// const DialogTitle = withStyles(styles)((props) => {
//   const { patientName, status, classes, onClose, ...other } = props;
//   return (
//     <MuiDialogTitle disableTypography className={classes.root} {...other}>
//       <Typography variant="h6">{patientName}&nbsp;</Typography>
//       <p>{status}&nbsp;</p>
//       {onClose ? (
//         <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
//           <CloseIcon />
//         </IconButton>
//       ) : null}
//     </MuiDialogTitle>
//   );
// });

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    height: 'calc(100vh - 300px) !important',
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    padding: 0,
    // border: '1px solid',
    // borderRadius: '2em',
  },
}))(MuiDialogActions);

const AsyncChat = (props, ref) => {
  const bottomRef = useRef(null);

  const {
    // loading,
    // recStatus,
    messages,
    typing,
    // message,
    // setMessage,
    sendMessageHandler,
    userChatCreds,
    fetchingHistory,
    // getS3BucketData,
    // profileId,
    upLoadFiles,
    uploadFileSuccess,
  } = props;
  const [chatMessages, setChatMessages] = useState({});
  const [textMessages, setTextMessages] = useState('');
  const [loaded, setLoaded] = useState(false);
  // sort the incoming messages
  useEffect(() => {
    if (Object.keys(messages).length > 0) {
      let newObj = {};
      let m = Object.keys(messages);
      m.sort(function (a, b) {
        return new Date(a) - new Date(b);
      });
      //===new===
      //checking for duplicate messages when another client is connected at the same time
      // let sources = Object.values(messages).map((m) => m.source);
      let mapcheck = {};
      m.map((c, i) => {
        let msgs = messages[c];
        //msgs contain per day messages
        msgs.map((d, j) => {
          if (d.id) {
            if (mapcheck[d.id]) {
              // console.log(d.id, mapcheck);
              d.status = 100;
            }
            mapcheck[d.id] = true;
            if (i == m.length - 1 && j == msgs.length - 1) {
              console.log('true');
              d.lastMessage = true;
            }
          }
        });
        return msgs;
      });
      //====new-=====
      m.map((c) => {
        return (newObj[c] = messages[c]);
      });

      setChatMessages(newObj);
      setLoaded(true);
    }
    return () => {
      setChatMessages({});
      setLoaded(false);
    };
  }, [messages]);
  // const handleClose = () => props.onClose(false);

  const checkForHyperLink = (str) => {
    let urlMatch = str.match(
      /[(http(s)?):\/\/(www\.)?-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi,
    );

    let emailMatch = str.match(/\S+@\S+\.\S+/gi);

    let final = str;
    if (emailMatch) {
      emailMatch.map((url) => {
        final = final.replace(
          url,
          '<a style="font-family:Work Sans;font-size:13px;letter-spacing:-0.2px;line-height:20px;" href="mailto:' +
            url +
            '" target="_BLANK">' +
            url +
            '</a>',
        );
      });
      return final;
    }
    if (urlMatch && !emailMatch) {
      urlMatch.map((url) => {
        let hrefUrl = url;

        if (!hrefUrl.includes('://')) {
          hrefUrl = 'https://' + hrefUrl;
        }
        final = final.replace(
          url,
          '<a style="font-family:Work Sans;font-size:13px;letter-spacing:-0.2px;line-height:20px;" href="' +
            hrefUrl +
            '" target="_BLANK">' +
            url +
            '</a>',
        );
      });
      return final;
    }
    return final;
  };

  const enterPressed = (e) => {
    let code = e.keyCode || e.which;
    if (code === 13) {
      if (textMessages.trim()) {
        // setLoaded(false);
        sendMessageHandler(textMessages);
        setTextMessages('');
      }
    }
  };

  const dispatch = useDispatch();
  const pendingRequests = useSelector((state) => state.chat.pendingRequests);
  const memberId = useSelector((state) => state.memberProfile?.programData?.participantId);
  const chat = useSelector((state) => state.chat.chat);
  const [fileError, setFileError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const errortimer = useRef(null);
  const [chatLoaded, setChatLoaded] = useState(false);

  useEffect(() => {
    if (chat?.name) {
      setChatLoaded(false);
      setFileError(true);
      setErrorMessage(chat?.response?.status);
    } else if (chat && Object.keys(chat).length !== 0 && chat !== null) {
      setChatLoaded(false);
      sendMessageHandler(chat);
    }
    return () => dispatch(uploadFileSuccess(null));
  }, [chat]);

  useEffect(() => {
    errortimer.current = setTimeout(() => {
      setFileError(false);
    }, 5000);
    return () => clearTimeout(errortimer.current);
  }, [fileError, errorMessage]);

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    if (loaded || chatLoaded || pendingRequests === 0) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
    }
  }, [loaded, chatMessages, chatLoaded, pendingRequests, fileError]);

  return (
    <Box>
      <DialogContent className={classes.chatBody} /*ref={scrollRef}*/>
        {fetchingHistory && <Loader />}
        {Object.keys(chatMessages).map((day, index) => (
          <div key={index}>
            <div className={classes.messageHeader}>
              {moment(new Date(day)).format('ddd, MMM DD')}
            </div>
            {chatMessages[day].map(
              (m, index) =>
                m.status !== 100 && (
                  <Flex
                    key={index}
                    justify={
                      m.source.split('@')[0] === userChatCreds.kccChatUserID.toLowerCase()
                        ? 'flex-end'
                        : 'flex-start'
                    }
                  >
                    <MessageBox
                      checkForHyperLink={checkForHyperLink}
                      memberName={props.memberName}
                      memberId={memberId !== undefined ? memberId : ''}
                      message={m}
                      setChatLoaded={setChatLoaded}
                      position={
                        m.source.split('@')[0] === userChatCreds.kccChatUserID.toLowerCase()
                          ? 'Right'
                          : 'Left'
                      }
                    />
                  </Flex>
                ),
            )}
          </div>
        ))}
        {typing && (
          <Flex justify={'flex-start'}>
            <div className={classes[`messageLeft`]}>
              <i style={{ fontSize: '10px', color: 'grey' }}>Typing...</i>
            </div>
          </Flex>
        )}

        {/* file upload error alert */}
        {fileError && (
          <Flex justify={'flex-end'}>
            <div className={classes[`messageRight`]}>
              {errorMessage && errorMessage === 400 ? (
                <i className={classes['fileUploadError']}>File format not supported</i>
              ) : (
                <i className={classes['fileUploadError']}>File size in greater than 25MB</i>
              )}
            </div>
          </Flex>
        )}

        {/* loader shows up if there is any pending request/response */}
        {pendingRequests !== undefined && pendingRequests !== 0 && (
          <Flex justify={'flex-end'}>
            <div className={classes[`messageRight`]}>
              <Loader />
            </div>
          </Flex>
        )}

        <div ref={bottomRef} />
      </DialogContent>

      {/* <Divider /> */}
      <DialogActions>
        <FlexItem colspan={10} className={classes.inputFieldContainer}>
          <Input
            placeholder={'Write Something'}
            value={textMessages}
            onChange={(e) => setTextMessages(e.target.value)}
            style={{ padding: '5px 16px', width: '100%' }}
            disableUnderline
            onKeyPress={enterPressed}
          />
          <input
            accept="*"
            id="icon-button-photo"
            onChange={(e) => {
              if (e.target.files?.length) {
                // getS3BucketData && getS3BucketData(profileId, e.target.files[0])
                // upLoadFiles(e.target.files[0])
                Object.values(e.target.files).map((item) => upLoadFiles(item));
              }
            }}
            type="file"
            multiple
            style={{ display: 'none' }}
          />
          <label htmlFor="icon-button-photo">
            <IconButton className={classes.sendButton} component="span">
              <AttachmentIcon htmlColor={colors.ALIVECORE_TEAL} />
            </IconButton>
          </label>
        </FlexItem>
        <FlexItem>
          <IconButton
            className={classes.sendButton}
            onClick={() => {
              if (textMessages.trim()) {
                sendMessageHandler(textMessages);
                setTextMessages('');
              }
              // return null;
            }}
            aria-label="send message"
          >
            <SendIcon htmlColor={colors.ALIVECORE_TEAL} />
          </IconButton>
        </FlexItem>
      </DialogActions>
    </Box>
    // </Dialog>
  );
};

export default withChat(React.forwardRef(AsyncChat));
