import { makeStyles } from '@material-ui/core/styles';

export const programStyles = makeStyles((theme) => ({
  root: {
    '& .MuiSelect-root': {
      fontFamily: 'Work Sans',
    },
    '& .MuiSelect-select': {
      '& :focus': {
        backgroundColor: 'white',
      },
    },
    '& .Mui-selected': {
      backgroundColor: '#F4FAF8 !important',
      // '&:hover': {
      //   backgroundColor: 'green !important',
      // },
    },
    '& .MuiMenuItem-root': {
      fontFamily: 'Work Sans !important',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #484850',
    },
  },
   divWrapperOverride: {
     padding: '16px',
   },
  divHeaderWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 10px',
    '& .MuiBox-root': {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '20px',
      '& .MuiTypography-body2': {
        fontWeight: 600,
        marginLeft: '20px',
      },
    },
  },
  chipHolder: {
    marginTop: '16px',
    display: 'flex',
    // justifyContent:'space-between'
  },
  holder: {
    marginTop: '8px',
  },
  holder_single: {
    margin: '0 !important',
    display: 'flex',
    '&>:nth-child(1)': {
      fontWeight: 600,
    },
    '&>:nth-child(2)': {
      marginLeft: '10px',
    },
  },
  flexWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  weekController: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiGrid-item': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  formControl: {
    minWidth: 120,
  },
  callTypeSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  distanceFromIcon: {
    marginLeft: '20px',
  },
}));
