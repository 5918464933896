import { AUTH_TYPE } from 'constants/app';
import Timezone from 'moment-timezone';
export const getAge = (dateString) => {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};
export function streamToPromise(stream) {
  return new Promise((resolve, reject) => {
    let res;
    stream.subscribe(
      (result) => {
        res = result;
      },
      (err) => reject(err),
      () => resolve(res),
    );
  });
}

export function fetchAuthProfileQueryParam(apiString, profile) {
  switch (profile) {
    case profile === AUTH_TYPE.DOCTOR:
      apiString += 'forDoctor=true';
      break;
    case profile === AUTH_TYPE.COACH:
      apiString += 'forCoach=true';
      break;
    default:

    return apiString;
  }
}
export function move(array, old_index, new_index) {
  // Copy incoming array, we want to return a new one
  let arr = [...array];

  // if old index is negative, assume we are looking backward in array (see https://h3manth.com/new/blog/2013/negative-array-index-in-javascript/)
  while (old_index < 0) {
    old_index += arr.length;
  }

  // if new index is negative, assume we are looking backward in array (see https://h3manth.com/new/blog/2013/negative-array-index-in-javascript/)
  while (new_index < 0) {
    new_index += arr.length;
  }

  // if new index is larger than array length, populate missing elements with undefined values
  if (new_index >= arr.length) {
    var k = new_index - arr.length;
    while (k-- + 1) {
      arr.push(undefined);
    }
  }

  // Insert at new_index the old_index value that we deleted (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/splice)
  arr.splice(
    new_index /* insert value at new_index */,
    0,
    arr.splice(old_index, 1)[0] /* delete value at old_index and return it */,
  );
  return arr;
}

export const formatTime = (timer) => {
  const getSeconds = `0${timer % 60}`.slice(-2);
  const minutes = `${Math.floor(timer / 60)}`;
  const getMinutes = `0${minutes % 60}`.slice(-2);
  // const getHours = `0${Math.floor(timer / 3600)}`.slice(-2)

  return `${getMinutes} : ${getSeconds}`;
};

export function getTimezoneName() {
  return Timezone.tz.guess();
}
export const getLocaleCompareOptions = () => ({
  numeric: false,
  sensitivity: 'variant',
  ignorePunctuation: true,
});
export const SORT_TYPES = {
  ASC: 'ASC',
  DESC: 'DESC',
};
export const StandardCompare = {
  strings: (a = '', b = '', direction = SORT_TYPES.ASC) => {
    let first = a;
    let second = b;

    if (first === null) {
      return direction === SORT_TYPES.ASC ? 1 : -1;
    }

    if (second === null) {
      return direction === SORT_TYPES.ASC ? -1 : 1;
    }

    first = first.trim();
    second = second.trim();

    if (first.length === 0) {
      return direction === SORT_TYPES.ASC ? 1 : -1;
    }

    if (second.length === 0) {
      return direction === SORT_TYPES.ASC ? -1 : 1;
    }

    if (direction === SORT_TYPES.DESC) {
      return second.localeCompare(first, 'en', getLocaleCompareOptions());
    }

    // default ASC
    return first.localeCompare(second, 'en', getLocaleCompareOptions());
  },
  dates: (a = null, b = null, direction = SORT_TYPES.ASC) => {
    if (a === null) {
      return direction === SORT_TYPES.ASC ? 1 : -1;
    }
    if (b === null) {
      return direction === SORT_TYPES.ASC ? -1 : 1;
    }

    const first = new Date(a);
    const second = new Date(b);

    if (direction === SORT_TYPES.DESC) {
      return second - first;
    }

    // default ASC
    return first - second;
  },
  tableStringFilter: (a = null, b = null) => {
    if (a === null || b === null) {
      return false;
    }

    return a.toLowerCase() === b.toLowerCase();
  },
  tableNumberRangeFilter: (
    nums = {
      values: {
        min: null,
        current: null,
        max: null,
      },
      limits: {
        min: Number.NEGATIVE_INFINITY,
        max: Number.POSITIVE_INFINITY,
      },
    },
  ) => {
    if (nums.values.min === nums.limits.min && nums.values.max !== nums.limits.max) {
      return nums.values.current <= nums.values.max;
    } else if (nums.values.min !== nums.limits.min && nums.values.max === nums.limits.max) {
      return nums.values.current >= nums.values.min;
    }

    return nums.values.current >= nums.values.min && nums.values.current <= nums.values.max;
  },
};

export const getPhysicianName = (firstName, lastName, fallbackField) => {
  const firstLetter = firstName ? firstName.charAt(0).toUpperCase() : '';
  return getName(firstLetter, lastName, fallbackField);
};

export const getName = (firstName, lastName, fallbackField) => {
  let result = '';

  if (firstName && lastName) {
    result = `${lastName} ${firstName}`;
  } else if (firstName && !lastName) {
    result = firstName;
  } else if (!firstName && lastName) {
    result = lastName;
  } else {
    result = fallbackField;
  }

  return result;
};

export const ValidatePassword = (password) => {
  // rules
  const minChars = 8;
  const maxChars = 20;
  const containsLowercase = /[a-z]/;
  const containsUppercase = /[A-Z]/;
  const containsWhitespace = /\s/;
  const containsNumber = /\d/;

  const tests = {
    validLength: false,
    noWhitespace: false,
    hasLowercase: false,
    hasUppercase: false,
    hasNumber: false,
  };

  if (password.length <= maxChars && password.length >= minChars) {
    tests.validLength = true;
  }

  tests.noWhitespace = !containsWhitespace.test(password);
  tests.hasLowercase = containsLowercase.test(password);
  tests.hasUppercase = containsUppercase.test(password);
  tests.hasNumber = containsNumber.test(password);

  return tests;
};

export function getFullJid(jid) {
  let { hostname } = new URL(process.env.REACT_APP_CHAT_DOMAIN);
  return `${jid}@${hostname}`;
}
export const makeSimilarReactRouterLocationObject = (location) => ({
  // key: 'ac3df4', // not with HashHistory!
  pathname: location.pathname,
  search: location.search,
  hash: location.hash,
  // state: {
  //   [userDefined]: true
  // }
});

export const toPascalCase = (str) => {
  return str.replace(/\w\S*/g, (m) => m.charAt(0).toUpperCase() + m.substr(1).toLowerCase());
};
