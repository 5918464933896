import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Dialog, IconButton, DialogContent } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import MuiTypography from '../Shared/Typography/MuiTypography';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    outline: 'none',
  },
  smallButton: {
    position: 'absolute',
    backgroundColor: 'rgba(20, 42, 57, 0.15)',
    right: theme.spacing(3),
    top: theme.spacing(2),
    color: 'black',
    padding: '10px',
    margin: '0px 0px 0px 10px',
    border: 'none',
    cursor: 'pointer',
    width: 40,
    height: 40,
    opacity: 0.9,
    borderRadius: '8px',
  },
});
const useStyles = makeStyles((theme) => ({
  root: {
    '&.MuiDialogActions-root': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'inherit',
    },
  },
  buttonText: {
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '0.75px',
    textTransform: 'uppercase',
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'column',
    color: ' #2D9F86 !important',
    padding: '20px 15px 0 0',
  },
}));
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, title, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <MuiTypography
        fontSize="15px"
        fontFamily="Work Sans"
        letterSpacing="0.15px"
        fontWeight={600}
        textTransform="uppercase"
        color="#415B6A"
      >
        {title}
      </MuiTypography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.smallButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function RecursiveConfirmationPopup(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const confirmText = [{ series: 'This event' }, { series: 'This event and all future events' }];

  return (
    <Dialog
      PaperProps={{
        style: {
          padding: '20px',
        },
      }}
      onClose={props.onClose}
      aria-labelledby="simple-dialog-title"
      open={props.show}
      fullWidth={true}
      maxWidth={'sm'}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <DialogTitle
        id="simple-dialog-title"
        onClose={props.onClose}
        title="Recurring Availability"
      />

      <DialogContent style={{ padding: '8px 15px !important' }}>
        <MuiTypography
          fontSize="14px"
          fontFamily="Work Sans"
          letterSpacing="0.15px"
          fontWeight={400}
          //textTransform="camelcase"
          color="#415B6A"
        >
          {t('This is one availability in a series')}. {t('What do you want to')}{' '}
          {props.type ? 'edit' : 'delete'}?
        </MuiTypography>
      </DialogContent>
      <DialogActions p={4} className={classes.root}>
        {confirmText.map((text, index) => (
          // <Grid key={index} item container direction="column">
          //   <a className={classes.buttonText}>{text.series}</a>
          // </Grid>
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a href=''
            className={classes.buttonText}
            key={index}
            onClick={() => props.recursiveSeriesConfirmHanlder(index)}
          >
            {text.series}{' '}
          </a>
        ))}
      </DialogActions>
    </Dialog>
  );
}
