import React from 'react';

import KPLogo from '../Logo';
import MenuIcon from 'assets/images/icons/menu.svg';
import KPCollapse from 'assets/images/icons/back.svg';
import styles from '../NavigationSidebar.module.css';

const TeamProfile = (props) => {
  const { me, sidebarIsCollapsed, handleToggleNavigationSidebar } = props;
  const { data } = me;

  let teamName = '';
  let initials = '';
  let fName = '';
  let lName = '';
  let avatar;

  if (!data) {
    teamName = '';
  } else {
    const { name, logoURL } = data.team;
    const { firstName, lastName } = data.profile;
    teamName = name;
    fName = firstName;
    lName = lastName;
    initials = firstName.charAt(0) + lastName.charAt(0);
    const avatarClassName = sidebarIsCollapsed ? 'avatarCollapsed' : 'avatar';
    const initialsClassName = sidebarIsCollapsed ? 'initialsCollapsed' : 'initials';

    if (logoURL) {
      avatar = <img alt="alivecor logo" className={styles[avatarClassName]} src={logoURL} />;
    } else {
      avatar = (
        <div className={styles[initialsClassName]}>
          <span>{initials}</span>
        </div>
      );
    }
  }

  const profileClassName = sidebarIsCollapsed ? 'profileCollapsed' : 'profile';
  const mainLogoClassName = sidebarIsCollapsed ? 'mainLogoCollapsed' : 'mainLogo';

  return (
    <React.Fragment>
      <div className={styles[mainLogoClassName]}>
        {sidebarIsCollapsed ? (
          <span
            onClick={() => {
              handleToggleNavigationSidebar();
            }}
          >
            <MenuIcon />
          </span>
        ) : (
          <React.Fragment>
            <KPLogo />{' '}
            <span
              onClick={() => {
                handleToggleNavigationSidebar();
              }}
            >
              <KPCollapse
                style={{
                  fill: '#2D9F86',
                }}
              />
            </span>
          </React.Fragment>
        )}
      </div>
      <div className={styles[profileClassName]}>
        {data ? (
          <div>
            {avatar}
            {!sidebarIsCollapsed && (
              <div>
                <h2 className={styles.userName}>
                  {fName} {lName}
                </h2>
                <h2 className={styles.teamName}>
                  {teamName}

                  <br />
                </h2>
              </div>
            )}
          </div>
        ) : (
          <div className={styles.loader}>{/* <Loading /> */}</div>
        )}
      </div>
    </React.Fragment>
  );
};

export default TeamProfile;
