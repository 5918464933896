/* eslint-disable */
import moment from 'moment';
import { TeleKardiaAPI } from '../../Axios/axios';
import { getToken } from 'auth/AuthUtilities';
import * as actionTypes from './actionTypes';
import { getAvailabilities } from './calendar';
import config from '../../setup';

export const getAppointmentsStart = () => {
  return {
    type: actionTypes.GET_APPOINTMENTS_START,
  };
};

export const getAppointmentsSuccess = (data) => {
  return { type: actionTypes.GET_APPOINTMENTS_SUCCESS, payload: data };
};

export const getAppointmentsFail = (error) => {
  return { type: actionTypes.GET_APPOINTMENTS_FAIL, error: error };
};

export const getAppointments = (teamId, clinicianid, params) => {
  return (dispatch) => {
    dispatch(getAppointmentsStart());
    config.TeleKardiaAPI.get(`/api/v1.1/teams/${teamId}/clinicians/${clinicianid}/appointments`, {
      headers: { Authorization: `Bearer ${getToken()}` },
      params: params,
    })
      .then((res) => {
        let fetchedAppointments = [];
        for (let key in res.data.result.appointments) {
          fetchedAppointments.push({
            id: res.data.result.appointments[key].appointmentId,
            patientId: res.data.result.appointments[key].patient.patientId,
            title: res.data.result.appointments[key].patient,
            status: res.data.result.appointments[key].appointmentStatusId,
            start: moment(res.data.result.appointments[key].scheduledStartTime)._d,
            end: moment(res.data.result.appointments[key].scheduledEndTime)._d,
            statusName: res.data.result.appointments[key].appointmentStatusDisplayName,
            ekgId:
              res.data.result.appointments[key].ekgResults &&
              res.data.result.appointments[key].ekgResults.length > 0
                ? res.data.result.appointments[key].ekgResults[0].ekgRecordId
                : null,
          });
        }
        if ('detailed_error' in res.data) {
          dispatch(getAppointmentsFail(res.data['detailed_error']));
        } else dispatch(getAppointmentsSuccess(fetchedAppointments));
      })
      .catch((err) => {
        dispatch(getAppointmentsFail(err));
      });
  };
};

export const deleteAppointmentStart = () => {
  return {
    type: actionTypes.DELETE_APPOINTMENT_START,
  };
};

export const deleteAppointmentSuccess = (id) => {
  return {
    type: actionTypes.DELETE_APPOINTMENT_SUCCESS,
    payload: id,
  };
};

export const deleteAppointmentFail = (error) => {
  return {
    type: actionTypes.DELETE_APPOINTMENT_FAIL,
    error: error,
  };
};

export const deleteAppointment = (
  id,
  clinicianid,
  teamId,
  queryParams,
  appointmentqueryParams,
  component,
  dashboardmetricsParams,
  deleteparams,
) => {
  return (dispatch) => {
    dispatch(deleteAppointmentStart());
    config.TeleKardiaAPI.delete(
      `/api/v1.1/teams/${teamId}/clinicians/${clinicianid}/appointment/${id}`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: deleteparams,
      },
    )
      .then((res) => {
        dispatch(deleteAppointmentSuccess(id));
        if ('detailed_error' in res.data)
          dispatch(deleteAppointmentFail(res.data['detailed_error']));

        if (component === 'dashboardmiddlesection') {
          dispatch(getAppointments(teamId, clinicianid, appointmentqueryParams));
          dispatch(getCliniciansAppointments(teamId, clinicianid, dashboardmetricsParams));
        } else dispatch(getAvailabilities(clinicianid, teamId, queryParams));
      })
      .catch((err) => {
        dispatch(deleteAppointmentFail(err));
      });
  };
};
export const getPatientDetailsStart = () => {
  return {
    type: actionTypes.GET_PATIENTDETAILS_START,
  };
};
export const getPatientDetailsSuccess = (data) => {
  return { type: actionTypes.GET_PATIENTDETAILS_SUCCESS, payload: data };
};

export const getPatientDetailsFail = (error) => {
  return { type: actionTypes.GET_PATIENTDETAILS_FAIL, error: error };
};

export const getPatientDetails = (teamId, clinicianid, appointmentId, params) => {
  return (dispatch) => {
    dispatch(getPatientDetailsStart());
    TeleKardiaAPI.get(
      `/api/v1.1/teams/${teamId}/clinicians/${clinicianid}/appointments/${appointmentId}/patient-detail`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: params,
      },
    )
      .then((res) => {
        if ('detailed_error' in res.data) {
          dispatch(getPatientDetailsFail(res.data.detailed_error));
        } else dispatch(getPatientDetailsSuccess(res.data));
      })
      .catch((err) => {
        dispatch(getPatientDetailsFail(err));
      });
  };
};

export const getCliniciansAppointmentsStart = () => {
  return {
    type: actionTypes.GET_CLINICIANS_APPOINTMENTS_START,
  };
};
export const getCliniciansAppointmentsSuccess = (data) => {
  return { type: actionTypes.GET_CLINICIANS_APPOINTMENTS_SUCCESS, payload: data };
};

export const getCliniciansAppointmentsFail = (error) => {
  return { type: actionTypes.GET_CLINICIANS_APPOINTMENTS_FAIL, error: error };
};
export const getCliniciansAppointments = (teamId, clinicianId, params) => {
  return (dispatch) => {
    dispatch(getCliniciansAppointmentsStart());
    config.TeleKardiaAPI.get(
      `/api/v1/teams/${teamId}/clinicians/${clinicianId}/dashboard-metrics`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: params,
      },
    )
      .then((res) => {
        if ('detailed_error' in res.data)
          dispatch(getCliniciansAppointmentsFail(res.data['detailed_error']));
        else {
          dispatch(getCliniciansAppointmentsSuccess(res.data.result));
        }
      })
      .catch((err) => {
        dispatch(getCliniciansAppointmentsFail(err));
      });
  };
};

export const getClinicianAvailabilityStart = () => {
  return {
    type: actionTypes.GET_CLINICIAN_AVAILABILITY_START,
  };
};
export const getClinicianAvailabilitySuccess = (data) => {
  return { type: actionTypes.GET_CLINICIAN_AVAILABILITY_SUCCESS, payload: data.result };
};

export const getCliniciansAvailabilityFail = (error) => {
  return { type: actionTypes.GET_CLINICIAN_AVAILABILITY_FAIL, error: error };
};
export const getClinicianAvailability = (clinicianId, teamId, params) => {
  return (dispatch) => {
    dispatch(getClinicianAvailabilityStart());
    config.TeleKardiaAPI.get(
      `/api/v1/teams/${teamId}/clinicians/${clinicianId}/dashboard-metrics/availability`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: params,
      },
    )
      .then((res) => {
        if ('detailed_error' in res.data)
          dispatch(getCliniciansAvailabilityFail(res.data['detailed_error']));
        else dispatch(getClinicianAvailabilitySuccess(res.data));
      })
      .catch((err) => {
        dispatch(getCliniciansAvailabilityFail(err));
      });
  };
};
