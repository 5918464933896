import React, { useEffect, useState } from 'react';
import { Field, reduxForm, FieldArray, change } from 'redux-form';
import styles from './Forms.module.css';
import MaskedInput from './components/MaskedInput';
import CheckboxInput from './components/CheckboxInput';
import SelectField from '@material-ui/core/Select';
import {
  emailMask,
  phoneValidation,
  requiredValidation,
  emailValidation,
  minLen1Validation,
  // phoneValidationWith1Prefix,
  nameValidation,
  // minPhoneNumberValidation,
  // ophn1Prefeix
} from './components/formValidations';
// import { green } from '@material-ui/core/colors';
import Loading from '../Loading';
import TextInput from './components/TextInput';
import { FormControl, InputLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { countryList } from './constants';
import MenuItem from '@material-ui/core/MenuItem';

import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import Link from '@material-ui/core/Link';
import { useDispatch, useSelector } from 'react-redux';
import pluseIcon from 'assets/images/Vector.png';
import { AUTH_TYPE } from 'constants/app';
import { useTranslation } from 'react-i18next';

const RegionList = [
  { name: 'ALABAMA', value: 'AL' },
  { name: 'ALASKA', value: 'AK' },
  { name: 'AMERICAN SAMOA', value: 'AS' },
  { name: 'ARIZONA', value: 'AZ' },
  { name: 'ARKANSAS', value: 'AR' },
  { name: 'CALIFORNIA', value: 'CA' },
  { name: 'COLORADO', value: 'CO' },
  { name: 'CONNECTICUT', value: 'CT' },
  { name: 'DELAWARE', value: 'DE' },
  { name: 'DISTRICT OF COLUMBIA', value: 'DC' },
  { name: 'FEDERATED STATES OF MICRONESIA', value: 'FM' },
  { name: 'FLORIDA', value: 'FL' },
  { name: 'GEORGIA', value: 'GA' },
  { name: 'GUAM', value: 'GU' },
  { name: 'HAWAII', value: 'HI' },
  { name: 'IDAHO', value: 'ID' },
  { name: 'ILLINOIS', value: 'IL' },
  { name: 'INDIANA', value: 'IN' },
  { name: 'IOWA', value: 'IA' },
  { name: 'KANSAS', value: 'KS' },
  { name: 'KENTUCKY', value: 'KY' },
  { name: 'LOUISIANA', value: 'LA' },
  { name: 'MAINE', value: 'ME' },
  { name: 'MARSHALL ISLANDS', value: 'MH' },
  { name: 'MARYLAND', value: 'MD' },
  { name: 'MASSACHUSETTS', value: 'MA' },
  { name: 'MICHIGAN', value: 'MI' },
  { name: 'MINNESOTA', value: 'MN' },
  { name: 'MISSISSIPPI', value: 'MS' },
  { name: 'MISSOURI', value: 'MO' },
  { name: 'MONTANA', value: 'MT' },
  { name: 'NEBRASKA', value: 'NE' },
  { name: 'NEVADA', value: 'NV' },
  { name: 'NEW HAMPSHIRE', value: 'NH' },
  { name: 'NEW JERSEY', value: 'NJ' },
  { name: 'NEW MEXICO', value: 'NM' },
  { name: 'NEW YORK', value: 'NY' },
  { name: 'NORTH CAROLINA', value: 'NC' },
  { name: 'NORTH DAKOTA', value: 'ND' },
  { name: 'NORTHERN MARIANA ISLANDS', value: 'MP' },
  { name: 'OHIO', value: 'OH' },
  { name: 'OKLAHOMA', value: 'OK' },
  { name: 'OREGON', value: 'OR' },
  { name: 'PALAU', value: 'PW' },
  { name: 'PENNSYLVANIA', value: 'PA' },
  { name: 'PUERTO RICO', value: 'PR' },
  { name: 'RHODE ISLAND', value: 'RI' },
  { name: 'SOUTH CAROLINA', value: 'SC' },
  { name: 'SOUTH DAKOTA', value: 'SD' },
  { name: 'TENNESSEE', value: 'TN' },
  { name: 'TEXAS', value: 'TX' },
  { name: 'UTAH', value: 'UT' },
  { name: 'VERMONT', value: 'VT' },
  { name: 'VIRGIN ISLANDS', value: 'VI' },
  { name: 'VIRGINIA', value: 'VA' },
  { name: 'WASHINGTON', value: 'WA' },
  { name: 'WEST VIRGINIA', value: 'WV' },
  { name: 'WISCONSIN', value: 'WI' },
  { name: 'WYOMING', value: 'WY' },
];
// const GreenCheckbox = withStyles({
//   root: {
//     color: green[400],
//     '&$checked': {
//       color: green[600],
//     },
//   },
//   checked: {},
// })((props) => <Checkbox color="default" {...props} />);

const NewMemberForm = (props) => {
  const { handleSubmit, invalid, pristine, submitting, isSubmitting, inviteMemberFormValues } =
    props;
  // const [required, setRequired] = useState(false);
  // const dispatch = useDispatch();
  // common array for all selected country and region.
  let allCountryRegionArray = [];

  // getting values from counrty and region field from teh form.
  if (inviteMemberFormValues && inviteMemberFormValues.region) {
    allCountryRegionArray.push({
      country: inviteMemberFormValues.country || '',
      region: inviteMemberFormValues.region,
    });
  }

  // geeting values form the members array and combine to the allCountryRegionArray
  if (
    inviteMemberFormValues &&
    inviteMemberFormValues.members &&
    inviteMemberFormValues.members.length > 0
  ) {
    allCountryRegionArray = [...allCountryRegionArray, ...inviteMemberFormValues.members];
  }

  // const RegionListRemoveSelected = RegionList.filter(function (cv) {
  //   return !allCountryRegionArray.find(function (er) {
  //     return er.region == cv.value;
  //   });
  // });
  const [disableSave, setDisableSave] = useState(false);
  useEffect(() => {
    if (!(inviteMemberFormValues?.isPhysician || inviteMemberFormValues?.isAdmin)) {
      setDisableSave(true);
    } else {
      setDisableSave(false);
    }
  }, [inviteMemberFormValues]);
  const renderSelectField = withStyles({
    select: {
      // height: '10px',
    },
    selectMenu: {
      minHeight: 'unset',
      background: 'transparent !important',
    },
  })(({ input, label, meta: { touched, error }, children, ...custom }) => (
    <FormControl size="small" variant="outlined">
      <InputLabel>{label}</InputLabel>
      <SelectField
        floatinglabeltext={label}
        errortext={touched ? error : ''}
        className={styles.selectFieldStyle}
        {...input}
        // eslint-disable-next-line react/no-children-prop
        children={children}
        {...custom}
      />
      {/* {!input.value ? setRequired(true) : setRequired(false)} */}
      {!input.value && <span className={styles.error}>{'Required'}</span>}
    </FormControl>
  ));

  const dispatch = useDispatch();
  const renderMembersSingleRegion = (temp) => (
    <ul style={{ padding: 0 }}>
      <li
        className={
          inviteMemberFormValues &&
          inviteMemberFormValues.region === null &&
          inviteMemberFormValues.country === null
            ? styles.displayNone
            : styles.editDropdown
        }
      >
        <div>
          <InputLabel className={styles.labelColorDropdown}>Country</InputLabel>
          <Field
            name="country"
            component={renderSelectField}
            // validate={[ requiredValidation ]}
            // label="Country"
          >
            {countryList.map((item, i) => (
              <MenuItem key={i} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </Field>
        </div>
        <div>
          <InputLabel className={styles.labelColorDropdown}>Region</InputLabel>
          <Field
            name="region"
            component={renderSelectField}
            // validate={[ requiredValidation ]}
            // label="Region"
          >
            {RegionList.map((item, i) => (
              <MenuItem
                key={i}
                disabled={allCountryRegionArray.find(function (rr) {
                  return rr.region == item.value ? true : false;
                })}
                value={item.value}
              >
                {item.name}
              </MenuItem>
            ))}
          </Field>
        </div>
        {/* {submitFailed && error && <span>{error}</span>} */}
      </li>
      {temp.fields.map((member, index) => (
        <li className={styles.editDropdown} key={index}>
          <div>
            <InputLabel className={styles.labelColorDropdown}>Country</InputLabel>
            <Field
              name={`${member}.country`}
              component={renderSelectField}
              // validate={[ requiredValidation ]}
              // label="Country"
            >
              {countryList.map((item, i) => (
                <MenuItem key={i} value={item.value}>
                  {item.name}
                </MenuItem>
              ))}
            </Field>
          </div>
          <div>
            <InputLabel className={styles.labelColorDropdown}>Region</InputLabel>
            <Field
              name={`${member}.region`}
              component={renderSelectField}
              // validate={[ requiredValidation ]}
              // label="Region"
            >
              {RegionList.map((item, i) => (
                <MenuItem
                  key={i}
                  disabled={allCountryRegionArray.find(function (r) {
                    return r.region == item.value ? true : false;
                  })}
                  value={item.value}
                >
                  {item.name}
                </MenuItem>
              ))}
            </Field>
          </div>
        </li>
      ))}
    </ul>
  );
  const renderMembersMultiRegion = (temp) => (
    <ul style={{ padding: 0 }}>
      <li
        className={
          inviteMemberFormValues &&
          inviteMemberFormValues.region === null &&
          inviteMemberFormValues.country === null
            ? styles.displayNone
            : styles.editDropdown
        }
      >
        <div>
          <InputLabel className={styles.labelColorDropdown}>Country</InputLabel>
          <Field
            name="country"
            component={renderSelectField}
            // validate={[ requiredValidation ]}
            // label="Country"
          >
            {countryList.map((item, i) => (
              <MenuItem key={i} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </Field>
        </div>
        <div>
          <InputLabel className={styles.labelColorDropdown}>Region</InputLabel>
          <Field
            name="region"
            component={renderSelectField}
            // validate={[ requiredValidation ]}
            // label="Region"
          >
            {RegionList.map((item, i) => (
              <MenuItem
                key={i}
                disabled={allCountryRegionArray.find(function (rr) {
                  return rr.region == item.value ? true : false;
                })}
                value={item.value}
              >
                {item.name}
              </MenuItem>
            ))}
          </Field>
        </div>
        <div style={{ marginTop: 34 }}>
          <Link
            href="#"
            style={{ marginLeft: 8 }}
            className={temp.fields.length === 0 ? styles.buttonVisibleMyinfo : styles.buttonCss}
            onClick={() => {
              dispatch(change('addNewMemberForm', 'region', null));
              dispatch(change('addNewMemberForm', 'country', null));
            }}
          >
            Remove
          </Link>
          <img
            style={{ marginTop: -6 }}
            className={temp.fields.length === 0 ? styles.buttonCss : styles.buttonVisibleMyinfo}
            src={pluseIcon}
            alt="add button"
          />
          <Link
            style={{ marginLeft: 8 }}
            href="#"
            className={temp.fields.length === 0 ? styles.buttonCss : styles.buttonVisibleMyinfo}
            onClick={() => temp.fields.push({})}
          >
            Add Location
          </Link>
        </div>
        {/* {submitFailed && error && <span>{error}</span>} */}
      </li>
      {temp.fields.map((member, index) => (
        <li className={styles.editDropdown} key={index}>
          <div>
            <InputLabel className={styles.labelColorDropdown}>Country</InputLabel>
            <Field
              name={`${member}.country`}
              component={renderSelectField}
              // validate={[ requiredValidation ]}
              // label="Country"
            >
              {countryList.map((item, i) => (
                <MenuItem key={i} value={item.value}>
                  {item.name}
                </MenuItem>
              ))}
            </Field>
          </div>
          <div>
            <InputLabel className={styles.labelColorDropdown}>Region</InputLabel>
            <Field
              name={`${member}.region`}
              component={renderSelectField}
              // validate={[ requiredValidation ]}
              // label="Region"
            >
              {RegionList.map((item, i) => (
                <MenuItem
                  key={i}
                  disabled={allCountryRegionArray.find(function (r) {
                    return r.region == item.value ? true : false;
                  })}
                  value={item.value}
                >
                  {item.name}
                </MenuItem>
              ))}
            </Field>
          </div>
          <div style={{ marginTop: 34 }}>
            <Link
              href="#"
              style={{ marginLeft: 8 }}
              className={
                temp.fields.length > 0 && temp.fields.length === index
                  ? styles.buttonVisibleMyinfo
                  : styles.buttonCss
              }
              onClick={() => temp.fields.remove(index)}
            >
              Remove
            </Link>
          </div>
        </li>
      ))}
      {temp.fields.map((member, index) => (
        <div style={{ marginTop: 14 }}>
          <img
            style={{ marginTop: -6 }}
            className={
              temp.fields.length > 0 && temp.fields.length - 1 === index
                ? styles.buttonCss
                : styles.buttonVisibleMyinfo
            }
            src={pluseIcon}
            alt="add button"
          />
          <Link
            href="#"
            style={{ marginLeft: 8 }}
            className={
              temp.fields.length > 0 && temp.fields.length - 1 === index
                ? styles.buttonCss
                : styles.buttonVisibleMyinfo
            }
            onClick={() => temp.fields.push({})}
          >
            Add Location
          </Link>
        </div>
      ))}
    </ul>
  );
  const { t } = useTranslation();

  const userType = useSelector((state) => state.user.chatProfile.userType);

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.formSection}>
        <h2 style={{ paddingTop: 0 }} className={styles.formSubHeader}>
          {userType === AUTH_TYPE.COACH ? 'Coach Information' : 'Member Information'}
        </h2>
        <div className={styles.formSectionRow} style={{ marginTop: '32px' }}>
          {props.showTKFeatureEnabled && (
            <React.Fragment>
              <div className={styles.editLabelGroup}>
                <Field
                  component={TextInput}
                  label="First Name"
                  name="firstName"
                  placeholder="Enter First Name"
                  required={true}
                  validate={[minLen1Validation, requiredValidation, nameValidation]}
                />
              </div>
              <div className={styles.editLabelGroup}>
                <Field
                  component={TextInput}
                  label="Last Name"
                  name="lastName"
                  placeholder="Enter Last Name"
                  required={true}
                  validate={[minLen1Validation, requiredValidation, nameValidation]}
                />
              </div>
              <div className={styles.editLabelGroup}>
                <Field
                  component={TextInput}
                  rightIcon={PhoneIcon}
                  label="Mobile"
                  name="phone"
                  placeholder="Enter mobile number"
                  required={true}
                  validate={[phoneValidation, requiredValidation]}
                  type={'number'}
                />
              </div>
            </React.Fragment>
          )}
          <div className={styles.editLabelGroup}>
            <Field
              component={MaskedInput}
              rightIcon={EmailIcon}
              mask={emailMask}
              placeholder="name@example.com"
              label="Email"
              name="email"
              required={true}
              validate={[emailValidation, minLen1Validation, requiredValidation]}
            />
          </div>

          {/* <div className={styles.placeholder} />
          <div className={styles.placeholder} /> */}
        </div>
        {props.showTKFeatureEnabled && (
          <React.Fragment>
            <div style={{ marginTop: 15 }}>
              {/* <InputLabel>Country</InputLabel> */}
              <div className={styles.editDropdown}>
                <FieldArray name="members" component={userType===AUTH_TYPE.COACH?renderMembersSingleRegion:renderMembersMultiRegion} />
              </div>
            </div>
          </React.Fragment>
        )}

        <h2 className={styles.formSubHeader}>{userType === AUTH_TYPE.DOCTOR ? t('Provider Roles') : t('Coach Roles')}</h2>

        <div className={styles.formSectionRow}>
          <div className={styles.checkboxWithDesc}>
            <Field
              component={CheckboxInput}
              label={userType === AUTH_TYPE.DOCTOR ? t('Provider') : t('Coach')}
              name="isPhysician"
            />
            <p className={styles.description}>
              {' '}
              {userType === AUTH_TYPE.DOCTOR ? t('DoctorDescription') : t('CoachDescription')}
            </p>
          </div>
          <div className={styles.checkboxWithDesc}>
            <Field
              component={CheckboxInput}
              label={
                userType === AUTH_TYPE.DOCTOR
                  ? t('Account Administrator')
                  : t('Coach Administrator')
              }
              name="isAdmin"
            />
            <p className={styles.description}>
              {' '}
              {userType === AUTH_TYPE.DOCTOR
                ? t('AccountAdministratorDescription')
                : t('CoachAdministratorDescription')}
            </p>
          </div>
          <div className={styles.placeholder} />
        </div>
        {userType === AUTH_TYPE.COACH && (
          <>
            <h2 style={{ marginLeft: 0, paddingTop: '40px' }} className={styles.formSubHeader}>
              Appointment Notifications
            </h2>
            <p className={styles.appointmentText}>
              Please choose the type of notification you would like this coach to receive for
              upcoming appointments
            </p>
            <div className={styles.formSectionRow}>
              <div style={{ justifyContent: 'unset' }} className={styles.formSectionRow}>
                <React.Fragment>
                  <div style={{ width: '10%' }} className={styles.checkboxWithDesc}>
                    <Field component={CheckboxInput} label="Text" name="isText" />
                  </div>
                  <div className={styles.checkboxWithDesc}>
                    <Field component={CheckboxInput} label="Email" name="isEmail" />
                  </div>
                  <div className={styles.placeholder} />
                </React.Fragment>
              </div>
            </div>
          </>
        )}
      </div>

      <div className={styles.inviteSubmitWrapper}>
        <div className={styles.buttonWrapper}>
          {!pristine && (submitting || isSubmitting) ? (
            <button disabled className={styles.submitLoading}>
              <Loading />
            </button>
          ) : (
            <button
              type="submit"
              disabled={invalid || pristine || disableSave}
              className={styles.submit}
            >
              Send Invite
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'addNewMemberForm',
})(NewMemberForm);
