/* eslint-disable  */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getAge } from 'utilities/Utilities';
import _get from 'lodash.get';
import moment from 'moment';
import VonageTest from './VonageCall';
import { useTranslation } from 'react-i18next';

const PatientDetails = (props) => {
  const { t } = useTranslation();
  const [updatedpatientDetails, setupdatedpatientDetails] = React.useState([]);
  const [duration, setduration] = React.useState('');

  useEffect(() => {
    setupdatedpatientDetails(props.patientData);
    let start = moment(_get(props.patientData, 'patientAppointment.scheduledStartTime'));
    let end = moment(_get(props.patientData, 'patientAppointment.scheduledEndTime'));
    let diff = end.diff(start, 'minutes');
    setduration(diff);
  }, [props.patientData]);
  return (
    <>
      {/* <div className="row">
                {props.stepper === 2 && 
                <>
                             <VonageTest {...props} patientName={(_get(updatedpatientDetails, "patient.firstName") +" "+ _get(updatedpatientDetails, "patient.lastName")) || "-"} mobile={_get(updatedpatientDetails, "patient.phone") || "-"} />
            </>
            }
            </div> */}

      <div className="row no-gutters">
        <div
          className={
            props.step === 2
              ? 'col-12 p-3 bg_white mb-md-3 mb-xl-3'
              : 'col-12 height460 bg_white p-3 mb-md-3 mb-xl-3'
          }
        >
          <div className="row no-gutters mb-3">
            <div className="col-12">
              <h5 className="head">
                {(_get(updatedpatientDetails, 'patient.lastName') || '-') +
                  ' ' +
                  (_get(updatedpatientDetails, 'patient.firstName') || '-')}
              </h5>
            </div>
          </div>
          <div className="row no-gutters" style={{ display: '-ms-flexbox' }}>
            <div className="col-7">
              <div className="row m-0">
                <label className="col-form-label subhead p-0">{t('Age')}: </label>
                <div className="pl-1">
                  <span className="form-control-plaintext p-0">
                    {getAge(_get(updatedpatientDetails, 'patient.dob'))
                      ? getAge(_get(updatedpatientDetails, 'patient.dob')) + ' years'
                      : '-'}
                  </span>
                </div>
              </div>
              <div className="row m-0">
                <label className="col-form-label subhead p-0">{t('Gender')}: </label>
                <div className="pl-1">
                  <span className="form-control-plaintext p-0">
                    {_get(updatedpatientDetails, 'patient.gender') || '-'}
                  </span>
                </div>
              </div>
              <div className="row m-0">
                <label className="col-form-label subhead p-0">{t('Location')}: </label>
                <div className="pl-1">
                  <span className="form-control-plaintext p-0">
                    {_get(updatedpatientDetails, 'patient.location') || '-'}
                  </span>
                </div>
              </div>
              <div className="row m-0">
                <label className="col-form-label subhead p-0">{t('Weight')}: </label>
                <div className="pl-1">
                  <span className="form-control-plaintext p-0">
                    {_get(updatedpatientDetails, 'patient.weight')
                      ? _get(updatedpatientDetails, 'patient.weight') <= 0
                        ? '0'
                        : _get(updatedpatientDetails, 'patient.weight') + ' lb'
                      : '-'}
                  </span>
                </div>
              </div>
              <div className="row m-0">
                <label className="col-form-label subhead p-0">{t('Height')}: </label>
                <div className="pl-1">
                  <span className="form-control-plaintext p-0">
                    {updatedpatientDetails && _get(updatedpatientDetails, 'patient.height')
                      ? Math.floor(_get(updatedpatientDetails, 'patient.height') / 30.48) +
                        "'" +
                        ' ' +
                        (Math.floor(_get(updatedpatientDetails, 'patient.height') / 2.54) -
                          12 * Math.floor(_get(updatedpatientDetails, 'patient.height') / 30.48)) +
                        '"'
                      : '-'}
                  </span>
                </div>
              </div>
              <div className="row m-0">
                <label className="col-form-label subhead p-0">{t('BMI')}: </label>
                <div className="pl-1">
                  <span className="form-control-plaintext p-0">
                    {_get(updatedpatientDetails, 'patient.bmi')
                      ? _get(updatedpatientDetails, 'patient.bmi') <= 0
                        ? '0'
                        : _get(updatedpatientDetails, 'patient.bmi')
                      : '-'}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-5">
              <div className="row m-0 pb-4">
                <label className="col-form-label subhead p-0">{t('Contact')}: </label>
                <div className="pl-1">
                  <span className="form-control-plaintext p-0">
                    <label className="col-form-label contacthead p-0">
                      {_get(updatedpatientDetails, 'patient.phone') || '-'}
                    </label>
                  </span>
                </div>
              </div>

              <div className="row m-0">
                <label className="col-form-label subhead p-0">{t('Session Time')}: </label>
                <div className="pl-1">
                  <span className="form-control-plaintext p-0">
                    {_get(updatedpatientDetails, 'patientAppointment.scheduledStartTime')
                      ? moment(
                          _get(updatedpatientDetails, 'patientAppointment.scheduledStartTime') ||
                            '-',
                        ).format('hh:mm A') +
                        '-' +
                        moment(
                          _get(updatedpatientDetails, 'patientAppointment.scheduledEndTime') || '-',
                        ).format('hh:mm A') +
                        ' ' +
                        '(' +
                        `${duration}` +
                        ' Minutes)'
                      : '-'}
                  </span>
                </div>
              </div>
              <div className="row m-0">
                <label className="col-form-label subhead p-0">{t('Type of Session')}: </label>
                <div className="pl-1">
                  <span className="form-control-plaintext p-0">
                    {_get(updatedpatientDetails, 'patientAppointment.appointmentType') || '-'}
                  </span>
                </div>
              </div>
              <div className="row m-0">
                <label className="col-form-label subhead p-0">{t('Method of Session')}: </label>
                <div className="pl-1">
                  <span className="form-control-plaintext p-0">
                    {_get(updatedpatientDetails, 'patientAppointment.appointmentChannelType') ||
                      '-'}
                  </span>
                </div>
              </div>
              <div className="row m-0">
                <label className="col-form-label subhead p-0">{t('Email')}: </label>
                <div className="pl-1">
                  <span className="form-control-plaintext p-0">
                    {_get(updatedpatientDetails, 'patient.email') || '-'}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row m-0 mt-3">
            <div className="col-12 p-0">
              <label className="col-form-label p-0">
                <strong>{t('Reason for Session')}: </strong>
                {_get(updatedpatientDetails, 'patientAppointment.visitReason') || '-'}
              </label>
            </div>
          </div> */}
          {/* <div className="row m-0 mt-3">
            <div className="col-12 p-0">
              <label className="col-form-label p-0">
                <strong>{t('Symptoms')}: </strong>
              </label>
              <table className="table table-striped mb-0">
                <thead>
                  <tr>
                    <th scope="col">Symptom</th>
                    <th scope="col">Severity </th>
                    <th scope="col">Duration</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(updatedpatientDetails).length > 0 &&
                  updatedpatientDetails.symptoms &&
                  updatedpatientDetails.symptoms.length > 0 ? (
                    updatedpatientDetails.symptoms.map((item, index) => (
                      <tr key={index}>
                        <td>{item.name}</td>
                        <td>{item.severity}</td>
                        <td>{item.duration}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>{'-'}</td>
                      <td>{'-'}</td>
                      <td>{'-'}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default PatientDetails;
