import { TextField, makeStyles, InputAdornment } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  root: {
    marginBottom: '20px',
    '& .MuiOutlinedInput-input': {
      padding: '10px 15px !important',
    },
  },
});
function InputFieldComponent(props) {
  const classes = useStyles();
  return (
    <>
      <TextField
        className={classes.root}
        id={props.id}
        value={props.value}
        name={props.name}
        onChange={props.onChange}
        type={props.type}
        fullWidth={props.fullWidth}
        error={props.error}
        helperText={props.helperText}
        placeholder={props.placeholder}
        variant="outlined"
        InputProps={{
          endAdornment: props.showIcon ? (
            <InputAdornment position="end">{props.icon()}</InputAdornment>
          ) : null,
        }}
      />
    </>
  );
}

export default InputFieldComponent;
