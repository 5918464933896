import React from 'react';
import styles from './comingSoon.module.css';
import DashboardIcon from 'assets/images/poll.svg';
import { useHistory } from 'react-router';
import { ROUTES } from 'constants/app';

const ComingSoonPage = ({ type }) => {
  const history = useHistory();
  const handleRouteToDashboard = () => {
    history.push(ROUTES.ROOT);
  };
  return (
    <div className={styles.NotFoundPage}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height:"100%",
          flexDirection:"column"

        }}
      >
        <h3>COMING SOON</h3>
        <p>Head back to the Dashboard.</p>
        <button className={styles.dashboardBtn} onClick={handleRouteToDashboard}>
          <DashboardIcon />
          Dashboard
        </button>
      </div>
    </div>
  );
};

export default ComingSoonPage;
