import { connect } from 'react-redux'
import Modal from './Modal'
import { closeModal } from 'store/reducers/app'

const mapActionCreators = {
  closeModal,
}

const mapStateToProps = state => ({
  modalIsOpen: state.app.modalIsOpen,
})

export default connect(
  mapStateToProps,
  mapActionCreators
)(Modal)
