/* eslint-disable */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import * as actions from 'store/actions';
import { setResetIdleTime } from 'auth/AuthUtilities';
import { connect, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import SidebarLayout from 'components/SidebarLayout/index';
import * as Screens from 'screens/common';
import ContentHeader from 'components/ContentHeader';
import { Paper, Container } from '@material-ui/core';
import { AUTH_TYPE, COACH_ROUTES, DOCTOR_ROUTES, getPageHeaderTitle, ROUTES } from 'constants/app';
import GlobalSearch from 'screens/coach/Common/GlobalSearch';
import AppNotification from 'components/AppNotification';
import * as DoctorRoutes from './routes/doctor';
import * as CoachRoutes from './routes/coach';
import AdhocCallWindow from 'screens/common/AdhocPhone/AdhocCallWindow';

const useStyles = makeStyles(() => ({
  accessDeniedContent: {
    backgroundColor: '#FFFFFF',
    margin: '50px 0 0 0 ',
    padding: '40px',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useNetwork = (onChange) => {
  const [status, setStatus] = useState(navigator.onLine);
  const handleChange = () => {
    if (typeof onChange === 'function') {
      onChange(navigator.onLine);
    }
    setStatus(navigator.onLine);
  };
  useEffect(() => {
    window.addEventListener('online', handleChange);
    window.addEventListener('offline', handleChange);
    return () => {
      window.removeEventListener('online', handleChange);
      window.removeEventListener('offline', handleChange);
    };
  }, []);
  return status;
};

const App = (props) => {
  const [permissions, setPermissions] = React.useState();
  const classes = useStyles();
  const [offlinealertopen, setofflinealertopen] = React.useState(false);
  // const [currentSocketUrl, setCurrentSocketUrl] = useState(null);
  // const SOCKET_URL_ONE = 'wss://echo.websocket.org';
  const [snackbaropen, setsnackbaropen] = React.useState(false);
  const [networkstatus, setnetworkstatus] = useState('online');
  const [routes, setRoutes] = useState([]);
  const isCallStarted = useSelector((state) => state.adhocCall.isFloatingWindow);

  // const handleNetworkChange = (online) => {
  //   online ? setofflinealertopen(false) : setofflinealertopen(true);
  //   online ? setnetworkstatus('online') : setnetworkstatus('We just offline');
  // };

  // const closeofflinepopup = () => {
  //   setofflinealertopen(false);
  // };
  // const onLine = useNetwork(handleNetworkChange);

  const handlesnackbarClose = (event, reason) => {
    setsnackbaropen(false);
  };

  const pageTitle = useMemo(
    () => getPageHeaderTitle(window.location.pathname, permissions?.isAdmin, props.userType),
    [window.location.pathname, permissions],
  );

  useEffect(() => {
    if (props.me) {
      const { permissions } = props.me || {};

      let routeConfig = {
        userAndAdmin: null,
        user: null,
        admin: null,
      };
      if (props.userType == AUTH_TYPE.COACH) {
        routeConfig.userAndAdmin = CoachRoutes.CoachAdmin;
        routeConfig.user = CoachRoutes.Coach;
        routeConfig.admin = CoachRoutes.Admin;
      }
      if (props.userType == AUTH_TYPE.DOCTOR) {
        routeConfig.userAndAdmin = DoctorRoutes.DoctorAdmin;
        routeConfig.user = DoctorRoutes.Doctor;
        routeConfig.admin = DoctorRoutes.Admin;
      }
      setPermissions(permissions);
      const { userAndAdmin, user, admin } = routeConfig;
      let r =
        permissions?.isAdmin && permissions?.isPhysician
          ? userAndAdmin
          : permissions?.isAdmin
          ? admin
          : user;
      setRoutes(r);
    }
    return () => setRoutes([]);
  }, [props.me]);

  const redirectRootManager = (userType, isAdmin) => {
    let redirectRoute = null;
    switch (userType) {
      case AUTH_TYPE.COACH:
        redirectRoute = isAdmin
          ? COACH_ROUTES.ADMIN_TELEKARDIA_DASHBOARD
          : COACH_ROUTES.TELEKARDIA_DASHBOARD;
        break;
      case AUTH_TYPE.DOCTOR:
        redirectRoute = isAdmin
          ? DOCTOR_ROUTES.TELEKARDIA_ADMIN_DASHBOARD
          : DOCTOR_ROUTES.TELEKARDIA_DASHBOARD;
        break;
    }
    return redirectRoute;
  };
  return (
    <SidebarLayout>
      <div className="container-fluid p-0">
        {isCallStarted && <AdhocCallWindow />}
        <AppNotification />

        <div onClick={setResetIdleTime}>
          {pageTitle && (
            <Container maxWidth="xl">
              <ContentHeader
                showLastRefresh={false}
                showAddPatient={false}
                text={pageTitle.name}
                memberWaiting={false}
                {...props}
              />
              {pageTitle.isSearchEnabled && (
                <Paper style={{ padding: '10px' }}>
                  <GlobalSearch />
                </Paper>
              )}
            </Container>
          )}
          <div>
            <Switch>
              {routes?.length > 0 && props.me && (
                <Route exact path={ROUTES.ROOT}>
                  <Redirect to={redirectRootManager(props.userType, permissions.isAdmin)} />
                </Route>
              )}
              {routes?.length > 0 &&
                props.me &&
                routes.map((route, index) => <Route key={index} {...route} />)}
              <Route component={Screens.NotFoundPage} />
            </Switch>
          </div>
        </div>
        {/* <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={snackbaropen}
          autoHideDuration={3000}
          onClose={handlesnackbarClose}
        >
          <Alert
            onClose={handlesnackbarClose}
            severity={networkstatus === 'online' ? 'success' : 'error'}
          >
            {networkstatus}
          </Alert>
        </Snackbar> */}
      </div>
    </SidebarLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    me: state.user?.profile,
    userType: state?.user.chatProfile.userType,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    onAddUser: (x) => dispatch(actions.addUser(x)),
    onLogOut: () => dispatch(actions.user_logout()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
