import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';
// import Timezone from 'moment-timezone';
import Paper from '@material-ui/core/Paper';
import { Collapse, makeStyles } from '@material-ui/core';
import { EnhancedTableHead } from '../Common/EnhancedTableHead';
// import { useTranslation } from 'react-i18next';
import Filter from './components/Filter';
import { useHistory } from 'react-router';
import { COACH_ROUTES, ROUTES } from 'constants/app';
import moment from 'moment';

import { headCells, memberFilters } from './data/mockData';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminSessions } from 'store/actions/user';
import EcgLoader from 'components/Shared/EcgLoader';
// import ConsultationsDetailModal from 'components/AdminConsultations/ConsultationsDetailModal';
import { getTimezoneName } from 'utilities/Utilities';
import { fontSizes, colors } from '../../../style/styleSettings';
import { GoAPI, TeleKardiaAPI } from '../../../Axios/axios';
import ViewNotes from '../Common/ViewNotes';
import { getToken } from 'auth/AuthUtilities';
import { sortParamActionsHandler } from 'store/actions/user.js';
const componentStyle = makeStyles({
  containerStyle: {
    //marginTop: '70px',
  },

  tableRowStyle: {
    '&:nth-of-type(4n+1)': {
      background: 'rgb(250, 250, 250) !important',
    },
    '&:hover': {
      backgroundColor: '#F0F8F5 !important',
    },
  },
  viewNotesTableColumn: {
    color: colors.SPRUCE,
    fontWeight: 'bold',
    fontSize: `${fontSizes.CAPTION} !important`,
    cursor: 'pointer',
  },
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  // console.log(stabilizedThis);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function Row({ row }) {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  // const [openReport, setopenReport] = useState(false);
  const [viewNotesData, setviewNotesData] = useState({});
  const [viewHHData, setViewHHData] = useState([]);
  const [notesLoading, setNotesLoading] = useState(false);
  const teamId = useSelector((state) => state.user.team.id);
  const coachId = useSelector((state) => state.user.profile.id);
  const offsetInMinutes = new Date().getTimezoneOffset();
  const timeZone = getTimezoneName();
  const classes = componentStyle();
  //NAVIGATION TO MEMBER PROFILE SCREEN ON CLICK OF MEMBER COLUMN DATA
  const memberProfileNavigation = (value, e) => {
    history.push(`${COACH_ROUTES.MEMBER_PROFILE}/${value}`);
  };
  //NAVIGATION TO MEMBER PROFILE SCREEN ON CLICK OF MEMBER COLUMN DATA
  const coachProfileNavigation = (value, e) => {
    history.push(`${ROUTES.SETTINGS}/team-members/${value}`);
  };

  const computeTime = (start, end) => {
    console.log(moment.duration(moment(end).diff(moment(start))).asMinutes());
    let mins = moment.duration(moment(end).diff(moment(start))).asMinutes();

    return `${mins} minutes`;
  };
  const viewNotes = (row) => {
    if (row.reportCompleted) {
      // setopenReport(true);
      return;
    } else {
      setNotesLoading(true);

      //fetching program focus data from telekardia
      TeleKardiaAPI.get(
        `/api/v1/teams/${teamId}/clinicians/${coachId}/patients/${row.patient.patientId}/appointments/${row.appointmentId}/detail?offsetInMinutes=${offsetInMinutes}&timeZone=${timeZone}`,
        {
          headers: { Authorization: ` Bearer ${getToken()}` },
        },
      )
        .then((res) => {
          const data = res.data.result;
          setviewNotesData(data);
          setNotesLoading(false);
        })
        .then(() => {
          //fetching heart habit data
          GoAPI.get(`/i/v1/kcc/heartHabit/${row.patient.patientId}/${row.appointmentId}`, {
            headers: { Authorization: ` Bearer ${getToken()}` },
          })
            .then((res) => {
              const data = res.data ? res.data : [];
              setViewHHData(data);
              // setNotesLoading(false);
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));

      setOpen(!open);
    }
  };
  return (
    <>
      <TableRow hover tabIndex={-1} key={row.id} className={classes.tableRowStyle}>
        <TableCell style={{ fontSize: '14px', fontFamily: 'Work Sans' }}>
          {moment(row.startdatetime).format('MM-DD-YYYY')}
        </TableCell>
        <TableCell style={{ fontSize: '14px', fontFamily: 'Work Sans' }}>
          {moment(row.startdatetime).format('hh:mm A')} -{moment(row.enddatetime).format('hh:mm A')}
        </TableCell>
        <TableCell
          style={{ fontSize: '14px', fontFamily: 'Work Sans', cursor: 'pointer' }}
          onClick={() => coachProfileNavigation(row.physician.memberId)}
        >
          <u>{`${row.physician.lastName ? row.physician.lastName : ''} ${
            row.physician.firstName ? row.physician.firstName : ''
          }`}</u>
        </TableCell>

        <TableCell
          style={{ fontSize: '14px', fontFamily: 'Work Sans', cursor: 'pointer' }}
          onClick={() => memberProfileNavigation(row.patient.patientId)}
        >
          <u>{`${row.patient.lastName ? row.patient.lastName : ''} ${
            row.patient.firstName ? row.patient.firstName : ''
          }  `}</u>
        </TableCell>

        <TableCell style={{ fontSize: '14px', fontFamily: 'Work Sans' }}>
          {row.appointmentStatusDisplayName}
        </TableCell>
        <TableCell style={{ fontSize: '14px', fontFamily: 'Work Sans' }}>
          {computeTime(row.startdatetime, row.enddatetime)}
        </TableCell>
        <TableCell style={{ fontSize: '14px', fontFamily: 'Work Sans' }}>
          {row.appointmentIndex === 0 ? 'Initial Consult' : 'Ongoing'}
        </TableCell>
        <TableCell style={{ fontSize: '14px', fontFamily: 'Work Sans' }}>
          {row.appointmentChannelType}
        </TableCell>
        <TableCell className={classes.viewNotesTableColumn} onClick={() => viewNotes(row)}>
          {open ? 'HIDE NOTES' : 'VIEW NOTES'}
          <IconButton aria-label="expand row" size="small">
            {open ? (
              <KeyboardArrowUpIcon style={{ color: colors.SPRUCE }} />
            ) : (
              <KeyboardArrowRightIcon style={{ color: colors.SPRUCE }} />
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow className={classes.tableBody}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {!!notesLoading ? (
              <EcgLoader />
            ) : (
              <ViewNotes sessionLogsViewNotes={viewNotesData} hhData={viewHHData} />
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function Sessions(props) {
  const orderMap = {
    asc: 'ascending',
    desc: 'descending',
  };
  const dispatch = useDispatch();
  // const { t } = useTranslation();
  // const timezoneString = useSelector((state) => state.user.team.timezone);
  // const offset = -Timezone.tz(timezoneString).utcOffset();
  const classes = componentStyle();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('lastName');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tableHeadCells, setTableHeadCells] = useState([]);
  const [pageCount, setPageCount] = useState(null);
  // const [open, setOpen] = useState(false);

  const [mL, setMl] = useState([]);
  //modal related
  // const [currentModalData, setCurrentModalData] = useState(null);
  // const [isConsultationDetailModalOpen, setIsConsultationDetailModalOpen] = useState(false);
  const coachId = useSelector((state) => state.user.profile.id);
  const teamId = useSelector((state) => state.user.team.id);
  const { data, loading } = useSelector((state) => state.sessions);
  const savedFilterData = useSelector((state) => state.sessions.sessionsFilterParams);
  const savedSortData = useSelector((state) => state.sessions.sessionsSortParams);
  useEffect(() => {
    dispatch(getAdminSessions(teamId, coachId, page, rowsPerPage));
  }, [teamId, coachId, page, rowsPerPage, dispatch]);
  useEffect(() => {
    if (savedSortData) {
      fetchAdminSessionsHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedSortData]);
  const fetchAdminSessionsHandler = () => {
    //=========Process filter data==========
    let coachesString = '';
    let statusString = '';
    let coachesArray = [];
    let statusArray = [];
    if (savedFilterData) {
      //make status

      let allStatuses = memberFilters.status.map((m) => m.id);
      allStatuses.map((m) => {
        if (savedFilterData[m].checked === true) {
          statusArray.push(savedFilterData[m].code.toString());
        }
        return m;
      });
      //make selected coaches

      Object.values(savedFilterData).map((i) => {
        if (i.type === 'coach' && i.label !== 'all-coach') {
          coachesArray.push(i.value);
        }
        return i;
      });

      coachesString = coachesArray.length > 0 ? coachesArray.join(',') : '';
      statusString = statusArray.join(',');
    }

    //=========Process sort data==========
    // console.log(savedSortData);
    let sortOrder = savedSortData?.order || undefined;
    let sortParam = savedSortData?.property || undefined;
    //call api
    dispatch(
      getAdminSessions(
        teamId,
        coachId,
        page,
        rowsPerPage,
        statusString,
        coachesString,
        orderMap[sortOrder],
        sortParam,
      ),
    );
  };
  /**
   * Populating head cells based on admin or coach
   */
  useEffect(() => {
    setTableHeadCells(headCells);
  }, []);

  /**
   * Data decoration for viewing
   */
  useEffect(() => {
    // console.log('setting new member list')

    if (!loading && data.appointments) {
      let temp = [...data.appointments];
      setMl(temp);
      setPageCount(data.pages);
    }
  }, [data, loading]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    let newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    dispatch(sortParamActionsHandler({ property, order: newOrder }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getAdminSessions(teamId, coachId, newPage, rowsPerPage));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatch(getAdminSessions(teamId, coachId, 0, parseInt(event.target.value, 10)));
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + 0) * rowsPerPage - mL.length) : 0;

  //======never used... thus commenting out....====//
  // const modalDataHandler = (data) => {
  //   setCurrentModalData({
  //     appointidofmodal: data.appointmentId,
  //     patientInfo: data.patient,
  //     memberId: data.physician.memberId,
  //   });
  //   setIsConsultationDetailModalOpen(true);
  // };
  // const consultationDetailModalOpenCloseHandler = () => {
  //   setIsConsultationDetailModalOpen((v) => !v);
  // };

  return (
    <Box sx={{ width: '100%', padding: '24px' }} className={classes.containerStyle}>
      {/* {currentModalData && (
        <ConsultationsDetailModal
          key="report-modal"
          appointmentId={currentModalData.appointidofmodal}
          patient={currentModalData.patientinfo}
          memberId={currentModalData.memberId}
          clinicianId={coachId}
          teamId={teamId}
          show={isConsultationDetailModalOpen}
          offset={offset}
          handleClose={() => consultationDetailModalOpenCloseHandler()}
        />
      )} */}
      <Paper sx={{ width: '100%', mb: 2 }}>
        <Filter
          memberFilters={memberFilters}
          fetchAdminSessionsHandler={fetchAdminSessionsHandler}
        />
        <TableContainer style={{ minHeight: '600px' }}>
          <Table aria-labelledby="Member table">
            <EnhancedTableHead
              from="adminSessions"
              columnData={tableHeadCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {!loading &&
                mL &&
                stableSort(mL, getComparator(order, orderBy))
                  .slice(0 * rowsPerPage, 0 * rowsPerPage + rowsPerPage)
                  .map((row, index) => <Row key={index} row={row} index={index} />)}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={4} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          {loading && <Box style={{ width: '100%', height: '100%' }}>{<EcgLoader />}</Box>}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={pageCount || 1}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
