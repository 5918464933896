/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from 'react';
import { Grid, Container, Box, IconButton } from '@material-ui/core';
import ListPageAdminConsultations from '../Shared/List/ListPageAdminConsultations';
import ConsultationsDetailModal from './ConsultationsDetailModal';
import apiservice from 'api/services/apiservice.service';
import { streamToPromise, getTimezoneName } from 'utilities/Utilities';
import get from 'lodash.get';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Timezone from 'moment-timezone';
import BottomLineTitle from '../Shared/Typography/BottomLineTitle';
import MuiTypography from '../Shared/Typography/MuiTypography';
import CachedIcon from '@material-ui/icons/Cached';
import { useTranslation } from 'react-i18next';
import { DOCTOR_ROUTES } from 'constants/app';
import { appointmentStatusName } from 'utilities/Constants';

function AdminConsultations(props) {
  const { t } = useTranslation();
  const { history } = props;
  const clinicianId = useSelector((state) => state.user.profile.id);
  const teamId = useSelector((state) => state.user.team.id);
  const timezoneString = useSelector((state) => state.user.team.timezone);

  const timezoneValue = getTimezoneName();
  const offset = -Timezone.tz(timezoneString).utcOffset();
  const [, setpageSize] = useState('');
  const [isConsultationDetailModalOpen, setisConsultationDetailModalOpen] = useState(false);

  const [appointidofmodal, setappointidofmodal] = useState('');
  const [patientinfo, setpatientinfo] = useState({});
  const [sortPropsName, setSortPropsName] = useState('Date');
  const [sortordertype, setSortordertype] = useState('asc');
  const [memberId, setmemberId] = useState('');
  const consultationDetailModalOpenCloseHandler = () => {
    setisConsultationDetailModalOpen(false);
    setappointidofmodal('');
    setpatientinfo({});
  };

  const [refreshaction, setrefreshaction] = useState(Date.now());
  const [refreshlistaction, setrefreshlistaction] = useState(false);

  const onRefreshClick = () => {
    setrefreshaction(Date.now());
    setrefreshlistaction(true);
  };

  useEffect(() => {
    return () => {
      setisConsultationDetailModalOpen(false);
    };
  }, []);

  const loadAction = useCallback(async (pageDetail) => {
    setrefreshlistaction(false);
    // Assign promises to variables so they run in parallel
    const skip = pageDetail.pageNumber ? (pageDetail.pageNumber - 1).toString() : '0';
    const nonStringSkip = pageDetail.pageNumber ? pageDetail.pageNumber : 1;
    const searchInput = pageDetail.searchInput ? pageDetail.searchInput.toString() : '';

    // const sortpropsname = pageDetail.sortprops && pageDetail.sortprops.sortparameters ? pageDetail.sortprops.sortparameters.headername : undefined;

    // const ordertype = pageDetail.sortprops && pageDetail.sortprops.sortparameters ? pageDetail.sortprops.sortparameters.order : undefined;

    const take = pageDetail.take ? pageDetail.take.toString() : '10';
    setpageSize(take);

    const sortpropsname =
      pageDetail.sortprops && pageDetail.sortprops.sortparameters
        ? pageDetail.sortprops.sortparameters.headername
        : undefined;
    const ordertype =
      pageDetail.sortprops && pageDetail.sortprops.sortparameters
        ? pageDetail.sortprops.sortparameters.order
        : undefined;
    const combinecountryvalue =
      pageDetail.countries && !get(pageDetail, 'countries').includes('All')
        ? pageDetail.countries
        : '';
    const combineregionvalue =
      pageDetail.regions && !get(pageDetail, 'regions').includes('All') ? pageDetail.regions : '';

    if (pageDetail.filter && Object.keys(pageDetail.filter).length > 0) {
      setSortPropsName(sortpropsname);
      setSortordertype(ordertype === 'asc' ? 'asc' : 'desc');
      let sortpropsnamenew =
        sortpropsname === t('Patient')
          ? 'member'
          : sortpropsname === t('Cardiologist')
          ? 'coach'
          : sortpropsname === t('Type')
          ? 'type'
          : sortpropsname === t('Date')
          ? 'date'
          : sortpropsname === t('Time')
          ? 'time'
          : sortpropsname;
      let sortordertypenew =
        ordertype === 'desc' ? 'descending' : ordertype === 'asc' ? 'ascending' : undefined;
      const localfilterstatus =
        get(pageDetail.filter, 'status') && !get(pageDetail.filter, 'status').includes('100')
          ? get(pageDetail.filter, 'status')
          : '';
      let offsetInMinutes = offset;
      const startDateformatted = get(pageDetail.filter, 'startDate') || '';
      const endDateformatted = get(pageDetail.filter, 'endDate') || '';
      // const memberIddata = get(pageDetail.filter, "memberId") && get(pageDetail.filter, "memberId") !== "All"? get(pageDetail.filter, "memberId") :  "";
      const memberIddata =
        get(pageDetail.filter, 'memberIdarray') &&
        !get(pageDetail.filter, 'memberIdarray').includes('all')
          ? get(pageDetail.filter, 'memberIdarray')
          : '';
      const appointmentRequest = streamToPromise(
        apiservice.getapiservice({
          baseUrl:
            get(pageDetail.filter, 'memberIdarray') &&
            // get(pageDetail.filter, "memberIdarray") !== "All"
            !get(pageDetail.filter, 'memberIdarray').includes('all')
              ? `api/v2/teams/${teamId}/admin/${clinicianId}/appointments?offsetInMinutes=${offsetInMinutes}&pageNumber=${skip}&pageSize=${take}&appointmentStatus=${localfilterstatus}&searchString=${searchInput}&startDateTime=${startDateformatted}&endDateTime=${endDateformatted}&filterMemberIds=${memberIddata}&sortOrder=${sortordertypenew}&sortParam=${sortpropsnamenew}&timeZone=${timezoneValue}&countryIds=${combinecountryvalue}&regionIds=${combineregionvalue}`
              : `api/v2/teams/${teamId}/admin/${clinicianId}/appointments?offsetInMinutes=${offsetInMinutes}&pageNumber=${skip}&pageSize=${take}&appointmentStatus=${localfilterstatus}&searchString=${searchInput}&startDateTime=${startDateformatted}&endDateTime=${endDateformatted}&sortOrder=${sortordertypenew}&sortParam=${sortpropsnamenew}&timeZone=${timezoneValue}&countryIds=${combinecountryvalue}&regionIds=${combineregionvalue}`,
        }),
      );

      const appointments = await appointmentRequest;
      setmemberId(appointments.data.result.memberId);
      setrefreshaction(Date.now());
      const data = appointments.data.result.appointments.map((item) => {
        return {
          ...item,
          Patient: `name-${item.patient.lastName === null ? '-' : item.patient.lastName} ${
            item.patient.firstName === null ? '-' : item.patient.firstName
          }`,
          date: `${moment(item.startdatetime).format('MM/DD/YYYY')} `,
          Cardiologist: `${item.physician.lastName === null ? '-' : item.physician.lastName} ${
            item.physician.firstName === null ? '-' : item.physician.firstName
          }`,
          Type: item.appointmentType,
          Status: item.appointmentStatusDisplayName,
          Method: item.appointmentChannelType,
          disabledAction: item.isOverReadReportCompleted || item.appointmentStatus === 'Completed',
          Time: `${moment(item.startdatetime).format('hh:mm A')}-${moment(item.enddatetime).format(
            'hh:mm A',
          )}`,
          appointmentId: `${item.appointmentId}`,
          patientId: `${item.patient.patientId}`,
        };
      });
      return {
        data,
        meta: {
          count: appointments.data.result.totalElements,
          currentPage: nonStringSkip,
        },
      };
    } else {
      let offsetInMinutes = offset;
      let status = '';
      setSortPropsName(sortpropsname);
      setSortordertype(ordertype === 'asc' ? 'asc' : 'desc');

      let sortpropsnamenew =
        sortpropsname === t('Patient')
          ? 'member'
          : sortpropsname === t('Cardiologist')
          ? 'Coach'
          : sortpropsname === t('Type')
          ? 'type'
          : sortpropsname === t('Date')
          ? 'date'
          : sortpropsname === t('Time')
          ? 'time'
          : sortpropsname;
      let sortordertypenew =
        ordertype === 'desc' ? 'descending' : ordertype === 'asc' ? 'ascending' : undefined;
      // const appointmentRequest = streamToPromise(
      //   apiservice.getapiservice({
      //     baseUrl: `api/v2/clinicians/${clinicianId}/appointments?offsetInMinutes=${offsetInMinutes}&pageNumber=${skip}&pageSize=${take}&appointmentStatus=${status}`,
      //   })
      // );
      const appointmentRequest = streamToPromise(
        apiservice.getapiservice({
          baseUrl: `api/v2/teams/${teamId}/admin/${clinicianId}/appointments?offsetInMinutes=${offsetInMinutes}&pageNumber=${skip}&pageSize=${take}&appointmentStatus=${status}&searchString=${searchInput}&sortOrder=${sortordertypenew}&sortParam=${sortpropsnamenew}&timeZone=${timezoneValue}&countryIds=${combinecountryvalue}&regionIds=${combineregionvalue}`,
        }),
      );

      const appointments = await appointmentRequest;
      setmemberId(appointments.data.result.memberId);
      setrefreshaction(Date.now());
      const data = appointments.data.result.appointments.map((item) => {
        return {
          ...item,
          Patient: `name-${item.patient.lastName === null ? '-' : item.patient.lastName} ${
            item.patient.firstName === null ? '-' : item.patient.firstName
          }`,
          date: `${moment(item.startdatetime).format('MM/DD/YYYY')}`,
          Cardiologist: `${item.physician.lastName === null ? '-' : item.physician.lastName} ${
            item.physician.firstName === null ? '-' : item.physician.firstName
          }`,
          Type: item.appointmentType,
          Status: item.appointmentStatusDisplayName,
          Method: item.appointmentChannelType,
          disabledAction:
            item.isOverReadReportCompleted === true || item.appointmentStatus === 'Completed',
          Time: `${moment(item.startdatetime).format('hh:mm A')}-${moment(item.enddatetime).format(
            'hh:mm A',
          )}`,
          appointmentId: `${item.appointmentId}`,
          patientId: `${item.patient.patientId}`,
        };
      });
      return {
        data,
        meta: {
          count: appointments.data.result.totalElements,
          currentPage: nonStringSkip,
        },
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const viewAdminConsultationDetailsAction = useCallback(({ entity, history: hstry }) => {
    setappointidofmodal(entity.appointmentId);
    setpatientinfo(entity.patient);
    setisConsultationDetailModalOpen(true);
  }, []);

  const openPatientProfile = (pId, appId, status) => {
    if (
      [
        appointmentStatusName.SCHEDULED,
        appointmentStatusName.INPROGRESS,
        appointmentStatusName.WAITING_ROOM,
        appointmentStatusName.ENCOUNTER_SUMMARY,
      ].includes(status)
    ) {
      history.push(`${DOCTOR_ROUTES.PATIENT_PROFILE}/${pId}/${appId}`);
    } else {
      history.push(`${DOCTOR_ROUTES.PATIENT_PROFILE}/${pId}/${' '}`);
    }
  };
  return (
    <div className="row no-gutters all_appointments">
      <div className="col-12">
        <Container maxWidth="xl">
          <Box mt={2} />
          {/* <Grid alignItems="center" container justify="space-between">
            <Grid item>
              <MuiTypography
                fontSize="34px"
                fontFamily="Work Sans"
                lineHeight="40px"
                letterSpacing="0.25px"
                fontWeight={500}
                color="#000000"
              >
                {t('Consultations')}
              </MuiTypography>

              <BottomLineTitle />
            </Grid>
            <Grid item>
              <Grid alignItems="center" container justify="space-between">
                <Grid item>
                  <MuiTypography
                    fontSize="12px"
                    fontFamily="Work Sans"
                    lineHeight="20px"
                    letterSpacing="-0.2px"
                    textTransform="uppercase"
                    color="#536063"
                  >
                    {t('LAST REFRESHED')} {moment(refreshaction).format('hh:mm A')}
                  </MuiTypography>
                </Grid>
                <Grid item>
                  <IconButton onClick={() => onRefreshClick()}>
                    <CachedIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box mt={2} /> */}

          {appointidofmodal !== '' && (
            <ConsultationsDetailModal
              key="report-modal"
              appointmentId={appointidofmodal}
              patient={patientinfo}
              memberId={memberId}
              clinicianId={clinicianId}
              teamId={teamId}
              show={isConsultationDetailModalOpen}
              offset={offset}
              handleClose={() => consultationDetailModalOpenCloseHandler()}
            />
          )}

          <ListPageAdminConsultations
            feature="Appointments"
            entityProps={[
              'date',
              'Time',
              'Cardiologist',
              'Patient',
              'Type',
              'Method',
              'Status',
              'disabledAction',
            ]}
            refreshaction={refreshlistaction}
            loadAction={loadAction}
            loading="false"
            history={history}
            sortPropsName={sortPropsName}
            sortordertype={sortordertype}
            headers={[
              t('Date'),
              t('Time'),
              t('Provider'),
              t('Member'),
              t('Type'),
              t('Method'),
              t('Status'),
            ]}
            searchEnabled={true}
            viewAdminConsultationDetailsAction={viewAdminConsultationDetailsAction}
            toggleField={'isActive'}
            openPatientProfileAction={openPatientProfile}
            filters={[
              {
                name: 'test',
                options: [
                  { value: '', label: 'Select a filter' },
                  { value: '123', label: 'Example filter' },
                ],
              },
            ]}
          />
        </Container>
      </div>
    </div>
  );
}

export default AdminConsultations;
