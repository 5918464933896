/* eslint-disable */
import React from 'react';
import { Column } from 'react-virtualized';
import { components } from 'react-select';
import moment from 'moment';

import Table from '../components/Table';
import TableStyles from '../components/Table/Table.module.css';
import TableHeader from '../components/TableHeader';
import TableActions from '../components/TableActions';
import TableActionStyles from '../components/TableActions/TableActions.module.css';
import HeartIcon from 'assets/images/icons/profile/beat.svg';
import ConfirmationIcon from 'assets/images/icons/check-circle.svg';
import {
  DatePopover,
  SliderPopover,
  DropdownSearchPopover,
  MultiSelectListPopover,
  SearchPopover,
} from '../../Popovers';
import EKGPreview from '../../chart/EKGPreview';
import NoEKGPreview from '../../Metrics/NoEKGPreview';
import styles from './EkgTable.module.css';
import {
  getDateFormat,
  getTimeFormat,
  EKG_TABLE_COLUMN_HEADERS,
  TABLE_CHECKBOX_WIDTH,
  TABLE_BULK_ACTIONS,
  CONFIRMED_OPTION,
  CONFIRMED,
  TRIAGE,
  KARDIA_DETERMINATIONS,
} from 'constants/app';
import {
  getName,
  getPatientName,
  getMrnFromRecordingParticipantProfile,
  getPatientNameFromRecordingParticipantProfile,
  getAnalysisBy,
  isConfirmedAnalysisBy,
  getPhysicianName,
  isEscKey,
} from '../../Utilities/Utilities';
import Loading from '../../Loading';
import { getSelectedList } from '../Utilities';
import Modal from '../../Metrics/Modal';
import modalStyles from '../../Metrics/Modal/Modal.module.css';
import SendIcon from 'assets/images/icons/check-circle.svg';
import SixLeadIcon from 'assets/images/icons/check-circle.svg';
import InformationIcon from 'assets/images/icons/check-circle.svg';
import DescriptiveToolTip from '../../Metrics/DescriptiveToolTip';

class EkgTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: {},
      displayDeterminationToolTip: false,
      selectedData: {},
      // popoverHeight: ''
    };
  }

  static getDerivedStateFromProps(props, s) {
    const state = s;

    if (state.selected !== props.selected) {
      state.selected = props.selected;
    }

    return state;
  }

  dateFormat = getDateFormat(this.props.me);
  timeFormat = getTimeFormat(this.props.me);

  handleOnSelectionClick = (selected) => {
    const { onSelectionClick } = this.props;

    this.setState({
      selected,
    });

    if (typeof onSelectionClick === 'function') {
      onSelectionClick(selected);
    }
  };

  renderAnalysisBy = (rowData) => {
    const rec = rowData;
    const isConfirmed = isConfirmedAnalysisBy(rec);

    const primaryText = getAnalysisBy(rec);
    const secondaryTextClassName = `${TableStyles.secondaryText} ${TableStyles.number}`;
    const content = !isConfirmed ? (
      primaryText
    ) : (
      <React.Fragment>
        <div className={secondaryTextClassName}>{primaryText}</div>
        <div className={styles.confirmed}>
          <ConfirmationIcon className={styles.confirmedIcon} />
          Confirmed
        </div>
      </React.Fragment>
    );

    return <div className={secondaryTextClassName}>{content}</div>;
  };

  handleToolTipClose = (e) => {
    this.setState({ displayDeterminationToolTip: false, selectedData: {} });
    e.stopPropagation();
  };

  render() {
    const {
      noDataText = 'No recordings found.',
      enableLoader,
      showLoader,
      showRowSelection = false,
      data,
      rowHeight,
      width,
      onScroll,
      onRowClick,
      selectedIndex,
      sort,
      sortBy,
      sortDirection,
      filterDate,
      filterPatient,
      filterAnalysis,
      filterAnalysisBy,
      filterPhysicians,
      filterAssignedPhysicians = undefined,
      filterQtOrderingPractice = undefined,
      orderingPacticesOptions = undefined,
      filterBpm,
      filterQtcf = undefined,

      handleOnFilterButtonClick,
      handleOnFilterChange,
      handleOnSortClick,
      handleOnOutsidePopoverClick,
      handleOnBulkAction,
      handleOnBulkActionTriggerModal,
      handleOnBulkActionCloseModal,
      handleOnBulkActionClearSelection,
      bulkActionComplete,
      bulkActionIsFetching,
      bulkActionSuccessMessage,
      showBulkActionRemove,
      selectedPopover,

      selectedInboxId,

      showConfirmedDate,
      selectedStartDate,
      selectedEndDate,
      dateRange,

      selectedBpmStartValue,
      selectedBpmEndValue,
      bpmRange,

      selectedQtcfStartValue = undefined,
      selectedQtcfEndValue = undefined,
      qtcfRange = undefined,

      selectedPatientText = undefined,
      selectedIntrText = undefined,

      showPatientColumns,
      patients,
      selectedPatientId,

      physicians,
      selectedPhysicianId,
      physicianOptions,
      selectedPhysicians,
      selectedAssignedPhysicians = undefined,
      selectedQtOrderingPractice = undefined,
      ekgResults,
      selectedEkgResult,
      showOrderingProvider,
      serviceProviderTable = false,
      selectedPageTab,
    } = this.props;
    const { selected } = this.state;

    let inboxTitle;
    switch (selectedInboxId) {
      case TRIAGE:
        inboxTitle = 'Practice';
        break;
      case CONFIRMED:
        inboxTitle = 'Archived';
        break;
      default:
        inboxTitle = 'Personal';
        break;
    }

    const isAnySelected = Object.keys(selected).some((key) => selected[key] === true);
    // console.log(selected, isAnySelected, this.props.selected)

    const ROW_HEIGHT = rowHeight;
    const DATE_WIDTH = 260;
    const PATIENT_WIDTH = 190;
    const ANALYSIS_BY_WIDTH = 350; // 220
    const ORDERING_PHYSICIAN_WIDTH = 190;
    const ORDERING_PRACTICE_WIDTH = 190;
    const QTCF_VALUE_WIDTH = 190;
    const INTERPRETATION_COMMENT_WIDTH = 190;
    const ASSIGNED_CARDIOLOGIST_WIDTH = 190;
    const BPM_WIDTH = 500;
    // let analysisWidth = width - DATE_WIDTH - ANALYSIS_BY_WIDTH - BPM_WIDTH;
    let analysisWidth = 356;
    analysisWidth = showPatientColumns ? analysisWidth - PATIENT_WIDTH : analysisWidth;

    if (showRowSelection) {
      analysisWidth -= TABLE_CHECKBOX_WIDTH;
    }

    // FIX: not clean but will fix later
    let analysisClassname;
    if (showRowSelection && showPatientColumns) {
      analysisClassname = styles.analysisPopoverEkgReviewWithSelection;
    } else if (showRowSelection && !showPatientColumns) {
      analysisClassname = styles.analysisPopoverWithSelection;
    } else if (!showRowSelection && showPatientColumns) {
      analysisClassname = styles.analysisPopoverEkgReview;
    } else {
      analysisClassname = styles.analysisPopover;
    }

    let selectedPatient;
    let patientsOptions;

    if (patients && patients.data) {
      patientsOptions = patients.data.sortedByLastName.map((id) => patients.data.byId[id]);
      selectedPatient = selectedPatientId && patients.data.byId[selectedPatientId];
    }

    let selectedPhysician;
    let physiciansOptions;

    if (physicians && physicians.data) {
      physiciansOptions = physicians.data.sortedByLastName.map((id) => physicians.data.byId[id]);
      selectedPhysician = selectedPhysicianId && physicians.data.byId[selectedPhysicianId];
    }

    const PATIENT_NAME_MRN_SPLIT_DELIMITER = '!~|~!~|~!';

    const Option = ({ children, ...props }) => {
      const parts = children.split(PATIENT_NAME_MRN_SPLIT_DELIMITER);
      const name = parts[0];
      const mrn = parts[1];

      return (
        <div className={styles.patientOption}>
          <components.Option {...props}>
            <p className={TableStyles.primaryText}>{name}</p>
            <p className={`${TableStyles.secondaryText} ${TableStyles.number}`}>{mrn}</p>
          </components.Option>
        </div>
      );
    };

    const SingleValue = ({ children, ...props }) => {
      const parts = children.split(PATIENT_NAME_MRN_SPLIT_DELIMITER);
      const name = parts[0];
      const mrn = parts[1];

      return (
        <div className={styles.patientOption}>
          <components.SingleValue {...props}>
            <p className={TableStyles.primaryText}>{name}</p>
            <p className={`${TableStyles.secondaryText} ${TableStyles.number}`}>{mrn}</p>
          </components.SingleValue>
        </div>
      );
    };

    const numberOfSelection = getSelectedList(selected).length;

    let ecgBulkActionRemoveButton;

    if (showBulkActionRemove) {
      ecgBulkActionRemoveButton = bulkActionIsFetching ? (
        <button disabled className={TableActionStyles.submitLoading}>
          <Loading />
        </button>
      ) : (
        <React.Fragment>
          <button
            className={TableActionStyles.actionButton}
            onClick={() => handleOnBulkActionTriggerModal(TABLE_BULK_ACTIONS.REMOVE_FROM_STACK)}
          >
            <span>{numberOfSelection === 1 ? 'Remove ECG' : 'Remove ECGs'}</span>
          </button>
          <Modal
            className={styles.bulkActionRemoveModal}
            onClose={(e) => handleOnBulkActionCloseModal()}
          >
            <div className={modalStyles.header}>Are you sure?</div>
            <div className={modalStyles.body}>
              <p>
                {numberOfSelection === 1
                  ? `You are about to remove ${numberOfSelection} ECG from the ${inboxTitle} Inbox`
                  : `You are about to remove ${numberOfSelection} ECGs from the ${inboxTitle} Inbox`}
              </p>
              <p>
                {numberOfSelection === 1
                  ? "It will still be available in patient's file."
                  : "They will still be available in patient's file."}
              </p>
            </div>
            <div className={modalStyles.controls}>
              <button
                className={modalStyles.secondaryButton}
                onClick={() => handleOnBulkActionCloseModal(TABLE_BULK_ACTIONS.REMOVE_FROM_STACK)}
              >
                Cancel
              </button>
              <button
                className={modalStyles.primaryButton}
                onClick={() => handleOnBulkAction(TABLE_BULK_ACTIONS.REMOVE_FROM_STACK)}
              >
                Remove
              </button>
            </div>
          </Modal>
        </React.Fragment>
      );
    }

    return (
      <div className={styles.ekgTable}>
        <Table
          // headerHeight={35}
          noDataText={noDataText}
          enableLoader={enableLoader}
          showLoader={showLoader}
          showRowSelection={showRowSelection}
          disableRowSelection={bulkActionComplete || bulkActionIsFetching}
          selected={selected}
          rowHeight={ROW_HEIGHT}
          uniqueKey="id"
          hasTableActions={isAnySelected}
          onSelectionClick={this.handleOnSelectionClick}
          onScroll={onScroll}
          // onRowClick={onRowClick}
          scrollToIndex={selectedIndex}
          data={data}
          sortBy={sortBy}
          sortDirection={sortDirection}
          colorField="severityClass"
        >
          {showConfirmedDate ? (
            <Column
              className={TableStyles.column}
              label="Confirmed on"
              dataKey={
                serviceProviderTable
                  ? EKG_TABLE_COLUMN_HEADERS.QT_CONFIRMED_AT
                  : EKG_TABLE_COLUMN_HEADERS.CONFIRMED_AT
              }
              width={DATE_WIDTH}
              headerRenderer={(headerProps) => {
                const { label } = headerProps;

                return (
                  <TableHeader
                    label={label}
                    columnHeader={
                      serviceProviderTable
                        ? EKG_TABLE_COLUMN_HEADERS.QT_CONFIRMED_AT
                        : EKG_TABLE_COLUMN_HEADERS.CONFIRMED_AT
                    }
                    handleOnSortClick={handleOnSortClick}
                    handleOnFilterButtonClick={handleOnFilterButtonClick}
                    filterActive={filterDate}
                    sortBy={sortBy}
                    sortDirection={sortDirection}
                    selectedPopover={selectedPopover}
                    Popover={
                      <div
                        className={
                          showRowSelection ? styles.datePopoverWithSelection : styles.datePopover
                        }
                      >
                        <DatePopover
                          showMonthArrow={selectedInboxId !== CONFIRMED_OPTION.id}
                          showMonthAndYearPickers={selectedInboxId !== CONFIRMED_OPTION.id}
                          handleOnChange={(dates) =>
                            handleOnFilterChange(EKG_TABLE_COLUMN_HEADERS.RECORDED_AT, dates)
                          }
                          handleOnOutsidePopoverClick={handleOnOutsidePopoverClick}
                          min={dateRange[0]}
                          max={dateRange[1]}
                          startDate={selectedStartDate}
                          endDate={selectedEndDate}
                        />
                      </div>
                    }
                  />
                );
              }}
              cellRenderer={({ dataKey, rowData }) => {
                const date = serviceProviderTable
                  ? rowData.qtMeasurementReceived
                  : rowData.newestInterpretation.confirmation.createdAt;
                const dateText = moment(date).format(this.dateFormat);
                const time = moment(date).format(this.timeFormat);
                return (
                  <div className={TableStyles.centeredColumnContent}>
                    <p className={`${TableStyles.primaryText} ${TableStyles.centeredText}`}>
                      {dateText}
                    </p>
                    <p className={`${TableStyles.secondaryText} ${TableStyles.centeredText}`}>
                      {time}
                    </p>
                  </div>
                );
              }}
            />
          ) : (
            <Column
              className={TableStyles.column}
              label="Date"
              dataKey={EKG_TABLE_COLUMN_HEADERS.RECORDED_AT}
              width={DATE_WIDTH}
              headerRenderer={(headerProps) => {
                const { label } = headerProps;

                return (
                  <TableHeader
                    label={label}
                    columnHeader={EKG_TABLE_COLUMN_HEADERS.RECORDED_AT}
                    handleOnSortClick={handleOnSortClick}
                    handleOnFilterButtonClick={handleOnFilterButtonClick}
                    filterActive={filterDate}
                    sortBy={sortBy}
                    sortDirection={sortDirection}
                    selectedPopover={selectedPopover}
                    Popover={
                      <div
                        className={
                          showRowSelection ? styles.datePopoverWithSelection : styles.datePopover
                        }
                      >
                        <DatePopover
                          handleOnChange={(dates) =>
                            handleOnFilterChange(EKG_TABLE_COLUMN_HEADERS.RECORDED_AT, dates)
                          }
                          handleOnOutsidePopoverClick={handleOnOutsidePopoverClick}
                          min={dateRange[0]}
                          max={dateRange[1]}
                          startDate={selectedStartDate}
                          endDate={selectedEndDate}
                        />
                      </div>
                    }
                  />
                );
              }}
              cellRenderer={({ dataKey, rowData }) => {
                const date = moment(rowData[dataKey]).format(this.dateFormat);
                const time = moment(rowData[dataKey]).format(this.timeFormat);
                return (
                  <div className={TableStyles.centeredColumnContent}>
                    <p className={`${TableStyles.primaryText} ${TableStyles.centeredText}`}>
                      {date}
                    </p>
                    <p className={`${TableStyles.secondaryText} ${TableStyles.centeredText}`}>
                      {time}
                    </p>
                  </div>
                );
              }}
            />
          )}
          {showPatientColumns && !serviceProviderTable && (
            <Column
              className={TableStyles.column}
              width={PATIENT_WIDTH}
              label="Patient"
              dataKey={EKG_TABLE_COLUMN_HEADERS.PATIENT} // not needed but field required by library
              headerRenderer={(headerProps) => {
                const { label } = headerProps;

                return (
                  <TableHeader
                    label={label}
                    columnHeader={EKG_TABLE_COLUMN_HEADERS.PATIENT}
                    handleOnSortClick={handleOnSortClick}
                    handleOnFilterButtonClick={handleOnFilterButtonClick}
                    filterActive={filterPatient}
                    sortBy={sortBy}
                    sortDirection={sortDirection}
                    selectedPopover={selectedPopover}
                    Popover={
                      <div className={styles.patientPopover}>
                        <DropdownSearchPopover
                          noResultsMessage="No patient found"
                          handleOnOutsidePopoverClick={handleOnOutsidePopoverClick}
                          placeholder="MRN / Patient Name..."
                          isLoading={patients && patients.isFetching}
                          options={patientsOptions}
                          value={selectedPatient}
                          onChange={(patient) =>
                            handleOnFilterChange(EKG_TABLE_COLUMN_HEADERS.PATIENT, patient)
                          }
                          getOptionLabel={(p) => {
                            let optionLabel = getPatientName(p.firstName, p.lastName, '');
                            if (p.customParticipantID) {
                              optionLabel += `${PATIENT_NAME_MRN_SPLIT_DELIMITER}${p.customParticipantID}`;
                            }
                            return optionLabel;
                          }}
                          components={{
                            Option,
                            SingleValue,
                          }}
                          getOptionFilter={(option, input) => {
                            const patient = option.data;
                            const fields =
                              `${patient.lastName}, ${patient.firstName} ${patient.customParticipantID}`.toLowerCase();
                            const searchInputParts = input.toLowerCase().split(' ');
                            return searchInputParts.every((text) => fields.includes(text));
                          }}
                          getOptionValue={(d) => d.id}
                        />
                      </div>
                    }
                  />
                );
              }}
              cellRenderer={({ rowData }) => {
                const rec = rowData;
                const primaryText = getPatientNameFromRecordingParticipantProfile(rec);
                const secondaryText = getMrnFromRecordingParticipantProfile(rec);

                const secondaryTextClassName = `${TableStyles.secondaryText} ${TableStyles.number}`;
                return (
                  <div className={TableStyles.columnContent}>
                    <p className={TableStyles.primaryText}>{primaryText}</p>
                    <p className={secondaryTextClassName}>{secondaryText}</p>
                  </div>
                );
              }}
            />
          )}
          {showPatientColumns && serviceProviderTable && (
            <Column
              className={TableStyles.column}
              width={PATIENT_WIDTH}
              label="Patient"
              dataKey={EKG_TABLE_COLUMN_HEADERS.QT_PATIENT} // not needed but field required by library
              headerRenderer={(headerProps) => {
                const { label } = headerProps;

                return (
                  <TableHeader
                    label={label}
                    columnHeader={EKG_TABLE_COLUMN_HEADERS.QT_PATIENT}
                    handleOnFilterButtonClick={handleOnFilterButtonClick}
                    handleOnSortClick={handleOnSortClick}
                    filterActive={selectedPatientText === '' ? false : true}
                    sortBy={sortBy}
                    sortDirection={sortDirection}
                    selectedPopover={selectedPopover}
                    // @TODO Filter dropdown value to be changed after API is done
                    Popover={
                      <div className={styles.interpretationCommentPopover}>
                        <SearchPopover
                          // handleOnPopoverKeyUp={handleOnPopoverKeyUp}
                          handleOnChange={(text) =>
                            handleOnFilterChange(EKG_TABLE_COLUMN_HEADERS.QT_PATIENT, text)
                          }
                          handleOnOutsidePopoverClick={handleOnOutsidePopoverClick}
                          value={selectedPatientText}
                          placeholder="MRN / Patient Name..."
                        />
                      </div>
                    }
                  />
                );
              }}
              cellRenderer={({ rowData }) => (
                <div className={TableStyles.columnContent}>
                  <p className={TableStyles.primaryText}>
                    {getMrnFromRecordingParticipantProfile(rowData)}
                  </p>
                </div>
              )}
            />
          )}
          <Column
            className={TableStyles.column}
            width={analysisWidth}
            label="Analysis"
            dataKey={EKG_TABLE_COLUMN_HEADERS}
            headerRenderer={(headerProps) => {
              const { label } = headerProps;

              return (
                <TableHeader
                  label={label}
                  columnHeader={EKG_TABLE_COLUMN_HEADERS.ANALYSIS}
                  handleOnSortClick={handleOnSortClick}
                  handleOnFilterButtonClick={handleOnFilterButtonClick}
                  filterActive={filterAnalysis}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  selectedPopover={selectedPopover}
                  Popover={
                    <div className={analysisClassname}>
                      <MultiSelectListPopover
                        list={ekgResults}
                        selected={selectedEkgResult}
                        getOptionLabel={(d) => d.label}
                        getOptionValue={(d) => d.id}
                        enableSearch={false}
                        handleOnClick={(id) =>
                          handleOnFilterChange(EKG_TABLE_COLUMN_HEADERS.ANALYSIS, id)
                        }
                        handleOnOutsidePopoverClick={handleOnOutsidePopoverClick}
                      />
                    </div>
                  }
                />
              );
            }}
            cellRenderer={({ dataKey, rowData }) => {
              const analysis = rowData[dataKey.ANALYSIS];
              return (
                <React.Fragment>
                  <div className={TableStyles.columnContent} id={TableStyles.columnContent}>
                    <p className={TableStyles.primaryText}>{analysis}</p>
                    {showOrderingProvider && this.renderAnalysisBy(rowData)}
                  </div>
                  {showOrderingProvider && !rowData.newestInterpretation && (
                    <span
                      className={TableStyles.determinationPopOver}
                      onClick={(e) => {
                        this.setState({
                          displayDeterminationToolTip: true,
                          selectedData: rowData,
                        });
                        e.stopPropagation();
                      }}
                    >
                      <InformationIcon />
                    </span>
                  )}
                  {this.state.displayDeterminationToolTip &&
                    this.state.selectedData.id === rowData.id && (
                      <DescriptiveToolTip
                        infoHeaderContent="More Information"
                        handleClose={this.handleToolTipClose}
                        bodyContent={() => (
                          <React.Fragment>
                            {getAnalysisBy(rowData)}
                            <br />
                            {rowData.algorithmPackage === null
                              ? KARDIA_DETERMINATIONS.DESCRIPTION.KAIv1
                              : KARDIA_DETERMINATIONS.DESCRIPTION[rowData.algorithmPackage]}
                          </React.Fragment>
                        )}
                      />
                    )}
                </React.Fragment>
              );
            }}
          />
          {showOrderingProvider && (
            <Column
              className={TableStyles.column}
              width={ORDERING_PHYSICIAN_WIDTH}
              label="Ordering Provider"
              dataKey={EKG_TABLE_COLUMN_HEADERS}
              headerRenderer={(headerProps) => {
                const { label } = headerProps;

                return (
                  <TableHeader
                    label={label}
                    columnHeader={EKG_TABLE_COLUMN_HEADERS.ORDERING_PHYSICIAN}
                    handleOnSortClick={handleOnSortClick}
                    handleOnFilterButtonClick={handleOnFilterButtonClick}
                    filterActive={filterPhysicians}
                    sortBy={sortBy}
                    sortDirection={sortDirection}
                    selectedPopover={selectedPopover}
                    Popover={
                      <div className={styles.providerPopover}>
                        <MultiSelectListPopover
                          list={physicianOptions}
                          selected={selectedPhysicians}
                          getOptionLabel={(d) => d.label}
                          getOptionValue={(d) => d.id}
                          enableSearch={true}
                          handleOnClick={(id) =>
                            handleOnFilterChange(EKG_TABLE_COLUMN_HEADERS.ORDERING_PHYSICIAN, id)
                          }
                          handleOnOutsidePopoverClick={handleOnOutsidePopoverClick}
                          controlShouldRenderValue={true}
                          backspaceRemovesValue={true}
                          onKeyDown={(e) => {
                            if (isEscKey(e)) {
                              handleOnOutsidePopoverClick();
                            }
                          }}
                        />
                      </div>
                    }
                  />
                );
              }}
              cellRenderer={({ rowData }) => {
                let physician = '';
                if (rowData.participantProfile) {
                  const patient = rowData.participantProfile;
                  physician =
                    patient.orderingPhysician &&
                    getPhysicianName(
                      patient.orderingPhysician.firstName,
                      patient.orderingPhysician.lastName,
                      '',
                    );
                }

                return (
                  <div className={TableStyles.columnContent}>
                    <p className={TableStyles.primaryText}>{physician}</p>
                  </div>
                );
              }}
            />
          )}
          {serviceProviderTable && (
            <Column
              className={TableStyles.column}
              width={ORDERING_PRACTICE_WIDTH}
              label="Ordering Practice"
              dataKey={EKG_TABLE_COLUMN_HEADERS}
              headerRenderer={(headerProps) => {
                const { label } = headerProps;

                return (
                  <TableHeader
                    label={label}
                    columnHeader={EKG_TABLE_COLUMN_HEADERS.QT_ORDERING_PRACTICE}
                    handleOnSortClick={handleOnSortClick}
                    handleOnFilterButtonClick={handleOnFilterButtonClick}
                    filterActive={filterQtOrderingPractice}
                    sortBy={sortBy}
                    sortDirection={sortDirection}
                    selectedPopover={selectedPopover}
                    // @TODO Filter multiselect values to be changed after API is done
                    Popover={
                      <div className={styles.providerPopover}>
                        <MultiSelectListPopover
                          list={orderingPacticesOptions}
                          selected={selectedQtOrderingPractice}
                          getOptionLabel={(d) => d.name}
                          getOptionValue={(d) => d.id}
                          enableSearch={true}
                          handleOnClick={(id) =>
                            handleOnFilterChange(EKG_TABLE_COLUMN_HEADERS.QT_ORDERING_PRACTICE, id)
                          }
                          handleOnOutsidePopoverClick={handleOnOutsidePopoverClick}
                          controlShouldRenderValue={true}
                          backspaceRemovesValue={true}
                          onKeyDown={(e) => {
                            if (isEscKey(e)) {
                              handleOnOutsidePopoverClick();
                            }
                          }}
                        />
                      </div>
                    }
                  />
                );
              }}
              cellRenderer={({ rowData }) => {
                let practice = '';
                if (rowData.orderingPractice) {
                  practice = rowData.orderingPractice.name;
                }

                return (
                  <div className={TableStyles.columnContent}>
                    <p className={TableStyles.primaryText}>{practice}</p>
                  </div>
                );
              }}
            />
          )}
          {!serviceProviderTable && !showOrderingProvider && (
            <Column
              className={TableStyles.column}
              width={ANALYSIS_BY_WIDTH}
              label="Analysis By"
              dataKey={EKG_TABLE_COLUMN_HEADERS.ANALYSIS_BY} // not needed but field required by library
              headerRenderer={(headerProps) => {
                // console.log(headerProps)
                const { label } = headerProps;

                return (
                  <TableHeader
                    label={label}
                    columnHeader={EKG_TABLE_COLUMN_HEADERS.ANALYSIS_BY}
                    handleOnSortClick={handleOnSortClick}
                    handleOnFilterButtonClick={handleOnFilterButtonClick}
                    filterActive={filterAnalysisBy}
                    sortBy={sortBy}
                    sortDirection={sortDirection}
                    selectedPopover={selectedPopover}
                    Popover={
                      <div className={styles.analysisByPopover}>
                        <DropdownSearchPopover
                          noResultsMessage="No provider found"
                          handleOnOutsidePopoverClick={handleOnOutsidePopoverClick}
                          placeholder="Provider Name..."
                          isLoading={physicians && physicians.isFetching}
                          options={physiciansOptions}
                          value={selectedPhysician}
                          onChange={(physician) =>
                            handleOnFilterChange(EKG_TABLE_COLUMN_HEADERS.ANALYSIS_BY, physician)
                          }
                          getOptionLabel={(p) => {
                            const optionLabel = getName(p.firstName, p.lastName, '');
                            return optionLabel;
                          }}
                          getOptionValue={(d) => d.id}
                        />
                      </div>
                    }
                  />
                );
              }}
              cellRenderer={({ rowData }) => {
                const rec = rowData;
                const isConfirmed = isConfirmedAnalysisBy(rec);

                const primaryText = getAnalysisBy(rec);

                const content = !isConfirmed ? (
                  primaryText
                ) : (
                  <React.Fragment>
                    <p className={TableStyles.primaryText}>{primaryText}</p>
                    <p className={styles.confirmed}>
                      <ConfirmationIcon className={styles.confirmedIcon} />
                      Confirmed
                    </p>
                  </React.Fragment>
                );

                return <div className={TableStyles.columnContent}>{content}</div>;
              }}
            />
          )}
          {/* Column only for QT Service Provider */}
          {serviceProviderTable && (
            <Column
              className={TableStyles.column}
              width={QTCF_VALUE_WIDTH}
              label="QTcf Value"
              dataKey={EKG_TABLE_COLUMN_HEADERS}
              headerRenderer={(headerProps) => {
                const { label } = headerProps;
                return (
                  <TableHeader
                    label={label}
                    columnHeader={EKG_TABLE_COLUMN_HEADERS.QTCF_VALUE}
                    filterActive={filterQtcf}
                    handleOnFilterButtonClick={handleOnFilterButtonClick}
                    handleOnSortClick={handleOnSortClick}
                    sortBy={sortBy}
                    sortDirection={sortDirection}
                    selectedPopover={selectedPopover}
                    Popover={
                      <div className={styles.bpmPopover}>
                        <SliderPopover
                          label="Select Range"
                          min={qtcfRange[0]}
                          max={qtcfRange[1]}
                          minValue={selectedQtcfStartValue}
                          maxValue={selectedQtcfEndValue}
                          handleOnChange={(selectedQtcf) =>
                            handleOnFilterChange(EKG_TABLE_COLUMN_HEADERS.QTCF_VALUE, selectedQtcf)
                          }
                          handleOnOutsidePopoverClick={handleOnOutsidePopoverClick}
                        />
                      </div>
                    }
                    lastElement
                  />
                );
              }}
              cellRenderer={({ rowData }) => {
                const qtcf = rowData.latestQtcf;

                return (
                  <div className={TableStyles.columnContent}>
                    <p className={TableStyles.primaryText}>{qtcf}</p>
                  </div>
                );
              }}
            />
          )}
          {/* Column only for QT Service Provider */}
          {serviceProviderTable && (
            <Column
              className={TableStyles.column}
              width={INTERPRETATION_COMMENT_WIDTH}
              label="Interpretation Comment"
              dataKey={EKG_TABLE_COLUMN_HEADERS}
              headerRenderer={(headerProps) => {
                const { label } = headerProps;

                return (
                  <TableHeader
                    label={label}
                    columnHeader={EKG_TABLE_COLUMN_HEADERS.INTERPRETATION_TEXT}
                    handleOnFilterButtonClick={handleOnFilterButtonClick}
                    handleOnSortClick={handleOnSortClick}
                    filterActive={selectedIntrText === '' ? false : true}
                    sortBy={sortBy}
                    sortDirection={sortDirection}
                    selectedPopover={selectedPopover}
                    // @TODO Filter dropdown value to be changed after API is done
                    Popover={
                      <div className={styles.interpretationCommentPopover}>
                        <SearchPopover
                          // handleOnPopoverKeyUp={handleOnPopoverKeyUp}
                          handleOnChange={(text) =>
                            handleOnFilterChange(EKG_TABLE_COLUMN_HEADERS.INTERPRETATION_TEXT, text)
                          }
                          handleOnOutsidePopoverClick={handleOnOutsidePopoverClick}
                          value={selectedIntrText}
                          placeholder="Comment..."
                        />
                      </div>
                    }
                  />
                );
              }}
              cellRenderer={({ rowData }) => {
                const interpretation = rowData.interpretationComment;
                return (
                  <div className={TableStyles.columnContent}>
                    <p className={TableStyles.primaryText}>{interpretation}</p>
                  </div>
                );
              }}
            />
          )}
          {/* Column only for QT Service Provider */}
          {serviceProviderTable && (
            <Column
              className={TableStyles.column}
              width={ASSIGNED_CARDIOLOGIST_WIDTH}
              // label="Assigned Cardiologist"
              label={
                selectedPageTab === CONFIRMED
                  ? 'Confirmed Interpretation Cardiologist'
                  : 'Assigned Cardiologist'
              }
              dataKey={EKG_TABLE_COLUMN_HEADERS}
              headerRenderer={(headerProps) => {
                const { label } = headerProps;
                return (
                  <TableHeader
                    label={label}
                    columnHeader={EKG_TABLE_COLUMN_HEADERS.ASSIGNED_CARDIOLOGIST}
                    handleOnSortClick={handleOnSortClick}
                    handleOnFilterButtonClick={handleOnFilterButtonClick}
                    filterActive={filterAssignedPhysicians}
                    sortBy={sortBy}
                    sortDirection={sortDirection}
                    selectedPopover={selectedPopover}
                    // @TODO Filter multiselect values to be changed after API is done
                    Popover={
                      <div className={styles.providerPopover}>
                        <MultiSelectListPopover
                          list={physicianOptions}
                          selected={selectedAssignedPhysicians}
                          getOptionLabel={(d) => d.label}
                          getOptionValue={(d) => d.id}
                          enableSearch={true}
                          handleOnClick={(id) =>
                            handleOnFilterChange(EKG_TABLE_COLUMN_HEADERS.ASSIGNED_CARDIOLOGIST, id)
                          }
                          handleOnOutsidePopoverClick={handleOnOutsidePopoverClick}
                          controlShouldRenderValue={true}
                          backspaceRemovesValue={true}
                          onKeyDown={(e) => {
                            if (isEscKey(e)) {
                              handleOnOutsidePopoverClick();
                            }
                          }}
                        />
                      </div>
                    }
                  />
                );
              }}
              cellRenderer={({ rowData }) => {
                const cardiologistFirstName =
                  rowData.assignedMember && rowData.assignedMember.firstName;
                const cardiologistLastName =
                  rowData.assignedMember && rowData.assignedMember.lastName;
                return (
                  <div className={TableStyles.columnContent}>
                    <p className={TableStyles.primaryText}>
                      {getName(cardiologistFirstName, cardiologistLastName, '')}
                    </p>
                  </div>
                );
              }}
            />
          )}
          <Column
            className={TableStyles.column}
            width={BPM_WIDTH}
            label="BPM + ECG Preview"
            dataKey={EKG_TABLE_COLUMN_HEADERS.BPM}
            headerRenderer={(headerProps) => {
              // console.log(headerProps)
              const { label } = headerProps;

              return (
                <TableHeader
                  label={label}
                  columnHeader={EKG_TABLE_COLUMN_HEADERS.BPM}
                  handleOnSortClick={handleOnSortClick}
                  handleOnFilterButtonClick={handleOnFilterButtonClick}
                  filterActive={filterBpm}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  selectedPopover={selectedPopover}
                  Popover={
                    <div className={styles.bpmPopover}>
                      <SliderPopover
                        label="Select Range"
                        min={bpmRange[0]}
                        max={bpmRange[1]}
                        minValue={selectedBpmStartValue}
                        maxValue={selectedBpmEndValue}
                        handleOnChange={(selectedBpms) =>
                          handleOnFilterChange(EKG_TABLE_COLUMN_HEADERS.BPM, selectedBpms)
                        }
                        handleOnOutsidePopoverClick={handleOnOutsidePopoverClick}
                      />
                    </div>
                  }
                  lastElement
                />
              );
            }}
            style={{
              height: ROW_HEIGHT,
            }}
            cellRenderer={({ dataKey, rowData }) => {
              const rec = rowData;
              const { [dataKey]: bpm, previewData, is6l } = rec;

              const ekgContent = previewData ? (
                <EKGPreview height={ROW_HEIGHT} previewData={previewData} />
              ) : (
                <NoEKGPreview height={ROW_HEIGHT} />
              );

              return (
                <div className={TableStyles.centeredColumnContent}>
                  <div className={styles.ekgPreview}>{ekgContent}</div>
                  {is6l && <SixLeadIcon className={styles.sixLIcon} />}
                  <div className={styles.tag}>
                    <HeartIcon className={styles.heartIcon} />
                    <p className={styles.bpm}>
                      {bpm}
                      <span>bpm</span>
                    </p>
                  </div>
                </div>
              );
            }}
          />
        </Table>
        {/* <TableActions
          selected={selected}
          visible={isAnySelected}
          clearSelection={handleOnBulkActionClearSelection}
          bulkActionComplete={bulkActionComplete}
          bulkActionIsFetching={bulkActionIsFetching}
          successMessage={bulkActionSuccessMessage}
        >
          {bulkActionIsFetching ? (
            <button disabled className={TableActionStyles.submitLoading}>
              <Loading />
            </button>
          ) : (
            <button
              className={`${TableActionStyles.actionButtonWithIcon} ${styles.exportToEmail}`}
              onClick={() =>
                handleOnBulkAction(TABLE_BULK_ACTIONS.MARK_AS_EXPORT)
              }
            >
              <SendIcon />
              <span>
                {numberOfSelection === 1 ? 'Export ECG' : 'Export ECGs'}
              </span>
            </button>
          )}
          {ecgBulkActionRemoveButton}
        </TableActions> */}
      </div>
    );
  }
}

export default EkgTable;
