// import axios from 'axios';
import { getToken } from 'auth/AuthUtilities';
import { EscalationAPI, GoAPI, IntakeKardiaAPI, TeleKardiaAPI } from '../../Axios/axios';
import { getTimezoneName } from 'utilities/Utilities';
import * as actionTypes from './actionTypes';
import { ALERT_NOTE } from 'constants/app';

const getMemberProfileStart = () => ({
  type: actionTypes.GET_MP_LOADING,
});

const getMemberProfileSuccess = (data) => ({
  type: actionTypes.GET_MP_PROGRAM_SUCCESS,
  payload: data,
});

const getMemberProfileFail = (error) => ({
  type: actionTypes.GET_MP_FAIL,
  payload: error,
});

const getMpHealthSuccess = (data) => ({
  type: actionTypes.GET_MP_HEALTH_SUCCESS,
  payload: data,
});

//PCP- fetch data
const getMpHealthPcpLoading = () => ({
  type: actionTypes.GET_MP_HEALTH_PCP_LOADING,
});
const getMpHealthPcpSuccess = (data) => ({
  type: actionTypes.GET_MP_HEALTH_PCP_SUCCESS,
  payload: data,
});
const getMpHealthPcpFail = (data) => ({
  type: actionTypes.GET_MP_HEALTH_PCP_FAIL,
  payload: data,
});
//PCP- fetch meta data
const getMpHealthPcpMetadataLoading = () => ({
  type: actionTypes.GET_MP_HEALTH_PCP_METADATA_LOADING,
});
const getMpHealthPcpMetadataSuccess = (data) => ({
  type: actionTypes.GET_MP_HEALTH_PCP_METADATA_SUCCESS,
  payload: data,
});
const getMpHealthPcpMetadataFail = (data) => ({
  type: actionTypes.GET_MP_HEALTH_PCP_METADATA_FAIL,
  payload: data,
});

//PCP - update data
const getMpHealthPcpUpdateLoading = () => ({
  type: actionTypes.GET_MP_HEALTH_PCP_UPDATE_LOADING,
});
const getMpHealthPcpUpdateSuccess = (data) => ({
  type: actionTypes.GET_MP_HEALTH_PCP_UPDATE_SUCCESS,
  payload: data,
});
const getMpHealthPcpUpdateFail = (data) => ({
  type: actionTypes.GET_MP_HEALTH_PCP_UPDATE_FAIL,
  payload: data,
});

const getMpProgramProgressStart = () => ({
  type: actionTypes.GET_MP_PROGRAM_PP_LOADING,
});
const getMpProgramProgressSuccess = (data) => ({
  type: actionTypes.GET_MP_PROGRAM_PP_SUCCESS,
  payload: data,
});

const getMpProgramFocusFail = (error) => ({
  type: actionTypes.GET_MP_PROGRAM_PF_FAIL,
  payload: error,
});
const getMpProgramFocusStart = () => ({
  type: actionTypes.GET_MP_PROGRAM_PF_LOADING,
});
const getMpProgramFocusSuccess = (data) => ({
  type: actionTypes.GET_MP_PROGRAM_PF_SUCCESS,
  payload: data,
});

const getMpProgramProgressFail = (error) => ({
  type: actionTypes.GET_MP_PROGRAM_PP_FAIL,
  payload: error,
});

export const getDocumentationLoading = (data) => ({
  type: actionTypes.GET_MP_DOCUMENTATION_LOADING,
  payload: data,
});

export const getDocumentationSuccess = (data) => ({
  type: actionTypes.GET_MP_DOCUMENTATION_SUCCESS,
  payload: data,
});
const getDocumentationFail = (error) => ({
  type: actionTypes.GET_MP_DOCUMENTATION_FAILURE,
  payload: error,
});

const getNotesMetadataLoading = () => ({
  type: actionTypes.NOTE_METADATA_LOADING,
  payload: null,
});
export const getNotesMetadataSuccess = (data) => ({
  type: actionTypes.NOTE_METADATA_SUCCESS,
  payload: data,
});
const getNotesMetadataFail = (error) => ({
  type: actionTypes.NOTE_METADATA_FAILURE,
  payload: error,
});
export const getNotesFilterMetadataSuccess = (data) => ({
  type: actionTypes.NOTE_FILTER_METADATA_SUCCESS,
  payload: data,
});
const getNotesFilterMetadataFail = (error) => ({
  type: actionTypes.NOTE_FILTER_METADATA_FAIL,
  payload: error,
});

// Program tab
export const getMemberProfileDetails = (participantId) => (dispatch, getState) => {
  dispatch(getMemberProfileStart());
  return GoAPI.get(`/i/v1/kcc/adminList/${participantId}`, {
    headers: { Authorization: ` Bearer ${getToken()}` },
  })
    .then((res) => {
      let resultData = res.data;
      resultData.lastRefreshed = new Date();
      dispatch(getMemberProfileSuccess(resultData));
      return resultData;
    })
    .catch((err) => {
      console.log(err);
      dispatch(getMemberProfileFail(err.message));
    });
};

export const setCurrentWeekDetails = (currentWeek) => (dispatch, getState) => {
  const pgData = getState().memberProfile.programData;
  if (pgData) {
    dispatch(getMemberProfileSuccess({ ...pgData, currentWeek: currentWeek }));
  }
};

// health details tab
export const getMemberProfileHealthDetails =
  (teamId, coachId, memberId, offsetInMinutes, timezoneValue) => (dispatch, getState) => {
    dispatch(getMemberProfileStart());
    TeleKardiaAPI.get(
      `/api/v1/teams/${teamId}/clinicians/${coachId}/patients/${memberId}/heartHealthAssessment?timeZoneOffset=${offsetInMinutes}&timeZone=${timezoneValue}`,
      {
        headers: { Authorization: ` Bearer ${getToken()}` },
      },
    )
      .then((res) => {
        let resultData = res.data;
        if (resultData.success) {
          dispatch(getMpHealthSuccess(resultData.result));
          //get medications
          GoAPI.get(`/i/v1/users/${memberId}/medications`, {
            headers: { Authorization: ` Bearer ${getToken()}` },
          }).then((res) => {
            if (res.status === 200) {
              if (res.data.length > 0) {
                let medArray = [];
                res.data.map((r) => {
                  medArray.push(`${r.drugName} - ${r.dosage} ${r.dosageUnit}`);
                });
                resultData.result.medications.medicationList = medArray;
                dispatch(getMpHealthSuccess(resultData.result));
              }
            }
          });
          //get surgical history
          GoAPI.get(`/i/v1/users/${memberId}/surgicalHistory`, {
            headers: { Authorization: ` Bearer ${getToken()}` },
          }).then((res) => {
            if (res.status === 200) {
              if (res.data.length > 0) {
                let surgArray = [];
                res.data.map((r) => {
                  surgArray.push(`${r.surgeryName} - ${r.surgeryDate}`);
                });
                resultData.result.medicalHistory.surgicalHistory = surgArray;
                dispatch(getMpHealthSuccess(resultData.result));
              }
            }
          });
          //get family disease history
          GoAPI.get(`/i/v1/users/${memberId}/familyDiseaseHistory`, {
            headers: { Authorization: ` Bearer ${getToken()}` },
          }).then((res) => {
            if (res.status === 200) {
              if (res.data.diseases.length > 0) {
                let fmlyArray = [];
                res.data.diseases.map((r) => {
                  if (r.name !== 'None' && r.toggle === true) {
                    fmlyArray.push(`${r.name}`);
                  }
                });
                resultData.result.medicalHistory.familyHistory = fmlyArray;

                dispatch(getMpHealthSuccess(resultData.result));
              }
            }
          });
        }
      })
      .catch((err) => {
        dispatch(getMemberProfileFail(err.message));
      });
  };

//pcp
export const getPCPMetadata = (memberId) => (dispatch) => {
  let consolidatedData = null;
  let retVal = [];
  dispatch(getMpHealthPcpMetadataLoading());
  IntakeKardiaAPI.get(
    `/api/v2/intake/AC-heartHealthAssessment/AC-HHA-ID-ProvideProviders?userId=${memberId}`,
    {
      headers: { Authorization: ` Bearer ${getToken()}` },
    },
  )
    .then((res) => {
      console.log(res);
      consolidatedData = res.data;

      if (res.status == 200) {
        IntakeKardiaAPI.get(
          `/i/v1/intakeCategory/heartHealthAssessment/screen/AC-HHA-ID-ProvideProviders/user/${memberId}/answers`,
          {
            headers: { Authorization: ` Bearer ${getToken()}` },
          },
        ).then((res) => {
          if (res.status == 200) {
            let cellData = consolidatedData;
            //construct answer array for pcp
            //construct empty answers
            let ans = [];
            [0, 1].map((a, index) => {
              let temp = {};
              cellData.items.map((c) => {
                temp[c.key] = '';
              });
              ans.push(temp);
            });
            if (res.data) {
              let answers = res.data.answers;
              if (answers.providerTable.data && answers.providerTable.data.length > 0) {
                //overwrite any existing answers on the two answers if any
                answers.providerTable.data.map((item, index) => {
                  if (item) {
                    ans[index] = item;
                  }
                });
                ans['updatedTime'] = res.data?.updatedTime;
              }
            }
            //store answers in redux
            dispatch(getMpHealthPcpSuccess(ans));
            //make two input sets whether answers or present or not
            [0, 1].map((a, index) => {
              cellData.items.map((c) => {
                if (a[c.key]) {
                  return;
                }
              });
              retVal.push(cellData);
              cellData = null;
              cellData = { ...consolidatedData };
              return;
            });
            dispatch(getMpHealthPcpMetadataSuccess(retVal));
          }
        });
      }
    })
    .catch((err) => {
      dispatch(getMpHealthPcpMetadataFail(err));
    });
};

export const updatePCPData = (tableKey, memberId, body) => (dispatch) => {
  dispatch(getMpHealthPcpUpdateLoading());
  IntakeKardiaAPI.post(
    `/api/v2/intake/heartHealthAssessment/AC-HHA-ID-ProvideProviders/${tableKey}/user/${memberId}`,
    body,
    {
      headers: { Authorization: ` Bearer ${getToken()}` },
    },
  )
    .then((res) => {
      console.log(res);
      if (res.status == 200) {
        dispatch(getMpHealthPcpUpdateSuccess());
        dispatch(getPCPMetadata(memberId));
      }
    })
    .catch((err) => {
      dispatch(getMpHealthPcpUpdateFail(err));
    });
};

// program progress
export const getMemberProfilePPDetails = (weekNumber, memberId) => (dispatch, getState) => {
  dispatch(getMpProgramProgressStart());
  GoAPI.get(`/i/v1/kcc/programTab/${weekNumber}?userID=${memberId}`, {
    headers: { Authorization: ` Bearer ${getToken()}` },
  })
    .then((res) => {
      let resultData = res.data;
      if (res.status === 200) {
        dispatch(getMpProgramProgressSuccess(resultData));
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(getMpProgramProgressFail(err.message));
    });
};

// program focus
export const getMemberProfilePFDetails =
  (teamId, coachId, memberId, offsetInMinutes) => (dispatch, getState) => {
    dispatch(getMpProgramFocusStart());
    TeleKardiaAPI.get(
      `/api/v1/teams/${teamId}/clinicians/${coachId}/patients/${memberId}/appointments/latest?offsetInMinutes=${offsetInMinutes}&timeZone=${getTimezoneName()}`,
      {
        headers: { Authorization: ` Bearer ${getToken()}` },
      },
    )
      .then((res) => {
        let resultData = res.data;
        if (res.status === 200) {
          GoAPI.get(`/i/v1/kcc/heartHabits/${memberId}/latest`, {
            headers: { Authorization: ` Bearer ${getToken()}` },
          }).then((res) => {
            let data = res.data;
            resultData.result.heartHabit = data ? data : [];
            if (res.status === 200) {
              dispatch(getMpProgramFocusSuccess(resultData.result));
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(getMpProgramFocusFail(err.message));
      });
  };

//=== session logs api call ======//
export const getMemberProfileSessionLogData = (patientId, filterString) => (dispatch, getState) => {
  dispatch(getDocumentationLoading());

  const teamId = getState().user.team.id;
  const coachId = getState().user.profile.id;
  let offsetInMinutes = new Date().getTimezoneOffset();

  const endpoint = `/api/v1/teams/${teamId}/clinicians/${coachId}/note/patients/${patientId}/documentation?offsetInMinutes=${offsetInMinutes}&timeZone=${getTimezoneName()}&${filterString}`;

  TeleKardiaAPI.get(endpoint, {
    headers: { Authorization: ` Bearer ${getToken()}` },
  })
    .then((res) => {
      let data = res.data.result;
      dispatch(getDocumentationSuccess(data));
    })
    .catch((err) => {
      dispatch(getDocumentationFail(err.message));
    });
};

export const setDocumentationFilterAction = (data) => (dispatch) => {
  return dispatch({
    type: actionTypes.SET_DOCUMENTATION_FILTER_DATA,
    payload: data,
  });
};

export const getNotesMetadata =
  (teamId, coachId, filter = false) =>
  (dispatch) => {
    dispatch(getNotesMetadataLoading());
    TeleKardiaAPI.get(
      `/api/v1/teams/${teamId}/clinicians/${coachId}/note/metadata?filter=${filter}`,
      {
        headers: { Authorization: ` Bearer ${getToken()}` },
      },
    )
      .then((res) => {
        let resultData = res.data;
        !filter
          ? dispatch(getNotesMetadataSuccess(resultData.result))
          : dispatch(getNotesFilterMetadataSuccess(resultData.result));
      })
      .catch((err) => {
        !filter
          ? dispatch(getNotesMetadataFail(err.message))
          : dispatch(getNotesFilterMetadataFail(err.message));
      });
  };

export const getNote = async (teamId, coachId, noteId) => {
  return TeleKardiaAPI.get(
    `/api/v1/teams/${teamId}/clinicians/${coachId}/note/${noteId}?offsetInMinutes=${new Date().getTimezoneOffset()}&timeZone=${getTimezoneName()}`,
    {
      headers: { Authorization: ` Bearer ${getToken()}` },
    },
  )
    .then((res) => {
      let resultData = res.data;
      return resultData;
    })
    .catch((err) => {
      return err;
    });
};
export const getAlertNoteDetails = (teamId, coachId, noteId) => {
  return EscalationAPI.get(
    `api/v1/teams/${teamId}/clinicians/${coachId}/alerts/note/${noteId}?offsetInMinutes=${new Date().getTimezoneOffset()}&timeZone=${getTimezoneName()}`,
    {
      headers: { Authorization: ` Bearer ${getToken()}` },
    },
  )
    .then((res) => {
      let resultData = res.data.result;
      return resultData;
    })
    .catch((err) => {
      return err;
    });
};

//no-redux-actions
export const postNote = async (teamId, coachId, data, type, alertId = '') => {
  const baseUrl = type === ALERT_NOTE ? EscalationAPI : TeleKardiaAPI;
  const endpoint =
    type === ALERT_NOTE
      ? `api/v1/teams/${teamId}/clinicians/${coachId}/patients/${data.patientId}/alerts/${alertId}/note`
      : `/api/v1/teams/${teamId}/clinicians/${coachId}/note`;
  if (type === ALERT_NOTE) {
    delete data.type;
    delete data.patientId;
  }
  return baseUrl
    .post(endpoint, data, {
      headers: { Authorization: ` Bearer ${getToken()}` },
      params: { timeZoneOffset: new Date().getTimezoneOffset(), timeZone: getTimezoneName() },
    })
    .then((res) => {
      let resultData = res.data;
      return resultData;
    })
    .catch((err) => {
      return err;
    });
};

//no-redux-actions
export const editNote = async (teamId, coachId, data, noteId) => {
  return TeleKardiaAPI.put(`/api/v1/teams/${teamId}/clinicians/${coachId}/note/${noteId}`, data, {
    headers: { Authorization: ` Bearer ${getToken()}` },
    params: { timeZoneOffset: new Date().getTimezoneOffset(), timeZone: getTimezoneName() },
  })
    .then((res) => {
      let resultData = res.data;
      return resultData;
    })
    .catch((err) => {
      return err;
    });
};

//no-redux-actions
export const postAddendum = async (teamId, coachId, data, noteId) => {
  return TeleKardiaAPI.post(
    `/api/v1/teams/${teamId}/clinicians/${coachId}/note/${noteId}/addendum`,
    data,
    {
      headers: { Authorization: ` Bearer ${getToken()}` },
      params: { timeZoneOffset: new Date().getTimezoneOffset(), timeZone: getTimezoneName() },
    },
  )
    .then((res) => {
      let resultData = res.data;

      return resultData;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

//no-redux-actions
export const editAddendum = async (teamId, coachId, data, noteId, addendumId) => {
  return TeleKardiaAPI.put(
    `/api/v1/teams/${teamId}/clinicians/${coachId}/note/${noteId}/addendum/${addendumId}`,
    data,
    {
      headers: { Authorization: ` Bearer ${getToken()}` },
      params: { timeZoneOffset: new Date().getTimezoneOffset(), timeZone: getTimezoneName() },
    },
  )
    .then((res) => {
      let resultData = res.data;

      // console.log(resultData);
      return resultData;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const uploadNoteAttachment = async (teamId, coachId, noteId, file) => {
  const { type } = file;
  const uploadPayload = {
    'Content-Type': type,
    'Access-Control-Allow-Origin': '*',
    files: file,
  };
  const formData = new FormData();
  for (const key in uploadPayload) {
    if (Object.hasOwnProperty.call(uploadPayload, key)) {
      formData.append(key, uploadPayload[key]);
    }
  }

  return TeleKardiaAPI.post(
    `/api/v1/teams/${teamId}/clinicians/${coachId}/note/${noteId}/attachment`,
    formData,
    {
      headers: {
        Authorization: ` Bearer ${getToken()}`,
        'Content-Type': 'multipart/form-data',
      },
    },
  )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { error: true };
    });
};

export const uploadAddendumAttachment = async (teamId, coachId, noteId, addId, file) => {
  const { type } = file;
  const uploadPayload = {
    'Content-Type': type,
    'Access-Control-Allow-Origin': '*',
    files: file,
  };
  const formData = new FormData();
  for (const key in uploadPayload) {
    if (Object.hasOwnProperty.call(uploadPayload, key)) {
      formData.append(key, uploadPayload[key]);
    }
  }

  return TeleKardiaAPI.post(
    `/api/v1/teams/${teamId}/clinicians/${coachId}/note/${noteId}/addendum/${addId}/attachment`,
    formData,
    {
      headers: {
        Authorization: ` Bearer ${getToken()}`,
        'Content-Type': 'multipart/form-data',
      },
    },
  )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};
