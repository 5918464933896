import { SORT_TYPES, TEAM_INVITES_TABLE_COLUMN_HEADERS } from 'constants/app';
import { StandardCompare } from '../Utilities';
export const sortTeamInvitesIds = (
  sorts = {
    sortBy: TEAM_INVITES_TABLE_COLUMN_HEADERS.EMAIL,
    sortDirection: SORT_TYPES.ASC,
  },
  invIds = [],
  teamInvitesById = {},
) => {
  const { sortBy, sortDirection } = sorts;
  const teamInvitesIds = [...invIds];
  let sortedTeamInvitesIds = teamInvitesIds;

  if (teamInvitesIds) {
    switch (sortBy) {
      case TEAM_INVITES_TABLE_COLUMN_HEADERS.EMAIL:
        sortedTeamInvitesIds = teamInvitesIds.sort((a, b) =>
          StandardCompare.strings(
            teamInvitesById[a].email,
            teamInvitesById[b].email,
            sortDirection,
          ),
        );
        break;
      case TEAM_INVITES_TABLE_COLUMN_HEADERS.FIRST_NAME:
        sortedTeamInvitesIds = teamInvitesIds.sort((a, b) =>
          StandardCompare.strings(
            teamInvitesById[a].firstName,
            teamInvitesById[b].firstName,
            sortDirection,
          ),
        );
        break;
      case TEAM_INVITES_TABLE_COLUMN_HEADERS.LAST_NAME:
        sortedTeamInvitesIds = teamInvitesIds.sort((a, b) =>
          StandardCompare.strings(
            teamInvitesById[a].lastName,
            teamInvitesById[b].lastName,
            sortDirection,
          ),
        );
        break;
      default:
        // No sort, keep previous sort
        break;
    }
  }

  return sortedTeamInvitesIds;
};

export const filterTeamInvitesIds = (
  filters = {
    selectedEmail: undefined,
    selectedFirstName: undefined,
    selectedLastName: undefined,
  },
  teamInvitesIds = [],
  teamInvitesById = {},
) => {
  const { selectedEmail, selectedFirstName, selectedLastName } = filters;
  let filteredTeamInvitesIds = teamInvitesIds;

  if (teamInvitesIds) {
    if (selectedEmail) {
      filteredTeamInvitesIds = teamInvitesIds.filter((id) => {
        const invite = teamInvitesById[id];
        const text = invite.email || '';
        return text.toLowerCase().startsWith(selectedEmail?.toLowerCase());
      });
    }
    if (selectedFirstName) {
      filteredTeamInvitesIds = teamInvitesIds.filter((id) => {
        const invite = teamInvitesById[id];
        const text = invite.firstName || '';
        return text.toLowerCase().startsWith(selectedFirstName?.toLowerCase());
      });
    }
    if (selectedLastName) {
      filteredTeamInvitesIds = teamInvitesIds.filter((id) => {
        const invite = teamInvitesById[id];
        const text = invite.lastName || '';
        return text.toLowerCase().startsWith(selectedLastName?.toLowerCase());
      });
    }
  }

  return filteredTeamInvitesIds;
};
