/* eslint-disable */
import { combineReducers } from 'redux';
import UserReducer, { initialUserState } from './user';
import AppointmentsReducer from './dashboard';
import CalendarReducer from './calendar';
import EkgReducer from './ekg';
import SideBarReducer from './sidebar';
import ChatReducer from './chat';
import appReducer from './app';
import { reducer as formReducer } from 'redux-form';
import settingsReducer from 'screens/common/Settings/module';
import teamMemberFileReducer from 'components/TeamMemberFile/module';
import { memberListReducer, memberProfileReducer } from './member';
import TeamSettingsReducer from 'components/TeamSettings/TeamSettingsReducers';
import teamMemberInvitesReducer from 'components/TeamMemberInvite/module';
import resetPasswordReducer from 'screens/common/ResetPassword/module';
import requestNewPasswordReducer from 'screens/common/RequestNewPassword/module';
import sessionReducer from './session';
import { adminSessionsReducer } from './sessions';
import patientFileReducer from 'screens/coach/MemberProfile/components/Metrics/PatientFile/module';
import * as actionTypes from '../actions/actionTypes';
import { AdHocReducer } from './Adhoc';
import escalationReducer from './escalation';

const rootReducer = combineReducers({
  events: CalendarReducer,
  user: UserReducer,
  appointments: AppointmentsReducer,
  ekg: EkgReducer,
  sidebar: SideBarReducer,
  chat: ChatReducer,
  app: appReducer,
  settings: settingsReducer,
  teamMemberFile: teamMemberFileReducer,
  memberList: memberListReducer,
  memberProfile: memberProfileReducer,
  form: formReducer,
  teamSettings: TeamSettingsReducer,
  teamMemberInvite: teamMemberInvitesReducer,
  resetPassword: resetPasswordReducer,
  requestNewPassword: requestNewPasswordReducer,
  session: sessionReducer,
  sessions: adminSessionsReducer,
  patientFile: patientFileReducer,
  adhocCall: AdHocReducer,
  escalation: escalationReducer,
});
export default (state, action) => {
  switch (action.type) {
    case actionTypes.USER_LOGOUT:
      return rootReducer(undefined, action);
    default:
      return rootReducer(state, action);
  }
};
