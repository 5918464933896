import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { fontSizes, spacing } from '../../../style/styleSettings';

import ChipComponent from 'components/Dashboard/SessionComponents/ChipComponent';
import moment from 'moment';
// import { styles } from '@material-ui/pickers/views/Calendar/Calendar';

const useStyle = makeStyles({
  root: {
    '& .MuiTypography-body2': {
      fontFamily: 'Work Sans',
      fontSize:14
    },
  },
  chipHolder: {
    marginTop: '16px',
    display: 'flex',
    // justifyContent:'space-between'
  },
  holder: {
    marginTop: '10px',
  },
  holder_single: {
    margin: '0 !important',
    display: 'flex',
    '&>:nth-child(1)': {
      fontWeight: 600,
    },
    '&>:nth-child(2)': {
      marginLeft: '10px',
    },
  },
});

function ViewNotes(props) {
  const { sessionLogsViewNotes, hhData } = props;
  const classes = useStyle();
  return (
    <>
      {sessionLogsViewNotes === null ||
      (sessionLogsViewNotes?.note?.appointmentNote === null &&
        sessionLogsViewNotes?.programFocus.length === 0 &&
        sessionLogsViewNotes?.heartHabit.length === 0) ? (
        <Typography variant="h6" style={{ textAlign: 'center', margin: '20px' }}>
          {' '}
          No data to show{' '}
        </Typography>
      ) : (
        <Box className={classes.root} style={{ margin: '10px 0 20px 0', padding: '0 10px' }}>
          {sessionLogsViewNotes?.note?.appointmentNote !== null && (
            <>
              <Typography variant="h6" gutterBottom component="div">
                <b>Session Notes:</b>
              </Typography>
              <Typography variant="body2">{sessionLogsViewNotes?.note?.appointmentNote}</Typography>
            </>
          )}
          {sessionLogsViewNotes?.programFocus.length !== 0 && (
            <>
              {/* <Typography variant='body1'><b>Get Active, Sleep Improvement, Nutrition</b></Typography> */}
              <Box className={classes.holder}>
                <Typography variant="h6">
                  <b>Program Focus</b>
                </Typography>
                <Grid container spacing={2}>
                  {sessionLogsViewNotes?.programFocus.map((item, index) => (
                    <Grid item lg={4} md={4} style={{ marginBottom: 0 }} key={index}>
                      <Typography variant="body1">
                        <b>{item.focus}</b>
                      </Typography>
                      <Box className={classes.chipHolder}>
                        <ChipComponent
                          variant="outlined"
                          size="small"
                          label={item.achievement || `--`}
                          color="primary"
                          style={{
                            fontSize: `${fontSizes.SMALL}`,
                          }}
                        />
                        <ChipComponent
                          variant="outlined"
                          size="small"
                          label={item.stageOfChange || `--`}
                          color="secondary"
                          style={{
                            marginLeft: `${spacing.SESSION_MARGIN_LARGE}`,
                            fontSize: `${fontSizes.SMALL}`,
                          }}
                        />
                      </Box>
                      <Box className={classes.holder}>
                        <Typography variant="body2">Current: {item.current || `--`}</Typography>
                        <Typography variant="body2">Target: {item.target || `--`}</Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </>
          )}
          {hhData.length !== 0 && (
            <>
              <Box className={classes.holder}>
                <Grid container spacing={2}>
                  {hhData.length > 0 &&
                    hhData.map((item, index) => (
                      <Grid item lg={4} md={4} style={{ marginBottom: 0 }} key={index}>
                        <Box className={classes.holder_single}>
                          <Typography variant="body2">Habit {index + 1}:</Typography>

                          <Typography variant="body2">{item.focusNote}</Typography>
                        </Box>
                        <Box className={classes.holder_single}>
                          <Typography variant="body2">Frequency:</Typography>

                          <Typography variant="body2">
                            {item.recurrencePattern.split('=')[1].split(';')[0]}
                          </Typography>
                        </Box>

                        <Box className={classes.holder_single}>
                          <Typography variant="body2">Duration:</Typography>

                          <Typography variant="body2">
                            {moment(item.startDate).format('MM/DD/YYYY')} -{' '}
                            {moment(item.endDate).format('MM/DD/YYYY')}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                </Grid>
              </Box>
            </>
          )}
        </Box>
      )}
    </>
  );
}
export default ViewNotes;
