import AdminDashBoard from 'components/AdminDashBoard/AdminDashBoard';
import AdminCalendar from 'components/AdminCalendar/AdminCalendar';
import TeamMemberFile from 'components/TeamMemberFile';
import * as Screens from 'screens/coach';
import * as CommmonScreens from 'screens/common';
import { COACH_ROUTES, ROUTES } from 'constants/app';
import AdminConsultations from 'components/AdminConsultations/AdminConsultations';
import Feedbacks from 'components/Feedbacks/Feedbacks';
import TeamMemberInvite from 'components/TeamMemberInvite';
export const OpenRoutes = [
  {
    exact: true,
    path: ROUTES.LOGIN,
    component: CommmonScreens.Login,
  },
  {
    path: `${ROUTES.TEAM_MEMBER_INVITE}/:inviteCode`,
    component: TeamMemberInvite,
  },
  {
    path: `${ROUTES.REQUEST_NEW_PASSWORD}`,
    component: CommmonScreens.RequestNewPassword,
  },
  {
    path: `${ROUTES.RESET_PASSWORD}/:token`,
    component: CommmonScreens.ResetPassword,
  },
  {
    path: `${ROUTES.RESET_PASSWORD_EMAIL_LINK}/:token`,
    component: CommmonScreens.ResetPasswordEmailLink,
  },
];
export const Admin = [
  {
    exact: true,
    path: ROUTES.ROOT,
    component: AdminDashBoard,
  },
  {
    path: COACH_ROUTES.ADMIN_TELEKARDIA_DASHBOARD,
    component: AdminDashBoard,
  },
  {
    path: COACH_ROUTES.ADMIN_CALENDAR,
    component: AdminCalendar,
  },
  {
    exact: true,
    path: COACH_ROUTES.ADMIN_MEMBER_LIST,
    component: Screens.MemberList,
  },
  {
    path: COACH_ROUTES.ADMIN_CONSULT,
    component: AdminConsultations,
  },
  {
    path: COACH_ROUTES.ADMIN_FEEDBACK,
    component: Feedbacks,
  },
  {
    exact: true,
    path: ROUTES.SETTINGS,
    component: CommmonScreens.Settings,
  },
  {
    path: `${ROUTES.SETTINGS}/team-members/:teamMemberId`,
    component: TeamMemberFile,
  },
  {
    exact: true,
    path: `${COACH_ROUTES.MEMBER_PROFILE}/:memberId`,
    component: Screens.MemberProfile,
  },
  {
    exact: true,
    path: `${COACH_ROUTES.ADMIN_SESSIONS}`,
    component: Screens.Sessions,
  },
  {
    exact: true,
    path: COACH_ROUTES.COACH_RESOURCES,
    component: CommmonScreens.ComingSoonPage,
  },
  {
    exact: true,
    path: `${ROUTES.ALERT}/:alertType`,
    component: CommmonScreens.Alert,
  },
];

export const Coach = [
  {
    exact: true,
    path: ROUTES.ROOT,
    component: Screens.DashboardNew,
  },
  {
    exact: true,
    path: COACH_ROUTES.COACH_MEMBER_LIST,
    component: Screens.CoachPortal,
  },
  {
    exact: true,
    path: COACH_ROUTES.COACH_SCHEDULE,
    component: Screens.CoachPortal,
  },
  {
    path: COACH_ROUTES.TELEKARDIA_DASHBOARD,
    component: Screens.DashboardNew,
  },
  {
    exact: true,
    path: ROUTES.CHAT_INBOX,
    component: Screens.ChatInbox,
  },
  {
    path: ROUTES.CHAT_INBOX_DETAILS,
    component: Screens.ChatInboxDetails,
  },
  {
    exact: true,
    path: ROUTES.SETTINGS,
    component: CommmonScreens.Settings,
  },
  {
    exact: true,
    path: `${COACH_ROUTES.MEMBER_PROFILE}/:memberId`,
    component: Screens.MemberProfile,
  },
  {
    exact: true,
    path: COACH_ROUTES.COACH_RESOURCES,
    component: CommmonScreens.ComingSoonPage,
  },
  {
    exact: true,
    path: `${ROUTES.ALERT}/:alertType`,
    component: CommmonScreens.Alert,
  },
];

export const CoachAdmin = [
  ...Admin,
  {
    path: COACH_ROUTES.COACH_PORTAL,
    component: Screens.CoachPortal,
  },
  {
    exact: true,
    path: ROUTES.CHAT_INBOX,
    component: Screens.ChatInbox,
  },
  {
    path: ROUTES.CHAT_INBOX_DETAILS,
    component: Screens.ChatInboxDetails,
  },
  {
    path: COACH_ROUTES.TELEKARDIA_DASHBOARD,
    component: Screens.DashboardNew,
  },
];
