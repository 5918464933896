import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import moment from 'moment';
import PageUpdated from 'components/PageUpdated';
const useStyle = makeStyles({
  headerLine: {
    width: '22px',
    height: '6px',
    borderRadius: '8px',
    backgroundColor: '#2D9F86',
    display: 'block',
    marginTop: '8px',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    '&:nth-of-type(n+2)': {
      marginLeft: '32px',
    },
  },
  headerSubText: {
    fontWeight: 700,
    marginLeft: '16px',
    background: 'white',
    padding: '7px',
  },
});
function Header({ refreshAction }) {
  const history = useHistory();
  const backClick = () => {
    history.goBack();
  };
  const classes = useStyle();
  const { programData: data, loading } = useSelector((state) => state.memberProfile);
  const { me } = useSelector((state) => state.app.dataSources);

  return (
    <Box style={{ marginBottom: '20px' }}>
      {data && (
        <>
          <h1 style={{ fontSize: '20px', fontFamily: 'Work Sans' }}>
            <b>
              {data.participantFirstName}, {data.participantLastName}
            </b>
            {/* todo- add gender here in the header */}
            <span className={classes.headerLine} />
          </h1>
          <p>
            <u
              onClick={backClick}
              style={{ width: 'auto', cursor: 'pointer', fontFamily: 'Work Sans' }}
            >
              Back
            </u>
          </p>
          <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box style={{ display: 'flex' }}>
              <Box className={classes.headerContainer}>
                <Typography variant="body1">MRN</Typography>
                <Typography variant="body1" className={classes.headerSubText}>
                  --
                </Typography>
              </Box>
              <Box className={classes.headerContainer}>
                <Typography variant="body1">DOB</Typography>
                <Typography variant="body1" className={classes.headerSubText}>
                  {data.participantDateOfBirth
                    ? moment(data.participantDateOfBirth).format('MM/DD/YYYY')
                    : ''}
                </Typography>
              </Box>
              <Box className={classes.headerContainer}>
                <Typography variant="body1">AGE</Typography>
                <Typography variant="body1" className={classes.headerSubText}>
                  {`${moment().diff(data.participantDateOfBirth, 'years')} yrs`}
                </Typography>
              </Box>
              <Box className={classes.headerContainer}>
                <Typography variant="body1">SEX</Typography>
                <Typography variant="body1" className={classes.headerSubText}>
                  {data.gender && data.gender[0].toUpperCase() + data.gender.substr(1)}
                </Typography>
              </Box>
              <Box className={classes.headerContainer}>
                <Typography variant="body1">WEIGHT</Typography>
                <Typography variant="body1" className={classes.headerSubText}>
                  --
                </Typography>
              </Box>
            </Box>
            <Box style={{ alignSelf: 'baseline' }}>
              <PageUpdated
                isLoading={loading}
                lastUpdated={data.lastRefreshed}
                handleOnClick={() => refreshAction()}
                hasTabs={true}
                me={me}
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}

export default Header;
