import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import AttachmentIcon from '@material-ui/icons/Attachment';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Box, Button, Grid } from '@material-ui/core';
import { fontSizes, colors, fonts } from '../../../../../style/styleSettings';
import { EnhancedTableHead } from '../../../Common/EnhancedTableHead';
import ViewNotes from '../../../Common/ViewNotes';
import ReportModal from 'components/AllAppointments/ReportModal';
import { useSelector, useDispatch } from 'react-redux';
// import { commonStyles } from '../../styles';
import moment from 'moment';
import { TeleKardiaAPI, GoAPI } from '../../../../../Axios/axios';
import { getTimezoneName } from 'utilities/Utilities';
import { getToken } from 'auth/AuthUtilities';
import EcgLoader from 'components/Shared/EcgLoader';
import { useParams } from 'react-router';
import {
  getMemberProfileSessionLogData,
  getNotesMetadata,
  setDocumentationFilterAction,
} from 'store/actions/memberProfile';
import Documentation from 'components/Common/ModalView/Documentation';
import TeamAlert from 'components/Shared/Alert/TeamAlert';
import { filterVariableMap, tableHead } from './data';
import Filter from './Filter';

const useRowStyles = makeStyles({
  root: {
    '& .MuiButton-label': {
      fontFamily: 'Work Sans',
    },
    minHeight: '100px',
    fontFamily: fonts.SESSION,
    marginTop: '20px',
    color: colors.SESSION_BLACK,
    '& .MuiTableCell-head': {
      fontWeight: 'bold !important',
      fontSize: fontSizes.BASE,
    },
    '& .MuiTypography-h4': {
      fontSize: fontSizes.HEADING,
    },
    '& .MuiTypography-body1': {
      fontSize: fontSizes.BASE,
    },
  },
  paperSection: {
    marginBottom: '20px',
    padding: '20px',
    font: fonts.SESSION,
    fontSize: fontSizes.BASE,
    borderRadius: '8px',
  },
  tableBody: {
    fontFamily: `${fonts.SESSION} !important`,
    '&:nth-of-type(4n+1)': {
      background: 'rgb(250, 250, 250) !important',
    },
    '& .MuiTableCell-body': {
      fontSize: fontSizes.BASE,
      fontFamily: 'Work Sans',
    },
    '& .MuiTypography-h6': {
      fontSize: `${fontSizes.MEDIUM} !important`,
    },
    '& .MuiTypography-body2': {
      fontSize: `${fontSizes.BASE} !important`,
    },
    '& .MuiChip-outlinedPrimary': {
      color: 'black',
      border: `1px solid ${colors.SPRUCE}`,
    },
    '& .MuiChip-outlinedSecondary': {
      color: 'black',
      border: `1px solid ${colors.SESSION_CHIP_OUTLINE_RED}`,
    },
  },
  topMargins: {
    marginTop: '20px',
    position: 'relative',
  },
  tableHeader: {
    fontWeight: 'bold',
    fontSize: fontSizes.MEDIUM,
  },
  viewNotesTableColumn: {
    color: colors.SPRUCE,
    fontWeight: 'bold',
    fontSize: `${fontSizes.CAPTION} !important`,
    cursor: 'pointer',
  },
  buttonAttachment: {
    backgroundColor: '#fff',
    color: '#067F6F',
    marginLeft: '20px',
    padding: '10px 20px',
    border: '1px solid #067F6F',
    fontWeight: '700',
    fontFamily: 'Work Sans',
  },
  buttonNotes: {
    backgroundColor: '#067F6F',
    color: 'white',
    marginLeft: '20px',
    padding: '10px 20px',
    border: '1px solid #067F6F',
    fontWeight: '700',
    fontFamily: 'Work Sans',
  },
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function Row(props) {
  const { row, memberId } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const [openReport, setopenReport] = useState(false);
  const [viewNotesData, setviewNotesData] = useState({});
  const [viewHHData, setViewHHData] = useState([]);
  const [loading, setloading] = useState(false);
  const teamId = useSelector((state) => state.user.team.id);
  const coachId = useSelector((state) => state.user.profile.id);
  const offsetInMinutes = new Date().getTimezoneOffset();
  const timeZone = getTimezoneName();

  const viewNotes = () => {
    if (row.type == 'Session' && row.physicianSession) {
      // for cardiology session on click on view notes the report should show up
      setopenReport(true);
    } else if (row.type == 'AdHoc Note' || row.type == 'AdHoc Call' || row.type == 'Alert') {
      props.setopenViewNotesModal(!props.openViewNotesModal);
    } else {
      setloading(true);
      //api call to receive session notes, program focus and heart habit.
      TeleKardiaAPI.get(
        `/api/v1/teams/${teamId}/clinicians/${coachId}/patients/${memberId}/appointments/${row.id}/detail?offsetInMinutes=${offsetInMinutes}&timeZone=${timeZone}`,
        {
          headers: { Authorization: ` Bearer ${getToken()}` },
        },
      )
        .then((res) => {
          const data = res.data.result;
          setviewNotesData(data);
          setloading(false);
        })
        .then(() => {
          //fetching heart habit data
          GoAPI.get(`/i/v1/kcc/heartHabit/${memberId}/${row.id}`, {
            headers: { Authorization: ` Bearer ${getToken()}` },
          })
            .then((res) => {
              const data = res.data ? res.data : [];
              setViewHHData(data);
              // setNotesLoading(false);
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
      setOpen(!open); //toggle the accordion based on click of "view notes"
    }
  };
  const reportmodalCloseHandler = () => {
    setopenReport(false);
  };
  return (
    <React.Fragment>
      <TableRow className={classes.tableBody}>
        <TableCell component="th" scope="row">
          {moment(row.dateTime).format('MM-DD-yyyy')}
        </TableCell>
        <TableCell>{moment(row.dateTime).format('hh:mm A')}</TableCell>
        <TableCell>
          <div
            style={{
              border: row.status == 'Draft' ? `2px solid ${colors.SPRUCE}` : `2px solid black`,
              borderRadius: '100px',
              padding: '8px 12px 8px 12px',
              width: 'fit-content',
            }}
          >
            <Typography
              variant="body1"
              style={{
                fontWeight: 600,
                textTransform: 'uppercase',
                fontSize: 11,
                letterSpacing: '1.5px',
                lineHeight: '13px',
              }}
            >
              {row.status}
            </Typography>
          </div>
        </TableCell>
        {/* <TableCell>
          {moment(row.scheduledEndTime || row.enddatetime).diff(
            moment(row.scheduledStartTime || row.startdatetime),
            'minutes',
          )}{' '}
          mins
        </TableCell> */}
        <TableCell
          style={{
            textAlign: 'right',
          }}
        >
          {row.attachment ? <AttachmentIcon /> : '-'}
        </TableCell>
        <TableCell>{row.type}</TableCell>
        <TableCell>{row.tag || '-'}</TableCell>
        <TableCell className={classes.viewNotesTableColumn} onClick={viewNotes}>
          {row.appointmentStatus === 'Scheduled' || row.appointmentStatus === 'InProgress' ? (
            '-- --'
          ) : (
            <>
              {open ? 'HIDE NOTES' : 'VIEW NOTES'}
              <IconButton aria-label="expand row" size="small">
                {open ? (
                  <KeyboardArrowUpIcon style={{ color: colors.SPRUCE }} />
                ) : (
                  <KeyboardArrowRightIcon style={{ color: colors.SPRUCE }} />
                )}
              </IconButton>
            </>
          )}
        </TableCell>
      </TableRow>
      <TableRow className={classes.tableBody}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {!!loading ? (
              <EcgLoader />
            ) : (
              <ViewNotes sessionLogsViewNotes={viewNotesData} hhData={viewHHData} />
            )}
          </Collapse>
        </TableCell>
      </TableRow>
      {openReport && ( //Report modal is for cardiology consultations.
        <ReportModal
          key="report-modal"
          appointmentId={row.id}
          patientId={memberId}
          memberId={coachId}
          show={openReport}
          handleClose={reportmodalCloseHandler}
        ></ReportModal>
      )}
    </React.Fragment>
  );
}

function ActivityAndNotes(props) {
  const { memberId } = useParams();
  const dispatch = useDispatch();
  const [sessionLogsDetails, setsessionLogsDetails] = useState([]);
  const [openNotesModal, setopenNotesModal] = useState(false);
  const sessionCredits = useSelector((state) => state.memberProfile?.programData);
  const teamId = useSelector((state) => state.user.team.id);
  const coachId = useSelector((state) => state.user.profile.id);
  const isAdmin = useSelector((state) => state.user.profile.permissions?.isAdmin);
  const { filters, data } = useSelector((state) => state.memberProfile.sessionLogs);
  const filterMetaData = useSelector((state) => state.memberProfile.sessionLogs.metaData);
  const noteMetaData = useSelector((state) => state.memberProfile.noteMetaData);

  const [loading, setloading] = useState(false);
  const [openedNoteId, setOpenedNoteId] = useState(null);

  useEffect(() => {
    if (openedNoteId == null && openNotesModal == false) {
      setloading(true);
      dispatch(getMemberProfileSessionLogData(memberId, ''));
    }
  }, [dispatch, memberId, openedNoteId, openNotesModal]);

  useEffect(() => {
    dispatch(getNotesMetadata(teamId, coachId, false)); //notesmetadata
    dispatch(getNotesMetadata(teamId, coachId, true));
  }, []);

  useEffect(() => {
    if (data) {
      setloading(false);
      setsessionLogsDetails(data);
    }
  }, [data]);

  const classes = useRowStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('scheduledStartTime' || 'startdatetime');
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const openNotes = () => {
    setopenNotesModal(!openNotesModal);
  };
  //to change the current note id
  const currentNoteManager = (id) => {
    setOpenedNoteId(id);
    openNotes();
  };

  //stores filters and refetches
  const filterAndRefetch = (data) => {
    const filterString = _parseFilters(data);
    dispatch(getMemberProfileSessionLogData(memberId, filterString));
    return saveFilters(data);
  };

  //store filters in redux
  const saveFilters = (data) => dispatch(setDocumentationFilterAction(data));

  //used internally
  const _parseFilters = (data) => {
    if (data) {
      //avoid 'all' flags
      //using an array as it facilitates the join operation
      let filterData = Object.values(data);
      let filterArray = [];
      Object.keys(filterVariableMap).map((f) => {
        let currentFilterCodes = [];
        filterData.map((item) => {
          if (item.checked && item.type == f && !item.id.includes('all')) {
            currentFilterCodes.push(item.code);
          }
        });
        if (currentFilterCodes.length > 0) {
          filterArray.push(`${f}=${currentFilterCodes.join(',')}`);
        }
      });
      return filterArray.join('&');
    }
  };

  const filterSeedData = (metaData) => {
    let disableWith = 'type';
    let initData = {};

    if (metaData) {
      const typeList = Object.keys(metaData[disableWith]);
      Object.entries(filterVariableMap).map((d) => {
        initData[`all-${d[0]}`] = {
          id: `all-${d[0]}`,
          name: `All`,
          checked: true,
          type: `${d[0]}`,
          code: metaData[d[0]] ? Object.keys(metaData[d[0]]).join(',') : null,
          disabled: false,
          enableForType: null,
        };
      });
      for (let i = 0; i < Object.keys(metaData).length; i++) {
        let currentFilter = Object.keys(metaData)[i];
        let currentFilterValues = Object.values(metaData)[i];

        for (let j = 0; j < Object.values(currentFilterValues).length; j++) {
          //need on the fly id ${currentFilter}${j+1}
          let enableForType = null;
          switch (currentFilter) {
            case 'type':
              break;
            case 'tag': //if tag then we enable it only for adhoc call and adhoc note
              enableForType = [typeList[0], typeList[1]];
              break;
            case 'attachment': //if attachment then we enable it only for adhoc call and adhoc note
              enableForType = [typeList[0], typeList[1]];
              break;
            case 'status': //if status> 100 (Draft = 101, signed=102) then we enable it only for adhoc call and adhoc note or else for session
              enableForType =
                Object.keys(currentFilterValues)[j] > 100
                  ? [typeList[0], typeList[1]]
                  : [typeList[2]];
              break;
            default:
              break;
          }

          initData[`${currentFilter}${j}`] = {
            name: Object.values(currentFilterValues)[j],
            id: `${currentFilter}${j}`,
            checked: true,
            type: currentFilter,
            code: Object.keys(currentFilterValues)[j],
            disabled: false,
            enableForType: enableForType,
          };
        }
      }
    }
    return initData;
  };
  //function to merge key and values of an object from an array
  function toObject(names, values) {
    var result = {};
    for (var i = 0; i < names.length; i++) result[names[i]] = values[i];
    return result;
  }

  //function to find intersection of two arrays
  function matches(arr1, arr2) {
    const intersection = arr1.filter((element) => arr2.includes(element));
    return intersection.length > 0;
  }

  const disableCriterion = (data) => {
    let filterData = Object.values(data);
    let typeName = 'type';
    let happyEnableTypeArray = ['1', '2', '3'];
    let enableTypeArray = [];
    filterData.map((f) => {
      if (f.type == typeName && !f.id.includes('all')) {
        if (f.checked && f.code) enableTypeArray.push(f.code);
      }
    });
    filterData.map((f) => {
      if (f.type != typeName && f.enableForType != null) {
        if (matches(enableTypeArray, f.enableForType)) f.disabled = false;
        else {
          f.disabled = true;
          f.checked = false;
          //remove "all" checked
          data[`all-${f.type}`].checked = false;
        }
      }
    });
    if (enableTypeArray !== happyEnableTypeArray) {
      filterData.map((f) => {
        if (f.id.includes('all')) {
          f.checked = false;
        }
      });
    }

    return toObject(Object.keys(data), filterData);
  };
  return (
    <>
      <Box className={classes.root}>
        {loading ? (
          <EcgLoader />
        ) : (
          <>
            <>
              {/* <TeamAlert title={'Note has been successfully saved'} error={false} type={"Warning"} /> */}
              <Paper className={classes.paperSection}>
                <Grid container>
                  <Grid item lg={3} md={3}>
                    <Typography variant="body1">Coaching Session</Typography>
                    <Typography variant="body1">
                      {sessionCredits.coachRemainingCredits && sessionCredits.coachTotalCredits ? (
                        <b>
                          {/* {' '}
                        {sessionCredits.coachRemainingCredits} of {sessionCredits.coachTotalCredits}{' '}
                        available{' '} */}
                          Unlimited
                        </b>
                      ) : (
                        <b> -- </b>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item lg={3} md={3}>
                    <Typography variant="body1">Cardiology Consultation</Typography>
                    <Typography variant="body1">
                      {sessionCredits.doctorRemainingCredits &&
                      sessionCredits.doctorTotalCredits ? (
                        sessionCredits.doctorRemainingCredits >
                        sessionCredits.doctorTotalCredits ? (
                          <b>Unlimited</b>
                        ) : (
                          <b>
                            {' '}
                            {sessionCredits.doctorRemainingCredits} of{' '}
                            {sessionCredits.doctorTotalCredits} available{' '}
                          </b>
                        )
                      ) : (
                        <b> -- </b>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>

              {/* filter and add note section  */}
              <Paper className={classes.paperSection}>
                <Grid container alignItems="flex-start" spacing={2} justify="space-between">
                  <Grid item>
                    <Typography variant="h4">Documentation</Typography>
                  </Grid>
                  <Grid item style={{ display: 'inline-flex' }}>
                    <Button onClick={openNotes} className={classes.buttonNotes}>
                      Add Note
                    </Button>
                  </Grid>
                </Grid>

                <Filter
                  filterVariableMap={filterVariableMap}
                  filterMetaData={filterMetaData}
                  filterSeedData={filterSeedData}
                  filterAndRefetch={filterAndRefetch}
                  filtersOnChange={(data) => disableCriterion(data)}
                  saveFilters={saveFilters}
                />

                <TableContainer component={Paper} className={classes.topMargins}>
                  <Table aria-label="collapsible table">
                    <EnhancedTableHead
                      from="sessionLogs"
                      columnData={tableHead}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      {sessionLogsDetails &&
                        sessionLogsDetails.length > 0 &&
                        stableSort(sessionLogsDetails, getComparator(order, orderBy)).map(
                          (row, index) => (
                            <Row
                              key={index}
                              row={row}
                              memberId={memberId}
                              openNotesModal={openNotesModal}
                              setopenViewNotesModal={() => currentNoteManager(row.id)}
                            />
                          ),
                        )}
                    </TableBody>
                  </Table>
                  {!sessionLogsDetails ||
                    (sessionLogsDetails.length == 0 && (
                      <Typography
                        variant="h6"
                        style={{
                          textAlign: 'center',
                          paddingTop: '30px',
                          fontFamily: 'Work Sans',
                          margin: '20px',
                        }}
                      >
                        No data available
                      </Typography>
                    ))}
                </TableContainer>
              </Paper>
            </>
          </>
        )}
      </Box>
      {openNotesModal && (
        <Documentation
          onClose={() => {
            currentNoteManager(null);
          }}
          noteId={openedNoteId}
          setopenNotesModal={setopenNotesModal}
          memberId={memberId}
          openNotesModal={openNotesModal}
        />
      )}
    </>
  );
}

export default ActivityAndNotes;
