import { getToken } from 'auth/AuthUtilities';
import { GoAPI, TeleKardiaAPI } from '../../Axios/axios';
import { getTimezoneName } from 'utilities/Utilities';
import * as actionTypes from './actionTypes';

export const callNextSessionAPI = (teamId, coachId, nextSessionInput) => {
  return TeleKardiaAPI.post(
    `api/v1/teams/${teamId}/clinicians/${coachId}/appointments/nextSession`,
    nextSessionInput,
    {
      headers: { Authorization: ` Bearer ${getToken()}` },
      params: {
        timeZoneOffset: new Date().getTimezoneOffset(),
        timeZone: getTimezoneName(),
      },
    },
  );
};
const appendNextSessionToStore = (resultData, mlData, success) => {
  let temp = [...mlData];
  if (success) {
    temp.map((m) => {
      m.nextSession = resultData[m.participantId];
      return m;
    });
    return temp;
  }
};
const getChatUsersStart = () => ({
  type: actionTypes.GET_CHAT_USERS_START,
});

const memberListAction = (data, count) => ({
  type: actionTypes.GET_MEMBERS_LIST,
  payload: { data, count },
});

const memberListNextSessionAction = (data) => ({
  type: actionTypes.GET_MEMBERS_LIST_NEXT_SESSION_DATA,
  payload: { data },
});
export const getMemberDetails =
  (
    isAdmin,
    coachId,
    teamId,
    page,
    pageLimit,
    sortColumn = 'user_last_name',
    sortType = 'ASC',
    filList = null,
  ) =>
  (dispatch, getState) => {
    let filterList = { ...filList };
    let bpFilter = [];

    //==updated filterList routine====
    let finalFilterString = '';
    if (filterList) {
      bpFilter = filterList?.bloodPressure || [];
      delete filterList.bloodPressure;
      let length = Object.entries(filterList).length - 1;
      Object.entries(filterList).map((f, i) => {
        let str = '';
        str = `${f[0]}:`;
        let filtered = f[1].filter(function (el) {
          return el != null;
        });
        str += filtered.join(',');
        if (i !== length) str += '*';
        finalFilterString += str;
      });
    }
    // console.log(bpFilter)
    // console.log(finalFilterString);
    dispatch(getChatUsersStart());
    let url = '';
    url = isAdmin
      ? `/i/v1/kcc/adminList?page=${
          page + 1
        }&pageLimit=${pageLimit}&sort=${sortColumn}&sorttype=${sortType}&filter=${finalFilterString}&bp=${bpFilter.join(
          ',',
        )}
        `
      : `/i/v1/kcc/adminList?coach=${coachId}&page=${
          page + 1
        }&pageLimit=${pageLimit}&sort=${sortColumn}&sorttype=${sortType}&filter=${finalFilterString}&bp=${bpFilter.join(
          ',',
        )}`;
    GoAPI.get(url, {
      headers: { Authorization: ` Bearer ${getToken()}` },
    })
      .then((res) => {
        let resultData = res.data;
        dispatch(memberListAction(resultData.participantList, resultData.totalCount));
        let nextSessionInput = resultData.participantList.map((m) => m.participantId);
        return callNextSessionAPI(teamId, coachId, nextSessionInput);
      })
      .then((res) => {
        // console.log(res)
        let mlData = getState().memberList.memberListData;
        let resultData = res.data.result;
        let data = appendNextSessionToStore(resultData, mlData, res.data.success);
        return dispatch(memberListNextSessionAction(data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

const searchedMemberAction = (data) => ({
  type: actionTypes.GET_SEARCH_MEMBER,
  payload: data,
});
export const getSearchedMember = () => (dispatch, getState) => {
  // console.log('search.. ');
  GoAPI.get(`/i/v1/kcc/participants/autocomplete`, {
    headers: { Authorization: ` Bearer ${getToken()}` },
  })
    .then((res) => {
      let resultData = res.data;
      dispatch(searchedMemberAction(resultData));
    })
    .catch((err) => {
      console.log(err);
    });
};

const filterActionActions = (data) => ({
  type: actionTypes.SET_FILTER_DATA,
  payload: data,
});
export const filterAction =
  (data, isAdmin, coachId, teamId, page, pageLimit, sortColumn, fillist) =>
  (dispatch, getState) => {
    dispatch(filterActionActions(data));
    dispatch(
      getMemberDetails(
        isAdmin,
        coachId,
        teamId,
        page,
        pageLimit,
        sortColumn,
        'ASC',
        fillist,
      ),
    );
  };
