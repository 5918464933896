import React from 'react'
import ReactDOM from 'react-dom'
import { modalRoot } from 'constants/app'

import styles from './Modal.module.css'

class Modal extends React.Component {
  componentDidMount() {
    document.addEventListener('mousedown', this.handleOverlayClick, false)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOverlayClick)
  }

  handleOverlayClick = e => {
    if (this.overlayRef && this.overlayRef.isSameNode(e.target)) {
      this.handleCloseModal()
    }
  }


  handleCloseModal = () => {
    const {
      closeModal,
      onClose,
    } = this.props

    closeModal()

    if (typeof onClose === 'function') {
      onClose()
    }
  }

  render() {
    const {
      modalIsOpen,
      children,
      className,
    } = this.props

    return modalIsOpen
      ? ReactDOM.createPortal(
        <div
          ref={node => { this.overlayRef = node }}
          className={styles.overlay}
        >
          <div
            className={`${styles.modal} ${className}`}
          >
            {children}
          </div>
        </div>,
        modalRoot,
      )
      : null
  }
}

export default Modal
