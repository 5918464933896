import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import * as API from '../../Services/API/actions';
import { useSelector } from 'react-redux';

const BootstrapInput = withStyles((theme) =>
  createStyles({
    root: {
      margin: 0,
      'label + &': {
        marginTop: theme.spacing(3),
        fontSize: 16,
        color: '#000 !important',
        width: '100%',
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #d8d8d8',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: ['inherit'].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#142A39',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }),
)(InputBase);

const useStyles = makeStyles((theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
    },
  }),
);

export default function CustomizedSelects(props) {
  const classes = useStyles();
  const clinicianId = useSelector((state) => state.user.profile.id);
  const teamId = useSelector((state) => state.user.team.id);
  const [period, setperiod] = useState('No Follow-up');

  useEffect(() => {
    if (!props.disable) {
      API.getdurationText(teamId, clinicianId, props.appointmentId).subscribe(
        (res) => {
          res.data.result !== null
            ? setperiod(decodeURI(res.data.result))
            : setperiod('No Follow-up');
          res.data.result !== null
            ? props.handleChange({
                target: {
                  value: decodeURI(res.data.result),
                },
              })
            : props.handleChange({
                target: {
                  value: 'No Follow-up',
                },
              });
        },
        (error) => {
          setperiod('No Follow-up');
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeperiod = (periodvalue) => {
    props.handleChange(periodvalue);
    setperiod(periodvalue.target.value);
  };
  return (
    <div className="d-block w-100">
      <FormControl className={classes.margin}>
        <InputLabel id="demo-customized-select-label">Follow-up</InputLabel>
        <Select
          disabled={props.disable}
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          // value={props.period}
          value={period}
          // onChange={props.handleChange}
          onChange={(e) => changeperiod(e)}
          input={<BootstrapInput />}
        >
          <MenuItem value={'No Follow-up'}>No Follow-up</MenuItem>
          <MenuItem value={'3 Days'}>3 Days</MenuItem>
          <MenuItem value={'1 Week'}>1 Week</MenuItem>
          <MenuItem value={'2 Weeks'}>2 Weeks</MenuItem>
          <MenuItem value={'1 Month'}>1 Month</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
