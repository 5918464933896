import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import EventIcon from '@material-ui/icons/Event';
import EmailIcon from '@material-ui/icons/Email';
// import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import HomeIcon from '@material-ui/icons/Home';
import WcIcon from '@material-ui/icons/Wc';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import SettingsIcon from '@material-ui/icons/Settings';
import SupportIcon from '@material-ui/icons/LiveHelpOutlined';
import { COACH_ROUTES, DOCTOR_ROUTES, ROUTES } from 'constants/app';
import DashboardIcon from 'assets/images/poll.svg';
import SchedulerIcon from 'assets/images/icons/scheduler.svg';
import FeedbackIcon from 'assets/images/icons/feedback.svg';
import ConsultationIcon from 'assets/images/icons/consultation.svg';
import WarningIcon from '@material-ui/icons/Warning';
// import TeleConnect from 'assets/images/icons/teleconnect.svg';

export const fetchConfigs = (isAdmin, isPhysician, history, unreadCount, status, alertCount) => {
  const coachConfig = {
    main: [
      {
        condition: isAdmin && !isPhysician,
        navigateTo: COACH_ROUTES.ADMIN_TELEKARDIA_DASHBOARD,
        icon: DashboardIcon,
        title: 'Dashboard',
        style: { marginLeft: '3px' },
      },

      {
        condition: isAdmin && isPhysician,
        navigateTo:
          history.location.pathname === ROUTES.ROOT
            ? ROUTES.ROOT
            : COACH_ROUTES.ADMIN_TELEKARDIA_DASHBOARD,
        icon: DashboardIcon,
        title: 'Dashboard',
        style: { marginLeft: '3px' },
      },
      {
        condition: !isAdmin && isPhysician,
        navigateTo: COACH_ROUTES.TELEKARDIA_DASHBOARD,
        icon: HomeIcon,
        unreadCount: { unreadCount },
        title: 'Home',
      },
      {
        condition: isAdmin && isPhysician,
        navigateTo: COACH_ROUTES.TELEKARDIA_DASHBOARD,
        icon: HomeIcon,
        unreadCount: { unreadCount },
        title: 'Home',
      },
      {
        condition: isPhysician,
        navigateTo: ROUTES.CHAT_INBOX,
        icon: EmailIcon,
        unreadCount: { unreadCount },
        status: status,
        title: 'Inbox',
      },
      {
        condition: !isAdmin && isPhysician,
        navigateTo: COACH_ROUTES.COACH_MEMBER_LIST,
        icon: FormatListBulletedIcon,
        title: 'Member List',
      },
      {
        condition: isPhysician,
        navigateTo: COACH_ROUTES.COACH_SCHEDULE,
        icon: EventIcon,
        title: 'Schedule',
      },
      {
        condition: isAdmin,
        navigateTo: COACH_ROUTES.ADMIN_MEMBER_LIST,
        icon: FormatListBulletedIcon,
        title: 'Member List',
      },
      {
        condition: isAdmin,
        navigateTo: COACH_ROUTES.ADMIN_FEEDBACK,
        icon: FeedbackIcon,
        title: 'Feedback',
        style: { marginLeft: '3px' },
      },
      {
        condition: isAdmin,
        navigateTo: COACH_ROUTES.ADMIN_CALENDAR,
        icon: SchedulerIcon,
        title: 'Scheduler',
        style: { marginLeft: '3px' },
      },
      {
        condition: isAdmin,
        navigateTo: COACH_ROUTES.ADMIN_SESSIONS,
        icon: ConsultationIcon,
        title: 'Sessions',
      },
      {
        condition: isAdmin || isPhysician,
        navigateTo: `${ROUTES.ALERT}/list`,
        icon: WarningIcon,
        alertCount: alertCount,
        title: 'Alert',
      },
    ],
    footer: [
      {
        navigateTo: COACH_ROUTES.COACH_RESOURCES,
        icon: AccessibilityNewIcon,
        title: 'Resources',
      },
      {
        navigateTo: ROUTES.SETTINGS,
        icon: SettingsIcon,
        title: 'Settings',
      },
    ],
  };
  const doctorConfig = {
    main: [
      {
        condition: isAdmin && !isPhysician,
        navigateTo: DOCTOR_ROUTES.TELEKARDIA_ADMIN_DASHBOARD,
        icon: DashboardIcon,
        title: 'Dashboard',
      },
      {
        condition: isAdmin && isPhysician,
        navigateTo: DOCTOR_ROUTES.TELEKARDIA_ADMIN_DASHBOARD,
        icon: DashboardIcon,
        title: 'Dashboard',
        style: { marginLeft: '3px' },
      },
      {
        condition: isAdmin && isPhysician,
        navigateTo: DOCTOR_ROUTES.TELEKARDIA_DASHBOARD,
        icon: HomeIcon,
        title: 'Home',
        style: { marginLeft: '3px' },
      },
      {
        condition: !isAdmin && isPhysician,
        navigateTo: DOCTOR_ROUTES.TELEKARDIA_DASHBOARD,
        icon: HomeIcon,
        title: 'Home',
      },
      {
        condition: isPhysician,
        navigateTo: DOCTOR_ROUTES.TELEKARDIA_CONSULTATION,
        icon: EventIcon,
        title: 'Schedule',
      },
      {
        condition: isAdmin && isPhysician,
        navigateTo: DOCTOR_ROUTES.TELEKARDIA_ALL_APPOINTMENT,
        icon: WcIcon,
        title: 'Provider Sessions',
      },
      {
        condition: isAdmin,
        navigateTo: DOCTOR_ROUTES.TELEKARDIA_ADMIN_FEEDBACKS,
        icon: FeedbackIcon,
        title: 'Feedbacks',
        style: { marginLeft: '3px' },
      },
      {
        condition: isAdmin,
        navigateTo: DOCTOR_ROUTES.TELEKARDIA_ADMIN_CALENDAR,
        icon: SchedulerIcon,
        title: 'Scheduler',
        style: { marginLeft: '3px' },
      },

      {
        condition: isAdmin && isPhysician,
        navigateTo: DOCTOR_ROUTES.TELEKARDIA_ADMIN_CONSULTATIONS,
        icon: ConsultationIcon,
        title: 'Admin Sessions',
      },
      {
        condition: isAdmin && !isPhysician,
        navigateTo: DOCTOR_ROUTES.TELEKARDIA_ADMIN_CONSULTATIONS,
        icon: ConsultationIcon,
        title: 'Sessions',
      },
      {
        condition: isPhysician && !isAdmin,
        navigateTo: DOCTOR_ROUTES.TELEKARDIA_ALL_APPOINTMENT,
        icon: WcIcon,
        title: 'Sessions',
      },
      // {
      //   condition: isAdmin || isPhysician,
      //   navigateTo: ROUTES.ALERT,
      //   icon: WarningIcon,
      //   title: 'Alert',
      // },
      {
        condition: isPhysician || isAdmin,
        href: 'https://kardiapro.zendesk.com/',
        target: '_blank',
        icon: SupportIcon,
        title: 'Support',
      },
    ],
    footer: [
      {
        navigateTo: DOCTOR_ROUTES.DOCTOR_RESOURCES,
        icon: AccessibilityNewIcon,
        title: 'Resources',
      },
      {
        navigateTo: ROUTES.SETTINGS,
        icon: SettingsIcon,
        title: 'Settings',
      },
    ],
  };
  return { coachConfig, doctorConfig };
};
