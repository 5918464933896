import { Button, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import NexmoClient from 'nexmo-client';
import { Resizable } from 're-resizable';
import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import Draggable from 'react-draggable';
import { useStopwatch } from 'react-timer-hook';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import styles from './adhoc.module.css';
import * as API from 'Services/API/actions';
import { closeAdhocCall, updateNotesId } from 'store/actions';
import AddEditNotes from 'screens/coach/MemberProfile/components/Documentation/AddEditNotes';
import { colors, fonts, fontSizes } from 'style/styleSettings';
import { getNotesMetadata } from 'store/actions/memberProfile';
import * as constant from 'constants/app';

const useStyles = makeStyles((theme) => ({
  paper: {
    // position: 'absolute',
    width: 'auto',
    backgroundColor: '#F1F2F3',
    //   border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: '15px 15px 30px',
    textAlign: 'center',
    '& .MuiTypography-h4': {
      fontSize: fontSizes.LARGE,
      fontFamily: fonts.SESSION,
      fontWeight: 500,
    },
    '& .MuiTypography-h6': {
      fontSize: fontSizes.HEADING,
      fontFamily: fonts.SESSION,
    },
    '& .MuiTypography-h5': {
      fontSize: fontSizes.BASE,
      fontFamily: fonts.SESSION,
    },
    '& .MuiButton-root': {
      padding: '8px 20px',
      fontWeight: 'bold',
      fontFamily: fonts.SESSION,
      // minWidth: '100%',
      border: '1px solid #142A39',
      textTransform: 'uppercase',
    },
    '& .MuiButtonBase-root.Mui-disabled': {
      backgroundColor: `lightgrey !IMPORTANT`,
      color: `white !important`,
      border: `none !important`,
    },
  },
}));

function AdhocCallWindow() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const timerRef = useRef(null);
  const [callProgress, setProgress] = useState(constant.CALL_MEMBER);
  const [vonageApp, setvonageApp] = useState(null);
  const [callStatus, setcallStatus] = useState(null);
  const [noteId, setNoteId] = useState(null);
  const [notePostMessage, setnotePostMessage] = useState({});
  const [dragState, setDragState] = useState(0);
  // const isCallStarted = useSelector((state) => state.adhocCall.isFloatingWindow);
  const memberId = useSelector((state) => state.adhocCall.memberId);
  const teamId = useSelector((state) => state.user.team.id);
  const profileId = useSelector((state) => state.user.profile.id);
  const {
    ParticipantPhoneNumber: phoneNumber,
    ParticipantCountryCode: phoneNumberCountryCode,
    participantFirstName,
    participantLastName,
  } = useSelector((state) => state.memberProfile.programData);

  const { seconds, minutes, start, pause } = useStopwatch({ autoStart: false });

  useEffect(() => {
    dispatch(getNotesMetadata(teamId, profileId, false));
    return () => {
      hangupCall();
    };
  }, []);

  useEffect(() => {
    if (Object.keys(notePostMessage).length > 0 && notePostMessage?.success) {
      timerRef.current = setTimeout(() => {
        handleClose();
      }, 3000);
    }
    return () => clearTimeout(timerRef.current);
  }, [notePostMessage]);

  const initiateCall = () => {
    setProgress(constant.INITIATING_CALL);
    let body = { toId: memberId };
    API.getVonageToken(teamId, profileId, body).subscribe(
      (res) => {
        // console.log(res);
        VonageInit(res.data.result.token, res.data.result.id);
        switchHandler();
      },
      (error) => {
        console.log(error);
      },
    );
  };

  useEffect(() => {
    if (vonageApp !== null) {
      switchHandler();
    }
  }, [vonageApp]);

  // const callMetrics = (conversationId) => {
  //   API.fallBack_CallMetrics(teamId, profileId, props.appointmentId, conversationId).subscribe(
  //     (res) => {
  //       //update callMetrics response
  //     },
  //     (error) => {
  //       console.log(error);
  //     },
  //   );
  // };

  const VonageInit = (token, callId) => {
    new NexmoClient({ debug: true })
      .login(token)
      .then((app) => {
        setvonageApp(app);
        app.on('member:call', (member, call) => {
          setcallStatus(call);
          // callMetrics(call.conversation.id);
          app.getConversation(call.conversation.id).then((conversation) => {
            conversation.on('member:left', (event) => {
              call.hangUp();
            });
          });
        });
        app.on('call:status:changed', (call) => {
          console.log(call.status);
          if (call.status === call.CALL_STATUS.STARTED) setProgress(constant.CONNECTING);
          else if (call.status === call.CALL_STATUS.RINGING) setProgress('Ringing');
          else if (
            call.status === call.CALL_STATUS.CANCELLED ||
            call.status === call.CALL_STATUS.BUSY ||
            call.status === call.CALL_STATUS.FAILED ||
            call.status === call.CALL_STATUS.TIMEOUT ||
            call.status === call.CALL_STATUS.REJECTED
          ) {
            setProgress(constant.NOT_ANSWERED);
            call.hangUp();
          } else if (call.status === call.CALL_STATUS.ANSWERED) {
            setProgress(constant.CALL_IN_PROGRESS);
            start();
            let body = { started: true };
            API.updateCallStatus(teamId, profileId, callId, body).subscribe(
              (res) => {
                // console.log(res);
              },
              (error) => {
                console.log(error);
              },
            );
          } else if (call.status === call.CALL_STATUS.COMPLETED) {
            setProgress(constant.CALL_COMPLETED);
            call.hangUp();
            pause();
            let body = { started: false };
            API.updateCallStatus(teamId, profileId, callId, body).subscribe(
              (res) => {
                setNoteId(res.data.result);
                dispatch(updateNotesId(res.data.result));
              },
              (error) => {
                console.log(error);
              },
            );
          } else {
            setProgress(constant.NOT_ANSWERED);
            API.callNotConnectedFallback(teamId, profileId, callId).subscribe(
              (res) => {
                setNoteId(res.data.result);
              },
              (error) => console.log(error),
            );
            call.hangUp();
          }
        });
        // debugger;
      })
      .catch((err) => console.log(err));
  };
  const switchHandler = () => {
    let number = `+${phoneNumberCountryCode}${phoneNumber.replace(/[^\w\s]/gi, '')}`;
    if (vonageApp === null) return;
    if (callProgress === 'Initiating Call') {
      vonageApp.callServer(number);
    } else if (
      callProgress === constant.CONNECTING ||
      callProgress === constant.RINGING ||
      callProgress === constant.CALL_IN_PROGRESS
    ) {
      callStatus.hangUp();
      pause();
    }
  };
  const hangupCall = () => {
    if (vonageApp !== null) callStatus.hangUp();
  };

  const handleClose = () => {
    dispatch(closeAdhocCall());
  };

  const onStart = () => {
    setDragState(dragState + 1);
  };

  const onStop = () => {
    setDragState(dragState - 1);
  };
  const dragHandlers = { onStart: onStart, onStop: onStop };

  return ReactDOM.createPortal(
    <div className={classes.paper}>
      {/* {isCallStarted && ( */}
      <Draggable
        handle="p"
        {...dragHandlers}
        bounds={{ top: -200, left: -500, right: 700, bottom: 600 }}
      >
        <Paper className={styles.mainContainer}>
          <Resizable
            defaultSize={{
              width: '560px',
              height: 'auto',
            }}
            style={{ margin: '-10px -10px -20px', padding: '10px', overflow: 'hidden' }}
          >
            <Grid container>
              <Grid item xs={12}>
                {Object.keys(notePostMessage).length > 0 &&
                  (notePostMessage.success ? (
                    <Typography
                      variant="h5"
                      style={{
                        color: colors.SPRUCE,
                        fontSize: fontSizes.MEDIUM,
                        fontWeight: 700,
                        marginBottom: '10px',
                      }}
                    >
                      Notes are saved successfully
                    </Typography>
                  ) : (
                    <Typography
                      variant="h5"
                      style={{
                        color: colors.ERROR_RED,
                        fontSize: fontSizes.MEDIUM,
                        fontWeight: 700,
                        marginBottom: '10px',
                      }}
                    >
                      Notes could not saved due to error.
                    </Typography>
                  ))}
              </Grid>
              <p style={{ cursor: 'move', width: '100%' }}>
                <Grid
                  item
                  xs={12}
                  style={{
                    textAlign: 'left',
                    borderBottom: '1px solid #B2B6B2',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '15px',
                    paddingBottom: '10px',
                  }}
                >
                  <Typography variant="h4">{callProgress}</Typography>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {callProgress === constant.CALL_IN_PROGRESS && (
                      <>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <Typography variant="h6">
                            {minutes < 10 ? <span>0{minutes}</span> : <span>{minutes}</span>}:
                            {seconds < 10 ? <span>0{seconds}</span> : <span>{seconds}</span>}
                          </Typography>
                        </div>
                        <Button
                          style={{
                            backgroundColor: '#CC3D3F',
                            color: 'white',
                            marginLeft: '20px',
                            borderColor: '#CC3D3F',
                          }}
                          onClick={switchHandler}
                        >
                          Hang Up
                        </Button>
                      </>
                    )}
                    {callProgress !== constant.CALL_IN_PROGRESS &&
                      callProgress !== constant.CONNECTING && (
                        <CloseIcon style={{ cursor: 'pointer' }} onClick={handleClose} />
                      )}
                  </div>
                </Grid>
              </p>
              {/* <hr style={{ color: 'white' }} /> */}
              <Grid item xs={12}>
                {callProgress === constant.NOT_ANSWERED ||
                callProgress === constant.CALL_IN_PROGRESS ||
                callProgress === constant.CALL_COMPLETED ? (
                  <div style={{ paddingBottom: '10px', marginTop: '-15px' }}>
                    <AddEditNotes
                      callProgress={callProgress}
                      type={constant.ADHOC_CALL}
                      noteId={noteId}
                      memberId={memberId}
                      setnotePostMessage={setnotePostMessage}
                      callDuration={`${minutes}min ${seconds}sec`}
                    />
                  </div>
                ) : (
                  <>
                    <Paper
                      style={{
                        backgroundColor: 'white',
                        borderRadius: 5,
                        padding: '0 20px 10px',
                      }}
                    >
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <Typography variant={'h4'}>
                            {callProgress === constant.CALL_MEMBER
                              ? `Are you sure you want to call?`
                              : `Calling...`}
                          </Typography>
                          <Typography
                            variant={'h4'}
                          >{`${participantFirstName} ${participantLastName}`}</Typography>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                          }}
                        >
                          {callProgress === constant.CALL_MEMBER && (
                            <Button
                              style={{ color: '#067F6F', borderColor: '#067F6F' }}
                              onClick={handleClose}
                            >
                              Cancel
                            </Button>
                          )}
                          {(callProgress === constant.INITIATING_CALL ||
                            callProgress === constant.CONNECTING ||
                            callProgress === constant.CALL_MEMBER) && (
                            <Button
                              style={{
                                backgroundColor:
                                  callProgress === constant.CALL_MEMBER ? '#067F6F' : '#CC3D3F',
                                color: 'white',
                                marginLeft: '20px',
                                borderColor:
                                  callProgress === constant.CALL_MEMBER ? '#067F6F' : '#CC3D3F',
                              }}
                              onClick={
                                callProgress === constant.CALL_MEMBER ? initiateCall : hangupCall
                              }
                              disabled={callProgress === constant.INITIATING_CALL}
                            >
                              {callProgress === constant.CALL_MEMBER
                                ? `Yes, Call Member`
                                : `Hang up`}
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </Paper>
                  </>
                )}
              </Grid>
            </Grid>
          </Resizable>
        </Paper>
      </Draggable>
      {/* )} */}
    </div>,
    document.getElementById('floating-root'),
  );
}

export default AdhocCallWindow;
