import { Typography } from '@mui/material';
import React from 'react';

function CustomText(props) {
  const { params, color, weight } = props;
  return (
    <Typography
      variant="body1"
      style={{ fontWeight: weight, color: params.row.status === 3 ? 'lightgray' : color }}
    >
      {`${params?.row?.lastName}, ${params?.row?.firstName}`}
    </Typography>
  );
}

export default CustomText;
