/* eslint-disable */
import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  Tabs,
  Tab,
  SvgIcon,
  Container,
  IconButton,
} from '@material-ui/core';
import ListPageFeedbacks from '../Shared/List/ListPageFeedbacks';
// import Container from "../Shared/Container";
import FeedbackModal from './FeedbackModal';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import apiservice from 'api/services/apiservice.service';
import { streamToPromise, getTimezoneName } from 'utilities/Utilities';
// import { feedbacksdummydata } from "./feedbackdummydata.js";
import moment from 'moment';
import BottomLineTitle from '../Shared/Typography/BottomLineTitle';
import PropTypes from 'prop-types';
// import EcgLoader from "../Shared/EcgLoader";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MuiTypography from '../Shared/Typography/MuiTypography';
import EcgLoaderSmall from '../Shared/EcgLoaderSmall';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import get from 'lodash.get';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#FFFFFF',
  },
  paperroot: {
    flexGrow: 1,
    // maxWidth: 500,
  },
  customTabRoot: {
    color: '#142A39',
  },
  customTabIndicator: {
    backgroundColor: '#2d9f86',
    height: '4px',
  },
  tabRoot: {
    fontSize: '14px !important',
    // textTransform: 'initial',
    minWidth: 166,
    fontWeight: '700',
    fontFamily: ['Work Sans'].join(','),
  },
  gridPadding: {
    padding: theme.spacing(1),
  },
  gridmhorizontalpadding: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  fromsurveyspadding: {
    paddingLeft: theme.spacing(1),
  },
  gridmverticaltop: {
    paddingTop: theme.spacing(1),
  },
  npsscorefont: {
    width: '72px',
    height: '56px',
    fontFamily: 'Work Sans !important',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '48px',
    lineHeight: '56px',
    color: '#142A39',
  },
  fromtitlefont: {
    width: '130px',
    height: '19px',
    fontFamily: 'Work Sans !important',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '0.25px',
    whiteSpace: 'nowrap',
    color: '#142A39',
  },
  npscopetitlefont: {
    height: '16px',
    fontFamily: 'Work Sans !important',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '2.5px',
    color: '#415B6A',
    textTransform: 'uppercase',
  },
  pagetitle: {
    height: '42px',
    fontFamily: 'Work Sans !important',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '34px',
    lineHeight: '40px',
    letterSpacing: '0.25px',
    color: '#142A39',
  },
  smallButton: {
    backgroundColor: 'rgba(20, 42, 57, 0.15)',
    color: 'black',
    padding: '10px',
    margin: '0px 0px 0px 10px',
    border: 'none',
    cursor: 'pointer',
    width: 40,
    height: 40,
    opacity: 0.9,
    borderRadius: '8px',
  },
}));

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box div={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function Feedbacks(props) {
  const { t } = useTranslation();
  const { history } = props;
  const clinicianId = useSelector((state) => state.user.profile.id);
  const teamId = useSelector((state) => state.user.team.id);
  const mediawidth = useWidth();
  const classes = useStyles();
  //   const clinicianId = useSelector((state) => state.user.profile.id);
  const [value, setValue] = useState(0);
  const [, setpageSize] = useState('');
  const [isReportModalOpen, setisReportModalOpen] = useState(false);
  const [appointmentinfomodal, setappointmentinfomodal] = useState('');
  const [, setpatientinfo] = useState({});
  const [submittedcount, setsubmittedcount] = useState('');
  const [unsubmittedcount, setunsubmittedcount] = useState('');
  const [avgnps, setavgnps] = useState('');
  const [isloading, setisloading] = useState(false);
  const [isnpsscoreloading, setisnpsscoreloading] = useState(false);

  const [npstotalcount, setnpstotalcount] = useState('');
  const [sortPropsName, setSortPropsName] = useState('date');
  const [sortordertype, setSortordertype] = useState('asc');
  const timezoneValue = getTimezoneName();

  useEffect(() => {
    return () => {
      setisReportModalOpen(false);
    };
  }, []);

  const reportmodalCloseHandler = () => {
    setisReportModalOpen(false);
  };

  const loadAction = useCallback(async (pageDetail) => {
    setisloading(true);
    setisnpsscoreloading(true);
    let offsetInMinutes = new Date().getTimezoneOffset();

    const unsubmittedsurveyrequest = streamToPromise(
      apiservice.getapiservice({
        baseUrl: `api/v1/teams/${teamId}/admin/${clinicianId}/surveys?offsetInMinutes=${offsetInMinutes}&surveyStatus=${1}&timeZone=${timezoneValue}`,
      }),
    );
    const unsubmittedsurveys = await unsubmittedsurveyrequest;
    setunsubmittedcount(unsubmittedsurveys.data.totalElements);
    const sortpropsname =
      pageDetail.sortprops && pageDetail.sortprops.sortparameters
        ? pageDetail.sortprops.sortparameters.headername
        : undefined;

    const ordertype =
      pageDetail.sortprops && pageDetail.sortprops.sortparameters
        ? pageDetail.sortprops.sortparameters.order
        : undefined;

    const skip = pageDetail.pageNumber ? (pageDetail.pageNumber - 1).toString() : '0';
    const nonStringSkip = pageDetail.pageNumber ? pageDetail.pageNumber : 1;

    const take = pageDetail.take ? pageDetail.take.toString() : '10';
    setpageSize(take);

    if (pageDetail.filter && Object.keys(pageDetail.filter).length > 0) {
      let offsetInMinutes = new Date().getTimezoneOffset();
      const memberId = get(pageDetail.filter, 'memberId')
        ? get(pageDetail.filter, 'memberId') === 'All'
          ? ''
          : get(pageDetail.filter, 'memberId')
        : '';
      const startDate = get(pageDetail.filter, 'startDate') || '';
      const endDate = get(pageDetail.filter, 'endDate') || '';
      let url =
        memberId.length > 0
          ? `api/v1/teams/${teamId}/admin/${clinicianId}/surveys?offsetInMinutes=${offsetInMinutes}&pageNumber=${skip}&pageSize=${take}&surveyStatus=${2}&memberId=${memberId}&startDateTime=${startDate}&endDateTime=${endDate}&timeZone=${timezoneValue}`
          : `api/v1/teams/${teamId}/admin/${clinicianId}/surveys?offsetInMinutes=${offsetInMinutes}&pageNumber=${skip}&pageSize=${take}&surveyStatus=${2}&startDateTime=${startDate}&endDateTime=${endDate}&timeZone=${timezoneValue}`;
      const appointmentRequest = streamToPromise(
        apiservice.getapiservice({
          baseUrl: url,
        }),
      );

      const surveys = await appointmentRequest;
      setavgnps(surveys.data.avgNps === null ? '-' : parseFloat(surveys.data.avgNps).toFixed(1));
      setisnpsscoreloading(false);
      setsubmittedcount(surveys.data.totalElements);
      setnpstotalcount(surveys.data.totalElements);
      setisloading(false);
      const data = surveys.data.surveys.map((item, index) => {
        return {
          ...item,
          name: item.physicianName,
          date: `${moment(item.appointmentDateTime).format('L')}  ${moment(
            item.appointmentDateTime,
          ).format('hh:mm A')}-${moment(item.appointmentDateTime).format('hh:mm A')}`,
          nps: item.nps,
          disabledAction: true,
        };
      });

      return {
        data,
        meta: {
          count: surveys.data.totalElements,
          currentPage: nonStringSkip,
        },
      };
    } else {
      let offsetInMinutes = new Date().getTimezoneOffset();
      const appointmentRequest = streamToPromise(
        apiservice.getapiservice({
          baseUrl: `api/v1/teams/${teamId}/admin/${clinicianId}/surveys?offsetInMinutes=${offsetInMinutes}&pageNumber=${skip}&pageSize=${take}&surveyStatus=${2}&timeZone=${timezoneValue}`,
        }),
      );

      const surveys = await appointmentRequest;
      setavgnps(surveys.data.avgNps === null ? '-' : parseFloat(surveys.data.avgNps).toFixed(1));
      setisnpsscoreloading(false);
      setsubmittedcount(surveys.data.totalElements);
      setnpstotalcount(surveys.data.totalElements);
      setisloading(false);
      const data = surveys.data.surveys.map((item, index) => {
        return {
          ...item,
          name: item.physicianName === null ? '-' : item.physicianName,
          date: `${moment(item.appointmentDateTime).format('L')}  ${moment(
            item.appointmentDateTime,
          ).format('hh:mm A')}-${moment(item.appointmentDateTime)
            .add(15, 'minutes')
            .format('hh:mm A')}`,
          nps: item.nps,
          disabledAction: true,
        };
      });
      if (sortpropsname === undefined && ordertype === undefined) {
        return {
          data,
          meta: {
            count: surveys.data.totalElements,
            currentPage: nonStringSkip,
          },
        };
      } else {
        setSortPropsName(sortpropsname);
        setSortordertype(ordertype === 'desc' ? 'desc' : 'asc');
        let sortpropsnamenew =
          sortpropsname === 'Consultation date & time'
            ? 'date'
            : sortpropsname === 'Coach'
            ? 'name'
            : sortpropsname === 'Member satisfaction'
            ? 'nps'
            : '';
        if (sortpropsnamenew === 'date') {
          data.sort((a, b) => moment(a.appointmentDateTime) - moment(b.appointmentDateTime));
          if (ordertype === 'desc') {
            data.reverse();
          }
        } else {
          data.sort((a, b) => (a[sortpropsnamenew] < b[sortpropsnamenew] ? -1 : 1));
          if (ordertype === 'desc') {
            data.reverse();
          }
        }

        // ordertype === "asc" ? data.sort((a, b) => (a[sortpropsnamenew] < b[sortpropsnamenew] ? -1 : 1)) : ordertype === "desc" ?  data.sort((a, b) => (a[sortpropsnamenew] < b[sortpropsnamenew] ? 1 : -1))  : data;

        return {
          data,
          meta: {
            count: surveys.data.totalElements,
            currentPage: nonStringSkip,
          },
        };
      }
    }
  }, []);

  const loadActionunsubmitted = useCallback(async (pageDetail) => {
    setisloading(true);
    let offsetInMinutes = new Date().getTimezoneOffset();
    const submittedsurveyrequest = streamToPromise(
      apiservice.getapiservice({
        baseUrl: `api/v1/teams/${teamId}/admin/${clinicianId}/surveys?offsetInMinutes=${offsetInMinutes}&surveyStatus=${2}&timeZone=${timezoneValue}`,
      }),
    );
    const submittedsurveys = await submittedsurveyrequest;
    setsubmittedcount(submittedsurveys.data.totalElements);

    const sortpropsname =
      pageDetail.sortprops && pageDetail.sortprops.sortparameters
        ? pageDetail.sortprops.sortparameters.headername
        : undefined;

    const ordertype =
      pageDetail.sortprops && pageDetail.sortprops.sortparameters
        ? pageDetail.sortprops.sortparameters.order
        : undefined;

    const skip = pageDetail.pageNumber ? (pageDetail.pageNumber - 1).toString() : '0';
    const nonStringSkip = pageDetail.pageNumber ? pageDetail.pageNumber : 1;

    const take = pageDetail.take ? pageDetail.take.toString() : '10';
    setpageSize(take);

    if (pageDetail.filter && Object.keys(pageDetail.filter).length > 0) {
      let offsetInMinutes = new Date().getTimezoneOffset();
      const memberId = get(pageDetail.filter, 'memberId')
        ? get(pageDetail.filter, 'memberId') === 'All'
          ? ''
          : get(pageDetail.filter, 'memberId')
        : '';
      const startDate = get(pageDetail.filter, 'startDate') || '';
      const endDate = get(pageDetail.filter, 'endDate') || '';
      let url =
        memberId.length > 0
          ? `api/v1/teams/${teamId}/admin/${clinicianId}/surveys?offsetInMinutes=${offsetInMinutes}&pageNumber=${skip}&pageSize=${take}&surveyStatus=${1}&memberId=${memberId}&startDateTime=${startDate}&endDateTime=${endDate}&timeZone=${timezoneValue}`
          : `api/v1/teams/${teamId}/admin/${clinicianId}/surveys?offsetInMinutes=${offsetInMinutes}&pageNumber=${skip}&pageSize=${take}&surveyStatus=${1}&startDateTime=${startDate}&endDateTime=${endDate}&timeZone=${timezoneValue}`;
      const appointmentRequest = streamToPromise(
        apiservice.getapiservice({
          baseUrl: url,
        }),
      );

      const surveys = await appointmentRequest;
      // setavgnps(
      //   surveys.data.avgNps === null
      //     ? "-"
      //     : parseFloat(surveys.data.avgNps).toFixed(1)
      // );
      setunsubmittedcount(surveys.data.totalElements);
      // setnpstotalcount(surveys.data.totalElements);
      setisloading(false);
      const data = surveys.data.surveys.map((item, index) => {
        return {
          ...item,
          name: item.physicianName,
          date: `${moment(item.appointmentDateTime).format('L')}  ${moment(
            item.appointmentDateTime,
          ).format('hh:mm A')}-${moment(item.appointmentDateTime).format('hh:mm A')}`,
          nps: item.nps,
          disabledAction: true,
        };
      });

      return {
        data,
        meta: {
          count: surveys.data.totalElements,
          currentPage: nonStringSkip,
        },
      };
    } else {
      let offsetInMinutes = new Date().getTimezoneOffset();
      const appointmentRequest = streamToPromise(
        apiservice.getapiservice({
          baseUrl: `api/v1/teams/${teamId}/admin/${clinicianId}/surveys?offsetInMinutes=${offsetInMinutes}&pageNumber=${skip}&pageSize=${take}&surveyStatus=${1}&timeZone=${timezoneValue}`,
        }),
      );

      const surveys = await appointmentRequest;
      // setavgnps(
      //   surveys.data.avgNps === null
      //     ? "-"
      //     : parseFloat(surveys.data.avgNps).toFixed(1)
      // );
      setunsubmittedcount(surveys.data.totalElements);
      // setnpstotalcount(surveys.data.totalElements);
      setisloading(false);
      // console.log(surveys.data);
      const data = surveys.data.surveys.map((item, index) => {
        return {
          ...item,
          name: item.physicianName === null ? '-' : item.physicianName,
          date: `${moment(item.appointmentDateTime).format('L')}  ${moment(
            item.appointmentDateTime,
          ).format('hh:mm A')}-${moment(item.appointmentDateTime)
            .add(15, 'minutes')
            .format('hh:mm A')}`,
          nps: item.nps,
          disabledAction: true,
        };
      });
      if (sortpropsname === undefined && ordertype === undefined) {
        return {
          data,
          meta: {
            count: surveys.data.totalElements,
            currentPage: nonStringSkip,
          },
        };
      } else {
        setSortPropsName(sortpropsname);
        setSortordertype(ordertype === 'desc' ? 'desc' : 'asc');
        let sortpropsnamenew =
          sortpropsname === 'Consultation date & time'
            ? 'date'
            : sortpropsname === 'Coach'
            ? 'name'
            : sortpropsname === 'Member satisfaction'
            ? 'nps'
            : '';
        if (sortpropsnamenew === 'date') {
          data.sort((a, b) => moment(a.appointmentDateTime) - moment(b.appointmentDateTime));
          if (ordertype === 'desc') {
            data.reverse();
          }
        } else {
          data.sort((a, b) => (a[sortpropsnamenew] < b[sortpropsnamenew] ? -1 : 1));
          if (ordertype === 'desc') {
            data.reverse();
          }
        }

        // ordertype === "asc" ? data.sort((a, b) => (a[sortpropsnamenew] < b[sortpropsnamenew] ? -1 : 1)) : ordertype === "desc" ?  data.sort((a, b) => (a[sortpropsnamenew] < b[sortpropsnamenew] ? 1 : -1))  : data;

        return {
          data,
          meta: {
            count: surveys.data.totalElements,
            currentPage: nonStringSkip,
          },
        };
      }
    }
  }, []);

  const viewFeedbackDetailsAction = useCallback(({ entity, history: hstry }) => {
    setappointmentinfomodal(entity.appointmentId);
    setpatientinfo(entity.appointmentId);
    setisReportModalOpen(true);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth="xl">
      {isReportModalOpen === true && appointmentinfomodal !== '' && (
        <FeedbackModal
          appointmentId={appointmentinfomodal}
          //   patient={patientinfo}
          show={isReportModalOpen}
          handleClose={() => reportmodalCloseHandler()}
        ></FeedbackModal>
      )}
      <Container maxWidth="xl">
        {/* <Grid alignItems="center" container justify="flex-start">
          <Grid item>
            <MuiTypography
              fontSize="34px"
              fontFamily="Work Sans"
              lineHeight="40px"
              letterSpacing="0.25px"
              fontWeight={500}
              color="#000000"
            >
              {t('Feedback')}
            </MuiTypography>
            <BottomLineTitle />
          </Grid>
        </Grid> */}
        <>
          <Box m={2} />
          <Grid
            container
            direction={mediawidth === 'xl' ? 'row' : 'column-reverse'}
            spacing={3}
            style={{ display: '-ms-flexbox' }}
          >
            <Grid item xs={mediawidth === 'xl' ? 9 : 12}>
              <div className={classes.root}>
                <Paper className={classes.paperroot}>
                  <Tabs
                    classes={{
                      root: classes.customTabRoot,
                      indicator: classes.customTabIndicator,
                    }}
                    value={value}
                    onChange={handleChange}
                    scrollButtons="auto"
                    centered
                    variant="fullWidth"
                  >
                    <Tab
                      label={`${t('SUBMITTED')} (${submittedcount})`}
                      classes={{ root: classes.tabRoot }}
                      {...a11yProps(0)}
                    />
                    <Tab
                      label={`${t('UNSUBMITTED')} (${unsubmittedcount})`}
                      classes={{ root: classes.tabRoot }}
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </Paper>
                <TabPanel value={value} index={0}>
                  <ListPageFeedbacks
                    teamId={teamId}
                    clinicianId={clinicianId}
                    value={value}
                    feature="Feedbacks"
                    entityProps={['date', 'name', 'nps', 'disabledAction']}
                    addAction={() => history.push(`/createClinic`)}
                    loadAction={loadAction}
                    loading="false"
                    history={history}
                    headers={[t('Consultation date & time'), t('Provider'), t('Member satisfaction')]}
                    sortPropsName={sortPropsName}
                    sortordertype={sortordertype}
                    searchEnabled={true}
                    title={'Feedbacks'}
                    viewFeedbackDetailsAction={viewFeedbackDetailsAction}
                    filters={[
                      {
                        name: 'test',
                        options: [
                          { value: '', label: 'Select a filter' },
                          { value: '123', label: 'Example filter' },
                        ],
                      },
                    ]}
                  />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <ListPageFeedbacks
                    teamId={teamId}
                    clinicianId={clinicianId}
                    value={value}
                    feature="Feedbacks"
                    entityProps={['date', 'name', 'nps', 'disabledAction']}
                    addAction={() => history.push(`/createClinic`)}
                    loadAction={loadActionunsubmitted}
                    loading="false"
                    history={history}
                    headers={[t('Consultation date & time'), t('Provider'), t('Member satisfaction')]}
                    searchEnabled={true}
                    title={'Feedbacks'}
                    sortPropsName={sortPropsName}
                    sortordertype={sortordertype}
                    // viewFeedbackDetailsAction={viewFeedbackDetailsAction}
                    filters={[
                      {
                        name: 'test',
                        options: [
                          { value: '', label: 'Select a filter' },
                          { value: '123', label: 'Example filter' },
                        ],
                      },
                    ]}
                  />
                </TabPanel>
              </div>
            </Grid>
            <Grid item xs={mediawidth === 'xl' ? 3 : 4}>
              <Paper className={classes.root}>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  style={{ display: '-ms-flexbox' }}
                >
                  <Grid item className={classes.gridmhorizontalpadding}>
                    <SvgIcon viewBox="0 0 37 32" style={{ fontSize: 40 }}>
                      <path
                        d="M12 18.324L19.416 22.8L17.448 14.364L24 8.688L15.372 7.944L12 0L8.628 7.944L0 8.688L6.54 14.364L4.584 22.8L12 18.324Z"
                        fill="#536063"
                      />
                    </SvgIcon>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.npscopetitlefont}>
                      {t('Member satisfaction')}
                    </Typography>
                  </Grid>
                </Grid>
                {isnpsscoreloading === true ? (
                  <EcgLoaderSmall />
                ) : (
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-end"
                    className={classes.gridmhorizontalpadding}
                    style={{ display: '-ms-flexbox' }}
                  >
                    <Grid item>
                      <MuiTypography
                        fontSize="48px"
                        fontFamily="Work Sans"
                        lineHeight="56px"
                        fontWeight={500}
                        color="#142A39"
                      >
                        {avgnps}
                      </MuiTypography>
                    </Grid>
                    <Grid item>
                      <MuiTypography
                        fontSize="24px"
                        fontFamily="Work Sans"
                        lineHeight="45.15px"
                        fontWeight={500}
                        color="#142A39"
                      >
                        /10
                      </MuiTypography>
                    </Grid>
                    <Grid item className={classes.fromsurveyspadding}>
                      <MuiTypography
                        fontSize="16px"
                        fontFamily="Work Sans"
                        lineHeight="45.15px"
                        fontWeight={400}
                        color="#142A39"
                      >
                        from {npstotalcount} surveys
                      </MuiTypography>
                    </Grid>
                    {/* <Grid item>
                     <IconButton
                       className={classes.smallButton}
                       aria-label="Delete"
                     >
                       <SvgIcon viewBox="0 0 21 20" style={{ fontSize: 20 }}>
                         <path
                           d="M14 8.78L18.24 1.45L19.97 2.45L14.74 11.5L8.23 7.75L3.46 16H20V18H0V0H2V14.54L7.5 5L14 8.78Z"
                           fill="#142A39"
                         />
                       </SvgIcon>
                     </IconButton>
                   </Grid> */}
                  </Grid>
                )}
              </Paper>
            </Grid>
          </Grid>
        </>
      </Container>
    </Container>
  );
}

export default Feedbacks;
