/* eslint-disable */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import moment from 'moment';
import _get from 'lodash.get';
import * as API from '../../../Services/API/actions';
import { makeStyles } from '@material-ui/core/styles';
import EkgRecording from '../../chart/EkgRecording';
import { useSelector, useDispatch } from 'react-redux';
import { calcRowSeconds } from 'components/Utilities';
import * as Lib from 'constants/RecordingConstants';
import PatientDetails from './PatientDetails';
import Overread_ReportDropdown from '../../AppointmentDetails/overread_ReportDropdown';
import Followup_Dropdown from '../../AppointmentDetails/followup_Dropdown';
import ConsulationSummary_tab from '../../AppointmentDetails/consulationSummary_tab';
import axios from 'axios';
import { streamToPromise, getTimezoneName } from 'utilities/Utilities';
import apiservice from 'api/services/apiservice.service';
import { GoAPI } from '../../../Axios/axios';
import { getToken } from 'auth/AuthUtilities';
import Alert from '@material-ui/lab/Alert';
// import { getEKGAlgorithmDeterminationDisplayText } from 'utilities/recordingMaps';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import 'assets/styles/bootstrap.scss';
import Details_tab from '../../Dashboard/DoctorTabDetails';
import { useTranslation } from 'react-i18next';
import Chat from './Chat';
import VideoCall from './VideoCall';
import { Grid, Drawer, SvgIcon, Box } from '@material-ui/core';
import KeyboardArrowLeftOutlinedIcon from '@material-ui/icons/KeyboardArrowLeftOutlined';
import clsx from 'clsx';
import MuiTypography from '../../Shared/Typography/MuiTypography';
import PreviewReportModal from '../../AppointmentDetails/PreviewReportModal';
import OverReadReportNote from './OverReadReportNote';
import VonageTest from './VonageCall';
import useResizeDetector from 'use-resize-observer/polyfilled';
import debounce from 'lodash.debounce';
import Authorize from '../../Authorize';
import { getPatientDetailsSuccess } from 'store/actions/dashboard';
import * as action from 'store/actions';
import EcgLoader from 'components/Shared/EcgLoader';

const useStyles = makeStyles((theme) => ({
  root: {},
  verticalgrid: {
    minHeight: 600,
  },
  cardHeader: {
    fontWeight: '600',
  },
  blackGrid: {
    fontWeight: '600',
    alignItems: 'center',
    height: '55px',
    padding: theme.spacing(1),
    background: '#E2E7E3',
    color: '#000000',
    lineHeight: '40px',
  },
  gridPadding: {
    padding: theme.spacing(1),
  },
  boldFont: {
    fontWeight: '600',
  },
  bolderFont: {
    fontWeight: '700',
  },
  cardlayout: {
    minHeight: 600,
    background: '#F1F1F1',
  },
  cardlayoutmini: {
    minHeight: 280,
    background: '#F1F1F1',
  },
  recordingInfo: {
    zIndex: 1,
    padding: '8px 10px',
    backgroundColor: '#fff',
    opacity: 0.8,
    width: 'fit-content',
    display: '-webkit-box' && '-moz-box' && '-ms-flexbox' && '-webkit-flex' && 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    position: 'absolute',
    right: '10px',
    margin: '20px',
  },
  recordingInfospan: {
    // width: "30px",
    paddingRight: '20px',
    color: '#000',
  },
  recordingInfospanfirstChild: {
    width: '30px',
    paddingLeft: '0px',
  },
  stickyvideocall: {
    position: '-webkit-sticky' && 'sticky',
    top: 0,
    zIndex: 5000,
    // margin:"20px"
  },
  drawer: {
    height: '75%',
    flexShrink: 0,
    marginLeft: '40px',
    marginRight: '40px',
    marginBottom: '40px',
    boxShadow:
      '0px 3px 6px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 1px 12px rgba(0, 0, 0, 0.04) !important',
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    height: 'calc(100vh - 345px)',
    marginLeft: '40px',
    marginRight: '40px',
    marginBottom: '100px',
    boxShadow: '0 0 15px -2px #444444 !important',
    transition: theme.transitions.create('height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    marginLeft: '40px',
    marginRight: '40px',
    marginBottom: '100px',
    boxShadow: '0 0 15px -2px #444444 !important',
    transition: theme.transitions.create('height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    // height: theme.spacing(7) + 1,
    height: '15%',
    // [theme.breakpoints.up('sm')]: {
    //  height: theme.spacing(9) + 1,
    // },
  },
  paddeddrawertitle: {
    padding: theme.spacing(1),
    minHeight: '80px',
  },
}));

function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}
const Doctor_Details = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const mediawidth = useWidth();
  const appointmentId = props.appointmentId;
  const clinicianId = useSelector((state) => state.user.profile.id);
  const teamId = useSelector((state) => state.user.team.id);
  const [consultationNote, setconsultationNote] = useState('');
  const [previewReportModalOpen, setpreviewReportModalOpen] = useState(false);
  const [patientDetails, setPatientDetails] = useState({});
  const loadReport = () => {
    if (
      Object.keys(patientDetails).length > 0 &&
      patientDetails.ekgResults &&
      patientDetails.ekgResults.length > 0 &&
      patientDetails.ekgResults[0].ekgRecordId != ''
    ) {
      if (props.id !== 0) {
        setpreviewReportModalOpen(true);
        props.setisError(false);
      } else {
        props.setError('Please select the EKG interpretation to proceed to the next step.');
        props.setisError(true);
      }
    } else {
      setpreviewReportModalOpen(true);
      props.setisError(false);
    }
  };

  const handlePreviewReportModalClose = () => {
    setpreviewReportModalOpen(false);
  };

  const [draweropen, setdraweropen] = React.useState(false);
  let actions = null;
  if (
    props.steppervalue === 1 &&
    (props.status === 'Scheduled' ||
      props.status === 'Physician-Review' ||
      props.status === 'Waiting-Room')
  ) {
    actions = (
      <>
        <Authorize>
          <button
            type="button"
            className="btn px-4 btn-outline-primary text-uppercase h40"
            onClick={props.onSubmit}
            disabled={props.disable}
          >
            REASSIGN CONSULTATION
          </button>
        </Authorize>
        {/* <button
          type="button"
          className="btn px-4 btn_teal text-uppercase h40 ml-3"
          onClick={props.clicked}
        >
          {_get(
            patientDetails,
            "patientAppointment.appointmentChannelTypeId"
          ) === 4
            ? "START CHAT"
            : "START CALL"}
        </button> */}
      </>
    );
  } else if (
    props.steppervalue === 3 ||
    props.status === 'Encounter-Summary-Preparation' ||
    props.status === 'Closed'
  ) {
    actions = (
      <>
        <button
          type="button"
          className="btn px-4 btn-outline-primary text-uppercase h40 ml-3"
          onClick={loadReport}
        >
          Preview REPORT
        </button>
        <button
          type="button"
          className="btn px-4 btn_teal text-uppercase h40 ml-3"
          onClick={props.handleClose}
        >
          SEND REPORT
        </button>
      </>
    );
  } else if (props.steppervalue === 2 || props.status === 'InProgress') {
    actions = (
      <>
        {/* <button type="button" className="btn px-4 btn-outline-primary text-uppercase h40" onClick={props.onSubmit}>
              Cancel Appoinment
              </button> */}

        <button
          type="button"
          className="btn px-4 btn-outline-secondary text-uppercase h40 ml-3"
          onClick={props.clicked}
          disabled={props.startcalldisable}
        >
          Next
        </button>
      </>
    );
  } else if (props.component === 'popover') {
    actions = (
      <>
        <button
          type="button"
          className="btn px-4 btn-outline-primary text-uppercase h40 f28_icon"
          onClick={props.handleClose}
        >
          <KeyboardArrowLeftOutlinedIcon /> Back
        </button>

        {(props['isfuturescheduledappintment'] == undefined ||
          props.isfuturescheduledappintment === true) && (
          <Authorize>
            <button
              type="button"
              className="btn px-4 btn-outline-secondary text-uppercase h40 ml-3"
              onClick={props.onSubmit}
              disabled={props.disable}
            >
              REASSIGN CONSULTATION
            </button>
          </Authorize>
        )}
      </>
    );
  }

  // => (event)
  const toggleDrawer = () => {
    // if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    //   return;
    // }
    setdraweropen(!draweropen);
    // setState({ ...state, [anchor]: open });
  };

  const [recording, setRecording] = useState({});
  const [toggleRecordingInvert, setoggleRecordingInvert] = useState(false);
  const [appNotes, setappNotes] = useState('');
  const [notesheight, setnotesheight] = useState(1000);
  const [comunicationheight, setcomunicationheight] = useState(1000);
  const viewportRef2 = useRef(null);
  const communicationref = useRef(null);

  // useResizeObserver({
  //   ref: viewportRef2,
  //   onResize: function ({ height }) {
  //     console.log("height", "100px");
  //     setnotesheight(height);
  //     // setnotesheight(height);
  //   }
  // });

  function handleResize({ height }) {
    if (notesheight !== height) {
      setnotesheight(height);
    }
  }

  const delay = 30;

  useResizeDetector({
    ref: viewportRef2,
    onResize: debounce(handleResize, delay, { maxWait: 1000 }),
  });

  function handlecommunicationResize({ height }) {
    if (comunicationheight !== height) {
      setcomunicationheight(height);
    }
  }

  useResizeDetector({
    ref: communicationref,
    onResize: debounce(handlecommunicationResize, delay, { maxWait: 1000 }),
  });

  const patientDetailsntwrk = useSelector((state) => state.appointments.patientDetails);
  // const recordingntwrk = useSelector((state) => state.ekg.recording);
  const [loading, setloading] = useState(false);
  const [alertOn, setAlertOn] = useState(false);

  const MIN_SECONDS = 1;
  const mainContentWidth = 902;
  const rowSeconds =
    calcRowSeconds(mainContentWidth) > 0 ? calcRowSeconds(mainContentWidth) : MIN_SECONDS;
  const ekgWidth = rowSeconds * Lib.PX_PER_SECOND + 18;

  let subscription = '';
  const loadAction = async () => {
    setloading(true);
    let offsetInMinutes = new Date().getTimezoneOffset();
    const appointmentRequest = streamToPromise(
      apiservice.getapiservice({
        baseUrl: `api/v1.1/teams/${teamId}/clinicians/${clinicianId}/appointments/${appointmentId}/patient-detail?timeZoneOffset=${offsetInMinutes}&timeZone=${getTimezoneName()}`,
      }),
    );
    const appointmentdetailsdata = await appointmentRequest;
    if (appointmentdetailsdata.data.length > 0) setloading(false);
    setPatientDetails(appointmentdetailsdata.data);
    //   dispatch(
    //     action.getRecordingSamples('', teamId, clinicianId, appointmentId, {
    //       timeZoneOffset: new Date().getTimezoneOffset(),
    //       timeZone: getTimezoneName(),
    //     }),
    //   );
  };

  // useEffect(() => {
  //   if (props.patientDetails && Object.keys(props.patientDetails).length && props.recording) {
  //     setPatientDetails(props.patientDetails);
  //     setRecording(props.recording);
  //   } else loadAction();
  // }, [appointmentId, props.patientDetails]);

  const loadEkgAction = useCallback((recordingid) => {
    setloading(true);
    let samps = GoAPI.get(`/i/v1/recordings/${recordingid}/samples`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    });
    let recs = GoAPI.get(`/i/v1/recordings/${recordingid}`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    });

    axios
      .all([recs, samps])
      .then(
        axios.spread((...responses) => {
          setRecording({ ...responses[0].data, ...responses[1].data });
          setloading(false);
          setNoEKG(false);
        }),
      )
      .catch((error) => {
        setAlertOn(true);
        setRecording({});
        setloading(false);
      });
  }, []);

  const [noEKG, setNoEKG] = useState(false);
  useEffect(() => {
    if (
      Object.keys(patientDetails).length > 0 &&
      patientDetails.ekgResults &&
      patientDetails.ekgResults.length > 0 &&
      patientDetails.ekgResults[0].ekgRecordId != ''
    ) {
      loadEkgAction(patientDetails.ekgResults[0].ekgRecordId);
    } else {
      setRecording({});
      setNoEKG(true);
    }
  }, [patientDetails]);

  useEffect(() => {
    // console.log('aya- ', patientDetailsntwrk);
    if (Object.keys(patientDetailsntwrk).length > 0 && props.component !== 'popover') {
      setPatientDetails(patientDetailsntwrk);
    } else {
      loadAction();
    }
  }, [patientDetailsntwrk]);
  useEffect(() => {
    if (
      (props.steppervalue && patientDetailsntwrk && patientDetailsntwrk.appointmentId) ||
      props.component === 'popover'
    ) {
      subscription = API.getAppointmentNotes(teamId, clinicianId, appointmentId).subscribe(
        (res) => {
          setloading(false);

          // if (res.data.appointmentNote)
          //   setappNotes(decodeURI(res.data.appointmentNote));
          // else setappNotes("");
          if (res.data.appointmentNote && res.data.appointmentNote.length > 0)
            setconsultationNote(res.data.appointmentNote);
          else setconsultationNote(null);
        },
        (error) => {
          // log error
          console.log(error);
        },
      );
    }
    return () => {
      setloading(false);
      if (subscription && subscription !== '') subscription.unsubscribe();
    };
  }, [props.steppervalue]);

  useEffect(() => {
    setconsultationNote(props.consultationNote);
  }, [props.consultationNote]);

  //=======call setAppointment api after 2 secs pause=======//
  const timer = useRef(null);
  const saveMyNotesAsync = (note) => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      props.setConsultationNote(note);
    }, 2000);
  };

  const [statistics, setstatistics] = React.useState([]);
  const [connectionflag, setconnectionflag] = React.useState(false);
  const [reconnectionflag, setreconnectionflag] = React.useState(false);
  const [finalcounttimer, setfinalcounttimer] = useState(0);
  const [patientTechnicalIssue, setpatientTechnicalIssue] = React.useState(false);
  const [phoneCall, choosePhoneCall] = useState(false);

  const settoggleRecordingInvert = (prps) => {
    setoggleRecordingInvert(prps);
  };

  const establishnewconnection = (props) => {
    setfinalcounttimer(props);
    setreconnectionflag(true);
    setconnectionflag(!connectionflag);
  };
  return (
    <div className="telekardia">
      {(Object.keys(patientDetails).length > 0 &&
        patientDetails.ekgResults &&
        patientDetails.ekgResults.length > 0 &&
        patientDetails.ekgResults[0].ekgRecordId === '') ||
      (Object.keys(patientDetails).length > 0 &&
        patientDetails.ekgResults &&
        patientDetails.ekgResults.length > 0 &&
        patientDetails.ekgResults[0].ekgRecordId !== '' &&
        Object.keys(recording).length > 0) ? (
        <div className={loading || props.consultationloader ? 'filtering' : null}>
          <div className={loading || props.consultationloader ? 'spinner-border' : null}></div>
          <div className="m-4">{props.alert}</div>
          <div className="container-fluid">
            {previewReportModalOpen && (
              <PreviewReportModal
                open={previewReportModalOpen}
                memberId={clinicianId}
                patientId={patientDetails.patient.patientId}
                // "2iik80h13leb5q8osgsyvesvz"
                appointmentId={appointmentId}
                handlePreviewReportModalClose={handlePreviewReportModalClose}
              />
            )}
            <div className="row m-0" style={{ display: '-ms-flexbox' }}>
              <div className="col-12 col-xl-7">
                {mediawidth !== 'xl' && mediawidth !== 'lg' ? (
                  <Drawer
                    variant="permanent"
                    PaperProps={{
                      style: { borderRadius: '24px 24px 0px 0px' },
                    }}
                    // className={clsx(classes.drawer, {
                    //   [classes.drawerOpen]: draweropen,
                    //   [classes.drawerClose]: !draweropen,
                    // })}
                    classes={{
                      paper: clsx({
                        [classes.drawerOpen]: draweropen,
                        [classes.drawerClose]: !draweropen,
                      }),
                    }}
                    anchor="bottom"
                    BackdropProps={{ invisible: true }}
                    open={draweropen}
                  >
                    <Grid
                      alignItems="center"
                      container
                      justify="space-between"
                      className={classes.paddeddrawertitle}
                    >
                      <Grid item>
                        <h5 className="text_head mt-4">Member Information</h5>
                      </Grid>
                      <Grid item>
                        <button
                          type="button"
                          className="btn text-uppercase h40 btn_green_light min-width163 position-relative"
                          onClick={toggleDrawer}
                        >
                          {draweropen ? (
                            <>
                              <SvgIcon
                                viewBox="0 0 12 8"
                                style={{ fontSize: 8 }}
                                className="left_15 f30"
                              >
                                <path
                                  d="M1.41 -7.62939e-08L6 4.59L10.59 -7.62939e-08L12 1.42L6 7.42L0 1.42L1.41 -7.62939e-08Z"
                                  fill="#067F6F"
                                />
                              </SvgIcon>
                              {t('Hide')}
                            </>
                          ) : (
                            <>
                              <SvgIcon
                                viewBox="0 0 12 8"
                                style={{ fontSize: 8 }}
                                className="left_15 f30"
                              >
                                <path
                                  d="M1.41 7.41L6 2.83L10.59 7.41L12 6L6 0L0 6L1.41 7.41Z"
                                  fill="#067F6F"
                                />
                              </SvgIcon>
                              {t('Show')}
                            </>
                          )}
                        </button>
                      </Grid>
                    </Grid>

                    <Details_tab
                      value={props.tabvalue}
                      patientData={patientDetails}
                      appointmentId={appointmentId}
                      stepper={props.step}
                      handleChange={props.changetabvalue}
                      setoggleRecordingInvert={settoggleRecordingInvert}
                      stats={statistics}
                      toggleRecordingInvert={toggleRecordingInvert}
                      recordingData={recording}
                      alertOn={alertOn}
                      appNotes={appNotes}
                      noEKG={noEKG}
                      {...props}
                    />
                  </Drawer>
                ) : (
                  <div ref={viewportRef2}>
                    <Details_tab
                      value={props.tabvalue}
                      patientData={patientDetails}
                      appointmentId={appointmentId}
                      stepper={props.step}
                      handleChange={props.changetabvalue}
                      setoggleRecordingInvert={settoggleRecordingInvert}
                      stats={statistics}
                      toggleRecordingInvert={toggleRecordingInvert}
                      recordingData={recording}
                      alertOn={alertOn}
                      appNotes={appNotes}
                      noEKG={noEKG}
                      {...props}
                    />
                  </div>
                )}
                {/* My Notes step3 */}
              </div>

              <div
                className={
                  mediawidth === 'xl' || mediawidth === 'lg'
                    ? 'col-12 col-xl-5 p-md-0 p-lg-0 px-xl-3'
                    : 'col-12 col-xl-5 p-md-0 p-lg-0 px-xl-3 order-first'
                }
              >
                {props.step === 2 && (
                  <>
                    {/* please dont remove below line only comment */}
                    {
                      // (mediawidth === "xl" || mediawidth === "lg") &&
                      (_get(patientDetails, 'patientAppointment.appointmentChannelTypeId') === 2 ||
                        (_get(patientDetails, 'patientAppointment.appointmentChannelTypeId') ===
                          3 &&
                          !phoneCall)) && (
                        <div ref={communicationref}>
                          <VideoCall
                            finalcounttimer={finalcounttimer}
                            consultationNote={consultationNote}
                            reconnectionflag={reconnectionflag}
                            setConsultationNotevideocall={props.setConsultationNote}
                            appointmentId={appointmentId}
                            clinicianId={clinicianId}
                            teamId={teamId}
                            appointmentChannelTypeId={_get(
                              patientDetails,
                              'patientAppointment.appointmentChannelTypeId',
                            )}
                            establishnewconnection={establishnewconnection}
                            patientTechnicalIssue={patientTechnicalIssue}
                            setpatientTechnicalIssue={setpatientTechnicalIssue}
                            key={connectionflag}
                            connectionflag={connectionflag}
                            patientName={
                              (_get(patientDetails, 'patient.firstName') || '-') +
                              ' ' +
                              (_get(patientDetails, 'patient.lastName') || '-')
                            }
                            choosePhoneCall={choosePhoneCall}
                            {...props}
                          />
                        </div>
                      )
                    }
                    {_get(patientDetails, 'patientAppointment.appointmentChannelTypeId') === 4 && (
                      <div ref={communicationref}>
                        <Chat
                          patientName={
                            (_get(patientDetails, 'patient.firstName') || '-') +
                            ' ' +
                            (_get(patientDetails, 'patient.lastName') || '-')
                          }
                          patientfirstname={_get(patientDetails, 'patient.firstName') || '-'}
                          appointmentId={appointmentId}
                          clinicianId={clinicianId}
                          {...props}
                        />
                      </div>
                    )}
                    {phoneCall && (
                      <VonageTest
                        {...props}
                        patientName={
                          _get(patientDetails, 'patient.firstName') +
                            ' ' +
                            _get(patientDetails, 'patient.lastName') || '-'
                        }
                        mobile={
                          _get(patientDetails, 'patient.phone').replace(/[^\w\s]/gi, '') || '-'
                        }
                      />
                    )}
                  </>
                )}

                {/*Consultation Notes*/}
                {
                  <div className="row no-gutters">
                    <div className="col-12 bg_white p-4">
                      <h5 className="head">{t('My Notes')}</h5>
                      <textarea
                        onChange={(e) => {
                          setconsultationNote(e.target.value);
                          saveMyNotesAsync(e.target.value);
                        }}
                        className="form-control bg_grey border-0"
                        className={
                          (props.step === 1 ||
                            props.step === 2 ||
                            props.step === 3 ||
                            props.component === 'popover') &&
                          (mediawidth === 'xl' || mediawidth === 'lg')
                            ? 'form-control bg_grey border-0'
                            : 'form-control bg_grey height_230 border-0'
                        }
                        style={{
                          height:
                            props.component === 'popover'
                              ? notesheight - 77
                              : props.step === 1 && (mediawidth === 'xl' || mediawidth === 'lg')
                              ? notesheight - 77
                              : props.step === 2 && (mediawidth === 'xl' || mediawidth === 'lg')
                              ? _get(
                                  patientDetails,
                                  'patientAppointment.appointmentChannelTypeId',
                                ) === 1
                                ? notesheight - 156
                                : notesheight - comunicationheight - 77
                              : props.step === 3 &&
                                (mediawidth === 'xl' || mediawidth === 'lg') &&
                                notesheight - 457 - 381 - 77 - 30,
                        }}
                        placeholder="Write your personal notes here. They will not be shared with the patient."
                        value={consultationNote}
                      />
                    </div>
                  </div>
                }
                {props.step === 3 &&
                  Object.keys(patientDetails).length > 0 &&
                  patientDetails.ekgResults &&
                  patientDetails.ekgResults.length > 0 &&
                  patientDetails.ekgResults[0].ekgRecordId != '' && (
                    <div className="row no-gutters">
                      <div className="col-12 height_381 bg_white p-4  mt-3">
                        <div className="row mb-4">
                          <div className="col-5 d-flex align-items-center">
                            <h5 className="head">
                              {t('EKG Review')}
                              {/* <span
                            style={{
                              content: " *",
                              color: "#d4154b",
                              margin: 0,
                            }}
                          >
                            *
                          </span> */}
                            </h5>
                          </div>
                          {/* {_get(
                        patientDetails,
                        "ekgResults"
                      ).length == 0 */}

                          <div className="col-7">
                            <Overread_ReportDropdown
                              appointmentId={appointmentId}
                              id={props.id}
                              error={props.isError}
                              setError={props.setisError}
                              handleidChange={props.handleidChange}
                              heartConditions={props.heartConditions}
                            />
                          </div>
                          <div className="col-12">
                            <OverReadReportNote
                              appointmentId={appointmentId}
                              id={props.id}
                              updateoverReadReport={props.updateoverReadReport}
                              overReadReport={props.overReadReport}
                            />
                            {/* <textarea
                            className="form-control bg_grey height_240 mt-3 border-0"
                            value={props.overReadReport} 
                            onChange={props.updateoverReadReport}
                            placeholder="The notes added here will be sent to the patient."
                          /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                {props.step === 3 && (
                  <div className="row no-gutters">
                    <div className="col-12 height_457 bg_white p-4 my-3">
                      <div className="row mb-4">
                        <div className="col-7 d-flex align-items-center">
                          <h5 className="head">{t('Member Note')}</h5>
                        </div>
                        <div className="col-5">
                          <Followup_Dropdown
                            period={props.period}
                            handleChange={props.handleperiodChange}
                            appointmentId={appointmentId}
                            disable={
                              props.Reason === 'Technical issue at my end' ||
                              props.Reason === "Technical issue at patient's end" ||
                              _get(patientDetails, 'patientAppointment.closedReasonType') === 2 ||
                              _get(patientDetails, 'patientAppointment.closedReasonType') === 3
                                ? true
                                : false
                            }
                          />
                        </div>
                        <div className="col-12">
                          <ConsulationSummary_tab
                            consultationSummary={props.consultationSummary}
                            updateconsultationSummary={props.updateconsultationSummary}
                            treatmentPlan={props.targetPlan}
                            updatetreatmentPlan={props.updatetargetplan}
                            appointmentId={appointmentId}
                            disable={
                              props.Reason === 'Technical issue at my end' ||
                              props.Reason === "Technical issue at patient's end" ||
                              _get(patientDetails, 'patientAppointment.closedReasonType') === 2 ||
                              _get(patientDetails, 'patientAppointment.closedReasonType') === 3
                                ? true
                                : false
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div
                className={mediawidth === 'xl' || mediawidth === 'lg' ? 'col-12' : 'col-12 order-2'}
              >
                <div className="telekardia">
                  <div className="d-flex justify-content-end py-3 px-0">{actions}</div>
                  {mediawidth !== 'xl' && mediawidth !== 'lg' && <Box mb={30} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <EcgLoader />
      )}
    </div>
  );
};
export default Doctor_Details;
