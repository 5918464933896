/* eslint-disable jsx-a11y/anchor-is-valid */
import { calcRowSeconds } from '../../Utilities';
import * as Lib from 'constants/RecordingConstants';
import EkgRecording from '../../chart/EkgRecording';
import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { getEKGAlgorithmDeterminationDisplayText } from 'utilities/recordingMaps';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  root: {},
  verticalgrid: {
    minHeight: 600,
  },
  cardHeader: {
    fontWeight: '600',
  },
  blackGrid: {
    fontWeight: '600',
    alignItems: 'center',
    height: '55px',
    padding: theme.spacing(1),
    background: '#E2E7E3',
    color: '#000000',
    lineHeight: '40px',
  },
  gridPadding: {
    padding: theme.spacing(1),
  },
  boldFont: {
    fontWeight: '600',
  },
  bolderFont: {
    fontWeight: '700',
  },
  cardlayout: {
    minHeight: 600,
    background: '#F1F1F1',
  },
  cardlayoutmini: {
    minHeight: 280,
    background: '#F1F1F1',
  },
  recordingInfo: {
    zIndex: 1,
    padding: '8px 10px',
    backgroundColor: '#fff',
    opacity: 0.8,
    width: 'fit-content',
    display: '-webkit-box' && '-moz-box' && '-ms-flexbox' && '-webkit-flex' && 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    position: 'absolute',
    right: '10px',
    margin: '20px',
  },
  recordingInfospan: {
    // width: "30px",
    paddingRight: '20px',
  },
  recordingInfospanfirstChild: {
    width: '30px',
    paddingLeft: '0px',
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

// const DialogActions = withStyles((theme) => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(1),
//   },
// }))(MuiDialogActions);

export default function EkgPreviewModal(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [toggleRecordingInvert, setoggleRecordingInvert] = React.useState(false);

  //const contentHeight = 500 - 124 - 14 + 34;
  const MIN_SECONDS = 1;
  const mainContentWidth = 1856;
  const rowSeconds =
    calcRowSeconds(mainContentWidth) > 0 ? calcRowSeconds(mainContentWidth) : MIN_SECONDS;
  const ekgWidth = rowSeconds * Lib.PX_PER_SECOND + 18;

  return (
    <Dialog
      maxWidth={'xl'}
      onClose={props.handleClose}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      fullWidth={true}
    >
      <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
        {t('EKG RECORDING')}:{' '}
        {props.recording !== undefined
          ? getEKGAlgorithmDeterminationDisplayText(
              props.recording.algorithmDetermination,
              props.recording.algorithmPackage,
            )
          : '-'}
      </DialogTitle>
      <DialogContent dividers>
        {/* <Grid container className={classes.root} spacing={3}> */}
        <div className="telekardia">
          <div className={classes.blackGrid + ' pl-2 d-flex justify-content-between'}>
            <div>
              {t('EKG Finding')}
              {' : '}
              {getEKGAlgorithmDeterminationDisplayText(
                props.recording.algorithmDetermination,
                props.recording.algorithmPackage,
              )}
            </div>
            <div>
              {t('Device Used')}
              {' : '}
              {props.recording && props.recording.is6l ? '6-Lead Device' : '1-Lead Device'}
            </div>
          </div>
        </div>
        <div className={classes.recordingInfo}>
          <div className={classes.recordingInfospan}>25 mm/s</div>
          <div className={classes.recordingInfospan}>10 mm/mV</div>
          {!props.recording.is6l && (
            <div className={classes.recordingInfospan}>
              {' '}
              <a onClick={() => setoggleRecordingInvert(!toggleRecordingInvert)}> Invert ECG</a>
            </div>
          )}
        </div>

        <EkgRecording
          height={650}
          width={ekgWidth}
          recording={props.recording}
          calpulse={false}
          rowSeconds={rowSeconds}
          inverted={toggleRecordingInvert}
          is6l={props.recording.is6l}
        ></EkgRecording>
        {/* </Grid> */}
      </DialogContent>
    </Dialog>
  );
}
