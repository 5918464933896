import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { commonStyles } from '../../styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Box, Button, FormControl, Grid, Typography, Select, MenuItem } from '@material-ui/core';
import classnames from 'classnames';
import moment from 'moment';
import PadIcon from 'assets/images/icons/profile/pcp.svg';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import InputFieldComponent from 'components/Dashboard/SessionComponents/InputFieldComponent';
import { useDispatch } from 'react-redux';
import { getPCPAnswers, getPCPMetadata, updatePCPData } from 'store/actions/memberProfile';
import { useSelector } from 'react-redux';
import Loading from 'components/Shared/EcgLoader';

const ITEM_TYPES = {
  SINGLE_SELECT: 'SingleSelect',
  SINGLE_LINE: 'SingleLine',
  MULTI_LINE: 'MultiLine',
};
const HealthDetailsPCP = ({ classes, memberId }) => {
  const dispatch = useDispatch();
  const commonClasses = commonStyles();
  const [pcpEdit, setPcpEdit] = useState(false);
  const [metaData, setMetaData] = useState([]);
  const [pcpAnswers, setPcpAnswers] = useState([]);

  const {
    metaData: metaDataFromStore,
    answers,
    update,
  } = useSelector((state) => state.memberProfile.pcp);

  useEffect(() => {
    dispatch(getPCPMetadata(memberId));
  }, []);

  useEffect(() => {
    if (!metaDataFromStore.loading && metaDataFromStore.data) {
      let cells = [];
      let itemsToPush = null;

      metaDataFromStore.data.map((cell, index) => {
        let temp = [];
        cell.items.map((d) => {
          var { mandatory, key, type, hint, title } = d;

          switch (d.type) {
            case ITEM_TYPES.SINGLE_SELECT:
              let { labels, values } = d;
              itemsToPush = { mandatory, key, title, labels, values, type };
              break;
            case ITEM_TYPES.SINGLE_LINE:
              itemsToPush = { mandatory, key, type, hint, title };
              break;
            case ITEM_TYPES.MULTI_LINE:
              let { rowLimit } = d;
              itemsToPush = { mandatory, key, type, hint, title, rowLimit };
              break;
          }
          if (itemsToPush) {
            //answer

            temp.push(itemsToPush);
            itemsToPush = null;
          }
        });
        cells.push(temp);
      });

      if (cells.length > 0) {
        setMetaData(cells);
      }
    }
  }, [metaDataFromStore.loading, metaDataFromStore.data, answers.data]);

  useEffect(() => {
    if (!answers.loading && answers.data && answers.data.length > 0) {
      setPcpAnswers(answers.data);
    }
  }, [answers.loading, answers.data]);

  const onValueChange = (key, val, outIndex, inIndex) => {
     console.log(key, outIndex, inIndex, val);
    setPcpAnswers((c) => {
      if (c.length > outIndex) c[outIndex][key] = val;
      else c.push({ [key]: val });
      return [...c];
    });
  };
  const onSubmit = () => {
    setPcpEdit((c) => !c);
    dispatch(updatePCPData('providerTable', memberId, { data: pcpAnswers }));
  };

  return (
    <Box className={classnames(commonClasses.divWrapper, classes.divWrapperOverride)}>
      <Box className={classes.headerWrapper}>
        <Typography variant="h6">{'Care Provider Information'}</Typography>
        <Box style={{ marginLeft: '10px', marginRight: '32px' }}>
          <Typography variant="subtitle1">Last updated: {answers?.data?.updatedTime && moment(answers?.data?.updatedTime).format('MM/DD/YYYY')}</Typography>
          <PadIcon />
        </Box>
      </Box>
      <hr style = {{marginTop:0,border:"1px solid #B4BBC0" }}></hr>
      <Box style={{ margin: '16px 30px' }}>
        <Grid container className={classes.root} spacing={2}>
          <Grid
            item
            xs={12}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            {metaDataFromStore.loading && <Loading />}
          </Grid>
          {!metaDataFromStore.loading &&
            metaData.map((d, outIndex) => (
              <React.Fragment>
                {pcpEdit && (
                  <Grid item xs={6} key={outIndex} style={{ marginTop: 0, paddingTop: 10 }}>
                    <Box style={{ marginBottom: 10 }}>
                      <Typography variant="h6" style={{ marginBottom: "2rem",fontWeight:500,fontSize:20 }}>Add Provider {outIndex + 1} Information</Typography>
                      {/* {d.map((item, j) => renderInput(item, i, j))} */}
                      {d.map((item, inIndex) => {
                        if (item.type === ITEM_TYPES.SINGLE_LINE) {
                          return (
                            <FormControl variant="outlined" className={`${classes.formControl}`}>
                              <Typography variant="body2" className={classes.pcpInputTitle}>
                               {item?.title.split(" ").splice(1).join('  ')}
                              </Typography>
                              <InputFieldComponent
                                // id={}
                                placeholder = {`Edit Provider ${item?.title.split(" ").splice(1).join('  ')}`}
                                value={pcpAnswers.length > 0 ? pcpAnswers[outIndex][item.key] : ''}
                                onChange={(e) => {
                                  onValueChange(item.key, e.target.value, outIndex, inIndex);
                                }}
                              />
                            </FormControl>
                          );
                        } else if (item.type === ITEM_TYPES.MULTI_LINE) {
                          return (
                            <FormControl variant="outlined" className={`${classes.formControl}`}>
                              <Typography variant="body2" className={classes.pcpInputTitle}>
                              {item?.title.split(" ").splice(1).join('  ')}
                              </Typography>
                              <InputFieldComponent
                                // id={}
                                placeholder = {`Edit Provider ${item?.title.split(" ").splice(1).join('  ')}`}
                                value={pcpAnswers.length > 0 ? pcpAnswers[outIndex][item.key] : ''}
                                onChange={(e) => {
                                  onValueChange(item.key, e.target.value, outIndex, inIndex);
                                }}
                              />
                            </FormControl>
                          );
                        } else if (item.type === ITEM_TYPES.SINGLE_SELECT) {
                          return (
                            <FormControl
                              variant="outlined"
                              className={`${classes.formControl}`}
                              style={{ marginBottom: '20px' }}
                            >
                              <Typography variant="body2" className={classes.pcpInputTitle}>
                                Provider Type
                              </Typography>
                              {/* <InputFieldComponent
                              // id={}
                              // value={}
                              // onChange={(e) =>{}}
                              /> */}
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                placeholder='Edit Provider type'
                                value={pcpAnswers.length > 0 ? pcpAnswers[outIndex][item.key] : ''}
                                onChange={(e) => {
                                  onValueChange(item.key, e.target.value, outIndex, inIndex);
                                }}
                                // disabled={item.id}
                              >
                                <MenuItem value="">Select</MenuItem>
                                {item.labels.map((l, index) => (
                                  <MenuItem value={item.values[index]}>{l}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </Box>
                  </Grid>
                )}
                {!pcpEdit && (
                  <Grid item xs={4} key={outIndex}>
                    <>
                      <Box>
                        <Typography variant="body1" style = {{fontWeight:500,fontSize:20}}>
                          Provider {outIndex + 1}: Information
                        </Typography>
                      </Box>

                      {d.map((item) => (
                        <Typography variant="body2" className={classes.pcpbody2}>
                              {item?.title.split(" ").splice(1).join('  ')}:{' '}
                          {pcpAnswers[outIndex][item.key] ? pcpAnswers[outIndex][item.key] : '-'}
                        </Typography>
                      ))}
                    </>
                  </Grid>
                )}
              </React.Fragment>
            ))}
        </Grid>
        <div className={classes.scheduleRow}>
          <Button
            variant="contained"
            onClick={() => (!pcpEdit ? setPcpEdit((c) => !c) : onSubmit())}
          >
            {pcpEdit ? 'Save' : 'Edit'} Provider Information
          </Button>
        </div>
      </Box>
    </Box>
  );
};

export default HealthDetailsPCP;
