import * as DoctorScreens from 'screens/doctor';
import * as CommmonScreens from 'screens/common';
import * as CoachScreens from 'screens/coach';
import { DOCTOR_ROUTES, ROUTES } from 'constants/app';
import TeamMemberFile from 'components/TeamMemberFile';
import AdminConsultations from 'components/AdminConsultations/AdminConsultations';
import Feedbacks from 'components/Feedbacks/Feedbacks';
import AdminCalendar from 'components/AdminCalendar/AdminCalendar';
import AllAppointments from 'components/AllAppointments/AllAppointments';

export const Doctor = [
  {
    exact: true,
    authenticatedRoute: true,
    path: DOCTOR_ROUTES.TELEKARDIA_ROOT,
    component: DoctorScreens.DoctorTeleconnectScreen,
  },
  {
    exact: true,
    authenticatedRoute: true,
    path: DOCTOR_ROUTES.TELEKARDIA_DASHBOARD,
    component: DoctorScreens.DoctorTeleconnectScreen,
  },
  {
    exact: true,
    authenticatedRoute: true,
    path: DOCTOR_ROUTES.TELEKARDIA_CONSULTATION,
    component: DoctorScreens.DoctorConsultations,
  },
  {
    exact: true,
    authenticatedRoute: true,
    path: DOCTOR_ROUTES.TELEKARDIA_ALL_APPOINTMENT,
    component: AllAppointments,
  },
  {
    exact: true,
    path: ROUTES.SETTINGS,
    component: CommmonScreens.Settings,
  },
  {
    path: `${DOCTOR_ROUTES.PATIENT_PROFILE}/:memberId/:appointmentId`,
    component: CoachScreens.MemberProfile,
  },
  {
    exact: true,
    path: `${DOCTOR_ROUTES.PATIENT_PROFILE}/:memberId/`,
    component: CoachScreens.MemberProfile,
  },
  {
    exact: true,
    path: DOCTOR_ROUTES.DOCTOR_RESOURCES,
    component: CommmonScreens.ComingSoonPage,
  },
  // {
  //   exact: true,
  //   path: ROUTES.ALERT,
  //   component: CommmonScreens.Alert,
  // },
];
export const Admin = [
  {
    exact: true,
    authenticatedRoute: true,
    path: DOCTOR_ROUTES.TELEKARDIA_ROOT,
    component: DoctorScreens.DoctorAdminDashboard,
  },
  {
    exact: true,
    authenticatedRoute: true,
    path: DOCTOR_ROUTES.TELEKARDIA_ADMIN_DASHBOARD,
    component: DoctorScreens.DoctorAdminDashboard,
  },
  {
    exact: true,
    authenticatedRoute: true,
    path: DOCTOR_ROUTES.TELEKARDIA_ADMIN_FEEDBACKS,
    component: Feedbacks,
  },
  {
    exact: true,
    authenticatedRoute: true,
    path: DOCTOR_ROUTES.TELEKARDIA_ADMIN_CALENDAR,
    component: AdminCalendar,
  },
  {
    exact: true,
    authenticatedRoute: true,
    path: DOCTOR_ROUTES.TELEKARDIA_ADMIN_CONSULTATIONS,
    component: AdminConsultations,
  },
  {
    exact: true,
    path: `${DOCTOR_ROUTES.PATIENT_PROFILE}/:memberId/:appointmentId`,
    component: CoachScreens.MemberProfile,
  },
  {
    exact: true,
    path: `${DOCTOR_ROUTES.PATIENT_PROFILE}/:memberId/`,
    component: CoachScreens.MemberProfile,
  },
  {
    exact: true,
    path: ROUTES.SETTINGS,
    component: CommmonScreens.Settings,
  },
  {
    path: `${ROUTES.SETTINGS}/team-members/:teamMemberId`,
    component: TeamMemberFile,
  },
  {
    exact: true,
    path: DOCTOR_ROUTES.DOCTOR_RESOURCES,
    component: CommmonScreens.ComingSoonPage,
  },
  // {
  //   exact: true,
  //   path: ROUTES.ALERT,
  //   component: CommmonScreens.Alert,
  // },
];
export const DoctorAdmin = [...Doctor, ...Admin];
