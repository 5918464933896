import React, { useState, useEffect } from 'react';
import * as API from '../../../Services/API/actions';
import { useSelector } from 'react-redux';

export default function OverReadReportNote(props) {
  const clinicianId = useSelector((state) => state.user.profile.id);
  const teamId = useSelector((state) => state.user.team.id);
  const [overReadReportNotes, setOverReadReportNotes] = useState('');

  useEffect(() => {
    if (props.id !== 0) {
      API.getOverReadReport(teamId, clinicianId, props.appointmentId).subscribe(
        (res) => {
          res.data.result !== null && setOverReadReportNotes(decodeURI(res.data.result));
          res.data.result !== null
            ? props.updateoverReadReport({
                target: {
                  value: decodeURI(res.data.result),
                },
              })
            : props.updateoverReadReport({
                target: {
                  value: '',
                },
              });
        },
        (error) => console.log(error),
      );
    } else {
      props.updateoverReadReport({
        target: {
          value: '',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setOverReadReportNotes(props.overReadReport);
  }, [props.overReadReport]);

  const changeOverReadReportNotes = (note) => {
    props.updateoverReadReport(note);
    setOverReadReportNotes(note.target.value);
  };

  return (
    <>
      <textarea
        className="form-control bg_grey height_240 mt-3 border-0"
        placeholder="The notes added here will be sent to the member."
        value={overReadReportNotes}
        onChange={(e) => changeOverReadReportNotes(e)}
      />
    </>
  );
}
