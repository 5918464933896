/* eslint-disable */
import React from 'react';
import moment from 'moment';
import EcgLoader from 'components/Shared/EcgLoader';
import { EkgTable, BloodPressureTable, WeightTable } from 'components/Tables';
import PageTab from 'components/PageTab';
import PageTabs from 'components/PageTabs';
import {
  getWeightSourceOptions,
  getWeightSeverityOptions,
  getPhysicianName,
} from 'components/Utilities';
// .PatientFile_ecgGraph__2tILF flexbox
//.PatientFile_tableContent__nCIXq text-align
import {
  HEADER_HEIGHT,
  KARDIA_PRO_START_DATE,
  KARDIA_PRO_END_DATE,
  PATIENT_FILE_TABS,
  MIN_BPM_VALUE,
  MAX_BPM_VALUE,
  MIN_SYSTOLIC_VALUE,
  MAX_SYSTOLIC_VALUE,
  MIN_DIASTOLIC_VALUE,
  MAX_DIASTOLIC_VALUE,
  ALL_RECORDINGS_TRIGGER_OPTION,
  TRIAGE_OPTION,
  CUSTOM_TRIGGER_DEFAULT,
  PRIMARY_TRIGGER_TYPES,
  TRIGGER_FREQUENCY_TYPES,
  getPrimaryTriggerOption,
  CUSTOM_TRIGGER_OPTION,
  TRIGGER_TYPES,
  BOTTOM_PADDING,
  ENROLLMENT_CONNECTION_STATUSES,
  getSexDisplayText,
  getPrimaryInsuranceDisplayText,
  getSecondaryInsuranceDisplayText,
  EKG_TABLE_ROW_HEIGHT,
  MAX_BMI_VALUE,
  MIN_BMI_VALUE,
  MAX_WEIGHT_VALUE,
  MIN_WEIGHT_VALUE,
  parseDobFormDisplay,
  US_STATES,
} from 'constants/app';
import { getEkgResultOptions } from 'constants/recordingMaps';
import styles from './PatientFile.module.css';
import { needToBustCache, filterIsPhysicianPermission } from 'store/reducers/app';
import {
  TemporaryEcgHackedGraph,
  TemporaryWeightHackedGraph,
  TemporaryBloodPressureHackedGraph,
} from 'components/Graphs';

// import {
//   checkIdtfFeatureEnabled,
//   checkIdtfServiceProviderFeatureEnabled,
//   checkNSRNotificationStatus,
// } from 'configs';
// import AccessDeniedContent from 'views/components/AccessDeniedContent/AccessDeniedContent';
// import AlertBar from 'components/Metrics/AlertBar';
// import {
//   ALERT_SEVERITY,
//   OVERRIDE_STYLE,
//   ALERT_MSG,
// } from 'components/Metrics/AlertBar/Constants';
import { STUDY_DURATION_DAY_EVENT_MCT } from 'components/Metrics/constsnts/patientEnrollmentConstants';
import { Box, Button, Grid, makeStyles, Paper, TextField, Typography } from '@material-ui/core';
import InputFieldComponent from 'components/Dashboard/SessionComponents/InputFieldComponent';
class PatientFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      innerHeight: 0,
      innerWidth: 0,
      memberId: this.props.match.params.memberId,
      isAnyDataSelected: false,
      showButtons: true,
      edit: false,
      baselineSystolic: '',
      goallineSystolic: '',
      baselineDiastolic: '',
      goallineDiastolic: '',
      baselineDiastolicErrorText: '',
      baselineDiastolicError: false,
      baselineSystolicErrorText: '',
      baselineSystolicError: false,
      goallineSystolicErrorText: '',
      goallineSystolicError: false,
      goallineDiastolicErrorText: '',
      goallineDiastolicError: false,
      error: true,
    };
    this.beginningDate = KARDIA_PRO_START_DATE;
    this.today = KARDIA_PRO_END_DATE;
  }

  componentDidMount() {
    const { goalBaselineBP } = this.props;
    goalBaselineBP(1);
    goalBaselineBP(2);

    this.setState({
      baselineSystolic: this.props.bpBaselineData?.systolic,
      baselineDiastolic: this.props.bpBaselineData?.diastolic,
      goallineSystolic: this.props.bpGoallineData?.systolic,
      goallineDiastolic: this.props.bpGoallineData?.diastolic,
    });
  }

  UNSAFE_componentWillMount() {
    const { match, location, dataLastUpdated, setInitialPatientFileData, getPatientFileData } =
      this.props;

    setInitialPatientFileData(match, location);

    getPatientFileData(needToBustCache(dataLastUpdated), this.state.memberId);

    window.addEventListener('resize', this.handleResize);
    this.setState({
      innerHeight: window.innerHeight,
      innerWidth: window.innerWidth,
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({
      innerHeight: window.innerHeight,
      innerWidth: window.innerWidth,
    });
  };

  handleOnTabClick = (tab) => {
    const { selectedTab, handleOnTabClick: onTabClick } = this.props;
    const { isAnyDataSelected } = this.state;

    const isAnyDataSelectedInNewTab = tab !== selectedTab ? false : isAnyDataSelected;
    this.setState({
      isAnyDataSelected: isAnyDataSelectedInNewTab,
    });

    //reseting the baselinegoal  states when bloodpressure tab is clicked
    this.setState({
      baselineSystolic: this.props.bpBaselineData?.systolic,
      baselineDiastolic: this.props.bpBaselineData?.diastolic,
      goallineSystolic: this.props.bpGoallineData?.systolic,
      goallineDiastolic: this.props.bpGoallineData?.diastolic,
    });

    onTabClick(tab);
  };

  render() {
    const {
      browser,
      dataIsFetching,
      match,
      patient: patientDataSource,
      patients,
      ekgs,
      bloodPressures,
      weights,
      enrollments: enrollmentsDataSource,
      reports: reportsDataSource,
      physicians,
      teamTriggers,
      patientTriggers: patientTriggersDataSource,
      selectedIndex,
      selectedTab,
      // selectedPatientId,
      handleOnFilterButtonClick,
      handleOnSelectionClick,
      refreshClicked,
      bulkActionComplete,
      bulkActionIsFetching,
      bulkActionSuccessMessage,
      selectedPopover,
      enrollmentIds,
      bloodPressureRecordingIds,
      setSelectedBloodPressureRecording,
      clearSelectedBloodPressureRecording,
      handleOnBloodPressureFilterChange,
      handleOnBloodPressureTableScroll,
      handleOnBloodPressureSortClick,
      updateSelectedBloodPressures,
      bloodPressureSortBy,
      bloodPressureSortDirection,
      selectedBloodPressureRecordingId,
      selectedBloodPressureSource,
      selectedBloodPressureSeverity,
      selectedBloodPressureStartDate,
      selectedBloodPressureEndDate,
      selectedBloodPressureBpmStartValue,
      selectedBloodPressureBpmEndValue,
      selectedBloodPressureSystolicStartValue,
      selectedBloodPressureSystolicEndValue,
      selectedBloodPressureDiastolicStartValue,
      selectedBloodPressureDiastolicEndValue,
      selectedBloodPressures,

      ekgRecordingIds,
      setSelectedEkgRecording,
      clearSelectedEkgRecording,
      handleOnEkgSortClick,
      handleOnEkgFilterChange,
      handleOnEkgTableScroll,
      handleOnEkgRowClick,
      handleOnEkgBulkAction,
      updateSelectedEkgs,
      ekgSortBy,
      ekgSortDirection,
      selectedEkgRecordingId,
      selectedEkgPhysicianId,
      selectedEkgPhysicians,
      selectedEkgResult,
      selectedEkgStartDate,
      selectedEkgEndDate,
      selectedEkgBpmStartValue,
      selectedEkgBpmEndValue,
      selectedEkgs,
      reportIds,
      weightRecordingIds,
      setSelectedWeightRecording,
      clearSelectedWeightRecording,
      handleOnWeightFilterChange,
      handleOnWeightTableScroll,
      handleOnWeightSortClick,
      updateSelectedWeights,
      weightSortBy,
      weightSortDirection,
      selectedWeightRecordingId,
      selectedWeightStartDate,
      selectedWeightEndDate,
      selectedWeightSource,
      selectedWeightSeverity,
      selectedWeightBmiStartValue,
      selectedWeightBmiEndValue,
      selectedWeightWeightStartValue,
      selectedWeightWeightEndValue,
      selectedWeights,
      templateList,
      me,
      // kardiaRxIDTFEcg,
      // kardiaRxCCTEcg,
      insurances,
      addresses,
      pateintEnrollOverview,
      idtfEnrollments,
    } = this.props;
    const { params } = match;
    const { innerHeight, innerWidth } = this.state;

    // use patient id from url to determine current patient instead of state
    // fix needed for having EKG tab default
    const selectedPatientId = this.props.match.params.memberId;
    const height = innerHeight - HEADER_HEIGHT - BOTTOM_PADDING;
    const width = 1180 - 20 * 2;

    let patient;
    let cleanProfile;

    // get IDTF feature is enabled status
    // const isIdtfEnabled = checkIdtfFeatureEnabled(me);
    // const isIdtfServiceEnabled = checkIdtfServiceProviderFeatureEnabled(me);
    // const NSRNotificationStatus = checkNSRNotificationStatus(me);
    const isIdtfEnabled = false;
    const isIdtfServiceEnabled = false;
    const NSRNotificationStatus = false;

    let isKM6L = false;
    let isKardiaPatch = false;
    let rpmUser = false;
    if (isIdtfEnabled) {
      if (
        pateintEnrollOverview &&
        pateintEnrollOverview.data &&
        pateintEnrollOverview.data['latest_enrollment']
      ) {
        isKM6L = pateintEnrollOverview.data.latest_enrollment.device == 'KM6L';
        isKardiaPatch = pateintEnrollOverview.data.latest_enrollment.device == 'kardiaPatch';
      } else {
        rpmUser = true;
      }
    }

    // To rewmove add patient and download report button in case of Kardia Rx ECG tab
    if (selectedTab === PATIENT_FILE_TABS.KARDIA_RX) {
      this.state.showButtons = false;
    } else {
      this.state.showButtons = true;
    }

    // clean patient profile for edit page
    // TODO: move this out of render so this function is not recreated every time render is called
    const cleanPatientProfile = (profile) => {
      const newDob = parseDobFormDisplay(profile.dob, me);
      const socialSecurityNumber = profile.socialSecurityNumber;
      const newPhone = profile.phone ? profile.phone.replace(/^(\+1)/g, '') : '';
      const primaryInsuranceType =
        insurances.data &&
        insurances.data.primary_insurance &&
        insurances.data.primary_insurance.insuranceType !== 'not_known'
          ? {
              label: getPrimaryInsuranceDisplayText(
                insurances.data &&
                  insurances.data.primary_insurance &&
                  insurances.data.primary_insurance.insuranceType,
              ),
              value:
                insurances.data &&
                insurances.data.primary_insurance &&
                insurances.data.primary_insurance.insuranceType,
            }
          : '';
      const secondaryInsuranceType =
        insurances.data &&
        insurances.data.secondary_insurance &&
        insurances.data.secondary_insurance.insuranceType !== 'not_known'
          ? {
              label: getSecondaryInsuranceDisplayText(
                insurances.data &&
                  insurances.data.secondary_insurance &&
                  insurances.data.secondary_insurance.insuranceType,
              ),
              value:
                insurances.data &&
                insurances.data.secondary_insurance &&
                insurances.data.secondary_insurance.insuranceType,
            }
          : '';

      const primaryState =
        addresses.data && addresses.data.primary_address && addresses.data.primary_address.state;
      const patientState = US_STATES.filter((item) => item.code == primaryState);
      const secondaryState =
        addresses.data && addresses.data.shipping_address && addresses.data.shipping_address.state;
      const shippingState = US_STATES.filter((item) => item.code == secondaryState);
      const alternatePhone = profile.alternatePhone
        ? profile.alternatePhone.replace(/^(\+1)/g, '')
        : '';
      const emergencyContactName = profile.emergencyContactName;
      const emergencyContactPhone = profile.emergencyContactPhone
        ? profile.emergencyContactPhone.replace(/^(\+1)/g, '')
        : '';
      const patientAddressLine1 =
        addresses.data &&
        addresses.data.primary_address &&
        addresses.data.primary_address.primaryLine;
      const patientAddressLine2 =
        addresses.data &&
        addresses.data.primary_address &&
        addresses.data.primary_address.secondaryLine;
      const patientCity =
        addresses.data && addresses.data.primary_address && addresses.data.primary_address.city;
      const patientZip =
        addresses.data &&
        addresses.data.primary_address &&
        addresses.data.primary_address.postalCode;
      const shippingAddressLine1 =
        addresses.data &&
        addresses.data.shipping_address &&
        addresses.data.shipping_address.primaryLine;
      const shippingAddressLine2 =
        addresses.data &&
        addresses.data.shipping_address &&
        addresses.data.shipping_address.secondaryLine;
      const shippingCity =
        addresses.data && addresses.data.shipping_address && addresses.data.shipping_address.city;
      const shippingZip =
        addresses.data &&
        addresses.data.shipping_address &&
        addresses.data.shipping_address.postalCode;
      // const primaryInsuranceType= insurances.data && insurances.data.primary_insurance && insurances.data.primary_insurance.insuranceType
      const primaryMedicareId =
        insurances.data &&
        insurances.data.primary_insurance &&
        insurances.data.primary_insurance.medicareId;
      const primaryInsuranceCompanyName =
        insurances.data &&
        insurances.data.primary_insurance &&
        insurances.data.primary_insurance.insuranceCompanyName;
      const primaryInsuranceGroup =
        insurances.data &&
        insurances.data.primary_insurance &&
        insurances.data.primary_insurance.insuranceGroup;
      const primaryInsuranceAuthorizationNumber =
        insurances.data &&
        insurances.data.primary_insurance &&
        insurances.data.primary_insurance.insuranceAuthorizationNumber;
      const primaryPolicyHolderName =
        insurances.data &&
        insurances.data.primary_insurance &&
        insurances.data.primary_insurance.policyHolderName;
      const primaryPolicyHolderRelationship =
        insurances.data &&
        insurances.data.primary_insurance &&
        insurances.data.primary_insurance.policyHolderRelationship;
      const primarySubscriberId =
        insurances.data &&
        insurances.data.primary_insurance &&
        insurances.data.primary_insurance.subscriberId;
      // const secondaryInsuranceType= insurances.data && insurances.data.secondary_insurance && insurances.data.secondary_insurance.insuranceType
      const secondaryMedicareId =
        insurances.data &&
        insurances.data.secondary_insurance &&
        insurances.data.secondary_insurance.medicareId;
      const secondaryInsuranceCompanyName =
        insurances.data &&
        insurances.data.secondary_insurance &&
        insurances.data.secondary_insurance.insuranceCompanyName;
      const secondaryInsuranceGroup =
        insurances.data &&
        insurances.data.secondary_insurance &&
        insurances.data.secondary_insurance.insuranceGroup;
      const secondaryInsuranceAuthorizationNumber =
        insurances.data &&
        insurances.data.secondary_insurance &&
        insurances.data.secondary_insurance.insuranceAuthorizationNumber;
      const secondaryPolicyHolderName =
        insurances.data &&
        insurances.data.secondary_insurance &&
        insurances.data.secondary_insurance.policyHolderName;
      const secondaryPolicyHolderRelationship =
        insurances.data &&
        insurances.data.secondary_insurance &&
        insurances.data.secondary_insurance.policyHolderRelationship;
      const secondarySubscriberId =
        insurances.data &&
        insurances.data.secondary_insurance &&
        insurances.data.secondary_insurance.subscriberId;
      const newSex =
        profile.sex && profile.sex !== 'not_known'
          ? {
              label: getSexDisplayText(profile.sex),
              value: profile.sex,
            }
          : '';

      const participantCustomFields = profile.participantCustomFields || [];
      const customFields = participantCustomFields.reduce((prev, { fieldName, fieldValue }) => {
        return { ...prev, [fieldName]: fieldValue };
      }, {});

      const newProfile = {
        ...profile,
        dob: newDob,
        phone: newPhone,
        sex: newSex,
        socialSecurityNumber: socialSecurityNumber,
        alternatePhone: alternatePhone,
        emergencyContactName: emergencyContactName,
        emergencyContactPhone: emergencyContactPhone,
        patientAddressLine1: patientAddressLine1,
        patientAddressLine2: patientAddressLine2,
        patientCity: patientCity,
        patientState: patientState,
        patientZip: patientZip,
        shippingAddressLine1: shippingAddressLine1,
        shippingAddressLine2: shippingAddressLine2,
        shippingCity: shippingCity,
        shippingState: shippingState,
        shippingZip: shippingZip,
        primaryInsuranceType: primaryInsuranceType,
        primaryMedicareId: primaryMedicareId,
        primaryInsuranceCompanyName: primaryInsuranceCompanyName,
        primaryInsuranceGroup: primaryInsuranceGroup,
        primaryInsuranceAuthorizationNumber: primaryInsuranceAuthorizationNumber,
        primaryPolicyHolderName: primaryPolicyHolderName,
        primaryPolicyHolderRelationship: primaryPolicyHolderRelationship,
        primarySubscriberId: primarySubscriberId,
        secondaryInsuranceType: secondaryInsuranceType,
        secondaryMedicareId: secondaryMedicareId,
        secondaryInsuranceCompanyName: secondaryInsuranceCompanyName,
        secondaryInsuranceGroup: secondaryInsuranceGroup,
        secondaryInsuranceAuthorizationNumber: secondaryInsuranceAuthorizationNumber,
        secondaryPolicyHolderName: secondaryPolicyHolderName,
        secondaryPolicyHolderRelationship: secondaryPolicyHolderRelationship,
        secondarySubscriberId: secondarySubscriberId,
        ...customFields,
      };
      return newProfile;
    };

    if (patients.data && patients.data.byId[selectedPatientId]) {
      patient = patients.data.byId[selectedPatientId];
      cleanProfile = cleanPatientProfile(patient);
    }

    let selectedTemplate = [];
    selectedTemplate =
      patient &&
      templateList &&
      templateList.data &&
      templateList.data.source.filter((item) => {
        if (item.id === patient.connectionTemplateID) {
          return item;
        }
      });
    const isKiwiAuth =
      selectedTemplate &&
      selectedTemplate[0] &&
      selectedTemplate[0].type &&
      selectedTemplate[0].type.String === 'kiwi-auth' &&
      selectedTemplate[0].type.Valid === true;

    /* Profile tab */
    let currentEnrollment = {};
    let enrollmentCode;
    let enrollments = [];
    if (patient && patient.enrollments && enrollmentsDataSource.data) {
      const currentEnrollmentId = patient.enrollments.find(
        (id) =>
          enrollmentsDataSource.data.byId[id] &&
          enrollmentsDataSource.data.byId[id].connectionStatus === patient.connectionStatus,
      );
      if (currentEnrollmentId) {
        currentEnrollment = enrollmentsDataSource.data.byId[currentEnrollmentId];
        enrollmentCode = currentEnrollment.code;
      }

      enrollments = enrollmentIds
        .map((id) => enrollmentsDataSource.data.byId[id])
        .filter((enrollment) => {
          if (!enrollment) return false;

          // remove if pending code
          // remove if expired pending code
          if (
            enrollment.connectionStatus === ENROLLMENT_CONNECTION_STATUSES.PENDING ||
            (enrollment.connectionStatus === ENROLLMENT_CONNECTION_STATUSES.EXPIRED &&
              enrollment.startDate === null)
          ) {
            return false;
          }
          return true;
        });
    }

    // Initial Values for Prescribe
    let prescribeInitialValues = {
      custom: {
        ...CUSTOM_TRIGGER_DEFAULT,
      },
      ekgReviewWhichRecordings: ALL_RECORDINGS_TRIGGER_OPTION,
      ekgReviewWhereRecordings: TRIAGE_OPTION,
      email: patient && patient.email,
    };

    const getFormInboxPresets = (triggersById, typeToIdLookup, presets) => {
      const newPresets = {
        ...presets,
      };
      const whichPrimaryTriggerType = Object.keys(PRIMARY_TRIGGER_TYPES).find((triggerType) => {
        const id = typeToIdLookup[triggerType];
        return triggersById[id].frequency === TRIGGER_FREQUENCY_TYPES.ALWAYS;
      });

      if (whichPrimaryTriggerType) {
        const id = typeToIdLookup[whichPrimaryTriggerType];
        const triggerData = triggersById[id];
        newPresets.ekgReviewWhichRecordings = getPrimaryTriggerOption(
          whichPrimaryTriggerType,
          triggerData,
        );
      } else {
        newPresets.ekgReviewWhichRecordings = CUSTOM_TRIGGER_OPTION;
        const possibleAFibResultId = typeToIdLookup[TRIGGER_TYPES.POSSIBLE_AFIB_DETECTION];
        if (triggersById[possibleAFibResultId].frequency === TRIGGER_FREQUENCY_TYPES.ALWAYS) {
          newPresets.custom.possibleAFibResultCheckmark = true;
        }
        const possibleAFibResultWithHrId = typeToIdLookup[TRIGGER_TYPES.HEART_RATE_OVER_X_AND_AFIB];
        if (triggersById[possibleAFibResultWithHrId].frequency === TRIGGER_FREQUENCY_TYPES.ALWAYS) {
          newPresets.custom.possibleAFibResultWithHrCheckmark = true;
          newPresets.custom.possibleAFibResultWithHr = parseInt(
            triggersById[possibleAFibResultWithHrId].value,
            10,
          );
        }
        const allUnclassifiedId = typeToIdLookup[TRIGGER_TYPES.ALL_UNCLASSIFIED];
        if (triggersById[allUnclassifiedId].frequency === TRIGGER_FREQUENCY_TYPES.ALWAYS) {
          newPresets.custom.unclassifiedResultCheckmark = true;
        }
        const heartRateUnderId = typeToIdLookup[TRIGGER_TYPES.HEART_RATE_UNDER_X];
        if (triggersById[heartRateUnderId].frequency === TRIGGER_FREQUENCY_TYPES.ALWAYS) {
          newPresets.custom.heartRateUnderCheckmark = true;
          newPresets.custom.heartRateUnder = parseInt(triggersById[heartRateUnderId].value, 10);
        }
        const heartRateOverId = typeToIdLookup[TRIGGER_TYPES.HEART_RATE_OVER_X];
        if (triggersById[heartRateOverId].frequency === TRIGGER_FREQUENCY_TYPES.ALWAYS) {
          newPresets.custom.heartRateOverCheckmark = true;
          newPresets.custom.heartRateOver = parseInt(triggersById[heartRateOverId].value, 10);
        }
        if (typeToIdLookup[TRIGGER_TYPES.BRADYCARDIA]) {
          const bradycardiaId = typeToIdLookup[TRIGGER_TYPES.BRADYCARDIA];
          if (triggersById[bradycardiaId].frequency === TRIGGER_FREQUENCY_TYPES.ALWAYS) {
            newPresets.custom.bradycardia = true;
          }
        }
        if (typeToIdLookup[TRIGGER_TYPES.TACHYCARDIA]) {
          const tachycardiaId = typeToIdLookup[TRIGGER_TYPES.TACHYCARDIA];
          if (triggersById[tachycardiaId].frequency === TRIGGER_FREQUENCY_TYPES.ALWAYS) {
            newPresets.custom.tachycardia = true;
          }
        }
        if (typeToIdLookup[TRIGGER_TYPES.SINUS_RHYTHM_PVC]) {
          const sinusRhythmWithPVCsId = typeToIdLookup[TRIGGER_TYPES.SINUS_RHYTHM_PVC];
          if (triggersById[sinusRhythmWithPVCsId].frequency === TRIGGER_FREQUENCY_TYPES.ALWAYS) {
            newPresets.custom.sinusRhythmWithPVCs = true;
          }
        }
        if (typeToIdLookup[TRIGGER_TYPES.SINUS_RHYTHM_SVE]) {
          const sinusRhythmWithSVEsId = typeToIdLookup[TRIGGER_TYPES.SINUS_RHYTHM_SVE];
          if (triggersById[sinusRhythmWithSVEsId].frequency === TRIGGER_FREQUENCY_TYPES.ALWAYS) {
            newPresets.custom.sinusRhythmWithSVEs = true;
          }
        }
        if (typeToIdLookup[TRIGGER_TYPES.SINUS_RHYTHM_WIDE_QRS]) {
          const sinusRhythmWithWideQRSId = typeToIdLookup[TRIGGER_TYPES.SINUS_RHYTHM_WIDE_QRS];
          if (triggersById[sinusRhythmWithWideQRSId].frequency === TRIGGER_FREQUENCY_TYPES.ALWAYS) {
            newPresets.custom.sinusRhythmWithWideQRS = true;
          }
        }
      }
      return newPresets;
    };

    // Team trigger default for which recordings
    if (teamTriggers.data) {
      prescribeInitialValues = {
        ...prescribeInitialValues,
        ...getFormInboxPresets(
          teamTriggers.data.byId,
          teamTriggers.data.typeToIdLookup,
          prescribeInitialValues,
        ),
      };
    }

    let enrollmentFormInitialValues = Object.assign({}, currentEnrollment, {
      custom: {
        ...CUSTOM_TRIGGER_DEFAULT,
      },
      billingCodes: null,
      icd10Codes: null,
      idtfDuration: {},
      device_serial_number: null,
    });

    let showQrInfo = false;
    // chek if IDTF enable, set chek true
    if (isIdtfEnabled) {
      const templateInfo =
        patient &&
        patient.connectionTemplateID &&
        templateList &&
        templateList.data &&
        templateList.data.source &&
        templateList.data.source.filter((item) => item.id === patient.connectionTemplateID);
      if (templateInfo && templateInfo.length > 0 && templateInfo[0].isIDTF === true) {
        showQrInfo = true;
      }
      // templet -- && isIdtf -- > match
      // showQrInfo = true / false
    } else {
      showQrInfo = false;
    }

    if (
      currentEnrollment &&
      typeof currentEnrollment.billingCodes !== 'string' &&
      currentEnrollment.billingCodes !== undefined
    ) {
      enrollmentFormInitialValues.billingCodes = currentEnrollment.billingCodes;
    }

    if (
      currentEnrollment &&
      typeof currentEnrollment.icd10Codes !== 'string' &&
      currentEnrollment.icd10Codes !== undefined
    ) {
      enrollmentFormInitialValues.icd10Codes = currentEnrollment.icd10Codes;
    }

    if (
      idtfEnrollments &&
      idtfEnrollments.data &&
      idtfEnrollments.data[0] &&
      idtfEnrollments.data[0].duration
    ) {
      enrollmentFormInitialValues.idtfDuration = STUDY_DURATION_DAY_EVENT_MCT.filter(
        (item) => item.value == idtfEnrollments.data[0].duration,
      );
    }

    if (
      pateintEnrollOverview &&
      pateintEnrollOverview.data &&
      pateintEnrollOverview.data['latest_enrollment']
    ) {
      enrollmentFormInitialValues.device_serial_number =
        pateintEnrollOverview.data.latest_enrollment.device_serial_number;
    }

    // There is a team's version of triggers and a patient's version of triggers (when the user has a connection status of pending or connected)
    const patientTriggers = {
      isFetching: true,
    };
    if (patientTriggersDataSource.data && physicians.data && patient && patient.triggers) {
      enrollmentFormInitialValues = {
        ...enrollmentFormInitialValues,
        ...getFormInboxPresets(
          patient.triggers.byId,
          patient.triggers.typeToIdLookup,
          enrollmentFormInitialValues,
        ),
      };
      if (!patient.assignedMemberID) {
        enrollmentFormInitialValues.ekgReviewWhereRecordings = TRIAGE_OPTION;
      } else {
        enrollmentFormInitialValues.ekgReviewWhereRecordings = {
          ...physicians.data.byId[patient.assignedMemberID],
        };
      }
      patientTriggers.data = patient.triggers;
      patientTriggers.isFetching = false;
    }

    // console.log(currentEnrollment, enrollmentFormInitialValues, enrollmentFormData)

    /* Reports table */
    let reports = [];
    if (patient && patient.reports && reportsDataSource.data && !refreshClicked) {
      reports = reportIds.map((id) => reportsDataSource.data.byId[id]);
    }
    // console.log(reports)
    /* Reports table */

    let chartHeight = 185;
    if (browser.name === 'ie') {
      chartHeight += 17;
    } else {
      chartHeight += 11;
    }

    /* EKGs table */
    let completeEkgRecordings = [];
    let ekgRecordings = [];
    let selectedEkgRecording;
    if (patient && patient.recordings && ekgs.data && !refreshClicked) {
      selectedEkgRecording = selectedEkgRecordingId ? ekgs.data.byId[selectedEkgRecordingId] : null;
      completeEkgRecordings = patient.recordings.map((id) => ekgs.data.byId[id]);
      ekgRecordings = ekgRecordingIds.map((id) => ekgs.data.byId[id]);
    }

    let physicianEkgOptions = [];
    const noneEkgOptionItem = {
      id: '0',
      label: '<None>',
    };

    if (physicians && physicians.data) {
      physicianEkgOptions = [noneEkgOptionItem].concat(
        filterIsPhysicianPermission(
          physicians.data.sortedByLastName.map((id) => physicians.data.byId[id]),
        ).map((p) => ({
          id: p.id,
          label: getPhysicianName(p.firstName, p.lastName, ''),
        })),
      );
    }
    /* EKGs table */

    /* Blood Pressure table */
    let completeBloodPressureRecordings = [];
    let bloodPressureRecordings = [];
    let selectedBloodPressureRecording;
    if (patient && patient.bloodPressure && bloodPressures.data && !refreshClicked) {
      selectedBloodPressureRecording = selectedBloodPressureRecordingId
        ? bloodPressures.data.byId[selectedBloodPressureRecordingId]
        : null;
      completeBloodPressureRecordings = patient.bloodPressure.map(
        (id) => bloodPressures.data.byId[id],
      );
      bloodPressureRecordings = bloodPressureRecordingIds.map((id) => bloodPressures.data.byId[id]);
    }
    /* Blood Pressure table */

    /* Weight table */
    let completeWeightRecordings = [];
    let weightRecordings = [];
    let selectedWeightRecording;
    if (patient && patient.weight && weights.data && !refreshClicked) {
      selectedWeightRecording = selectedWeightRecordingId
        ? weights.data.byId[selectedWeightRecordingId]
        : null;
      completeWeightRecordings = patient.weight.map((id) => weights.data.byId[id]);
      weightRecordings = weightRecordingIds.map((id) => weights.data.byId[id]);
    }
    /* Weight table */

    /* GA metric value */
    let gaValue;
    if (patient) {
      switch (patient.connectionStatus) {
        case 'none':
          gaValue = 0;
          break;
        case 'connected':
          gaValue = 1;
          break;
        case 'pending':
          gaValue = 2;
          break;
        default:
          gaValue = -1;
          break;
      }

      // if (selectedTab === PATIENT_FILE_TABS.EKGS
      //   && !((dataIsFetching && !patient && (patient && !patient.weight)) || refreshClicked)) {
      //   ReactGA.event({
      //     category: 'patient-ecg-table',
      //     action: 'default-tab-ecg',
      //     value: gaValue
      //   })
      // }
    }

    this.editBP = () => {
      this.setState({ edit: !this.state.edit });
      if (this.state.edit) {
        const baseLineBody = {
          systolic: this.state.baselineSystolic,
          diastolic: this.state.baselineDiastolic,
          type: 1,
        };
        this.props.postGoalBaseLineBP(baseLineBody);
        const goalLineBody = {
          systolic: this.state.goallineSystolic,
          diastolic: this.state.goallineDiastolic,
          type: 2,
        };
        this.props.postGoalBaseLineBP(goalLineBody);

        this.props.goalBaselineBP(1);
        this.props.goalBaselineBP(2);
      }
    };

    this.handleChange = (e) => {
      if (e.target.value.length > 3) {
        this.setState({
          [`${e.target.name}Error`]: true,
          [`${e.target.name}ErrorText`]: 'max 3 digits allowed',
          error: true,
        });
      } else {
        this.setState({
          [`${e.target.name}Error`]: false,
          [`${e.target.name}ErrorText`]: '',
          error: false,
        });
      }
      this.setState({
        [e.target.name]: e.target.value,
      });
    };

    this.validation = (value) => {
      console.log(value);
    };

    const getTabContent = (tab) => {
      let content = null;

      switch (tab) {
        case PATIENT_FILE_TABS.EKGS:
          content =
            !isIdtfServiceEnabled &&
            !((dataIsFetching && !patient && patient && !patient.recordings) || refreshClicked) ? (
              <React.Fragment>
                {/* {!NSRNotificationStatus && !isIdtfEnabled && !isIdtfServiceEnabled && (
                  <AlertBar
                    severity={ALERT_SEVERITY.INFO}
                    overrideStyle={OVERRIDE_STYLE.NSR}
                    textMsg={ALERT_MSG.NSR_INFO}
                    onClickClose={() => setNSRStatus(true)}
                  />
                )} */}
                <div
                  // className={styles.ecgPage}
                  style={{
                    height,
                  }}
                >
                  <Paper className={styles.ecgGraph}>
                    <Typography variant="h5" className={styles.headings}>
                      EKG
                    </Typography>
                    <hr />
                    <TemporaryEcgHackedGraph
                      completeRecordings={completeEkgRecordings}
                      recordingIds={ekgRecordingIds}
                      recordings={ekgRecordings}
                      selectedRecording={selectedEkgRecording}
                      setSelectedRecording={(rec) => setSelectedEkgRecording(ekgRecordingIds, rec)}
                      clearSelectedRecording={clearSelectedEkgRecording}
                      width={width}
                    />
                  </Paper>
                  <Paper
                    // className={styles.tableContent}
                    style={{
                      // height: (ekgRecordings.length * EKG_TABLE_ROW_HEIGHT) + TABLE_HEADER_HEIGHT,
                      // width,
                      height: height - chartHeight - 24 - 16,
                    }}
                    data-page="patient-ecg-table"
                    data-value={ekgRecordings.length}
                  >
                    <Typography variant="h5" className={styles.headings}>
                      Data Display
                    </Typography>
                    <hr />
                    <EkgTable
                      enableLoader={ekgRecordingIds.length === 0 || refreshClicked}
                      showLoader={dataIsFetching}
                      data={ekgRecordings}
                      rowHeight={EKG_TABLE_ROW_HEIGHT}
                      showRowSelection={true}
                      selected={selectedEkgs}
                      onSelectionClick={(selected) =>
                        handleOnSelectionClick(selected, updateSelectedEkgs)
                      }
                      // height={height}
                      width={width}
                      onScroll={handleOnEkgTableScroll}
                      onRowClick={handleOnEkgRowClick}
                      selectedIndex={selectedIndex}
                      sortBy={ekgSortBy}
                      sortDirection={ekgSortDirection}
                      me={me}
                      filterDate={
                        !moment(selectedEkgStartDate).isSame(this.beginningDate, 'day') ||
                        !moment(selectedEkgEndDate).isSame(this.today, 'day')
                      }
                      filterAnalysis={selectedEkgResult.length > 0}
                      filterAnalysisBy={selectedEkgPhysicianId}
                      filterPhysician={selectedEkgPhysicians && selectedEkgPhysicians.length > 0}
                      filterBpm={
                        selectedEkgBpmStartValue !== MIN_BPM_VALUE ||
                        selectedEkgBpmEndValue !== MAX_BPM_VALUE
                      }
                      handleOnFilterButtonClick={handleOnFilterButtonClick}
                      handleOnFilterChange={handleOnEkgFilterChange}
                      handleOnSortClick={handleOnEkgSortClick}
                      handleOnOutsidePopoverClick={() => handleOnFilterButtonClick(null)}
                      handleOnBulkAction={handleOnEkgBulkAction}
                      handleOnBulkActionClearSelection={() =>
                        handleOnSelectionClick({}, updateSelectedEkgs)
                      }
                      bulkActionComplete={bulkActionComplete}
                      bulkActionIsFetching={bulkActionIsFetching}
                      bulkActionSuccessMessage={bulkActionSuccessMessage}
                      showBulkActionRemove={false}
                      selectedPopover={selectedPopover}
                      selectedStartDate={selectedEkgStartDate}
                      selectedEndDate={selectedEkgEndDate}
                      dateRange={[this.beginningDate, this.today]}
                      selectedBpmStartValue={selectedEkgBpmStartValue}
                      selectedBpmEndValue={selectedEkgBpmEndValue}
                      bpmRange={[MIN_BPM_VALUE, MAX_BPM_VALUE]}
                      showPatientColumns={false}
                      showOrderingProvider={false}
                      physicians={physicians}
                      physicianOptions={physicianEkgOptions}
                      selectedPhysicianId={selectedEkgPhysicianId}
                      selectedPhysicians={selectedEkgPhysicians}
                      ekgResults={getEkgResultOptions().slice(1)}
                      selectedEkgResult={selectedEkgResult}
                    />
                  </Paper>
                </div>
              </React.Fragment>
            ) : (
              <div
                className={styles.contentWrapper}
                style={{
                  height,
                }}
              >
                <EcgLoader />
              </div>
            );
          break;
        case PATIENT_FILE_TABS.BLOOD_PRESSURES:
          content =
            !isIdtfServiceEnabled &&
            !(
              (dataIsFetching && !patient && patient && !patient.bloodPressure) ||
              refreshClicked
            ) ? (
              <div
                // className={styles.ecgPage}
                style={{
                  height,
                }}
              >
                <Paper className={styles.ecgGraph}>
                  <Typography variant="h5" className={styles.headings}>
                    Blood Pressure
                  </Typography>
                  <hr />
                  <TemporaryBloodPressureHackedGraph
                    completeRecordings={completeBloodPressureRecordings}
                    recordingIds={bloodPressureRecordingIds}
                    recordings={bloodPressureRecordings}
                    selectedRecording={selectedBloodPressureRecording}
                    setSelectedRecording={(rec) =>
                      setSelectedBloodPressureRecording(bloodPressureRecordingIds, rec)
                    }
                    clearSelectedRecording={clearSelectedBloodPressureRecording}
                    width={width}
                  />
                </Paper>

                {/* BASELINE GOAL */}
                <Paper className={styles.tableContent}>
                  <Typography variant="h5" className={styles.headings}>
                    Baseline and Goal
                  </Typography>
                  <hr />
                  <Grid container spacing={2}>
                    <Grid item xs={6} style={{ padding: '10px 40px' }}>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold', marginBottom: '5px', fontSize: '16px' }}
                      >
                        Baseline Blood Pressure
                      </Typography>
                      {this.state.edit ? (
                        <>
                          <Typography>Systolic</Typography>
                          <InputFieldComponent
                            name="baselineSystolic"
                            value={this.state.baselineSystolic}
                            onChange={this.handleChange}
                            type="number"
                            error={this.state.baselineSystolicError}
                            helperText={this.state.baselineSystolicErrorText}
                            fullWidth={true}
                          />
                          <Typography>Diastolic</Typography>
                          <InputFieldComponent
                            name="baselineDiastolic"
                            value={this.state.baselineDiastolic}
                            onChange={this.handleChange}
                            type="number"
                            error={this.state.baselineDiastolicError}
                            helperText={this.state.baselineDiastolicErrorText}
                            fullWidth={true}
                          />
                        </>
                      ) : (
                        <>
                          <Typography>Systolic: {this.state.baselineSystolic}</Typography>
                          <Typography>Diastolic: {this.state.baselineDiastolic}</Typography>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={6} style={{ padding: '10px 30px' }}>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold', marginBottom: '5px', fontSize: '16px' }}
                      >
                        Goal Blood Pressure
                      </Typography>
                      {this.state.edit ? (
                        <>
                          <Typography>Systolic</Typography>
                          <InputFieldComponent
                            name="goallineSystolic"
                            value={this.state.goallineSystolic}
                            onChange={this.handleChange}
                            type="number"
                            error={this.state.goallineSystolicError}
                            helperText={this.state.goallineSystolicErrorText}
                            fullWidth={true}
                          />
                          {/* <TextField id="outlined-basic" label="Systolic" variant="outlined" /> */}
                          <Typography>Diastolic</Typography>
                          <InputFieldComponent
                            name="goallineDiastolic"
                            value={this.state.goallineDiastolic}
                            onChange={this.handleChange}
                            type="number"
                            error={this.state.goallineDiastolicError}
                            helperText={this.state.goallineDiastolicErrorText}
                            fullWidth={true}
                          />
                        </>
                      ) : (
                        <>
                          <Typography>Systolic: {this.state.goallineSystolic}</Typography>
                          <Typography>Diastolic: {this.state.goallineDiastolic}</Typography>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        style={{
                          float: 'right',
                          marginRight: '20px',
                          border: this.state.edit ? 'none' : '1px solid black',
                          backgroundColor: this.state.edit
                            ? this.state.error
                              ? 'lightgrey'
                              : 'black'
                            : 'white',
                          color: this.state.edit ? 'white' : 'black',
                          width: '160px',
                          fontWeight: 700,
                        }}
                        onClick={this.editBP}
                        disabled={this.state.edit ? this.state.error : false}
                      >
                        {this.state.edit ? 'Save' : 'Edit readings'}
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>

                <Paper
                  // className={styles.tableContent}
                  style={{
                    // height: (ekgRecordings.length * EKG_TABLE_ROW_HEIGHT) + TABLE_HEADER_HEIGHT,
                    // width,
                    height: height - chartHeight - 24 - 16,
                  }}
                  data-page="patient-bpm-table"
                  data-value={bloodPressureRecordings.length}
                >
                  <Typography variant="h5" className={styles.headings}>
                    Data Display
                  </Typography>
                  <hr />
                  <BloodPressureTable
                    enableLoader={bloodPressureRecordingIds.length === 0 || refreshClicked}
                    showLoader={dataIsFetching}
                    data={bloodPressureRecordings}
                    showRowSelection={false}
                    selected={selectedBloodPressures}
                    onSelectionClick={(selected) =>
                      handleOnSelectionClick(selected, updateSelectedBloodPressures)
                    }
                    // height={height}
                    width={width}
                    onScroll={handleOnBloodPressureTableScroll}
                    selectedIndex={selectedIndex}
                    sortBy={bloodPressureSortBy}
                    sortDirection={bloodPressureSortDirection}
                    me={me}
                    filterDate={
                      !moment(selectedBloodPressureStartDate).isSame(this.beginningDate, 'day') ||
                      !moment(selectedBloodPressureEndDate).isSame(this.today, 'day')
                    }
                    filterSource={selectedBloodPressureSource.length > 0}
                    filterCategory={selectedBloodPressureSeverity.length > 0}
                    filterBpm={
                      selectedBloodPressureBpmStartValue !== MIN_BPM_VALUE ||
                      selectedBloodPressureBpmEndValue !== MAX_BPM_VALUE
                    }
                    filterSystolic={
                      selectedBloodPressureSystolicStartValue !== MIN_SYSTOLIC_VALUE ||
                      selectedBloodPressureSystolicEndValue !== MAX_SYSTOLIC_VALUE
                    }
                    filterDiastolic={
                      selectedBloodPressureDiastolicStartValue !== MIN_DIASTOLIC_VALUE ||
                      selectedBloodPressureDiastolicEndValue !== MAX_DIASTOLIC_VALUE
                    }
                    handleOnFilterButtonClick={handleOnFilterButtonClick}
                    handleOnFilterChange={handleOnBloodPressureFilterChange}
                    handleOnSortClick={handleOnBloodPressureSortClick}
                    handleOnOutsidePopoverClick={() => handleOnFilterButtonClick(null)}
                    selectedPopover={selectedPopover}
                    selectedStartDate={selectedBloodPressureStartDate}
                    selectedEndDate={selectedBloodPressureEndDate}
                    dateRange={[this.beginningDate, this.today]}
                    showPatientColumns={false}
                    selectedSource={selectedBloodPressureSource}
                    selectedSeverity={selectedBloodPressureSeverity}
                    selectedBpmStartValue={selectedBloodPressureBpmStartValue}
                    selectedBpmEndValue={selectedBloodPressureBpmEndValue}
                    bpmRange={[MIN_BPM_VALUE, MAX_BPM_VALUE]}
                    selectedSystolicStartValue={selectedBloodPressureSystolicStartValue}
                    selectedSystolicEndValue={selectedBloodPressureSystolicEndValue}
                    systolicRange={[MIN_SYSTOLIC_VALUE, MAX_SYSTOLIC_VALUE]}
                    selectedDiastolicStartValue={selectedBloodPressureDiastolicStartValue}
                    selectedDiastolicEndValue={selectedBloodPressureDiastolicEndValue}
                    diastolicRange={[MIN_DIASTOLIC_VALUE, MAX_DIASTOLIC_VALUE]}
                  />
                </Paper>
              </div>
            ) : (
              <div
                className={styles.contentWrapper}
                style={{
                  height,
                }}
              >
                <EcgLoader />
              </div>
            );
          break;
        case PATIENT_FILE_TABS.WEIGHTS:
          content =
            !isIdtfServiceEnabled &&
            !((dataIsFetching && !patient && patient && !patient.weight) || refreshClicked) ? (
              <div
                // className={styles.ecgPage}
                style={{
                  height,
                }}
              >
                <Paper className={styles.ecgGraph}>
                  <Typography variant="h5" className={styles.headings}>
                    Weight
                  </Typography>
                  <hr />
                  <TemporaryWeightHackedGraph
                    completeRecordings={completeWeightRecordings}
                    recordingIds={weightRecordingIds}
                    recordings={weightRecordings}
                    selectedRecording={selectedWeightRecording}
                    setSelectedRecording={(rec) =>
                      setSelectedWeightRecording(weightRecordingIds, rec)
                    }
                    clearSelectedRecording={clearSelectedWeightRecording}
                    width={width}
                    me={me}
                  />
                </Paper>
                <Paper
                  className={styles.tableContent}
                  style={{
                    // height: (ekgRecordings.length * EKG_TABLE_ROW_HEIGHT) + TABLE_HEADER_HEIGHT,
                    // width,
                    height: height - chartHeight - 24 - 16,
                  }}
                  data-page="patient-weight-table"
                  data-value={weightRecordings.length}
                >
                  <Typography variant="h5" className={styles.headings}>
                    Data Display
                  </Typography>
                  <hr />
                  <WeightTable
                    enableLoader={weightRecordingIds.length === 0 || refreshClicked}
                    showLoader={dataIsFetching}
                    data={weightRecordings}
                    showRowSelection={false}
                    selected={selectedWeights}
                    onSelectionClick={(selected) =>
                      handleOnSelectionClick(selected, updateSelectedWeights)
                    }
                    // height={height}
                    width={width}
                    onScroll={handleOnWeightTableScroll}
                    selectedIndex={selectedIndex}
                    sortBy={weightSortBy}
                    sortDirection={weightSortDirection}
                    me={me}
                    filterDate={
                      !moment(selectedWeightStartDate).isSame(this.beginningDate, 'day') ||
                      !moment(selectedWeightEndDate).isSame(this.today, 'day')
                    }
                    filterSource={selectedWeightSource.length > 0}
                    filterCategory={selectedWeightSeverity.length > 0}
                    filterBmi={
                      selectedWeightBmiStartValue !== MIN_BMI_VALUE ||
                      selectedWeightBmiEndValue !== MAX_BMI_VALUE
                    }
                    filterWeight={
                      selectedWeightWeightStartValue !== MIN_WEIGHT_VALUE ||
                      selectedWeightWeightEndValue !== MAX_WEIGHT_VALUE
                    }
                    handleOnFilterButtonClick={handleOnFilterButtonClick}
                    handleOnFilterChange={handleOnWeightFilterChange}
                    handleOnSortClick={handleOnWeightSortClick}
                    handleOnOutsidePopoverClick={() => handleOnFilterButtonClick(null)}
                    selectedPopover={selectedPopover}
                    selectedStartDate={selectedWeightStartDate}
                    selectedEndDate={selectedWeightEndDate}
                    dateRange={[this.beginningDate, this.today]}
                    showPatientColumns={false}
                    selectedSource={selectedWeightSource}
                    sourceList={getWeightSourceOptions()}
                    selectedSeverity={selectedWeightSeverity}
                    severityList={getWeightSeverityOptions().slice(1)}
                    selectedBmiStartValue={selectedWeightBmiStartValue}
                    selectedBmiEndValue={selectedWeightBmiEndValue}
                    bmiRange={[MIN_BMI_VALUE, MAX_BMI_VALUE]}
                    selectedWeightStartValue={selectedWeightWeightStartValue}
                    selectedWeightEndValue={selectedWeightWeightEndValue}
                    weightRange={[MIN_WEIGHT_VALUE, MAX_WEIGHT_VALUE]}
                  />
                </Paper>
              </div>
            ) : (
              <div
                className={styles.contentWrapper}
                style={{
                  height,
                }}
              >
                <EcgLoader />
              </div>
            );
          break;
        default:
          break;
      }

      return content;
    };

    // if (!checkRpmFeatureEnabled(me)) {
    //   return (
    //     <div data-page="patient-file" className={styles.patientFile}>
    //       <AccessDeniedContent title="Patient File" />
    //     </div>
    //   );
    // }

    return (
      <div data-page="patient-file" data-value={gaValue} className={styles.patientFile}>
        {me.isFetching ? (
          <EcgLoader />
        ) : (
          <React.Fragment>
            <Box style={{ minHeight: '100px', marginTop: '10px' }}>
              <Paper
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '10px 0 0',
                  margin: '15px 0',
                }}
              >
                {/* <Typography variant={'h5'} color={'#142A39'}>
                  Heart Health Data
                </Typography> */}
                <PageTabs>
                  <PageTab
                    isActive={selectedTab === PATIENT_FILE_TABS.EKGS}
                    handleOnClick={() => this.handleOnTabClick(PATIENT_FILE_TABS.EKGS)}
                    label="ECG"
                  />
                  <PageTab
                    isActive={selectedTab === PATIENT_FILE_TABS.BLOOD_PRESSURES}
                    handleOnClick={() => this.handleOnTabClick(PATIENT_FILE_TABS.BLOOD_PRESSURES)}
                    label="Blood Pressure"
                  />
                  <PageTab
                    isActive={selectedTab === PATIENT_FILE_TABS.WEIGHTS}
                    handleOnClick={() => this.handleOnTabClick(PATIENT_FILE_TABS.WEIGHTS)}
                    label="Weight"
                  />
                </PageTabs>
              </Paper>

              <section data-page={`patient-file-${selectedTab}`} className={styles.content}>
                {getTabContent(selectedTab)}
              </section>
            </Box>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default PatientFile;
