import { getToken } from 'auth/AuthUtilities';
import { ChatKardiaAPI, TeleKardiaAPI } from '../../Axios/axios';
// import { AUTH_TYPES } from 'constants/app';
// import { fetchApi } from '../../Utilities/ApiUrls';
import { getFullJid } from 'utilities/Utilities';
import * as actionTypes from './actionTypes';

export const getChatUsersStart = () => ({
  type: actionTypes.GET_CHAT_USERS_START,
});

export const getChatUsers = (chatData) => ({
  type: actionTypes.GET_CHAT_USERS_SUCCESS,
  payload: { data: chatData },
});

export const setChatLoader = (data) => ({
  type: actionTypes.SET_CHAT_LOADER,
  payload: data,
});

export const uploadFileStart = () => ({
  type: actionTypes.UPLOAD_FILE_START,
});

export const uploadFileSuccess = (data) => ({
  type: actionTypes.UPLOAD_FILE_SUCCESS,
  payload: data,
});

export const uploadFileFail = (error) => ({
  type: actionTypes.UPLOAD_FILE_FAIL,
  payload: error,
});

export const getLastMessageChatUser = (getHistory) => (dispatch, getState) => {
  const chatUserData = getState().chat.data;
  const userChatCreds = getState().user.chatProfile;
  chatUserData.map((d) => {
    let loader = `loader${d.chatUserId}`;
    dispatch(setChatLoader({ key: loader, value: true }));
    const callbackFn = () => {
      dispatch(setChatLoader({ key: loader, value: false }));
    };
    getHistory(getFullJid(userChatCreds.kccChatUserID),getFullJid(d.chatUserId), 1, callbackFn);

    return d
  });
};
export const getChatUserAction = (profileId) => (dispatch) => {
  dispatch(getChatUsersStart());
  TeleKardiaAPI.get(`/api/v1/chat/userInfo/${profileId}`, {
    headers: { Authorization: ` Bearer ${getToken()}` },
  })
    .then((res) => {
      let resultData = res.data.result;
      dispatch(getChatUsers(resultData));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getS3BucketData = (profileId, file) => {
  console.log(profileId, file)
};


//==================================//
// ==== file upload script =========//


const uploadedAssetInfo = (uploadData) => {
  return ChatKardiaAPI.get(`api/v1/asset/${uploadData}/info`)
  .then(res => {
    // console.log('res 1 - ', res.data.data)
    return res?.data?.data
  }).catch(err => console.log(err))
}
export const uploadStatusPoller = (uploadData) => {
  return ChatKardiaAPI.get(`api/v1/asset/${uploadData}/status`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: ` Bearer ${getToken()}`,
    }
  })
  .then(res => {
    if (res?.data?.data?.uploaded === true) {
      return uploadedAssetInfo(uploadData)
    }else {
      uploadStatusPoller(uploadData)
    }
  })
  .then(res => {
    return res
  })
  .catch(err => console.log(err))
}
export const upLoadFiles = file => dispatch => {
  const { type } = file
  const uploadPayload = {
    'Content-Type': type,
    file: file,
  };
  const formData = new FormData();
  for (const key in uploadPayload) {
    if (Object.hasOwnProperty.call(uploadPayload, key)) {
      formData.append(key, uploadPayload[key]);
    }
  }
  dispatch(uploadFileStart())
  return ChatKardiaAPI.post(`api/v1/asset/upload`, formData, {
    headers: {
      Authorization: ` Bearer ${getToken()}`,
      'Content-Type': 'multipart/form-data'
    }
  }).then(res => {
    const uploadData = res?.data?.data || ''
    uploadStatusPoller(uploadData.fileId)
    .then(() => {
      dispatch(uploadFileSuccess(uploadData.uri))
    })
  }).catch(err => {
    dispatch(uploadFileFail(err));
    console.log(err)
  })
}
//======== ends =========//