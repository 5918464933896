import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { commonStyles } from '../../styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Divider,
  Grid,
  Typography,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { getMemberProfileHealthDetails } from 'store/actions/memberProfile';
import { useParams } from 'react-router';
import Loading from 'components/Shared/EcgLoader';
//icons
import Fruit from 'assets/images/icons/profile/fruit.svg';
import Beat from 'assets/images/icons/profile/beat.svg';
import Doctor from 'assets/images/icons/profile/doctor.svg';
import PadIcon from 'assets/images/icons/profile/pcp.svg';
import Pill from 'assets/images/icons/profile/pill.svg';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import { getTimezoneName } from 'utilities/Utilities';
import moment from 'moment';
import { colors, fonts, fontSizes } from 'style/styleSettings';
import InputFieldComponent from 'components/Dashboard/SessionComponents/InputFieldComponent';
import HealthDetailsPCP from './pcp';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
    '& .MuiGrid-item': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      '& .MuiDivider-root': {
        marginTop: '16px',
        color: '#142A39',
      },
    },
    '& .MuiSelect-outlined': {
      border: '1px solid rgba(20, 42, 57, 0.16)',
      padding: '10px',
    },
    '& .MuiTypography-root': {
      fontFamily: 'Work Sans',
    },
  },
  divWrapperOverride: {
    // paddingTop: '32px',
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding:"16px",
    marginLeft: '10px',
    '& .MuiBox-root': {
      width: '25%',
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  scheduleRow: {
    marginTop: '24px !important',
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiButton-root': {
      border: '1px solid #142A39',
      borderRadius: '4px',
      fontFamily: 'Work Sans',
      color: '#142A39',
      backgroundColor: 'white',
      fontWeight: 600,
    },
  },
  pcpInputTitle: {
    marginBottom: 8,
    marginLeft:"0.7rem"
  },
  pcpbody2: {
    marginTop: 16,
  },
  formControl: {
    width: '100%',
  },
  customInputStyle: {
    display: 'flex !important',
    flexDirection: 'column',
  },
}));

const HealthDetails = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const commonClasses = commonStyles();
  const { memberId } = useParams();

  const { healthData, loading } = useSelector((state) => state.memberProfile);
  const coachId = useSelector((state) => state.user.profile.id);
  const teamId = useSelector((state) => state.user.team.id);

  const [data, setData] = useState([]);

  //timezone related data
  const timezoneValue = getTimezoneName();
  const offsetInMinutes = new Date().getTimezoneOffset();
  useEffect(() => {
    dispatch(
      getMemberProfileHealthDetails(teamId, coachId, memberId, offsetInMinutes, timezoneValue),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //prepare the data\
  let constants = {
    atrialFibrillationHistory: 'Atrial Fibrillation History',
    medications: 'Medications',
    careDelivery: 'Care Delivery',
    lifeStyleDetails: 'Lifestyle Details',
    medicalHistory: 'Medical History',
    symptoms: 'Symptoms',
    episodeManagement: 'Episode Management',
    overallManagement: 'Overall Management',
    triggers: 'Triggers',
    episodes: 'Episodes', //not there
    duration: 'Duration', //not there
    heartMeds: 'Heart Meds',
    medicationList: 'Medication list',
    adherence: 'Adherence',
    newMeds: 'New Meds',
    hospitalAdmissions: 'Hospital Admissions',
    providers: 'Providers',
    emergencyRoomVisits: 'Emergency room visits',
    surgicalHistory: 'Surgical history',
    heartConditions: 'Heart conditions',
    familyHistory: 'Family History',
    otherMedicalConditions: 'Other Medical conditions',
    pregnant: 'Pregnant',
    sleep: 'Sleep',
    nutrition: 'Nutrition',
    wellbeing: 'Overall Wellbeing',
    physicalLimitations: 'Physical limitations',
    exercise: 'Exercise',
    behavior: 'Behaviour',
    title: null,
  };
  let icons = {
    atrialFibrillationHistory: (() => <Beat />)(),
    medications: (() => <Pill />)(),
    careDelivery: (() => <Doctor />)(),
    lifeStyleDetails: (() => <Fruit />)(),
    medicalHistory: (() => <AddCircleIcon />)(),
  };
  useEffect(() => {
    if (healthData && !loading) {
      const {
        lifestyleDetails,
        atrialFibrillationHistory,
        medicalHistory,
        careDelivery,
        medications,
      } = healthData;
      lifestyleDetails.title = 'lifeStyleDetails';
      atrialFibrillationHistory.title = 'atrialFibrillationHistory';
      medications.title = 'medications';
      medicalHistory.title = 'medicalHistory';
      careDelivery.title = 'careDelivery';
      let d = [
        lifestyleDetails,
        atrialFibrillationHistory,
        medicalHistory,
        careDelivery,
        medications,
      ];
      setData(d);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [healthData]);

  //pcp demo data
  const pcpDemoData = [
    {
      type: 'Cardiologist',
      name: 'Dr. Aurdre Washington',
      phno: '586-358-3684',
      email: 'droffice@gmail.com',
      fax: '953-575-1268',
      address: '4974 Imaginary Rd Somewhere, OR 35623',
    },
    {
      type: 'Cardiologist 2',
      name: 'Dr. George Washington',
      phno: '586-358-3684',
      email: 'droffice@gmail.com',
      fax: '953-575-1268',
      address: '4974 Imaginary Rd Somewhere, OR 35623',
    },
  ];
  return (
    <div className={classes.root}>
      {/* All the components are the same as below, will be making this modular and re use it with data */}
      {loading && <Loading />}
      {data &&
        !loading &&
        data.map((d, index) => (
          <Box
            className={classnames(commonClasses.divWrapper, classes.divWrapperOverride)}
            key={index}
          >
            <Box className={classes.headerWrapper} style = {{marginRight:32}}>
              <Typography variant="h6">{constants[d.title]}</Typography>
              <Box>
                <Typography variant="subtitle1">
                  Last updated:{' '}
                  {d.updatedTime ? moment(d.updatedDateTime).format('MM/DD/YYYY') : '-'}
                </Typography>
                {icons[d.title]}
              </Box>
            </Box>
            <hr style = {{marginTop:0,border:"1px solid #B4BBC0" }}></hr>
            <Box style={{ margin: '16px 10px',padding:"16px" }}>
              <Grid container spacing={2}>
                {Object.entries(d).map((e, index) => {
                  if (constants[e[0]])
                    return (
                      <Grid item xs={4} key={index}>
                        <Typography variant="body1">{constants[e[0]]}</Typography>
                        {/* {console.log(constants[e[0]], typeof e[1], e[1])} */}
                        {typeof e[1] === 'object' ? (
                          e[1].map((m) => (
                            <Typography key={index} variant="body2">
                              {m}
                            </Typography>
                          ))
                        ) : typeof e[1] === 'string' ? (
                          <Typography variant="body2">{e[1]}</Typography>
                        ) : (
                          <Typography variant="body2" style={{ color: 'grey' }}>
                            No data
                          </Typography>
                        )}

                        {(Object.values(e[1]).length === 0 || !e[1]) && (
                          <Typography variant="body2" style={{ color: 'grey' }}>
                            No data
                          </Typography>
                        )}
                        <Divider className={classes.divider} />
                      </Grid>
                    );
                })}
              </Grid>
              <div className={classes.scheduleRow}>
                <Button variant="contained">Previous data</Button>
              </div>
            </Box>
            {/* <div className={commonClasses.overlay}>Coming Soon!</div> */}
          </Box>
        ))}
      {data && !loading && <HealthDetailsPCP classes={classes} memberId={memberId} />}
    </div>
  );
};

export default HealthDetails;
