import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Grid,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';
import PhoneIcon from '@material-ui/icons/Phone';
import VideocamIcon from '@material-ui/icons/Videocam';
import { GoAPI } from 'Axios/axios';
// import Loading from 'components/Loading';
import styles from '../../memberProfile.module.css';
import classnames from 'classnames';
import { programStyles } from './style';
import { commonStyles } from 'screens/coach/MemberProfile/styles';
import { StatusColumn } from 'screens/coach/MemberList/components/StatusColumn';
import EKGPreview from 'components/chart/EKGPreview';
import { getToken } from 'auth/AuthUtilities';
import SkeletonLoader from 'components/Loading/SkeletonLoader';

const cssbutton = {
  position: 'absolute',
  top: '20%',
  left: '250px',
  // right: 0,
  margin: '0 auto',
  height: '30px',
  // width: '86px',
  display: '-webkit-box' && '-moz-box' && '-ms-flexbox' && '-webkit-flex' && 'flex',
  justify: 'space-around',
  alignItems: 'center',
  border: '1px solid #EFEFEF',
  background: '#F2F4F8',
  paddingLeft: '5px',
  paddingRight: '5px',
  '--box-shadow': '0px 3px 4px 0px light-grey',
  '--border-bottom-left-radius': '8px',
  '--border-bottom-right-radius': '8px',
};

function ThisSession() {
  const classes = programStyles();
  const commonClasses = commonStyles();

  const [data, setData] = useState({});
  const [ekgData, setEKGData] = useState({});

  const patientData = useSelector((state) => state.appointments.patientDetails);

  useEffect(() => {
    if (Object.keys(patientData).length > 0) {
      setData(patientData);
      if (patientData?.ekgResults && patientData?.ekgResults[0]?.ekgRecordId !== '') {
        loadEKG(patientData?.ekgResults[0]?.ekgRecordId);
      }
    }
  }, [patientData]);

  const loadEKG = (recordingId) => {
    GoAPI.get(`/i/v1/recordings/${recordingId}`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
      .then((res) => {
        setEKGData(res.data);
      })
      .catch((err) => {
        console.log(err);
        setEKGData({});
      });
  };

  return (
    <div className={classes.root}>
      <Box
        className={classnames(commonClasses.divWrapper, classes.divWrapperOverride)}
        style={{ marginTop: '20px' }}
      >
        <Box className={classes.divHeaderWrapper}>
          <Typography variant="h5">Session</Typography>
        </Box>
        <Box className={commonClasses.divContentWrapper}>
          <Grid container className={styles.tableRowHeading}>
            <Grid item lg={4} md={4}>
              <Typography variant="body2">Date</Typography>
              <SkeletonLoader isloading={Object.keys(data).length === 0}>
                <Typography variant="body1">
                  {moment(data?.patientAppointment?.appointmentBookedDate).format('ll') || '--'}
                </Typography>
              </SkeletonLoader>
            </Grid>
            <Grid item lg={4} md={4}>
              <Typography variant="body2">Time</Typography>
              <SkeletonLoader isloading={Object.keys(data).length === 0}>
                <Typography variant="body1">{`${moment(
                  data?.patientAppointment?.scheduledStartTime,
                ).format('LT')} -
                          ${moment(data?.patientAppointment?.scheduledEndTime).format(
                            'LT',
                          )} (20 mins)`}</Typography>
              </SkeletonLoader>
            </Grid>
            <Grid item lg={4} md={4} className={classes.callTypeSection}>
              {data &&
                (data?.patientAppointment?.appointmentChannelType === 'Video Call' ? (
                  <VideocamIcon />
                ) : (
                  <PhoneIcon />
                ))}

              <div className={classes.distanceFromIcon}>
                <Typography variant="body2">Method of Session</Typography>
                <SkeletonLoader isloading={Object.keys(data).length === 0}>
                  <Typography variant="body1">
                    {data?.patientAppointment?.appointmentChannelType}
                  </Typography>
                </SkeletonLoader>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">Reason for Appointment</Typography>
              <SkeletonLoader isloading={Object.keys(data).length === 0}>
                <Typography variant="body1">{data?.visitReason || '--'}</Typography>
              </SkeletonLoader>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className={classnames(commonClasses.divWrapper, classes.divWrapperOverride)}>
        <Box>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow hover>
                  <TableCell style={{ width: '60%' }}>
                    <span className={classes.tableHeaderText}>Symptoms</span>
                  </TableCell>
                  <TableCell>
                    <span className={classes.tableHeaderText}>Severity</span>
                  </TableCell>
                  <TableCell>
                    <span className={classes.tableHeaderText}>Duration</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              {Object.keys(data).length === 0 ? (
                <>
                  <Skeleton animation="wave" height={10} width="80%" style={{ marginTop: 20 }} />
                  <Skeleton animation="wave" height={10} width="80%" style={{ marginTop: 20 }} />
                </>
              ) : (
                <TableBody>
                  {data?.symptoms.map((row, index) => (
                    <TableRow className={classes.tableRowStyle} key={index}>
                      <TableCell style={{ width: '60%', fontWeight: 700, fontSize: '16px' }}>
                        {row.name}
                      </TableCell>
                      <TableCell>
                        <StatusColumn data={row.severity} />
                      </TableCell>
                      <TableCell>{row.duration}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Box className={classnames(commonClasses.divWrapper, classes.divWrapperOverride)}>
        <Box className={classes.divHeaderWrapper}>
          <Typography variant="h5">EKG Reading</Typography>
          <Box className={classes.divHeaderWrapper_status}></Box>
        </Box>
        {Object.keys(data).length === 0 ? (
          <>
            <Skeleton animation="wave" height={10} width="20%" style={{ marginTop: 20 }} />
            <Skeleton animation="wave" height={10} width="40%" style={{ marginTop: 20 }} />
          </>
        ) : (
          <Box className={commonClasses.divContentWrapper}>
            <Grid container className={styles.tableRowHeading}>
              <>
                {data?.ekgResults[0]?.ekgRecordId === '' ? (
                  <Typography variant="body2" style={{ color: 'red', marginTop: '20px' }}>
                    EKG is not provided...
                  </Typography>
                ) : (
                  <>
                    {Object.keys(ekgData).length === 0 ? (
                      <Skeleton
                        animation="wave"
                        height={10}
                        width="40%"
                        style={{ marginBottom: 6 }}
                      />
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          color="default"
                          style={cssbutton}
                          startIcon={
                            <SvgIcon
                              style={{
                                margin: '5px',
                                width: '25px',
                                height: '25px',
                              }}
                              // color="#ffff"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#d0021b"
                                d="M17,0c-1.9,0-3.7,0.8-5,2.1C10.7,0.8,8.9,0,7,0C3.1,0,0,3.1,0,7c0,6.4,10.9,15.4,11.4,15.8 c0.2,0.2,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2C13.1,22.4,24,13.4,24,7C24,3.1,20.9,0,17,0z"
                              />
                            </SvgIcon>
                          }
                        >
                          <Grid container direction="row" style={{ alignItems: 'center' }}>
                            <Grid item className={classes.bpm}>
                              {ekgData?.bpm ? ekgData.bpm : '-'}
                            </Grid>
                            <Grid item className={classes.bpmspan}>
                              bpm
                            </Grid>
                          </Grid>
                        </Button>
                        <Grid item xs={12}>
                          <EKGPreview width={356} height={100} previewData={ekgData?.previewData} />
                        </Grid>

                        <Grid item lg={6} md={6}>
                          <Typography variant="body1">Tags</Typography>
                          {ekgData?.tags.map((item, i) => (
                            <div key={i}>
                              <Typography variant="body2">{item}</Typography>
                            </div>
                          )) || '--'}
                        </Grid>
                        <Grid item lg={6} md={6}>
                          <Typography variant="body1">EKG Details</Typography>
                          <Typography variant="body2">
                            Heart Rate: {ekgData?.bpm || '--'}
                          </Typography>
                          <Typography variant="body2">
                            Recorded Date: {moment(ekgData?.recordedAt).format('l') || '--'}
                          </Typography>
                          <Typography variant="body2">
                            Recorded Time: {moment(ekgData?.recordedAt).format('LT') || '--'}
                          </Typography>
                        </Grid>
                        {/* <Grid item lg={4} md={4}>
                          <Typography variant="body1">QT Analysis</Typography>
                          {ekgData?.is6l ? (
                            '--'
                          ) : (
                            <Typography variant="body2">
                              Only available for 6L recordings.{' '}
                            </Typography>
                          )}
                        </Grid> */}
                      </>
                    )}
                  </>
                )}
              </>
            </Grid>
          </Box>
        )}
      </Box>
      <Box className={classnames(commonClasses.divWrapper, classes.divWrapperOverride)}>
        <Box className={classes.divHeaderWrapper}>
          <Typography variant="h5">Additional Information</Typography>
          <Box className={classes.divHeaderWrapper_status}></Box>
        </Box>
        <Box className={commonClasses.divContentWrapper}>
          <Grid container className={styles.tableRowHeading}>
            <Grid item lg={6} md={6} sm={6}>
              <Typography variant="body1">Medical History</Typography>
              <SkeletonLoader isloading={Object.keys(data).length === 0}>
                <Typography variant="body2">{data?.medicalHistory || '--'}</Typography>
              </SkeletonLoader>
            </Grid>

            <Grid item lg={6} md={6} sm={6}>
              <Typography variant="body1">Family History</Typography>
              <SkeletonLoader isloading={Object.keys(data).length === 0}>
                <Typography variant="body2">{data?.familyMedicalHistory || '--'}</Typography>
              </SkeletonLoader>
            </Grid>

            <Grid item lg={6} md={6} sm={6}>
              <Typography variant="body1">Social History</Typography>
              <SkeletonLoader isloading={Object.keys(data).length === 0}>
                <Typography variant="body2">{data?.lifeStyleHistoryString || '--'}</Typography>
              </SkeletonLoader>
            </Grid>

            <Grid item lg={6} md={6}>
              <Typography variant="body1">Member Notes</Typography>
              <SkeletonLoader isloading={Object.keys(data).length === 0}>
                <Typography variant="body2">{data?.patientNotes || '--'}</Typography>
              </SkeletonLoader>
            </Grid>
            <Grid item lg={6} md={6} sm={6}>
              <Typography variant="body1">Allergies</Typography>
              <SkeletonLoader isloading={Object.keys(data).length === 0}>
                <Typography variant="body2">{data?.allergyDetails || '--'}</Typography>
              </SkeletonLoader>
            </Grid>
            <Grid item lg={6} md={6} sm={6}>
              <Typography variant="body1">Surgeries</Typography>
              <SkeletonLoader isloading={Object.keys(data).length === 0}>
                <Typography variant="body2">{data?.surgeryDetails || '--'}</Typography>
              </SkeletonLoader>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
}

export default ThisSession;
