import {
  Button,
  Grid,
  makeStyles,
  Input,
  // createMuiTheme,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import React, { useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getSearchedMember } from 'store/actions';
import { AUTH_TYPE, COACH_ROUTES, DOCTOR_ROUTES, ROUTES } from 'constants/app';
import { AppointmentStatusTypes } from 'utilities/Constants';

const newStyles = makeStyles((theme) => ({
  input: {
    color: '#142A39 !important',
    width: '90%',
    fontFamily: 'Work Sans !important',
    marginLeft: '25px',
    '& input::placeholder': {
      fontFamily: 'Work Sans !important',
      fontWeight: 400,
      color: '#142A39 !important',
      opacity: 1,
      textAlign: 'left',
      padding: '5px',
    },
    '& .Mui-focused': {
      borderColor: '#fff',
    },
    border: 'unset',
  },

  listbox: {
    width: 'auto',
    height: '80%',
    overflowY: 'scroll',
    marginTop: 0,
    marginLeft: '20px',
    padding: '10px',
    zIndex: 100,

    position: 'absolute',
    listStyle: 'none',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    border: '1px solid rgba(0,0,0,.25)',
    '& li': {
      fontFamily: 'Work Sans',
      margin: 2,
    },
    '& li[data-focus="true"]': {
      backgroundColor: '#C1C1C1',
      color: 'white',
      cursor: 'pointer',
      fontFamily: 'Work Sans',
    },
    '& li:active': {
      backgroundColor: '#2977f5',
      color: 'white',
    },
  },
}));

const UseAutocomplete = () => {
  const classes = newStyles();
  const [appId, setAppId] = useState(' ');
  const userType = useSelector((state) => state?.user.chatProfile.userType);
  const searchData = useSelector((state) => state.memberList.searchMemberData);
  const upcomingSession = useSelector((state) => state.appointments.clinicianAppointments);

  const memberData = searchData?.reduce((acc, cur) => {
    const datas = { title: `${cur.lastName} ${cur.firstName}`, id: cur.id };
    return [...acc, datas];
  }, []);

  const {
    getRootProps,
    // getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    value,
    groupedOptions,
  } = useAutocomplete({
    id: 'use-autocomplete-demo',
    options: memberData,
    getOptionLabel: (option) => option.title,
  });

  const history = useHistory();
  useEffect(() => {
    if (value) {
      if (userType === AUTH_TYPE.COACH) {
        history.push(`${COACH_ROUTES.MEMBER_PROFILE}/${value?.id}`);
      } else {
        const isSessionAvailable = upcomingSession.filter((item) => {
          return (
            [
              AppointmentStatusTypes.Scheduled,
              AppointmentStatusTypes.InProgress,
              AppointmentStatusTypes.MemberInWaitingRoom,
              AppointmentStatusTypes.Encounter_Summary_Preparation,
            ].includes(item.status) && item.patientId === value.id
          );
        });
        // console.log(isSessionAvailable);
        if (isSessionAvailable.length > 0) {
          history.push(`${DOCTOR_ROUTES.TELEKARDIA_PATIENT_PROFILE}/${value?.id}/${appId}`);
        } else {
          history.push(`${DOCTOR_ROUTES.TELEKARDIA_PATIENT_PROFILE}/${value?.id}/${' '}`);
        }
      }
    }
    return () => setAppId(' ');
  }, [history, value, upcomingSession]);

  return (
    <div>
      <div
        {...getRootProps()}
        style={{ position: 'relative', display: 'flex', alignItems: 'center' }}
      >
        <SearchIcon style={{ position: 'absolute' }} />
        <div className={classes.inputRoot}>
          <Input
            disableUnderline={true}
            placeholder="Search Member..."
            className={classes.input}
            {...getInputProps()}
          />
        </div>
      </div>
      {groupedOptions.length > 0 ? (
        <ul className={classes.listbox} {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <li key={index} {...getOptionProps({ option, index })}>
              {option.title}
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

const useStyles = makeStyles({
  topbar: {
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .MuiButton-label': {
      fontFamily: 'Work Sans',
    },
  },
});

function GlobalSearch() {
  const dispatch = useDispatch();
  // const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    dispatch(getSearchedMember());
  }, [dispatch]);
  // const onSearchClick = () => {};
  return (
    <Grid alignItems="center" container className={classes.topbar}>
      <Grid item xs={7} style={{ borderBottom: '1px solid #000' }}>
        <UseAutocomplete />
      </Grid>
      <Grid item xs={5}>
        {/* <Button
          onClick={onSearchClick}
          style={{
            fontFamily: 'Work Sans !important',
          }}
        >
          Search
        </Button> */}
      </Grid>
    </Grid>
  );
}

export default GlobalSearch;
