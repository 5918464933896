import MemberProfile from './MemberProfile';
import { connect } from 'react-redux';
import { getMemberProfileSessionLogData, upLoadFiles, uploadFileSuccess } from 'store/actions';

const mapActionCreators = {
  getMemberProfileSessionLogData,
  upLoadFiles,
  uploadFileSuccess,
};

const mapStateToProps = (state) => ({
  getMemberProfileSessionLogData: getMemberProfileSessionLogData,
});

export default connect(mapStateToProps, mapActionCreators)(MemberProfile);
