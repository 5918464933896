export const USER_LOGOUT = 'USER_LOGOUT';
export const GET_AVAILABILITY_START = 'GET_AVAILABILITY_START';
export const GET_AVAILABILITY_SUCCESS = 'GET_AVAILABILITY_SUCCESS';
export const GET_AVAILABILITY_FAIL = 'GET_AVAILABILITY_FAIL';

export const ADD_AVAILABILITY_START = 'ADD_AVAILABILITY_START';
export const ADD_AVAILABILITY_SUCCESS = 'ADD_AVAILABILITY_SUCCESS';
export const ADD_AVAILABILITY_FAIL = 'ADD_AVAILABILITY_FAIL';

export const UPDATE_AVAILABILITY_START = 'UPDATE_AVAILABILITY_START';
export const UPDATE_AVAILABILITY_SUCCESS = 'UPDATE_AVAILABILITY_SUCCESS';
export const UPDATE_AVAILABILITY_FAIL = 'UPDATE_AVAILABILITY_FAIL';

export const DELETE_AVAILABILITY_START = 'DELETE_AVAILABILITY_START';
export const DELETE_AVAILABILITY_SUCCESS = 'DELETE_AVAILABILITY_SUCCESS';
export const DELETE_AVAILABILITY_FAIL = 'DELETE_AVAILABILITY_FAIL';

export const GET_USER_START = 'GET_USER_START';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';
export const GET_USER_CHAT_CREDS = 'GET_USER_CHAT_CREDS';
export const BACKEND_DOWN = 'BACKEND_DOWN';
export const SET_AUTHENTICATION = 'SET_AUTHENTICATION';
export const SET_LOGIN = 'SET_LOGIN';

export const GET_APPOINTMENTS_START = 'GET_APPOINTMENTS_START';
export const GET_APPOINTMENTS_SUCCESS = 'GET_APPOINTMENTS_SUCCESS';
export const GET_APPOINTMENTS_FAIL = 'GET_APPOINTMENTS_FAIL';

export const DELETE_APPOINTMENT_START = 'DELETE_APPOINTMENT_START';
export const DELETE_APPOINTMENT_SUCCESS = 'DELETE_APPOINTMENT_SUCCESS';
export const DELETE_APPOINTMENT_FAIL = 'DELETE_APPOINTMENT_FAIL';

export const GET_PATIENTDETAILS_START = 'GET_PATIENTDETAILS_START';
export const GET_PATIENTDETAILS_SUCCESS = 'GET_PATIENTDETAILS_SUCCESS';
export const GET_PATIENTDETAILS_FAIL = 'GET_PATIENTDETAILS_FAIL';

export const GET_RECORDING_START = 'GET_RECORDING_START';
export const GET_RECORDING_SUCCESS = 'GET_RECORDING_SUCCESS';
export const GET_RECORDING_FAIL = 'GET_RECORDING_FAIL';

export const GET_CHAT_USERS_START = 'GET_CHAT_USERS_START';
export const GET_CHAT_USERS_SUCCESS = 'GET_CHAT_USERS_SUCCESS';
export const GET_CHAT_USERS_FAIL = 'GET_CHAT_USERS_FAIL';
export const SET_CHAT_LOADER = 'SET_CHAT_LOADER';

//==== file upload ====//
export const UPLOAD_FILE_START = 'UPLOAD_FILE_START';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAIL = 'UPLOAD_FILE_FAIL';
export const IS_PENDING_REQUEST = 'IS_PENDING_REQUEST';

export const GET_RECORDING_SAMPLE_START = 'GET_RECORDING_SAMPLE_START';
export const GET_RECORDING_SAMPLE_SUCCESS = 'GET_RECORDING_SAMPLE_SUCCESS';
export const GET_RECORDING_SAMPLE_FAIL = 'GET_RECORDING_SAMPLE_FAIL';
export const GET_CLINICIANS_APPOINTMENTS_START = 'GET_CLINICIANS_APPOINTMENTS_START';
export const GET_CLINICIANS_APPOINTMENTS_SUCCESS = 'GET_CLINICIANS_APPOINTMENTS_SUCCESS';
export const GET_CLINICIANS_APPOINTMENTS_FAIL = 'GET_CLINICIANS_APPOINTMENTS_FAIL';

export const GET_CLINICIAN_AVAILABILITY_START = ' GET_CLINICIAN_AVAILABILITY_START';
export const GET_CLINICIAN_AVAILABILITY_SUCCESS = ' GET_CLINICIAN_AVAILABILITY_SUCCESS';
export const GET_CLINICIAN_AVAILABILITY_FAIL = ' GET_CLINICIAN_AVAILABILITY_FAIL';

export const RESET_PATIENT_DETAILS_SUCCESS = ' RESET_PATIENT_DETAILS_SUCCESS';

export const TOGGLE_NAVIGATION_SIDEBAR = `TOGGLE_NAVIGATION_SIDEBAR`;
export const ERROR_LOGS = 'ERROR_LOGS';

//======STATUS COUNT======
export const GET_MEMBER_STATUS_COUNT_LOADING = 'GET_MEMBER_STATUS_COUNT_LOADING';
export const GET_MEMBER_STATUS_COUNT_SUCCESS = 'GET_MEMBER_STATUS_COUNT_SUCCESS';
export const GET_MEMBER_STATUS_COUNT_FAIL = 'GET_MEMBER_STATUS_COUNT_FAIL';
//=======SESSIONS========
export const GET_ADMIN_SESSIONS_LOADING = 'GET_ADMIN_SESSIONS_LOADING';
export const GET_ADMIN_SESSIONS_SUCCESS = 'GET_ADMIN_SESSIONS_SUCCESS';
export const GET_ADMIN_SESSIONS_FAIL = 'GET_ADMIN_SESSIONS_FAIL';
export const SET_SESSIONS_FILTER_DATA = 'SET_SESSIONS_FILTER_DATA';
export const SET_SESSIONS_SORT_DATA = 'SET_SESSIONS_SORT_DATA';

//==== memberlist ====//
export const GET_MEMBERS_LIST = `GET_MEMBERS_LIST`;
export const GET_MEMBERS_LIST_NEXT_SESSION_DATA = `GET_MEMBERS_LIST_NEXT_SESSION_DATA`;
export const GET_SEARCH_MEMBER = `GET_SEARCH_MEMBER`;
export const SET_FILTER_DATA = `SET_FILTER_DATA`;

//========Member profile=======

//----------General action types on member profile------------
export const GET_MP_LOADING = `GET_MP_LOADING`;
export const GET_MP_FAIL = `GET_MP_FAIL`;
export const GET_MP_PROGRAM_SUCCESS = `GET_MP_PROGRAM_SUCCESS`;
export const GET_MP_HEALTH_SUCCESS = `GET_MP_HEALTH_SUCCESS`;

//pcp

export const GET_MP_HEALTH_PCP_SUCCESS = `GET_MP_HEALTH_PCP_SUCCESS`;
export const GET_MP_HEALTH_PCP_LOADING = `GET_MP_HEALTH_PCP_LOADING`;
export const GET_MP_HEALTH_PCP_FAIL = `GET_MP_HEALTH_PCP_FAIL`;

export const GET_MP_HEALTH_PCP_METADATA_SUCCESS = `GET_MP_HEALTH_PCP_METADATA_SUCCESS`;
export const GET_MP_HEALTH_PCP_METADATA_LOADING = `GET_MP_HEALTH_PCP_METADATA_LOADING`;
export const GET_MP_HEALTH_PCP_METADATA_FAIL = `GET_MP_HEALTH_PCP_METADATA_FAIL`;

export const GET_MP_HEALTH_PCP_UPDATE_SUCCESS = `GET_MP_HEALTH_PCP_UPDATE_SUCCESS`;
export const GET_MP_HEALTH_PCP_UPDATE_LOADING = `GET_MP_HEALTH_PCP_UPDATE_LOADING`;
export const GET_MP_HEALTH_PCP_UPDATE_FAIL = `GET_MP_HEALTH_PCP_UPDATE_FAIL`;

export const GET_MP_DOCUMENTATION_LOADING = `GET_MP_DOCUMENTATION_LOADING`;
export const GET_MP_DOCUMENTATION_SUCCESS = `GET_MP_DOCUMENTATION_SUCCESS`;
export const GET_MP_DOCUMENTATION_FAILURE = `GET_MP_DOCUMENTATION_FAILURE`;
export const SET_DOCUMENTATION_FILTER_DATA = 'SET_DOCUMENTATION_FILTER_DATA';

export const NOTE_ADD_LOADING = `NOTE_ADD_LOADING`;
export const NOTE_ADD_SUCCESS = `NOTE_ADD_SUCCESS`;
export const NOTE_ADD_FAILURE = `NOTE_ADD_FAILURE`;

export const NOTE_METADATA_LOADING = `NOTE_METADATA_LOADING`;
export const NOTE_METADATA_SUCCESS = `NOTE_METADATA_SUCCESS`;
export const NOTE_METADATA_FAILURE = `NOTE_METADATA_FAILURE`;
export const NOTE_FILTER_METADATA_SUCCESS = `NOTE_FILTER_METADATA_SUCCESS`;
export const NOTE_FILTER_METADATA_FAIL = `NOTE_FILTER_METADATA_FAIL`;
//--------------------------------------------------------------

//-----------Program progress actions in program tab------------
export const GET_MP_PROGRAM_PP_LOADING = `GET_MP_PROGRAM_PP_LOADING`;
export const GET_MP_PROGRAM_PP_SUCCESS = `GET_MP_PROGRAM_PP_SUCCESS`;
export const GET_MP_PROGRAM_PP_FAIL = `GET_MP_PROGRAM_PP_FAIL`;
//--------------------------------------------------------------
//-----------Program progress actions in program tab------------
export const GET_MP_PROGRAM_PF_LOADING = `GET_MP_PROGRAM_PF_LOADING`;
export const GET_MP_PROGRAM_PF_SUCCESS = `GET_MP_PROGRAM_PF_SUCCESS`;
export const GET_MP_PROGRAM_PF_FAIL = `GET_MP_PROGRAM_PF_FAIL`;
//--------------------------------------------------------------

//========END Member profile=======

//==== Session Screen =====//
export const GET_SESSION_PATIENT_DETAILS = 'GET_SESSION_PATIENT_DETAILS';
export const GET_PREVIOUS_SESSION_DETAILS = 'GET_PREVIOUS_SESSION_DETAILS';
export const ADD_HEART_HABIT = 'ADD_HEART_HABIT';
export const GET_HEART_HABIT = 'GET_HEART_HABIT';
export const EDIT_HEART_HABIT_LOADING = 'EDIT_HEART_HABIT_LOADING';
export const EDIT_HEART_HABIT = 'EDIT_HEART_HABIT_SUCCESS';
export const EDIT_HEART_HABIT_FAIL = 'EDIT_HEART_HABIT_FAIL';
export const DELETE_HEART_HABIT = 'DELETE_HEART_HABIT';
export const GET_PROGRAM_FOCUS = 'GET_PROGRAM_FOCUS';
export const EDIT_PROGRAM_FOCUS = 'EDIT_PROGRAM_FOCUS';

//===== api error handler ======//
export const VIDEOCALL_STARTED = 'VIDEOCALL_STARTED';
//========//

// adhoc call
export const START_PHONE_CALL = 'START_PHONE_CALL';
export const CLOSE_PHONE_CALL = 'CLOSE_PHONE_CALL';
export const CHANGE_CALL_STATUS = 'CHANGE_CALL_STATUS';
export const NOTES_UPDATE = 'NOTES_UPDATE';

//escalation
export const TOTAL_ALERT = 'TOTAL_ALERT';
