/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import classes from './Chat.module.css';
import moment from 'moment';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import AttachmentIcon from '@material-ui/icons/Attachment';
// eslint-disable-next-line import/no-unresolved
import Iframe from 'react-iframe-click';
import Documentation from 'components/Common/ModalView/Documentation';
/**
 * Display message on chat screen
 * @param {Object} message - contain message data like text, time.
 * @param {string} position -  msg position Left or Right
 * @returns {ReactElement}
 */

const MessageBox = ({
  message,
  setChatLoaded,
  position = 'Right',
  memberName,
  memberId,
  checkForHyperLink,
}) => {
  const fileBaseUrl = process.env.REACT_APP_FILE_RENDER_URL;
  const imageDownloadUrl = process.env.REACT_APP_CHAT_KARDIA_SERVICE;
  const [chatMessage, setChatMessage] = useState('');
  const [iamgeUrl,setImageUrl] = useState('');
  const [openDocument, setopenDocument] = useState({
    open: false,
    file: null,
  });

  useEffect(() => {
    setChatLoaded(true);
    return () => setChatLoaded(false);
  }, []);
  useEffect(() => {
    if (message?.text.includes('asset://')) {
      setChatMessage(`${fileBaseUrl}${message.text.split('//')[1].split('?')[0]}`);
      setImageUrl(`${imageDownloadUrl}api/v1/asset/${message.text.split('//')[1].split('?')[0]}`)
    } else {
      setChatMessage(message.text);
      setImageUrl(message.text);
    }
  }, [message]);

  const toDataURL = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          }),
      );

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const getUpdatedObjectFile = (url) => {
    // let fileArr = [];
    toDataURL(url + "/download").then((dataUrl) => {
      // console.log('Here is Base64 Url', dataUrl);\
      var fileData = dataURLtoFile(dataUrl, url.slice(-12));
      console.log('Here is JavaScript File Object', fileData);

      setopenDocument({
        open: true,
        file: fileData,
      });
    });
  };

  //open add notes modal on click of attachment icon
  const openDocuments = async (file) => {
    console.log(file)
    let fileObject = getUpdatedObjectFile(file);
  };
  const handlePDFile = (pdf) => {
    console.log('PDFFF');
    window.open(pdf);
  };
  return (
    <>
      <div className={classes[`message${position}`]}>
        {false ? (
          <div className={classes.imgBox}>
            <img src="https://miro.medium.com/max/640/1*Mom9A9c2MVqI-V-KPz14ag.png" alt="message" />
          </div>
        ) : (
          <div className={classes.messageContainer}>
            {position === 'Left' && (
              <div className={classes.userimg}>{memberName[0].toUpperCase()}</div>
            )}
            {
              // when chat message contains files
              chatMessage && chatMessage.includes(fileBaseUrl) ? (
                chatMessage.includes('mp4') || chatMessage.includes('mov') ? (
                  <div className={classes[`imageMessageInnerContainer`]}>
                    <AttachmentIcon
                      onClick={() => {
                        openDocuments(iamgeUrl);
                        // saveAs(chatMessage);
                      }}
                      className={classes['downloadIcon']}
                    />
                    <video
                      width="auto"
                      height="320px"
                      controls
                      poster={
                        chatMessage.includes('mp4')
                          ? chatMessage.replace('.mp4', '-tmb.jpg')
                          : chatMessage.replace('.mov', '-tmb.jpg')
                      }
                    >
                      <source src={chatMessage} type="video/mp4" />
                    </video>
                  </div>
                ) : chatMessage.includes('pdf') ? (
                  <div className={classes[`imageMessageInnerContainer`]}>
                    <AttachmentIcon
                      onClick={() => {
                        // saveAs(chatMessage);
                        openDocuments(iamgeUrl);
                      }}
                      className={classes['downloadIcon']}
                    />
                    {/* <iframe
                      title="docs"
                      src={`${chatMessage}#toolbar=0&navpanes=0`}
                      width="540px"
                      height="400px"
                    /> */}
                    <Iframe
                      title="docs"
                      src={`${chatMessage}#toolbar=0&navpanes=0`}
                      onInferredClick={() => handlePDFile(`${chatMessage}`)}
                      width="540px"
                      height="400px"
                      id="myFrame"
                    ></Iframe>
                    ;
                  </div>
                ) : chatMessage.includes('mp3') ? (
                  <div className={classes[`imageMessageInnerContainer`]}>
                    <AttachmentIcon
                      onClick={() => {
                        openDocuments(iamgeUrl);
                        // saveAs(chatMessage);
                      }}
                      className={classes['downloadIcon']}
                    />
                    <audio src={chatMessage} controls="false" type="audio/mp3"></audio>
                  </div>
                ) : (
                  <div className={classes[`imageMessageInnerContainer`]}>
                    <AttachmentIcon
                      onClick={() => {
                        openDocuments(iamgeUrl);
                        // saveAs(chatMessage);
                      }}
                      className={classes['downloadIcon']}
                    />
                    {/* to zoom the image file on click */}
                    <Zoom>
                      <img alt="" src={chatMessage} width="380px" height="auto" />
                    </Zoom>
                  </div>
                )
              ) : (
                <div className={classes[`messageInnerContainer${position}`]}>
                  <p
                    className={classes.messageContent}
                    dangerouslySetInnerHTML={{ __html: checkForHyperLink(chatMessage) }}
                  ></p>
                </div>
              )
            }
          </div>
        )}
        <div className={classes[`messageTimestamp${position}`]}>
          {moment(new Date(message.time)).format('LT')}
        </div>
      </div>
      {openDocument.open && (
        <Documentation
          onClose={() => {
            setopenDocument(false);
          }}
          noteId={''}
          setopenNotesModal={setopenDocument}
          memberId={memberId}
          openNotesModal={openDocument.open}
          file={openDocument.file}
        />
      )}
    </>
  );
};

export default MessageBox;
