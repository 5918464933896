import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Box, Paper } from '@material-ui/core';
import DashboardTable from './DashboardTable';
import CardBottomContainer from './Card/CardBottomContainer';
import { useDispatch, useSelector } from 'react-redux';
import { getUserMemberStatuses } from 'store/actions/user';
import { AUTH_TYPE } from 'constants/app';
import DoctorDashboardKPIs from '../../doctor/DoctorDashboardKPIs';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: '30px',
    font: 'Work Sans !important',
  },
  paper: {
    height: 300,
    width: 'auto',
  },
  kpis: {
    padding: '20px',
  },
}));
const Dashboard = () => {
  const classes = useStyles();
  const userType = useSelector((state) => state.user.chatProfile.userType);

  return (
    <Box className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {/* Card display here */}
          <Grid container spacing={4}>
            {userType === AUTH_TYPE.COACH ? (
              <Grid item xs={12}>
                <CardBottomContainer />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <DoctorDashboardKPIs />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {/* Todays session table  */}
          <Paper>
            <DashboardTable userType={userType} />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
