/* eslint-disable */
import history from '../History';
import { cloneDeep } from 'lodash';
import {
  getDataSource,
  getMe,
  getTeamMember,
  getTeamMemberReminders,
  putTeamMember,
  putTeamMemberReminders,
  deleteTeamMember,
  fetchMemberAppointmentNotification,
  postAppointmentNotification,
} from 'store/reducers/app';
import { ROUTES, DATASOURCES, SETTINGS_PAGE_TABS, CLEAR_STATE } from 'constants/app';
import { handleOnNavClick as settingsHandleOnNavClick } from 'screens/common/Settings/module';
// ------------------------------------
// Constants
// ------------------------------------
const PREFIX = 'TeamMemberFile.';

export const DATA_REQUEST = `${PREFIX}DATA_REQUEST`;
export const DATA_SUCCESS = `${PREFIX}DATA_SUCCESS`;
export const DATA_FAILURE = `${PREFIX}DATA_FAILURE`;
export const UPDATE_SELECTED_TEAM_MEMBER_ID = `${PREFIX}UPDATE_SELECTED_TEAM_MEMBER_ID`;

export const REQUEST_APPOINTMENT_NOTIFICATION = `${PREFIX}REQUEST_APPOINTMENT_NOTIFICATION`;
export const REQUEST_APPOINTMENT_NOTIFICATION_SUCCESS = `${PREFIX}REQUEST_APPOINTMENT_NOTIFICATION_SUCCESS`;
export const REQUEST_APPOINTMENT_NOTIFICATION_FAILURE = `${PREFIX}REQUEST_APPOINTMENT_NOTIFICATION_FAILURE`;
export const REQUEST_POST_APPOINTMENT_NOTIFICATION = `${PREFIX}REQUEST_POST_APPOINTMENT_NOTIFICATION`;
export const POST_APPOINTMENT_NOTIFICATION_SUCCESS = `${PREFIX}POST_APPOINTMENT_NOTIFICATION_SUCCESS`;
export const POST_APPOINTMENT_NOTIFICATION_FAILURE = `${PREFIX}POST_APPOINTMENT_NOTIFICATION_FAILURE`;
// ------------------------------------
// Actions
// ------------------------------------
export const dataRequest = () => ({
  type: DATA_REQUEST,
});
export const dataSuccess = () => ({
  type: DATA_SUCCESS,
});
export const dataFailure = () => ({
  type: DATA_FAILURE,
});

export const updateSelectedTeamMemberId = (id) => ({
  type: UPDATE_SELECTED_TEAM_MEMBER_ID,
  payload: id,
});

// Appointment notification
export const requestAppointmentNotificationsChanges = () => ({
  type: REQUEST_POST_APPOINTMENT_NOTIFICATION,
});
export const postAppointmentNotificationsChangesSuccess = () => ({
  type: POST_APPOINTMENT_NOTIFICATION_SUCCESS,
});
export const postAppointmentNotificationsChangesFailure = (error) => ({
  type: POST_APPOINTMENT_NOTIFICATION_FAILURE,
  payload: error,
});
export const fetchAppointmentNotifications = () => ({
  type: REQUEST_APPOINTMENT_NOTIFICATION,
});
export const fetchAppointmentNotificationsSuccess = () => ({
  type: REQUEST_APPOINTMENT_NOTIFICATION_SUCCESS,
});
export const fetchAppointmentNotificationsFailure = (error) => ({
  type: REQUEST_APPOINTMENT_NOTIFICATION_FAILURE,
  payload: error,
});
// --------------

export const setInitialTeamMemberData = (match) => (dispatch, getState) => {
  const { teamMemberId } = match.params;

  dispatch(updateSelectedTeamMemberId(teamMemberId));
};

export const getTeamMemberFileData =
  (forceUpdate = false) =>
  (dispatch, getState) => {
    dispatch(dataRequest());
    const meDataSource = getDataSource(getState(), DATASOURCES.ME);
    const { selectedTeamMemberId } = getState().teamMemberFile;
    let promises;

    if (meDataSource.data && !forceUpdate) {
      promises = Promise.resolve();
    } else {
      promises = dispatch(getMe());
    }

    return promises
      .then(() => {
        const me = getDataSource(getState(), DATASOURCES.ME);

        const { teams } = me.data;

        return dispatch(getTeamMember(teams[0].id, selectedTeamMemberId));
      })
      .then(() => {
        const me = getDataSource(getState(), DATASOURCES.ME);

        const { teams } = me.data;

        return dispatch(getTeamMemberReminders(teams[0].id, selectedTeamMemberId));
      })
      .then(() => dispatch(dataSuccess()))
      .catch((err) => {
        console.log('getTeamMemberFileData', err);
        dispatch(dataFailure());
      });
  };

export const handleOnEditTeamMemberSubmit = (form) => (dispatch, getState) => {
  dispatch(dataRequest());
  const meDataSource = getDataSource(getState(), DATASOURCES.ME);
  const physiciansDataSource = getDataSource(getState(), DATASOURCES.PHYSICIANS);
  const { selectedTeamMemberId } = getState().teamMemberFile;

  let promises;

  if (meDataSource.data) {
    promises = Promise.resolve();
  } else {
    promises = dispatch(getMe());
  }

  if (physiciansDataSource.data && physiciansDataSource.data.byId[selectedTeamMemberId]) {
    // no-op
  } else {
    promises = promises.then(() => {
      const me = getDataSource(getState(), DATASOURCES.ME);

      const { teams } = me.data;

      return dispatch(getTeamMember(teams[0].id, selectedTeamMemberId));
    });
  }

  return promises
    .then(() => {
      const me = getDataSource(getState(), DATASOURCES.ME);
      const physicians = getDataSource(getState(), DATASOURCES.PHYSICIANS);

      const { teams, profile } = me.data;

      const { reminders: rem, ...physician } = physicians.data.byId[selectedTeamMemberId];

      // const regionArray = [ form.region ]
      const regions =
        (form.members && form.members.length && form.members.map((item) => item.region)) || [];
      if (form.region) {
        regions.push(form.region);
      }
      let country;
      if (form.country) {
        country = form.country;
      } else {
        country = '';
        if (regions && regions.length > 0) {
          country = 'US';
        }
      }
      const {
        firstName,
        lastName,
        email,
        canManageAvailability,
        phone,
        permissions: { isAdmin, isPhysician },
        reminders: {
          emailFrequency,
          personalReminder,
          triageReminder,
          personalEsignReportReminder,
          teamEsignReportReminder,
          teamExportReportReminder,
        },
      } = form;

      // Payload for put team member
      const teamMember = {
        ...physician, // backfill existing data first
        email,
        firstName,
        lastName,
        canManageAvailability,
        phone,
        country,
        regions,
        permissions: {
          isAdmin,
          isPhysician,
        },
      };

      // Payload for put team member reminders
      const reminders = {
        ...rem, // backfill existing data first
        emailFrequency: emailFrequency.id,
        personalReminder,
        triageReminder,
        personalEsignReportReminder,
        teamEsignReportReminder,
        teamExportReportReminder,
        member_id: '', // Disregard, dont change
        team_id: '', // Disregard, dont change
      };

      // if team member change is for the user that is logged in, only call reminders, not put team member
      if (selectedTeamMemberId === profile.id) {
        return dispatch(putTeamMemberReminders(teams[0].id, selectedTeamMemberId, reminders));
      } else {
        return dispatch(putTeamMember(teams[0].id, selectedTeamMemberId, teamMember)).then(() =>
          dispatch(putTeamMemberReminders(teams[0].id, selectedTeamMemberId, reminders)),
        );
      }
    })
    .then(() => dispatch(dataSuccess()))
    .catch((err) => {
      console.log('handleOnEditTeamMemberSubmit', err);
      dispatch(dataFailure());
    });
};

// Appointment Notifications

// Appointment Notifications change
export const getAppointmentNotificationsSettings = (data) => (dispatch, getState) => {
  const meDataSource = getDataSource(getState(), DATASOURCES.ME);
  const { selectedTeamMemberId } = getState().teamMemberFile;
  let promises;

  if (meDataSource.data) {
    promises = Promise.resolve();
  } else {
    promises = dispatch(getMe());
  }
  dispatch(fetchAppointmentNotifications());

  return promises
    .then(() => {
      const me = getDataSource(getState(), DATASOURCES.ME);
      const { teams } = me.data;
      return Promise.all([
        dispatch(fetchMemberAppointmentNotification(teams[0].id, selectedTeamMemberId)),
      ]);
    })
    .then((res) => dispatch(fetchAppointmentNotificationsSuccess(res)))
    .catch((err) => {
      dispatch(fetchAppointmentNotificationsFailure(err));
    });
};

export const handleAppointmentNotificationsChange = (data) => (dispatch, getState) => {
  dispatch(requestAppointmentNotificationsChanges());
  const me = getDataSource(getState(), DATASOURCES.ME);
  const { selectedTeamMemberId } = getState().teamMemberFile;
  const { teams } = me.data;
  const postDta = {
    emailEnabled: data.isEmail,
    memberId: selectedTeamMemberId,
    smsEnabled: data.isText,
    teamId: teams[0].id,
  };
  return dispatch(postAppointmentNotification(teams[0].id, selectedTeamMemberId, postDta))
    .then(() => dispatch(getAppointmentNotificationsSettings()))
    .then(() => dispatch(postAppointmentNotificationsChangesSuccess()))
    .catch((err) => {
      dispatch(postAppointmentNotificationsChangesFailure(err));
    });
};

export const handleOnTeamMemberDeleteClick = () => (dispatch, getState) => {
  dispatch(dataRequest());
  const meDataSource = getDataSource(getState(), DATASOURCES.ME);
  const { selectedTeamMemberId } = getState().teamMemberFile;

  let promises;

  if (meDataSource.data) {
    promises = Promise.resolve();
  } else {
    promises = dispatch(getMe());
  }

  return promises
    .then(() => {
      const me = getDataSource(getState(), DATASOURCES.ME);

      const { teams } = me.data;

      return dispatch(deleteTeamMember(teams[0].id, selectedTeamMemberId));
    })
    .then(() => dispatch(dataSuccess()))
    .then(() => history.push(ROUTES.SETTINGS))
    .catch((err) => {
      console.log('handleOnEditTeamMemberSubmit', err);
      dispatch(dataFailure());
    });
};

export const handleOnTeamMemberClick = (teamMemberId) => (dispatch, getState) => {
  history.push(`${ROUTES.SETTINGS}/team-members/${teamMemberId}`);
  // window.location.href = `${ROUTES.SETTINGS}/team-members/${teamMemberId}`;
};

const appointmentNotification = Object.assign(
  {},
  {
    appointmentNotificationIsFetching: false,
    appointmentNotificationFetchSuccess: false,
    appointmentNotificationFetchFailure: false,
    appointmentNotificationPostRequesting: false,
    appointmentNotificationPostSuccess: false,
    appointmentNotificationPostFailure: false,
    appointmentNotificationData: {},
    appointmentNotificationErr: {},
  },
);

const initialState = {
  selectedTeamMemberId: null,
  ...cloneDeep(appointmentNotification),
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  //  - - - Appointment Notification
  [REQUEST_APPOINTMENT_NOTIFICATION]: (state, action) => ({
    ...state,
    appointmentNotificationIsFetching: true,
  }),
  [REQUEST_APPOINTMENT_NOTIFICATION_SUCCESS]: (state, action) => ({
    ...state,
    appointmentNotificationIsFetching: true,
    appointmentNotificationFetchSuccess: true,
    appointmentNotificationFetchFailure: false,
  }),
  [REQUEST_APPOINTMENT_NOTIFICATION_FAILURE]: (state, action) => ({
    ...state,
    appointmentNotificationIsFetching: false,
    appointmentNotificationFetchSuccess: false,
    appointmentNotificationFetchFailure: true,
    appointmentNotificationErr: action.payload,
  }),
  [REQUEST_POST_APPOINTMENT_NOTIFICATION]: (state, action) => ({
    ...state,
    appointmentNotificationPostRequesting: true,
  }),
  [POST_APPOINTMENT_NOTIFICATION_SUCCESS]: (state, action) => ({
    ...state,
    appointmentNotificationPostRequesting: false,
    appointmentNotificationPostSuccess: true,
    appointmentNotificationPostFailure: false,
  }),
  [POST_APPOINTMENT_NOTIFICATION_FAILURE]: (state, action) => ({
    ...state,
    appointmentNotificationPostRequesting: false,
    appointmentNotificationPostSuccess: false,
    appointmentNotificationPostFailure: true,
    appointmentNotificationErr: action.payload,
  }),
  //  - - - - - - - - - - - - -
  [UPDATE_SELECTED_TEAM_MEMBER_ID]: (state, action) => ({
    ...state,
    selectedTeamMemberId: action.payload,
  }),
  [DATA_REQUEST]: (state, action) => ({
    ...state,
    dataIsFetching: true,
  }),
  [DATA_SUCCESS]: (state, action) => ({
    ...state,
    dataIsFetching: false,
    dataLastUpdated: new Date(),
  }),
  [DATA_FAILURE]: (state, action) => ({
    ...state,
    dataIsFetching: false,
    dataLastUpdated: new Date(),
  }),
  [CLEAR_STATE]: (state, action) => ({ ...initialState }),
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function teamMemberFileReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
