import React, { useEffect } from 'react';
import { Dialog, Radio, RadioGroup, FormControlLabel, Grid, Box } from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import CloseIcon from '@material-ui/icons/Close';
import MuiTypography from '../Shared/Typography/MuiTypography';
import * as API from '../../Services/API/actions';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'store/actions';
import Alert from '../Shared/Alert/Alerts';
import * as zone from 'utilities/Utilities';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '10px 0 30px 0',
    flexGrow: 1,
    padding: '0',
    display: 'flex',
    alignItems: 'center',
    height: '36px',
    width: '98%',
    left: '0',
    top: '0px',
    borderRadius: '10px',
    background: 'rgba(118, 118, 128, 0.12)',
  },
  grid: {
    padding: theme.spacing(4),
    textAlign: 'center',
  },
  label: {
    paddingLeft: '0',
    textAlign: 'left',
  },
  dialogPaper: {
    height: '239px',
  },
  iconButton: {
    padding: 10,
  },
  customTextField: {
    '& input::placeholder': {
      fontSize: '17px',
      fontFamily: 'Work Sans',
      lineHeight: '22px',
      letterSpacing: '-0.40799999237060547px',
      fontWeight: 400,
      color: '#000000',
      textAlign: 'left',
    },
  },
}));
const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    alignContent: 'center',
    flexGrow: 1,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#00000',
  },
});
const GreenRadio = withStyles({
  root: {
    margin: '0 20px 0 0',
    width: '16px',
    height: '16px',
    '&$checked': {
      color: '#2D9F86',
      width: '16px',
      height: '16px',
      // position: 'absolute',
      // left: 'calc(50% - 16px/2)',
      // top: 'calc(50% - 16px/2)',
      background: '#DADADA',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
export default function ReAssignAppointment(props) {
  const pattern = 'YYYY-MM-DDTHH:mm:ss.SSS';
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = React.useState('');
  const [seriespattern, setseriesPattern] = React.useState('');
  const [availabledoctors, setavailabledoctors] = React.useState([]);
  const [loading, setloading] = React.useState(false);
  const [alert, setAlert] = React.useState(null);
  const teamId = useSelector((state) => state.user.team.id);
  const profileId = useSelector((state) => state.user.profile.id);

  let dateTime =
    props.component === 'popover'
      ? moment(props.event.start).format(pattern)
      : props.component === 'appointments'
      ? moment(props.startDate).format(pattern)
      : props.patientDetails.patientAppointment['scheduledStartTime'];
  let appointmentId =
    props.component === 'popover'
      ? props.event.id
      : props.component === 'appointments'
      ? props.appointmentId
      : props.id;
  useEffect(() => {
    setloading(true);
    let queryparams = {
      dateTime: dateTime,
      offsetInMinutes: new Date().getTimezoneOffset(),
      timeZone: zone.getTimezoneName(),
      appointmentId,
    };
    API.AvailabledoctorList(teamId, queryparams).subscribe(
      (res) => {
        setloading(false);
        if ('detailed_error' in res.data)
          setAlert(
            <Alert
              date={new Date()}
              title={
                'detailed_error' in res.data ? 'Unable to process the request' : 'Technical Issue'
              }
              message={
                'detailed_error' in res.data
                  ? res.data['detailed_error']
                  : 'Unable to process this request due to internal server error.'
              }
            />,
          );
        else {
          setavailabledoctors(res.data.result);
        }
      },
      (error) => {
        setloading(false);
        console.log(error);
      },
    );
  }, [appointmentId, dateTime, teamId]);
  const reassignsubmitHandler = () => {
    if (seriespattern) {
      let selectedDoctor = availabledoctors.filter((doctor) => {
        if (doctor.availabilityId === seriespattern) return doctor;
      });
      let id =
        props.component === 'popover'
          ? props.event.id
          : props.component === 'appointments'
          ? props.appointmentId
          : props.patientDetails.patientAppointment['appointmentId'];
      let body = {
        availabilityBlockId: seriespattern,
        memberId: selectedDoctor[0].physician['memberId'],
        patientAppointmentId: id,
        teamId: selectedDoctor[0].physician['teamId'],
        timeZoneOffset: new Date().getTimezoneOffset(),
        timeZone: zone.getTimezoneName(),
      };
      let params = {
        version: props.patientDetails.version,
      };
      API.ReAssignAppointment(teamId, profileId, id, body, params).subscribe(
        (res) => {
          setloading(false);
          if ('detailed_error' in res.data)
            setAlert(
              <Alert
                date={new Date()}
                title={
                  'detailed_error' in res.data ? 'Unable to process the request' : 'Technical Issue'
                }
                message={
                  'detailed_error' in res.data
                    ? res.data['detailed_error']
                    : 'Unable to process this request due to internal server error.'
                }
              />,
            );
          else {
            let appointmentqueryParams = {
              startDateTime: moment(moment().startOf('day')._d).format(pattern),
              endDateTime: moment(moment().endOf('day')._d).format(pattern),
              offsetInMinutes: new Date().getTimezoneOffset(),
              timeZone: zone.getTimezoneName(),
              appointmentStatus: '1,2,3,6,7,9',
            };

            if (props.component === 'popover') {
              dispatch(actions.getAppointments(teamId, profileId, appointmentqueryParams));
              props.onSubmit({
                type: 'reassign',
                doctorFirstName: selectedDoctor[0].physician['firstName'],
                doctorLastName: selectedDoctor[0].physician['lastName'],
              });
            } else if (props.component === 'appointments') {
              props.onSubmit({
                type: 'reassign',
                doctorFirstName: selectedDoctor[0].physician['firstName'],
                doctorLasttName: selectedDoctor[0].physician['lastName'],
              });
            } else {
              props.handleClose();
              props.loader();
              props.dashboardmetrics && props.dashboardmetrics(null, 0);
              props.reassignsubmit({
                doctorFirstName: selectedDoctor[0].physician['firstName'],
                doctorLastName: selectedDoctor[0].physician['lastName'],
              });
            }
          }
        },

        (error) => {
          setloading(false);
          setAlert(
            <Alert
              date={new Date()}
              title={'Technical Issue'}
              message={'Unable to process this request due to internal server error.'}
            />,
          );
        },
      );
    } else {
      setAlert(
        <Alert
          date={new Date()}
          title={'Select Doctor'}
          message={'select doctor to reassign the appointment.'}
        />,
      );
    }
  };
  const updateSearchInputValue = (e) => {
    setSearchInput(e.target.value);
  };
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
  }))(MuiDialogActions);
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, title, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <MuiTypography
          fontSize="24px"
          fontFamily="Work Sans"
          lineHeight="28.15px"
          letterSpacing="28px"
          fontWeight={500}
          color="#000000"
          textAlign="left"
        >
          Reassign Session
        </MuiTypography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  const handleRadioChange = (e) => {
    setseriesPattern(e.target.value);
  };
  const filterdata = (doctor) => {
    let name = doctor.physician['lastName'] + ' ' + doctor.physician['firstName'];
    return (
      name
        .toLowerCase()
        .replace(/ /g, '')
        .indexOf(searchInput.toLowerCase().trim().replace(/ /g, '')) !== -1
    );
  };
  const filtereddoctors = availabledoctors.filter(
    (doctor) => doctor.physician['firstName'] && filterdata(doctor),
  );
  return (
    <Dialog
      classes={loading ? { paper: classes.dialogPaper } : null}
      maxWidth={'sm'}
      fullWidth={true}
      onClose={props.handleClose}
      aria-labelledby="customized-dialog-title"
      open={props.show}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <DialogTitle title="" id="customized-dialog-title" onClose={props.handleClose} />
      <div className="telekardia">
        <div className="m-3">{alert}</div>
      </div>

      <DialogContent>
        {Object.keys(availabledoctors).length > 0 ? (
          <>
            <Grid item>
              <Box px={0}>
                <Input
                  classes={{ root: classes.customTextField }}
                  startAdornment={
                    <InputAdornment>
                      <IconButton
                        disabled={true}
                        className={classes.iconButton}
                        aria-label="search"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  autoFocus
                  id="standard-search"
                  className={classes.root}
                  disableUnderline={true}
                  placeholder={
                    'Search'
                    //     <MuiTypography
                    //   fontSize="17px"
                    //   fontFamily="Work Sans"
                    //   lineHeight="22px"
                    //   letterSpacing="-0.41px"
                    //   fontWeight={400}
                    //   color="#3C3C43"
                    //   textAlign= 'left'
                    // >
                    //   Search
                    // </MuiTypography>
                  }
                  type="search"
                  onChange={(e) => updateSearchInputValue(e)}
                  value={searchInput}
                />
                <MuiTypography
                  fontFamily="Work Sans"
                  fontSize="14px"
                  fonStyle="normal"
                  fonWeight="400"
                  lineHeight="16px"
                  margin="0 0 0 10px"
                  letterSpacing="0.002em"
                  textAlign="left"
                  color="#000"
                  textTransform="uppercase"
                >
                  Available doctors on {moment(dateTime).format('Do MMM, YYYY')} at{' '}
                  {moment(dateTime).format('hh:mm A')}:
                </MuiTypography>
              </Box>
            </Grid>

            <Grid container spacing={2} className={classes.grid}>
              <Grid container item xs={12} spacing={1}>
                {Object.keys(availabledoctors).length > 0 &&
                  filtereddoctors.map((items, index) => (
                    <Grid item xs={6} key={index}>
                      <RadioGroup
                        aria-label="quiz"
                        name="quiz"
                        value={seriespattern}
                        onChange={handleRadioChange}
                      >
                        <FormControlLabel
                          className={classes.label}
                          value={items.availabilityId}
                          control={<GreenRadio />}
                          label={
                            <MuiTypography
                              fontSize="14px"
                              fontFamily="Work Sans"
                              lineHeight="24px"
                              letterSpacing="-0.2px"
                              fontWeight={400}
                              color="#142A39"
                            >
                              {items.physician['firstName'] + ' ' + items.physician['lastName']}
                            </MuiTypography>
                          }
                        />
                      </RadioGroup>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </>
        ) : loading ? (
          <div className="telekardia">
            <div className={loading ? 'filtering' : null}>
              <div className={loading ? 'spinner-border' : null}></div>
            </div>
          </div>
        ) : (
          <MuiTypography
            fontFamily="Work Sans"
            fontSize="14px"
            fonStyle="normal"
            fonWeight="400"
            lineHeight="24px"
            letterSpacing="-0.20000000298023224px"
            textAlign="left"
            color="#000000"
          >
            No Coaches are available in this time slot. Do you want to cancel the session?
          </MuiTypography>
        )}
      </DialogContent>
      {!loading && (
        <DialogActions>
          <div className="telekardia">
            <button
              type="button"
              className="btn px-4 text-uppercase swal_green"
              onClick={availabledoctors.length > 0 ? reassignsubmitHandler : props.onCancel}
            >
              {availabledoctors.length > 0 ? 'CONFIRM' : 'YES, CANCEL'}
            </button>
          </div>
        </DialogActions>
      )}
    </Dialog>
  );
}
