import * as actionTypes from '../actions/actionTypes';

const baseState = {
  loading: false,
  data: null,
  error: null,
};
const initialState = {
  loading: false,
  memberListData: [],
  searchMemberData: [],
  memberListFilter: null,
};
const profileInitialState = {
  laoding: false,
  programData: null,
  healthData: null,
  pcp: {
    metaData: baseState,
    answers: baseState,
    update: baseState,
  },
  sessionLogs: {
    filters: null,
    metaData: null,
    data: null,
  },
  noteMetaData: null,
  error: null,
  programProgress: {},
  programFocus: {},
};
export function memberListReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_CHAT_USERS_START:
      return {
        ...state,
        loading: true,
        memberListData: [],
      };
    case actionTypes.GET_MEMBERS_LIST:
      return {
        ...state,
        loading: false,
        memberListData: action.payload.data,
        count: action.payload.count,
      };
    case actionTypes.GET_MEMBERS_LIST_NEXT_SESSION_DATA: {
      return {
        ...state,
        memberListData: action.payload.data,
      };
    }
    case actionTypes.GET_SEARCH_MEMBER:
      return {
        ...state,
        loading: false,
        searchMemberData: action.payload,
      };
    case actionTypes.SET_FILTER_DATA:
      return {
        ...state,
        memberListFilter: action.payload,
      };
    default:
      return state;
  }
}
export function memberProfileReducer(state = profileInitialState, action) {
  switch (action.type) {
    case actionTypes.GET_MP_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_MP_PROGRAM_SUCCESS:
      return {
        ...state,
        loading: false,
        programData: action.payload,
      };
    case actionTypes.GET_MP_PROGRAM_PP_LOADING:
      return {
        ...state,
        loading: false,
        programProgress: {
          loading: true,
        },
      };
    case actionTypes.GET_MP_PROGRAM_PP_FAIL:
      return {
        ...state,
        loading: false,
        programProgress: {
          ...state.programProgress,
          loading: false,
          error: action.payload,
        },
      };
    case actionTypes.GET_MP_PROGRAM_PP_SUCCESS:
      return {
        ...state,
        loading: false,
        programProgress: {
          ...state.programProgress,
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    case actionTypes.GET_MP_PROGRAM_PF_LOADING:
      return {
        ...state,
        loading: false,
        programFocus: {
          loading: true,
        },
      };
    case actionTypes.GET_MP_PROGRAM_PF_FAIL:
      return {
        ...state,
        loading: false,
        programFocus: {
          ...state.programFocus,
          loading: false,
          error: action.payload,
        },
      };
    case actionTypes.GET_MP_PROGRAM_PF_SUCCESS:
      return {
        ...state,
        loading: false,
        programFocus: {
          ...state.programFocus,
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    case actionTypes.GET_MP_HEALTH_SUCCESS:
      return {
        ...state,
        loading: false,
        healthData: action.payload,
      };
    case actionTypes.GET_MP_DOCUMENTATION_LOADING:
      return {
        ...state,
        sessionLogs: {
          ...state.sessionLogs,
          data: null,
          loading: true,
          error: null,
        },
      };

    case actionTypes.GET_MP_DOCUMENTATION_SUCCESS:
      return {
        ...state,
        sessionLogs: {
          ...state.sessionLogs,
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    case actionTypes.SET_DOCUMENTATION_FILTER_DATA:
      return {
        ...state,
        sessionLogs: {
          ...state.sessionLogs,
          filters: action.payload,
        },
      };
    case actionTypes.GET_MP_DOCUMENTATION_FAILURE:
      return {
        ...state,
        sessionLogs: {
          ...state.sessionLogs,
          data: null,
          loading: false,
          error: action.payload,
        },
      };
    case actionTypes.NOTE_METADATA_LOADING:
      return {
        ...state,
        loading: false,
        noteMetaData: {
          loading: true,
          data: null,
          error: null,
        },
      };
    case actionTypes.NOTE_METADATA_SUCCESS:
      return {
        ...state,
        loading: false,
        noteMetaData: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case actionTypes.NOTE_METADATA_FAILURE:
      return {
        ...state,
        loading: false,
        noteMetaData: {
          loading: false,
          data: null,
          error: action.payload,
        },
      };
    case actionTypes.NOTE_FILTER_METADATA_SUCCESS:
      return {
        ...state,
        sessionLogs: {
          ...state.sessionLogs,
          metaData: action.payload,
        },
      };
    case actionTypes.NOTE_FILTER_METADATA_FAIL:
      return {
        ...state,
        sessionLogs: {
          ...state.sessionLogs,
          metaData: null,
        },
      };
    case actionTypes.GET_MP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_MP_HEALTH_PCP_LOADING:
      return {
        ...state,
        pcp: {
          ...state.pcp,
          answers: {
            loading: true,
            data: null,
            error: null,
          },
        },
      };
    case actionTypes.GET_MP_HEALTH_PCP_SUCCESS:
      return {
        ...state,
        pcp: {
          ...state.pcp,
          answers: {
            loading: false,
            data: action.payload,
            error: null,
          },
        },
      };
    case actionTypes.GET_MP_HEALTH_PCP_FAIL:
      return {
        ...state,
        pcp: {
          ...state.pcp,
          answers: {
            loading: false,
            data: null,
            error: action.payload,
          },
        },
      };

    case actionTypes.GET_MP_HEALTH_PCP_METADATA_LOADING:
      return {
        ...state,
        pcp: {
          ...state.pcp,
          metaData: {
            loading: true,
            data: null,
            error: null,
          },
        },
      };
    case actionTypes.GET_MP_HEALTH_PCP_METADATA_SUCCESS:
      return {
        ...state,
        pcp: {
          ...state.pcp,
          metaData: {
            loading: false,
            data: action.payload,
            error: null,
          },
        },
      };
    case actionTypes.GET_MP_HEALTH_PCP_METADATA_FAIL:
      return {
        ...state,
        pcp: {
          ...state.pcp,
          metaData: {
            loading: false,
            data: null,
            error: action.payload,
          },
        },
      };
    case actionTypes.GET_MP_HEALTH_PCP_UPDATE_LOADING:
      return {
        ...state,
        pcp: {
          ...state.pcp,
          update: {
            loading: true,
            data: null,
            error: null,
          },
        },
      };
    case actionTypes.GET_MP_HEALTH_PCP_UPDATE_SUCCESS:
      return {
        ...state,
        pcp: {
          ...state.pcp,
          update: {
            loading: false,
            data: action.payload,
            error: null,
          },
        },
      };
    case actionTypes.GET_MP_HEALTH_PCP_UPDATE_SUCCESS:
      return {
        ...state,
        pcp: {
          ...state.pcp,
          update: {
            loading: false,
            data: null,
            error: action.payload,
          },
        },
      };

    default:
      return state;
  }
}
