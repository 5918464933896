import { Box, Typography } from '@mui/material';
import { getEKGAlgorithmDeterminationDisplayText } from 'constants/recordingMaps';
import React from 'react';

function Pills(props) {
  const { params, color, column } = props;
  let pillColor,
    outline = 'solid';
  switch (params.row.status) {
    case 1:
      pillColor = '#CC3D3F';
      break;
    case 2:
      pillColor = '#FDC246';
      break;
    default:
      pillColor = 'lightgray';
      outline = 'dashed';
      break;
  }
  return (
    <>
      {column === 'ekg' && params?.row?.ekgDetermination !== null ? (
        <Box
          style={{
            border: `${params?.row?.ekgDetermination === '' ? '0px' : '1px'} solid ${
              params.row.status === 3 ? 'lightgray' : color
            }`,
            borderRadius: '20px',
            padding: '5px 10px',
          }}
        >
          <Typography
            variant="body1"
            style={{ color: params.row.status === 3 ? 'lightgray' : '#142A39' }}
          >
            {params?.row?.ekgDetermination !== '' &&
              getEKGAlgorithmDeterminationDisplayText(
                params?.row?.ekgDetermination,
                params?.row?.ekgPackage,
              )}
          </Typography>
        </Box>
      ) : (
        column === 'status' && (
          <Box
            style={{
              border: `1px ${outline} ${params.row.status === 3 ? 'lightgray' : pillColor}`,
              borderRadius: '20px',
              padding: '5px 10px',
            }}
          >
            <Typography
              variant="body1"
              style={{ color: params.row.status === 3 ? 'lightgray' : '#142A39' }}
            >
              {params?.row?.statusName}
            </Typography>
          </Box>
        )
      )}
    </>
  );
}

export default Pills;
