import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useSelector } from 'react-redux';

import { fonts, fontSizes } from 'style/styleSettings';
import { useEffect } from 'react';
import { editAddendum, postAddendum, uploadAddendumAttachment } from 'store/actions/memberProfile';
import DocumentationFileUpload from './Upload';

const useStyles = makeStyles({
  root: {
    fontFamily: fonts.SESSION,
    marginTop: '10px',
    '& .MuiButton-root:hover': {
      backgroundColor: `lightgray !important`,
    },
    '& .MuiTypography-h5': {
      fontSize: `${fontSizes.HEADING} !important`,
    },
    '& .MuiButton-label': {
      fontFamily: 'Work Sans',
    },
  },
});
function AddAddendums({ noteId, row, snackBarDataHandler, refetch }) {
  const classes = useStyles();
  // const userType = useSelector((state) => state.user.chatProfile.userType);
  const profile = useSelector((state) => state.user.profile);
  const teamId = useSelector((state) => state.user.team.id);
  const coachId = useSelector((state) => state.user.profile.id);
  const [attachments, setAttachements] = React.useState([]);
  const [cachedUploads, setCachedUploads] = React.useState([]);
  const [state, setState] = React.useState({
    description: '',
  });

  useEffect(() => {
    if (row !== null && Object.keys(row).length > 0) {
      setState({
        ...state,
        description: row.description,
      });
      let att = [];

      if (row.attachmentDetails.length > 0) {
        row.attachmentDetails.map((item) => {
          att.push({
            name: item.filename,
            size: null,
            uploaded: true,
            success: true,
            error: false,
            ...item,
          });
        });
      }
      setAttachements(att);
    }
  }, [row]);
  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleSubmitAddendum = (status) => {
    const data = {
      ...state,
      status,
      noteId: noteId,
    };
    if (status == 102) {
      data.signedBy = coachId;
    }
    if (row) {
      editAddendum(teamId, coachId, data, noteId, row.id).then((res) => {
        if (res.success) {
          refetch();
          setAttachements([]);
          setState({
            description: '',
          });
          snackBarDataHandler({
            open: true,
            text: 'Addendum edited successfully!',
            severity: 'success',
          });
        }
      });
    } else {
      //if addendum status is signed, we cannot upload attachments once it is signed
      // so we save as draft -> upload attchments -> save as signed
      if (status == 102) {
        data.status = 101;
      }
      postAddendum(teamId, coachId, data, noteId).then((res) => {
        if (res.success) {
          snackBarDataHandler({
            open: true,
            text: 'Saving attachments',
            severity: 'success',
          });
          let promises = [];
          if (cachedUploads.length > 0) {
            cachedUploads.map((c) => {
              promises.push(
                uploadAddendumAttachment(teamId, coachId, noteId, res.result, c).then((res) =>
                  console.log(res),
                ),
              );
            });
          }
          Promise.all(promises).then(
            function () {
              //stack promises
              if (status == 102) {
                data.status = 102;
                editAddendum(teamId, coachId, data, noteId, res.result).then((res) => {});
              }
              setCachedUploads([]);
              setAttachements([]);
              setState({
                description: '',
              });
              snackBarDataHandler({
                open: true,
                text: 'Addendum created successfully!',
                severity: 'success',
              });
              refetch();
            },
            function (err) {
              // error occurred
              console.log(err);
            },
          );
        }
      });
    }
  };

  const cacheUploadHander = async (file) => {
    setCachedUploads((s) => [...s, file]);
    return { status: 200 };
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Paper
          style={{
            backgroundColor: 'white',
            borderRadius: 5,
            boxShadow: '10px, 5px #c1c1c1',
            padding: '0 20px',
            textAlign: 'left',
            marginTop: '10px',
          }}
        >
          <Grid container spacing={2}>
            {/* tags & inputs */}
            <Grid item xs={12}>
              <Typography style={{ fontSize: fontSizes.MEDIUM, margin: '10px 0' }}>
                <b>Addendum</b>
              </Typography>
              <Typography style={{ fontSize: fontSizes.BASE, margin: '10px 0' }}>
                Name: {profile.firstName} {profile.lastName}
              </Typography>

              <textarea
                style={{ width: '100%', padding: '10px' }}
                name="description"
                rows="5"
                placeholder="Addendum goes here..."
                value={state.description}
                onChange={handleChange}
              ></textarea>
            </Grid>

            <DocumentationFileUpload
              data={attachments}
              snackBarDataHandler={snackBarDataHandler}
              onUpload={(file) => {
                if (row && row.id) {
                  //if data is already present , we can use id to upload the attachment
                  return uploadAddendumAttachment(teamId, coachId, noteId, row.id, file).then(
                    (res) => res,
                  );
                } else {
                  //if not, the system caches the attachment and finally uploads it at the time
                  return cacheUploadHander(file).then((res) => res);
                }
              }}
            />
            {/* action button */}

            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <>
                <Button
                  style={{
                    color: '#067F6F',
                    border: '2px solid #067F6F',
                    padding: '10px 20px',
                    fontWeight: '800',
                  }}
                  onClick={() => handleSubmitAddendum(101)}
                  disabled={!state.description}
                >{`Save Draft`}</Button>
                <Button
                  style={{
                    backgroundColor: '#067F6F',
                    color: 'white',
                    marginLeft: '20px',
                    border: '2px solid #067F6F',
                    padding: '10px 20px',
                    fontWeight: '800',
                  }}
                  disabled={!state.description}
                  onClick={() => handleSubmitAddendum(102)}
                >
                  {`Save And Sign`}
                </Button>
              </>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default AddAddendums;
