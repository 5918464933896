import React, { useContext, useEffect, useState } from 'react';
import { Box, Paper, Typography, Tabs, Tab, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import HealthDetails from './components/HealthDetails';
import Program from './components/Program';
import queryString from 'query-string';
import Header from './Header';
import { useDispatch, useSelector } from 'react-redux';
import { getMemberProfileDetails } from 'store/actions/memberProfile';
import PatientFile from './components/Metrics/PatientFile';
// import { getChatUserAction } from 'store/actions/chat';
import Messages from './components/Messages';
import { ChatContext, getFullJid } from '@alivecor/strophe2.0';
import ThisSession from './components/ThisSession/ThisSession';
import { AUTH_TYPE } from 'constants/app';
import * as action from 'store/actions';
import { getTimezoneName } from 'utilities/Utilities';
import ActivityAndNotes from './components/ActivityAndNotes';

const useStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
    margin: '26px',
    '& .MuiTypography-root': {
      fontFamily: 'Work Sans',
    },
  },
  paperroot: {
    flexGrow: 1,
  },
  customTabRoot: {
    color: '#BABFBD',
    '& .Mui-selected': {
      color: '#142A39',
    },
  },
  customTabIndicator: {
    backgroundColor: '#067F6F',
    height: '4px',
  },
  tabRoot: {
    fontSize: '14px !important',
    minWidth: 166,
    fontWeight: '700',
    fontFamily: 'Work Sans',
    letterSpacing: '0.75px',
  },
  divWrapper: {
    background: '#FFFFFF',
    boxShadow:
      '0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1)',
    marginTop: '24px',
    marginBottom: '24px',
    padding: '16px',
    paddingBottom: '32px',
    '& .MuiGrid-item': {
      marginTop: '32px',
      marginBottom: '16px',
    },
    '& .MuiTypography-body1': {
      fontWeight: 600,
    },
  },
  divHeaderWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiBox-root': {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '20px',
      '& .MuiTypography-body2': {
        fontWeight: 600,
        marginLeft: '20px',
      },
    },
  },
  chipHolder: {
    marginTop: '16px',
    display: 'flex',
  },
  holder: {
    marginTop: '8px',
  },
  holder_single: {
    margin: '0 !important',
    display: 'flex',
    '& .MuiTypography-root:nth-of-type(1)': {
      fontWeight: 600,
    },
  },
  lastTabWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  count: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '24px',
    height: '24px',
    borderRadius: '12px',
    color: 'white',
    backgroundColor: 'teal',
    fontSize: '12px',
    marginLeft: '10px',
    lineHeight: '0px',
  },
}));
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box div={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
export default function MemberProfile(props) {
  const { t } = useTranslation();
  const { unreadMsgCount, setUnreadMsgCount } = useContext(ChatContext);

  const { memberId, appointmentId } = useParams();
  const dispatch = useDispatch();
  const classes = useStyle();
  let location = useLocation();
  const [value, setValue] = useState(0);
  const [tabIndex, settabIndex] = useState(0);
  const clinicianId = useSelector((state) => state.user.profile.id);
  const teamId = useSelector((state) => state.user.team.id);
  const permissions = useSelector((state) => state.user.profile.permissions);
  const { programData } = useSelector((state) => state.memberProfile);
  const userType = useSelector((state) => state.user.chatProfile.userType);
  const [msgCount, setMsgCount] = useState(0);
  useEffect(() => {
    if (programData) {
      let count = unreadMsgCount[getFullJid(programData?.chatUserID.toLowerCase())];
      setMsgCount((c) => (count ? count : 0));
    }
  }, [programData, unreadMsgCount]);
  const handleChange = (event, newValue) => {
    //history.push(`${ROUTES.MEMBER_PROFILE}/${memberId}?tab=${newValue}`);
    setValue(newValue);
  };
  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    const { tab } = queryParams;
    if (tab) {
      setValue(parseInt(tab));
    }
    if (userType === AUTH_TYPE.DOCTOR && appointmentId !== ' ') {
      settabIndex(1);
    } else {
      settabIndex(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    dispatch(getMemberProfileDetails(memberId));
    if (userType === AUTH_TYPE.DOCTOR && appointmentId !== ' ') {
      dispatch(
        action.getRecordingSamples('', teamId, clinicianId, appointmentId, {
          timeZoneOffset: new Date().getTimezoneOffset(),
          timeZone: getTimezoneName(),
        }),
      );
    }
  }, [dispatch, memberId, appointmentId]);

  return (
    <div className={classes.root}>
      <Header refreshAction={() => dispatch(getMemberProfileDetails(memberId))} />
      <Paper className={classes.paperroot}>
        <Tabs
          classes={{
            root: classes.customTabRoot,
            indicator: classes.customTabIndicator,
          }}
          value={value}
          onChange={handleChange}
          scrollButtons="auto"
          centered
          variant="fullWidth"
        >
          {userType === AUTH_TYPE.DOCTOR && appointmentId !== ' ' && (
            <Tab label={t('THIS SESSION')} classes={{ root: classes.tabRoot }} {...a11yProps(0)} />
          )}
          {/* <Tab label={t('THIS SESSION')} classes={{ root: classes.tabRoot }} {...a11yProps(0)} /> */}
          <Tab label={t('PROGRAM')} classes={{ root: classes.tabRoot }} {...a11yProps(tabIndex)} />
          <Tab
            label={t('HEALTH DETAILS')}
            classes={{ root: classes.tabRoot }}
            {...a11yProps(tabIndex + 1)}
          />
          <Tab
            label={t('METRICS')}
            classes={{ root: classes.tabRoot }}
            {...a11yProps(tabIndex + 2)}
          />
          <Tab
            label={t('ACTIVITY / NOTES')}
            classes={{ root: classes.tabRoot }}
            {...a11yProps(tabIndex + 3)}
          />
          {userType === AUTH_TYPE.COACH && (
            <Tab
              disabled={permissions?.isAdmin ? true : false}
              onClick={() => {
                setUnreadMsgCount(
                  (d) => (d[getFullJid(programData?.chatUserID.toLowerCase())] = 0),
                );
              }}
              label={
                <React.Fragment>
                  <Grid container>
                    <Grid item lg={msgCount > 0 ? '9' : '12'}>
                      <p>Messages</p>
                    </Grid>
                    {msgCount > 0 && (
                      <Grid item lg={3}>
                        <p className={classes.count}>{msgCount}</p>
                      </Grid>
                    )}
                  </Grid>
                </React.Fragment>
              }
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
              classes={{ root: classes.tabRoot }}
              {...a11yProps(tabIndex + 4)}
            />
          )}
        </Tabs>
      </Paper>
      {userType === AUTH_TYPE.DOCTOR && appointmentId !== ' ' && (
        <TabPanel value={value} index={0}>
          <ThisSession />
        </TabPanel>
      )}
      <TabPanel value={value} index={tabIndex}>
        <Program />
      </TabPanel>
      <TabPanel value={value} index={tabIndex + 1}>
        <HealthDetails />
      </TabPanel>
      <TabPanel value={value} index={tabIndex + 2}>
        {/* <Metrics /> */}
        <PatientFile {...props} />
      </TabPanel>
      <TabPanel value={value} index={tabIndex + 3}>
        <ActivityAndNotes />
      </TabPanel>
      {userType === AUTH_TYPE.COACH && (
        <TabPanel value={value} index={tabIndex + 4}>
          <Messages {...props} />
        </TabPanel>
      )}
    </div>
  );
}
