import { getToken } from 'auth/AuthUtilities';
import { GoAPI, TeleKardiaAPI } from '../../Axios/axios';
// import { patientDetails } from 'components/Dashboard/SessionComponents/data/mockData';
import { getTimezoneName } from 'utilities/Utilities';
import * as actionTypes from './actionTypes';

export const sessionPatientDetailsAction = (data) => ({
  type: actionTypes.GET_SESSION_PATIENT_DETAILS,
  payload: data,
});
export const previousSessionDetailsAction = (data) => ({
  type: actionTypes.GET_PREVIOUS_SESSION_DETAILS,
  payload: data,
});
export const sessionHeartHabitDetailsAction = (data) => ({
  type: actionTypes.GET_HEART_HABIT,
  payload: data,
});
export const sessionProgramFocusDetailsAction = (data) => ({
  type: actionTypes.GET_PROGRAM_FOCUS,
  payload: data,
});
export const addSessionHeartHabitDetailsAction = (prevData, data) => ({
  type: actionTypes.ADD_HEART_HABIT,
  payload: { prevData, data },
});
export const editSessionHeartHabitDetailsLoadingAction = (prevData, id) => ({
  type: actionTypes.EDIT_HEART_HABIT_LOADING,
  payload: { loading: true, prevData, id },
});
export const editSessionHeartHabitDetailsAction = (prevData, data, id) => ({
  type: actionTypes.EDIT_HEART_HABIT,
  payload: { prevData, data, id, loading: false },
});
export const editSessionHeartHabitDetailsFailAction = (prevData, id) => ({
  type: actionTypes.EDIT_HEART_HABIT_FAIL,
  payload: { loading: false, prevData, id, error: true },
});
export const deleteSessionHeartHabitDetailsAction = (prevData, id) => ({
  type: actionTypes.DELETE_HEART_HABIT,
  payload: { prevData, id },
});
export const editSessionProgramFocusDetailsAction = (prevData, data, id) => ({
  type: actionTypes.EDIT_PROGRAM_FOCUS,
  payload: { prevData, data, id },
});

export const sessionPatientDetails =
  (teamId, clinicianId, patientId, appointmentId) => (dispatch, getState) => {
    //dispatch(sessionPatientDetailsAction(patientDetails));
    // TeleKardiaAPI.get(
    //   `api/v1/teams/${teamId}/clinicians/${clinicianId}/patients/${patientId}/appointments/${appointmentId}/patientDetails`,
    //   {
    //     headers: { Authorization: ` Bearer ${getToken()}` },
    //   },
    // )
    //   .then((res) => {
    //     let resultData = res.data.result;
    //     dispatch(sessionPatientDetailsAction(resultData));
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

export const previousSessionDetails =
  (teamId, clinicianId, patientId, appointmentId, offsetInMinutes) => (dispatch, getState) => {
    //dispatch(getChatUsersStart());
    TeleKardiaAPI.get(
      `api/v1/teams/${teamId}/clinicians/${clinicianId}/patients/${patientId}/appointments/${appointmentId}/previous?offsetInMinutes=${offsetInMinutes}&timeZone=${getTimezoneName()}`,
      {
        headers: { Authorization: ` Bearer ${getToken()}` },
      },
    )
      .then((res) => {
        let resultData = res.data.result;
        if (res.status === 200) {
          GoAPI.get(`/i/v1/kcc/heartHabits/${patientId}/previous`, {
            headers: { Authorization: ` Bearer ${getToken()}` },
          }).then((res) => {
            let data = res.data;
            console.log(data);
            resultData.heartHabit = data ? data : [];
            if (res.status === 200) {
              console.log(resultData);
              dispatch(previousSessionDetailsAction(resultData));
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
export const sessionHeartHabitDetails =
  (teamId, clinicianId, patientId, appointmentId, offsetInMinutes) => (dispatch, getState) => {
    // TeleKardiaAPI.get(
    //   `api/v1/teams/${teamId}/clinicians/${clinicianId}/patients/${patientId}/appointments/${appointmentId}/heartHealth?offsetInMinutes=${offsetInMinutes}&timeZone=${getTimezoneName()}`,
    //   {
    //     headers: { Authorization: ` Bearer ${getToken()}` },
    //   },
    // )
    GoAPI.get(`/i/v1/kcc/heartHabit/${patientId}/${appointmentId}`, {
      headers: { Authorization: ` Bearer ${getToken()}` },
    })
      .then((res) => {
        console.log(res.data);
        let resultData = res.data || [];
       
          dispatch(sessionHeartHabitDetailsAction(resultData));
        
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

export const sessionProgramFocus =
  (teamId, clinicianId, patientId, appointmentId) => (dispatch) => {
    TeleKardiaAPI.get(
      `api/v1/teams/${teamId}/clinicians/${clinicianId}/patients/${patientId}/appointments/${appointmentId}/programFocus`,
      {
        headers: { Authorization: ` Bearer ${getToken()}` },
      },
    )
      .then((res) => {
        let resultData = res.data.result;
        console.log('res data- ', resultData);
        dispatch(sessionProgramFocusDetailsAction(resultData));
      })
      .catch((err) => console.log('error- ', err));
  };
export const editSessionProgramFocus =
  (teamId, clinicianId, patientId, appointmentId, programFocusId, data) => (dispatch, getState) => {
    const existingSessionData = getState().session.programFocus;
    TeleKardiaAPI.put(
      `api/v1/teams/${teamId}/clinicians/${clinicianId}/patients/${patientId}/appointments/${appointmentId}/programFocus/${programFocusId}`,
      data,
      {
        headers: { Authorization: ` Bearer ${getToken()}` },
      },
    )
      .then((res) => {
        let resultData = res.data.result;
        console.log('res data- ', resultData);
        dispatch(
          editSessionProgramFocusDetailsAction(existingSessionData, resultData, programFocusId),
        );
      })
      .catch((err) => console.log('error- ', err));
  };

export const editSessionHeartHabitDetails =
  (teamId, clinicianId, patientId, appointmentId, offsetInMinutes, heartHealthId, data) =>
  (dispatch, getState) => {
    const existingSessionData = getState().session.heartHabit;
    data.recurrencePattern = data.recurrencePattern.toString();
    dispatch(editSessionHeartHabitDetailsLoadingAction(existingSessionData, heartHealthId));
    // TeleKardiaAPI.put(
    //   `api/v1/teams/${teamId}/clinicians/${clinicianId}/patients/${patientId}/appointments/${appointmentId}/heartHealth/${heartHealthId}?offsetInMinutes=${offsetInMinutes}&timeZone=${getTimezoneName()}`,
    //   data,
    //   {
    //     headers: { Authorization: ` Bearer ${getToken()}` },
    //   },
    // )
    GoAPI.put(`/i/v1/kcc/heartHabit/${heartHealthId}`, data, {
      headers: { Authorization: ` Bearer ${getToken()}` },
    })
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          const resultData = res.data;
          dispatch(
            editSessionHeartHabitDetailsAction(existingSessionData, resultData, heartHealthId),
          );
        }
      })
      .catch((err) => {
        editSessionHeartHabitDetailsFailAction(existingSessionData, heartHealthId);
        console.log('error', err);
      });
  };

export const addSessionHeartHabitDetails =
  (teamId, clinicianId, patientId, appointmentId, offsetInMinutes, data) =>
  (dispatch, getState) => {
    const existingSessionData = getState().session.heartHabit;
    data.recurrencePattern = data.recurrencePattern.toString();
    // TeleKardiaAPI.post(
    //   `api/v1/teams/${teamId}/clinicians/${clinicianId}/patients/${patientId}/appointments/${appointmentId}/heartHealth?offsetInMinutes=${offsetInMinutes}&timeZone=${getTimezoneName()}`,
    //   data,
    //   {
    //     headers: { Authorization: ` Bearer ${getToken()}` },
    //   },
    // );
    GoAPI.post(`/i/v1/kcc/heartHabit/${patientId}/${appointmentId}`, data, {
      headers: { Authorization: ` Bearer ${getToken()}` },
    })
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          const resultData = res.data;
          dispatch(addSessionHeartHabitDetailsAction(existingSessionData, resultData));
        }
      })
      .catch((err) => {
        console.log('error', err);
      });
  };
export const deleteSessionHeartHabitDetails =
  (teamId, clinicianId, patientId, appointmentId, offsetInMinutes, heartHealthId) =>
  (dispatch, getState) => {
    const existingSessionData = getState().session.heartHabit;
    // TeleKardiaAPI.delete(
    //   `api/v1/teams/${teamId}/clinicians/${clinicianId}/patients/${patientId}/appointments/${appointmentId}/heartHealth/${heartHealthId}?offsetInMinutes=${offsetInMinutes}&timeZone=${getTimezoneName()}`,
    //   {
    //     headers: { Authorization: ` Bearer ${getToken()}` },
    //   },
    // )
    GoAPI.delete(`/i/v1/kcc/heartHabit/${heartHealthId}`, {
      headers: { Authorization: ` Bearer ${getToken()}` },
    })
      .then((res) => {
        if (res.status === 200) {
          // const resultData = res.data.result;
          dispatch(deleteSessionHeartHabitDetailsAction(existingSessionData, heartHealthId));
        }
      })
      .catch((err) => {
        console.log('error', err);
      });
  };
