import React from 'react';
import { NavLink as NL } from 'react-router-dom';
import styles from '../NavigationSidebar.module.css';
import { Strophe } from 'strophe.js';
import { useTranslation } from 'react-i18next';

const renderLinkContent = (props) => {
  const { Icon, text, secondaryBullet = false, color = '#2D9F86' } = props;

  return (
    <div className={styles.leftGroup}>
      {Icon && (
        <div className={styles.navIcon}>
          <Icon style={{ fill: color }} />
        </div>
      )}
      {secondaryBullet && <div className={styles.bullet} />}
      <span className={styles.navText}>{text}</span>
    </div>
  );
};

const NavLink = (props) => {
  const { t } = useTranslation();
  const {
    className,
    activeClassName,
    to,
    href,
    onClick,
    target = '',
    text,
    unreadCount,
    style,
    status,
    alertCount,
  } = props;
  const args = { ...props };
  args.text = t(text);
  if (href) {
    return (
      <a href={href} className={styles.navExtLink} target={target}>
        {renderLinkContent(props)}
      </a>
    );
  }
  return (
    <NL
      style={style}
      className={className}
      activeClassName={activeClassName}
      onClick={() => {
        if (typeof onClick === 'function') {
          onClick();
        }
      }}
      to={to}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {renderLinkContent(props)}
        {text === 'Inbox' && (
          <div
            style={{
              margin: '0 10px',
              width: '5px',
              height: '5px',
              borderRadius: '50%',
              backgroundColor:
                status === Strophe.Status.CONNECTED
                  ? 'green'
                  : status === Strophe.Status.AUTHFAIL
                  ? 'red'
                  : 'grey',
            }}
          ></div>
        )}
        {text === 'Inbox' && unreadCount > 0 && <div className={styles.count}>{unreadCount}</div>}
        {text === 'Alert' && alertCount > 0 && <div className={styles.count}>{alertCount}</div>}
      </div>
    </NL>
  );
};

export default NavLink;
