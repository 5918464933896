/* eslint-disable */
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import styles from './Forms.module.css';
import TextInput from './components/TextInput';
import MaskedInput from './components/MaskedInput';
import CheckboxInput from './components/CheckboxInput';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import SelectField from '@material-ui/core/Select';

import Loading from '../Loading';
import ValidationRules from '../ValidationRules';
import {
  emailMask,
  requiredValidation,
  emailValidation,
  minLen1Validation,
  phoneValidationWith1Prefix,
  nameValidation,
  minPhoneNumberValidation,
  ophn1Prefeix,
  phoneValidation,
} from './components/formValidations';
// import { checkTKFeatureEnabled } from 'configs'
import pluseIcon from 'assets/images/Vector.png';
import { FormControl, Grid, InputLabel, Link } from '@material-ui/core';
import { Item } from 'rc-menu';
import { useTranslation } from 'react-i18next';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import EcgLoader from '../Shared/EcgLoader';
import { AUTH_TYPE } from 'constants/app';
import MenuItem from '@material-ui/core/MenuItem';
// check teleconnect enabled
export function checkTKFeatureEnabled(me) {
  if (me.data) {
    const { teams } = me.data;
    const features = teams ? teams[0].features : null;
    const idtfFeature = features && features.find((feat) => feat.feature === 'teleconnect');
    return idtfFeature && idtfFeature.enabled;
  }

  return me.isFetching;
}

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

// constants for country and region
const countryList = [
  {
    name: 'United States',
    value: 'US',
  },
];

const RegionList = [
  { name: 'ALABAMA', value: 'AL' },
  { name: 'ALASKA', value: 'AK' },
  { name: 'AMERICAN SAMOA', value: 'AS' },
  { name: 'ARIZONA', value: 'AZ' },
  { name: 'ARKANSAS', value: 'AR' },
  { name: 'CALIFORNIA', value: 'CA' },
  { name: 'COLORADO', value: 'CO' },
  { name: 'CONNECTICUT', value: 'CT' },
  { name: 'DELAWARE', value: 'DE' },
  { name: 'DISTRICT OF COLUMBIA', value: 'DC' },
  { name: 'FEDERATED STATES OF MICRONESIA', value: 'FM' },
  { name: 'FLORIDA', value: 'FL' },
  { name: 'GEORGIA', value: 'GA' },
  { name: 'GUAM', value: 'GU' },
  { name: 'HAWAII', value: 'HI' },
  { name: 'IDAHO', value: 'ID' },
  { name: 'ILLINOIS', value: 'IL' },
  { name: 'INDIANA', value: 'IN' },
  { name: 'IOWA', value: 'IA' },
  { name: 'KANSAS', value: 'KS' },
  { name: 'KENTUCKY', value: 'KY' },
  { name: 'LOUISIANA', value: 'LA' },
  { name: 'MAINE', value: 'ME' },
  { name: 'MARSHALL ISLANDS', value: 'MH' },
  { name: 'MARYLAND', value: 'MD' },
  { name: 'MASSACHUSETTS', value: 'MA' },
  { name: 'MICHIGAN', value: 'MI' },
  { name: 'MINNESOTA', value: 'MN' },
  { name: 'MISSISSIPPI', value: 'MS' },
  { name: 'MISSOURI', value: 'MO' },
  { name: 'MONTANA', value: 'MT' },
  { name: 'NEBRASKA', value: 'NE' },
  { name: 'NEVADA', value: 'NV' },
  { name: 'NEW HAMPSHIRE', value: 'NH' },
  { name: 'NEW JERSEY', value: 'NJ' },
  { name: 'NEW MEXICO', value: 'NM' },
  { name: 'NEW YORK', value: 'NY' },
  { name: 'NORTH CAROLINA', value: 'NC' },
  { name: 'NORTH DAKOTA', value: 'ND' },
  { name: 'NORTHERN MARIANA ISLANDS', value: 'MP' },
  { name: 'OHIO', value: 'OH' },
  { name: 'OKLAHOMA', value: 'OK' },
  { name: 'OREGON', value: 'OR' },
  { name: 'PALAU', value: 'PW' },
  { name: 'PENNSYLVANIA', value: 'PA' },
  { name: 'PUERTO RICO', value: 'PR' },
  { name: 'RHODE ISLAND', value: 'RI' },
  { name: 'SOUTH CAROLINA', value: 'SC' },
  { name: 'SOUTH DAKOTA', value: 'SD' },
  { name: 'TENNESSEE', value: 'TN' },
  { name: 'TEXAS', value: 'TX' },
  { name: 'UTAH', value: 'UT' },
  { name: 'VERMONT', value: 'VT' },
  { name: 'VIRGIN ISLANDS', value: 'VI' },
  { name: 'VIRGINIA', value: 'VA' },
  { name: 'WASHINGTON', value: 'WA' },
  { name: 'WEST VIRGINIA', value: 'WV' },
  { name: 'WISCONSIN', value: 'WI' },
  { name: 'WYOMING', value: 'WY' },
];

const UpdatePasswordForm = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = React.useState({
    current: false,
    new: false,
    confirm: false,
  });
  const notificationData = useSelector((state) => state.app.dataSources.memberNotificationSettings);
  const memberData = useSelector((state) => state.app.dataSources.memberNotificationSettings);
  const userType = useSelector((state) => state.user.chatProfile.userType);
  const [appointmentNotification, setAppointmentNotification] = useState({
    isEmail:
      TKFeatureEnabled && notificationData && notificationData.data && notificationData.data.result
        ? notificationData.data.result.emailEnabled
        : false,
    isText:
      TKFeatureEnabled && notificationData && notificationData.data && notificationData.data.result
        ? notificationData.data.result.smsEnabled
        : false,
  });
  const {
    handleSubmit,
    handleInputChange,
    handleNotificationChange,
    validationTests,
    inputFieldValidation = false,
    invalid,
    pristine,
    anyTouched ,
    submitSucceeded,
    submitFailed,
    isSubmitting,
    submitting,
    pwError,
    pwErrorMsg,
    me,
    getAppointmentNotificationsSettings,
    onInfoSubmit,
    getMemberInfo,
    memberOnChangeInfo,
    initialValues,
    getMe,
    handleUpdateInputChange,
    isLoading,
    ...rest
  } = props;

  // Constants to check telekardia enabled and user is admin or not
  const TKFeatureEnabled = checkTKFeatureEnabled(me);
  const isAdminEnabled = me && me.data && me.data.profile.permissions.isAdmin;

  // common array for all selected country and region.
  let allCountryRegionArray = [];

  // getting values from counrty and region field from teh form.
  if (memberOnChangeInfo && memberOnChangeInfo.region) {
    allCountryRegionArray.push({
      country: memberOnChangeInfo.country || '',
      region: memberOnChangeInfo.region,
    });
  }

  // geeting values form the members array and combine to the allCountryRegionArray
  if (memberOnChangeInfo && memberOnChangeInfo.members && memberOnChangeInfo.members.length > 0) {
    allCountryRegionArray = [...allCountryRegionArray, ...memberOnChangeInfo.members];
  }

  const [disableSave, setDisableSave] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  useEffect(() => {
    if (memberOnChangeInfo) {
      const { firstName, lastName, phone, email, permissions } = memberOnChangeInfo;
      if (permissions) {
        const { isAdmin, isPhysician } = permissions;
        if (!firstName || !lastName || !phone || !email) {
          setDisableSave(true);
          return;
        }
        let comparingObj = {};
        comparingObj = _.omit(memberOnChangeInfo, ['currentPassword']);
        if (!_.isEqual(comparingObj, initialValues) || anyTouched) {
          setDisableSave(false);
          formSubmitted && setFormSubmitted(false);
        } else {
          setDisableSave(true);
        }
        if (!(isAdmin || isPhysician)) {
          setDisableSave(true);
        }
        if (formSubmitted) {
          setDisableSave(true);
        }
      } else {
        setDisableSave(true);
      }
    }
  }, [memberOnChangeInfo]);

  useEffect(() => {
    if (TKFeatureEnabled) {
      getAppointmentNotificationsSettings();
      // !isAdminEnabled ? getMe() : ''
      getMemberInfo(); // For getting member's data on my info page
    }
  }, []);
  useEffect(() => {
    if (
      TKFeatureEnabled &&
      notificationData &&
      notificationData.data &&
      notificationData.data.result
    ) {
      setAppointmentNotification({
        isEmail: notificationData.data.result.emailEnabled,
        isText: notificationData.data.result.smsEnabled,
      });
    }
  }, [notificationData]);

  const [ButtonDisabled, setButtonDisabled] = useState(true);
  const [isDisabled, setisDisabled] = useState(false);
   const anyError = useSelector((state) => state.form?.updatePasswordForm?.syncErrors);
    const requiredValues =
      anyError &&
      Object.keys(anyError).filter((item) => {
        return item === 'lastName' || item === 'firstName' || item === 'phone' || item === 'email';
      });
   useEffect(() => {
     setButtonDisabled(
        // requiredValues?.length === 0 ? (inputFieldValidation ? false : !isDisabled) : true,
       requiredValues?.length === 0 ? false:true
     );
   }, [isDisabled, inputFieldValidation, requiredValues]);
  const renderSelectField = withStyles({
    select: {
      height: '10px',
    },
  })(({ input, label, meta: { touched, error }, children, ...custom }) => (
    <FormControl size="small" variant="outlined">
      <InputLabel>{label}</InputLabel>
      <SelectField
        floatingLabelText={label}
        errorText={touched && error}
        className={styles.selectFieldStyle}
        {...input}
        // eslint-disable-next-line react/no-children-prop
        children={children}
        {...custom}
      />
      {error && <span className={styles.error}>{error}</span>}
    </FormControl>
  ));

  const renderMembers = (temp) => (
    <ul style={{ padding: 0 }}>
      <li
        className={
          memberOnChangeInfo &&
          memberOnChangeInfo.region === null &&
          memberOnChangeInfo.country === null
            ? styles.displayNone
            : styles.editDropdown
        }
      >
        <div>
          <InputLabel className={styles.labelColorDropdown}>Country</InputLabel>
          <Field
            name="country"
            component={renderSelectField}
            disabled={!isAdminEnabled}
            validate={[requiredValidation]}
            //  label="Country"
          >
            {countryList.map((item, i) => (
              <MenuItem key={i} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </Field>
        </div>
        <div>
          <InputLabel className={styles.labelColorDropdown}>Region</InputLabel>
          <Field
            name="region"
            component={renderSelectField}
            disabled={!isAdminEnabled}
            validate={[requiredValidation]}
            //  label="Region"
          >
            {RegionList.map((item, i) => (
              <MenuItem
                key={i}
                disabled={allCountryRegionArray.find(function (r) {
                  return r.region == item.value ? true : false;
                })}
                value={item.value}
              >
                {item.name}
              </MenuItem>
            ))}
          </Field>
        </div>
        {isAdminEnabled ? (
          <div style={{ marginTop: 34 }}>
            <Link
              href="#"
              style={{ marginLeft: 8 }}
              className={temp.fields.length === 0 ? styles.buttonVisibleMyinfo : styles.buttonCss}
              onClick={() => {
                dispatch(change('updatePasswordForm', 'region', null));
                dispatch(change('updatePasswordForm', 'country', null));
              }}
            >
              Remove
            </Link>
            <img
              style={{ marginTop: -6 }}
              className={temp.fields.length === 0 ? styles.buttonCss : styles.buttonVisibleMyinfo}
              src={pluseIcon}
              alt="add button"
            />
            <Link
              style={{ marginLeft: 8 }}
              href="#"
              className={temp.fields.length === 0 ? styles.buttonCss : styles.buttonVisibleMyinfo}
              onClick={() => temp.fields.push({})}
            >
              Add Location
            </Link>
          </div>
        ) : (
          ''
        )}
        {/* {submitFailed && error && <span>{error}</span>} */}
      </li>
      {temp.fields.map((member, index) => (
        <li className={styles.editDropdown} key={index}>
          <div>
            <InputLabel className={styles.labelColorDropdown}>Country</InputLabel>
            <Field
              name={`${member}.country`}
              component={renderSelectField}
              disabled={!isAdminEnabled}
              validate={[requiredValidation]}
              //  label="Country"
            >
              {countryList.map((item, i) => (
                <MenuItem key={i} value={item.value}>
                  {item.name}
                </MenuItem>
              ))}
            </Field>
          </div>
          <div>
            <InputLabel className={styles.labelColorDropdown}>Region</InputLabel>
            <Field
              name={`${member}.region`}
              component={renderSelectField}
              disabled={!isAdminEnabled}
              validate={[requiredValidation]}
              //  label="Region"
            >
              {RegionList.map((item, i) => (
                <MenuItem
                  key={i}
                  disabled={allCountryRegionArray.find(function (r) {
                    return r.region == item.value ? true : false;
                  })}
                  value={item.value}
                >
                  {item.name}
                </MenuItem>
              ))}
            </Field>
          </div>
          {isAdminEnabled ? (
            <div style={{ marginTop: 34 }}>
              <Link
                href="#"
                style={{ marginLeft: 8 }}
                className={
                  temp.fields.length > 0 && temp.fields.length === index
                    ? styles.buttonVisibleMyinfo
                    : styles.buttonCss
                }
                onClick={() => temp.fields.remove(index)}
              >
                Remove
              </Link>
            </div>
          ) : (
            ''
          )}
        </li>
      ))}
      {temp.fields.map((member, index) =>
        isAdminEnabled ? (
          <div style={{ marginTop: 14 }}>
            <img
              style={{ marginTop: -6 }}
              className={
                temp.fields.length > 0 && temp.fields.length - 1 === index
                  ? styles.buttonCss
                  : styles.buttonVisibleMyinfo
              }
              src={pluseIcon}
              alt="add button"
            />
            <Link
              href="#"
              style={{ marginLeft: 8 }}
              className={
                temp.fields.length > 0 && temp.fields.length - 1 === index
                  ? styles.buttonCss
                  : styles.buttonVisibleMyinfo
              }
              onClick={() => temp.fields.push({})}
            >
              Add Location
            </Link>
          </div>
        ) : (
          ''
        ),
      )}
    </ul>
  );
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        setDisableSave(true);
        setFormSubmitted(true);
        handleSubmit(e);
      }}
    >
      {props.isLoading ? (
        <EcgLoader />
      ) : (
        <React.Fragment>
          {TKFeatureEnabled && (
            <React.Fragment>
              <h2 className={styles.formTitle}>
                {isAdminEnabled
                  ? t('Admin')
                  : userType === AUTH_TYPE.DOCTOR
                  ? t('Doctor')
                  : t('Coach')}{' '}
                {t('Information')}
              </h2>
              <div className={styles.editMemberSection}>
                <div className={styles.formSectionRow}>
                  <div className={styles.editLabelGroup}>
                    <Field
                      component={TextInput}
                      label="First Name"
                      name="firstName"
                      placeholder="Enter First Name"
                      required={true}
                      validate={[minLen1Validation, requiredValidation, nameValidation]}
                      onChange={handleUpdateInputChange}
                    />
                  </div>
                  <div className={styles.editLabelGroup}>
                    <Field
                      component={TextInput}
                      label="Last Name"
                      name="lastName"
                      placeholder="Enter Last Name"
                      required={true}
                      validate={[minLen1Validation, requiredValidation, nameValidation]}
                      onChange={handleUpdateInputChange}
                    />
                  </div>
                  <div className={styles.editLabelGroup}>
                    <Field
                      component={TextInput}
                      rightIcon={PhoneIcon}
                      label="Mobile"
                      name="phone"
                      placeholder="Enter mobile number"
                      required={true}
                      validate={[phoneValidation, requiredValidation]}
                      type={'number'}
                      onChange={handleUpdateInputChange}
                    />
                  </div>
                  <div className={styles.editLabelGroup}>
                    <Field
                      component={MaskedInput}
                      rightIcon={EmailIcon}
                      mask={emailMask}
                      placeholder="name@example.com"
                      label="Email"
                      name="email"
                      required={true}
                      validate={[emailValidation, minLen1Validation, requiredValidation]}
                      onChange={handleUpdateInputChange}
                    />
                  </div>
                </div>

                {userType === AUTH_TYPE.DOCTOR && (
                  <React.Fragment>
                    <div>
                      <div className={styles.editDropdown}>
                        <FieldArray name="members" component={renderMembers} />
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
              <h2 className={styles.formTitle}>{t('Roles')}</h2>
              <div className={styles.editMemberSection}>
                <div className={styles.formSectionRow}>
                  <div
                    style={{
                      opacity: !isAdminEnabled ? 0.4 : '',
                      pointerEvents: !isAdminEnabled ? 'none' : '',
                    }}
                    className={styles.checkboxWithDesc}
                  >
                    <Field
                      component={CheckboxInput}
                      label={userType === AUTH_TYPE.DOCTOR ? t('Doctor') : t('Coach')}
                      name="permissions.isPhysician"
                      onChange={() => {
                        setisDisabled(true);
                        setDisableSave(false);
                      }}
                    />
                    <p style={{ marginBottom: 10 }} className={styles.description}>
                      {userType === AUTH_TYPE.DOCTOR
                        ? t('DoctorDescription')
                        : t('CoachDescription')}
                    </p>
                  </div>
                  <div
                    style={{ opacity: 0.4, pointerEvents: 'none' }}
                    className={styles.checkboxWithDesc}
                  >
                    <Field
                      component={CheckboxInput}
                      label={
                        userType === AUTH_TYPE.DOCTOR
                          ? t('Account Administrator')
                          : t('Coach Administrator')
                      }
                      name="permissions.isAdmin"
                      onChange={() => {
                        setisDisabled(true);
                        setDisableSave(false);
                      }}
                    />
                    <p className={styles.description}>
                      {userType === AUTH_TYPE.DOCTOR
                        ? t('AccountAdministratorDescription')
                        : t('CoachAdministratorDescription')}
                    </p>
                  </div>
                  <div className={styles.placeholder} />
                </div>
              </div>
              {/* Start Appointment Notifications  */}
              <h2 style={{ marginLeft: 0 }} className={styles.formTitle}>
                {t('Appointment Notifications')}
              </h2>
              <p className={styles.appointmentText}>{t('AppointmentNotificationsDescription')}</p>
              <div className={styles.formSection}>
                <div style={{ justifyContent: 'unset' }} className={styles.formSectionRow}>
                  {notificationData && notificationData.isFetching ? (
                    <Loading />
                  ) : (
                    <React.Fragment>
                      <div style={{ width: '10%' }} className={styles.checkboxWithDesc}>
                        <FormControlLabel
                          control={
                            <GreenCheckbox
                              checked={appointmentNotification.isText}
                              onChange={(e) => {
                                setAppointmentNotification({
                                  ...appointmentNotification,
                                  isText: e.target.checked,
                                });
                                // setisDisabled(true);
                                setDisableSave(false);
                              }}
                              name="isText"
                            />
                          }
                          label="Text"
                        />
                      </div>
                      <div className={styles.checkboxWithDesc}>
                        <FormControlLabel
                          control={
                            <GreenCheckbox
                              checked={appointmentNotification.isEmail}
                              onChange={(e) => {
                                setAppointmentNotification({
                                  ...appointmentNotification,
                                  isEmail: e.target.checked,
                                });
                                // setisDisabled(true);
                                setDisableSave(false);
                              }}
                              name="isEmail"
                            />
                          }
                          label="Email"
                        />
                      </div>
                      <div className={styles.placeholder} />
                    </React.Fragment>
                  )}
                </div>
              </div>
              <div className={styles.appointmentbuttonWrapper}>
                {submitting || isSubmitting ? (
                  <button disabled className={styles.submitLoading}>
                    <Loading />
                  </button>
                ) : (
  // Member info section implementation on My Info page
                  <button
                    style={{ width: 'auto' }}
                    className={styles.submit}
                    // disabled={isDisabled ? !isDisabled : requiredValues?.length === 0}
                    // disabled={ButtonDisabled}
                    onClick={() => {
                      handleNotificationChange(appointmentNotification),
                        onInfoSubmit(memberOnChangeInfo),
                        setDisableSave(true)
                    }}
                     disabled={ ButtonDisabled || disableSave}
                  >
                    {t(`Save Changes`)}
                  </button>
                )}
              </div>
            </React.Fragment>
          )}
          <h2 style={{ marginLeft: 0 }} className={styles.formTitle}>
            {t('Update Password')}
          </h2>
          <div className={styles.formSection}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  component={TextInput}
                  rightIcon={showPassword.current ? VisibilityOffIcon : VisibilityIcon}
                  onRightIconClick={() =>
                    setShowPassword((prev) => ({ ...prev, current: !prev.current }))
                  }
                  name="currentPassword"
                  label="Current password"
                  type={showPassword.current ? 'text' : 'password'}
                  showRedRequiredIcon={true}
                  placeholder="********"
                  validate={requiredValidation}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={TextInput}
                  rightIcon={showPassword.new ? VisibilityOffIcon : VisibilityIcon}
                  onRightIconClick={() => setShowPassword((prev) => ({ ...prev, new: !prev.new }))}
                  name="newPassword"
                  label="New password"
                  type={showPassword.new ? 'text' : 'password'}
                  showRedRequiredIcon={true}
                  validate={requiredValidation}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={4}>
                {' '}
                <Field
                  component={TextInput}
                  rightIcon={showPassword.confirm ? VisibilityOffIcon : VisibilityIcon}
                  onRightIconClick={() =>
                    setShowPassword((prev) => ({ ...prev, confirm: !prev.confirm }))
                  }
                  name="newPasswordConfirm"
                  label="Confirm new password"
                  type={showPassword.confirm ? 'text' : 'password'}
                  showRedRequiredIcon={true}
                  validate={requiredValidation}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={4}>
                <div className={styles.updatePwWrapper}>
                  <div className={styles.buttonWrapper}>
                    {submitting || isSubmitting ? (
                      <button disabled className={styles.submitLoading}>
                        <Loading />
                      </button>
                    ) : (
                      <button
                        type="submit"
                        disabled={invalid || pristine}
                        className={styles.submit}
                      >
                        {t('Update Password')}
                      </button>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
            <div className={styles.validationRules}>
              <ValidationRules validationTests={validationTests} updatePassword={true} />
            </div>

            {pwError && <div className={styles.pwValidationError}>{pwErrorMsg}</div>}
          </div>
        </React.Fragment>
      )}
    </form>
  );
};

export default reduxForm({
  form: 'updatePasswordForm',
  enableReinitialize: true,
})(UpdatePasswordForm);
