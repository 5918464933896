import React, { useContext, useEffect, useRef, useState } from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { getAge, getTimezoneName } from 'utilities/Utilities';
import { makeStyles, Paper } from '@material-ui/core';
import ToastButton from '../ContentHeader/ToastButton';
import * as actions from 'store/actions';
import moment from 'moment';
// import { Chat } from '../../providers';
import MemberMissedAlert from 'screens/coach/Common/MemberMissedAlert';
import { ChatContext } from '@alivecor/strophe2.0';
import { TeleKardiaAPI } from '../../Axios/axios';
import { getToken } from 'auth/AuthUtilities';
import config from '../../setup';
const useStyle = makeStyles({
  root: {
    '& .MuiAlert-message': {
      padding: '0 !important',
    },
  },
});
function Alert(props) {
  const classes = useStyle();
  return (
    <MuiAlert
      style={{
        backgroundColor: props.bg,
        fontFamily: 'Work Sans',
        fontWeight: 'bold',
        fontSize: '14px',
        width: 'auto',
        visibility: props.visibility,
        display: 'flex',
        alignItems: 'center',
      }}
      className={classes.root}
      elevation={6}
      variant="filled"
      {...props}
    />
  );
}

const AppNotification = () => {
  const { currentAppointmentUser: caUser } = useContext(ChatContext);
  const [minLeft, setminLeft] = useState('');
  const [isSessionAlert, setisSessionAlert] = useState(false);
  const [memberWaiting, setmemberWaiting] = useState(false);
  const teamId = useSelector((state) => state.user.team.id);
  const [showAlert, setShowAlert] = useState(false); //state to flag if there is any upcoming session or member has joined the session
  const [showMissedAlert, setshowMissedAlert] = useState(false); //state to flag if member missed session.
  const appointments = useSelector((state) => state.events.events);
  const errorMsg = useSelector((state) => state.user.errorMsg);
  //Things needed for opening appointment on click
  // console.log('ERROR MESSAGE', errorMsg);
  const dispatch = useDispatch();

  const [appointmentId_, setappointmentId_] = React.useState('');
  const [patientDetails, setPatientDetails] = React.useState({});
  const [recording, setRecording] = React.useState([]);

  const patientDetailsntwrk = useSelector((state) => state.appointments.patientDetails);
  const recordingntwrk = useSelector((state) => state.ekg.recording);
  // const error = useSelector((state) => state.appointments.error);
  const patientDetailsLoader = useSelector((state) => state.appointments.detailsloader);
  const profileId = useSelector((state) => state.user?.profile.id);
  const isComponentMounted = useSelector((state) => state.user.isComponentMounted);

  const pattern = 'YYYY-MM-DDTHH:mm:ss.SSS';
  //===Setting visibility of toast baner false when coach joined the session===//
  const [visibility, setvisibility] = useState('visible');
  const hideToast = () => {
    setvisibility('hidden');
  };

  /**
   * Adding this as a fallback if events are not fetched to show
   */
  useEffect(() => {
    if (appointments.length === 0) {
      let queryParams = {
        startDate: moment(moment().startOf('day')._d).format(pattern),
        endDate: moment(moment().endOf('day')._d).format(pattern),
        offsetInMinutes: new Date().getTimezoneOffset(),
        timeZone: getTimezoneName(),
        splitBasedOnDays: true,
      };
      dispatch(actions.getAvailabilities(profileId, teamId, queryParams));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //method that shows banner
  const prepForBanner = (app, waiting) => {
    !isComponentMounted && setappointmentId_(app.id);
    if (waiting && !isComponentMounted) {
      setmemberWaiting(true);
      setisSessionAlert(false);
      dispatch(
        actions.getRecordingSamples(app.ekgId, teamId, profileId, app.id, {
          timeZoneOffset: new Date().getTimezoneOffset(),
          timeZone: getTimezoneName(),
        }),
      );
    } else {
      setmemberWaiting(false);
      setisSessionAlert(true);
    }
    setShowAlert(true);
    return;
  };
  //api call
  useEffect(() => {
    let app = null;
    app = appointments.filter((t) => t.status === 12 && t.eventType === 'appointment');

    if (app.length > 0 && !isComponentMounted) {
      prepForBanner(app[0], true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointments]);

  //bosh call
  useEffect(() => {
    //need to check if there are no current running appointment before setting the appointment id
    // console.log('bosh call coming in!');
    if (caUser && !isComponentMounted) {
      // console.log('doing bosh call routine');
      if (caUser.currentMode === 'MemberEnteredWaitingRoom') {
        setmemberWaiting(true);
        setisSessionAlert(false);
        setShowAlert(true);
      } else if (caUser.currentMode === 'UpcomingNotification') {
        setmemberWaiting(false);
        setisSessionAlert(true);
        setShowAlert(true);
      } else if (caUser.currentMode === 'MemberNotEnteredWaitingRoom') {
        //====== Showing alert modal when member did not join the waiting room within 6 mins of sesison =====//
        setmemberWaiting(false);
        setisSessionAlert(false);
        setshowMissedAlert(true);
        setShowAlert(false); // not to show the banner alert when member missed the session
      } else if (caUser.currentMode === 'CoachNotJoined') {
        setvisibility('hidden');
      }
      setappointmentId_(caUser.extraData?.appointmentId);
    }
  }, [caUser, isComponentMounted]);
  //to populate appointment.patientDetails
  useEffect(() => {
    if (appointmentId_ && !isComponentMounted) {
      console.log('DIAGNOSTIC: bosh call appappointmentId_ - ', appointmentId_);
      let app = appointments.filter((t) => t.id === appointmentId_);
      dispatch(
        actions.getRecordingSamples(app.ekgId, teamId, profileId, appointmentId_, {
          timeZoneOffset: new Date().getTimezoneOffset(),
          timeZone: getTimezoneName(),
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentId_, appointments]);

  useEffect(() => {
    if (patientDetailsntwrk.patient && !isComponentMounted) {
      patientDetailsntwrk.patient['age'] = getAge(patientDetailsntwrk.patient.dob);
      setPatientDetails(patientDetailsntwrk);
      setRecording(recordingntwrk);
    }
  }, [patientDetailsntwrk, recordingntwrk, isComponentMounted]);

  const latestAppointmentWatcher = useRef(null);
  //session is in 'x' mins
  useEffect(() => {
    if (appointments.length > 0 && isSessionAlert && appointmentId_ && !isComponentMounted) {
      const filteredApp = appointments.filter((item) => item.id === appointmentId_);

      setisSessionAlert(true);
      setShowAlert(true);
      //The wrapper watcher
      if (filteredApp.length > 0) {
        latestAppointmentWatcher.current = setInterval(() => {
          let item = filteredApp[0];
          let start = moment(item.start);
          let diffMins = moment(start).diff(new Date(), 'minute');
          // console.log('DIFF MINS', diffMins);
          if (diffMins < 15 && diffMins >= 0) {
            setminLeft(diffMins);
          }
        }, 10000);
      }
    }
    return () => {
      // console.log('decomissionsing')
      clearInterval(latestAppointmentWatcher.current);
    };
  }, [appointments, isSessionAlert, appointmentId_, isComponentMounted]);

  //Fail safe poller for waiting room event
  // 1. check if appointments are there
  // 2. if yes, check if any of the appointments starting x mins from now (latest scheduled app)
  // 3. if yes, start poller and poll with appointmemt id for the status
  // 4. poll until the app status is 12
  // 5. cancel poll

  const MainPoller = useRef(null);
  const MainPollerInterval = 60000;
  const PollForAppStatus = useRef(null);
  const PollerInterval = 10000;

  const startAppointmentPoller = (appointmentToPoll) => {
    PollForAppStatus.current = setInterval(() => {
      console.log('DIAGNOSTIC: POLLER STARTED!');
      let version = appointmentToPoll.version;
      config.TeleKardiaAPI.get(
        `/api/v1/teams/${teamId}/clinicians/${profileId}/appointments/${appointmentToPoll.id}/status?version=${version}`,
        {
          headers: { Authorization: ` Bearer ${getToken()}` },
        },
      )
        .then((res) => {
          //cancelling poller if api fails
          if (res.status !== 200) {
            clearInterval(PollForAppStatus.current);
          }
          const apStatus = res.data.result.appointment_statusid;
          // console.log(`Polled appointment status for ${appointmentToPoll.id} is ${apStatus}`);
          if (apStatus === 12 && !isComponentMounted) {
            console.log('DIAGNOSTIC: Prep appointment and cancel poller');
            prepForBanner(appointmentToPoll, true);
            clearInterval(PollForAppStatus.current);
          }
        })
        .catch((err) => console.log('error - ', err));
    }, PollerInterval);
  };
  useEffect(() => {
    if (appointments.length > 0) {
      let appointmentToPoll = null;
      let cusApp = appointments
        .filter((t) => t.eventType === 'appointment' && t.status === 1)
        .sort(function (a, b) {
          return new Date(a.start) - new Date(b.start);
        });
      if (cusApp.length > 0 && !isComponentMounted) {
        MainPoller.current = setInterval(() => {
          // console.log('MAIN POLLER GOING!');
          appointmentToPoll = cusApp[0]; //latest scheduled app
          let start = moment(appointmentToPoll.start);
          let diffMins = moment(start).diff(new Date(), 'minute');
          // console.log('DIFF MINS', diffMins);
          if (diffMins < 15 && diffMins >= -3 && !PollForAppStatus.current) {
            //showing banner
            prepForBanner(appointmentToPoll, false);
          }
          if (diffMins < 2 && diffMins >= -5 && !PollForAppStatus.current) {
            console.log('DIAGNOSTIC: STARTING APPOINTMENT POLLER AND CANCELLING MAIN');
            startAppointmentPoller(appointmentToPoll);
            clearInterval(MainPoller.current);
          }
        }, MainPollerInterval);
      }
      return () => {
        //decommision the main poller
        clearInterval(MainPoller.current);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointments]);

  return (
    <>
      {showAlert && (
        <Paper
          style={{
            zIndex: visibility === 'hidden' ? -1 : 10,
            position: 'absolute',
            top: 12,
            right: 32,
          }}
        >
          <Alert severity="info" visibility={visibility} bg="#885FA5">
            <span>
              {isSessionAlert
                ? minLeft >= 1
                  ? `SESSION STARTS IN ${minLeft} ${minLeft > 1 ? 'MINUTES' : 'MINUTE'}`
                  : 'SESSION WILL START SOON'
                : memberWaiting
                ? 'MEMBER IN WAITING ROOM'
                : 'SESSION IN PROGRESS'}
            </span>
            <ToastButton
              {...{
                id: appointmentId_,
                patientDetails: patientDetails,
                recording: recording,
                appointments: appointments,
              }}
              patientDetailsLoader={patientDetailsLoader}
              reviewSession={isSessionAlert}
              memberInWaitingRoom={memberWaiting}
              cancelMemberWaiting={() => setmemberWaiting(false)}
              closeAlert={() => setShowAlert(false)}
              hideToast={hideToast}
            />
          </Alert>
        </Paper>
      )}

      {/* //====== component that shows alert modal when member did not join the waiting room within 6 mins of sesison =====// */}
      {showMissedAlert && <MemberMissedAlert />}

      {errorMsg && (
        <Paper style={{ zIndex: 10, position: 'absolute', top: 12, right: 32 }}>
          <Alert bg="#808080" severity="info">
            <span>{errorMsg}</span>
          </Alert>
        </Paper>
      )}
    </>
  );
};

export default AppNotification;
