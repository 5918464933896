import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import Grid from '@material-ui/core/Grid';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import ChipComponent from './ChipComponent';
import moment from 'moment';
import { fontSizes, fonts, colors, spacing } from '../../../style/styleSettings';

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: `${fonts.SESSION} !important`,
    '& .MuiAccordionDetails-root': {
      marginLeft: theme.spacing(2),
      maxHeight: '340px',
      overflowY: 'scroll',
    },
    '& .MuiChip-outlinedPrimary': {
      color: '#000',
      border: `1px solid ${colors.SPRUCE} !important`,
    },
    '& .MuiChip-outlinedSecondary': {
      color: '#000',
      border: `1px solid ${colors.SESSION_CHIP_OUTLINE_RED} !important`,
    },
    '& .MuiCollapse-container': {
      //marginBottom: '20px !important',
    },
    '& .MuiGrid-item': {
      marginBottom: `${spacing.SMALL} !important`,
    },
    '& .MuiAccordionSummary-content': {
      justifyContent: 'space-between',
      paddingTop: '12px !important',
    },
    '& .MuiIconButton-label': {
      color: `${colors.SPRUCE} !important`,
    },
    '& .MuiAccordionSummary-expandIcon': {
      transform: 'rotate(-90deg)',
    },
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
      transform: 'rotate(180deg)',
    },
    '& .MuiTypography-body2': {
      fontSize: `${fontSizes.BASE}`,
      lineHeight: '0.6',
    },
  },
  headerText: {
    fontWeight: '600 !important',
    color: `${colors.SESSION_BLACK} !important`,
    margin: `${spacing.SESSION_MARGIN_LARGE} 0 !important`,
    fontSize: `${fontSizes.MEDIUM} !important`,
  },
  sectionHeader: {
    fontWeight: '600 !important',
    color: `${colors.SESSION_BLACK} !important`,
    marginBottom: `${spacing.SMALL} !important`,
    fontSize: `${fontSizes.HEADING} !important`,
  },
  headerCollapse: {
    color: `${colors.SPRUCE} !important`,
    fontFamily: 'SF Pro Display !important',
    fontSize: `${fontSizes.BASE} !important`,
    fontWeight: 'bold !important',
  },
  surveyQa: {
    marginLeft: theme.spacing(2),
    '& .MuiGrid-item': {
      marginBottom: theme.spacing(1),
    },
  },
  rowSpaces: {
    marginBottom: `${spacing.SESSION_MARGIN_LARGE}`,
  },
}));

const PreviousSession = () => {
  const lastLogin = useSelector((state) => state.app.dataSources.me.data.profile.previousLoginAt);
  const [expand, setExpand] = useState(true);
  const [sessionData, setsessionData] = useState({});
  const [sessionDataProgramFocus, setsessionDataProgramFocus] = useState([]);
  const [sessionhearthabit, setsessionhearthabit] = useState([]);

  let m = moment.utc(lastLogin, 'YYYY-MM-DD h:mm:ss A'); // parse input as UTC
  const prevLoginInfo = m.clone().local().format('DD/MM/YYYY hh:mm A'); // local time

  const previousNotes = useSelector((state) => state.session.previousSessionData);
  useEffect(() => {
    setsessionData(previousNotes);
    setsessionDataProgramFocus(previousNotes.programFocus);
    setsessionhearthabit(previousNotes.heartHabit);
  }, [previousNotes]);
  const classes = useStyles();

  return (
    <>
      {/* if previous session api response has no data to show then hide the previous note section  */}
      {Object.keys(previousNotes).length !== 0 &&
        ((previousNotes?.note !== null && previousNotes?.note?.appointmentNote !== null) ||
          previousNotes?.programFocus.length !== 0 ||
          previousNotes?.heartHabit.length !== 0) && (
          <Box>
            <Typography
              variant="caption"
              style={{ display: 'flex', justifyContent: 'end', fontSize: `${fontSizes.CAPTION}` }}
            >
              Last login: {prevLoginInfo}
            </Typography>
            <Accordion className={classes.root} expanded={expand}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={() => setExpand((e) => !e)}
              >
                <Typography variant="h5" className={classes.sectionHeader}>
                  Previous Session Notes
                </Typography>
                <Typography variant="h6" className={classes.headerCollapse}>
                  {expand ? 'COLLAPSE' : 'EXPAND'}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container rowSpacing={10}>
                  <Grid item xs={12} className={classes.rowSpaces}>
                    <Typography variant="h6">
                      <span className={classes.headerText}>
                        {m.clone().local().format('DD/MM/YYYY')}
                      </span>
                      <span
                        style={{
                          fontSize: `${fontSizes.SMALL} !important`,
                          marginLeft: '20px',
                          fontWeight: 'bold',
                        }}
                      >
                        Risk Level: {sessionData?.risk_level || ' -- '}
                      </span>
                    </Typography>
                  </Grid>
                  {sessionData?.note?.appointmentNote && (
                    <Grid item xs={12} className={classes.rowSpaces}>
                      <Typography variant="h6" className={classes.headerText}>
                        Notes:
                      </Typography>
                      <Typography variant="body2" style={{ lineHeight: '1.5' }}>
                        {sessionData?.note?.appointmentNote}
                      </Typography>
                    </Grid>
                  )}
                  {previousNotes?.programFocus.length !== 0 && (
                    <Grid item xs={12}>
                      <Typography variant="h6" className={classes.headerText}>
                        Program Focus:
                      </Typography>
                      {sessionDataProgramFocus &&
                        sessionDataProgramFocus.map((item) => {
                          return (
                            <Grid
                              item
                              xs={12}
                              style={{ marginLeft: `${spacing.SESSION_MARGIN_SMALL}` }}
                              key={item.id}
                            >
                              <div style={{ display: 'inline-flex' }}>
                                <p style={{ fontWeight: 600, fontSize: `${fontSizes.BASE}` }}>
                                  {item.focus}
                                </p>
                                {item.stageOfChange !== null && (
                                  <ChipComponent
                                    variant="outlined"
                                    size="small"
                                    label={item.stageOfChange}
                                    color="primary"
                                    style={{
                                      marginLeft: `${spacing.SESSION_MARGIN_LARGE}`,
                                      fontSize: `${fontSizes.SMALL}`,
                                    }}
                                  />
                                )}
                                {item.achievement !== null && (
                                  <ChipComponent
                                    variant="outlined"
                                    size="small"
                                    label={item.achievement}
                                    color="secondary"
                                    style={{
                                      marginLeft: `${spacing.SESSION_MARGIN_LARGE}`,
                                      fontSize: `${fontSizes.SMALL}`,
                                    }}
                                  />
                                )}
                              </div>
                              <Grid
                                container
                                rowSpacing={1}
                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                              >
                                <Grid item xs={6}>
                                  <Typography variant="body2" style={{ lineHeight: '1.2' }}>
                                    Current: {item.current || ' -- '}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography variant="body2" style={{ lineHeight: '1.2' }}>
                                    Target: {item.target || ' -- '}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          );
                        })}
                    </Grid>
                  )}
                  <Grid item xs={12} md={12} className={classes.headerText}>
                    {previousNotes?.heartHabit.length !== 0 && (
                      <Grid container spacing={0}>
                        {sessionhearthabit &&
                          sessionhearthabit.map((item, index) => {
                            return (
                              <>
                                <Grid item lg={6} md={6} style={{ marginBottom: 0 }} key={index}>
                                  <Typography variant="h6" className={classes.headerText}>
                                    <b>
                                      Heart Habit {index + 1}: {item.focus}
                                    </b>
                                  </Typography>
                                  <Typography variant="body2" style={{ lineHeight: '1.2' }}>
                                    <b>Goal:</b> {item.focusNote}
                                  </Typography>
                                  <Typography variant="body2">
                                    <b>Frequency:</b>{' '}
                                    {item.recurrencePattern.split('=')[1].split(';')[0]}
                                  </Typography>
                                  <Typography variant="body2" style={{ lineHeight: '1.2' }}>
                                    <b>Duration:</b> {moment(item.startDate).format('MM/DD/YYYY')} -{' '}
                                    {moment(item.endDate).format('MM/DD/YYYY')}
                                  </Typography>
                                </Grid>
                              </>
                            );
                          })}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
    </>
  );
};

export default PreviousSession;
