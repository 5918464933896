import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { Box, Typography } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import styles from '../../../memberProfile.module.css';
import { programStyles } from '../styles';
import { useParams } from 'react-router';
import {
  getMemberProfilePPDetails,
  setCurrentWeekDetails,
} from 'store/actions/memberProfile';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'components/Shared/EcgLoader';
import moment from 'moment';
const ProgramProgress = () => {
  const classes = programStyles();
  const dispatch = useDispatch();
  const { memberId } = useParams();
  const { loading, data, error } = useSelector((state) => state.memberProfile.programProgress);
  //local states
  const [ppData, setPpData] = useState(null);
  const [currentWeek, setCurrentWeek] = useState(1);
  const [weekInProgress, setWeekInProgress] = useState(0);
  // programStartTime: "2022-01-08T14:53:12.597317Z"
  //7xqui976wb5yztlgj4mbu6usk
  useEffect(() => {
    dispatch(getMemberProfilePPDetails(1, memberId)); //memberId
  }, [dispatch, memberId]);
  useEffect(() => {
    weekInProgress > 0 && dispatch(getMemberProfilePPDetails(weekInProgress, memberId)); //memberId
  }, [weekInProgress, dispatch, memberId]);
  useEffect(() => {
    if (!loading && data) {
      setPpData(data);
      //setting up the current week from program time
      let start = new Date(data.programStartTime);
      let diff = moment.duration(moment().diff(moment(start)));
      let d = Math.floor(diff.asDays() / 7) + 1;
      if (weekInProgress === 0) {
        //setting current week in redux to use it in the other sections of the program tab
        dispatch(setCurrentWeekDetails(d));
        setCurrentWeek(d);
        setWeekInProgress(d);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data]);
  const changeWeek = (v) => {
    if (currentWeek + v > 0) {
      setCurrentWeek((w) => w + v);
      dispatch(getMemberProfilePPDetails(currentWeek + v, memberId)); //memberId
    }
  };

  const computeDateRange = () => {
    let start = new Date(ppData.programStartTime);
    let noOfDays = 7 * (currentWeek - 1);
    let rangeStart = moment(start).add(noOfDays, 'days').format('MMMM Do YYYY');
    let rangeEnd = moment(start)
      .add(noOfDays + 7, 'days')
      .format('MMMM Do YYYY');
    return `${rangeStart} - ${rangeEnd}`;
  };
  return (
    <Box style={{ minHeight: '300px' }}>
      <Box style={{ width: '100%' }}>
        {/* week controller comes here */}
        <Grid container className={classes.weekController}>
          <Grid item lg={1} md={1}>
            <Button onClick={() => changeWeek(-1)} disabled={currentWeek === 1}>
              <ChevronLeft />
            </Button>{' '}
          </Grid>
          {ppData && (
            <Grid item lg={2} md={2} style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant="h6">Week {currentWeek}</Typography>
              <Typography variant="body2" style={{ textAlign: 'center' }}>
                {computeDateRange()}
              </Typography>
            </Grid>
          )}
          <Grid item lg={1} md={1}>
            <Button onClick={() => changeWeek(1)}>
              <ChevronRight />
            </Button>
          </Grid>
        </Grid>
        {loading && (
          <Box style={{ marginBottom: '50px' }}>
            <Loader />
          </Box>
        )}
      </Box>

      {!loading && (
        <Box>
          <Box>
            <Typography variant="h6">Member Tasks</Typography>

            <Grid container className={styles.tableRowHeading}>
              {!loading &&
                ppData &&
                ppData.tasks &&
                ppData.tasks.map((p, idx) => (
                  <Grid item lg={4} md={4} key={idx}>
                    <Box style={{ display: 'flex', alignItems: 'flex-start' }}>
                      {currentWeek <= weekInProgress && (
                        <CheckCircleIcon
                          style={{
                            marginTop: '5px',
                            color: p.completedCount === p.totalCount ? 'green' : 'grey',
                          }}
                        />
                      )}
                      <Box style={{ marginLeft: '18px' }}>
                        <Typography variant="h6">{p.Title}</Typography>
                        <Typography variant="body2">{p.SubTitle}</Typography>
                        {currentWeek <= weekInProgress && (
                          <Typography variant="body2">
                            {p.completedCount} of {p.totalCount} times this week
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                ))}
              {error ? (
                <Typography variant="body2">Failed to fetch tasks</Typography>
              ) : (
                ppData &&
                !ppData.tasks && (
                  <Box
                    style={{
                      height: '50px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <Typography variant="body2">No data to display</Typography>
                  </Box>
                )
              )}
            </Grid>
          </Box>
          <Box>
            <Typography variant="h6">Learn</Typography>
            <Grid container className={styles.tableRowHeading}>
              {!loading &&
                ppData &&
                ppData.lessonPlans &&
                ppData.lessonPlans.lessons.map((l, idx) => (
                  <Grid item lg={4} md={4} key={idx}>
                    <Box style={{ display: 'flex', alignItems: 'flex-start' }}>
                      {currentWeek <= weekInProgress && (
                        <CheckCircleIcon
                          style={{
                            marginTop: '5px',
                            color: l.isComplete ? 'green' : 'grey',
                          }}
                        />
                      )}
                      <Box style={{ marginLeft: '18px' }}>
                        <Typography variant="h6">{l.name}</Typography>
                        <Typography variant="body2">{l.description}</Typography>
                        {currentWeek <= weekInProgress && (
                          <Typography variant="body2">
                            Completed: {l.isComplete ? 'Yes' : 'No'}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                ))}
              {error ? (
                <Typography variant="body2">Failed to fetch tasks</Typography>
              ) : (
                ppData &&
                !ppData.lessonPlans && (
                  <Box
                    style={{
                      height: '50px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <Typography variant="body2">No data to display</Typography>
                  </Box>
                )
              )}
            </Grid>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ProgramProgress;
