/* eslint-disable */
import React, { Component } from 'react';
import 'assets/styles/bs-stepper.scss';
import 'assets/styles/bootstrap.scss';
import Stepper from 'bs-stepper';
import DoctorDetails from '../Appointments/Calendar/DoctorDetails';
import CountdownTimer from './CountdownTimer';

export default class Doctor_AppointmentContent extends Component {
  state = {
    timer: null,
    step: 1,
    graceTimerStart: false,
    graceTimerEnds: false,
    graceTimer: 300,
    counter_title: 'Time remaining',
    visibility_flag: 'visible',
  };

  componentDidMount() {
    console.log('values --> ', this.props.steppervalue, this.props.appointmentStatus);
    if (
      (this.props.appointmentStatus === 'Physician-Review' ||
        this.props.appointmentStatus === 'Scheduled') &&
      this.props.steppervalue === 1
    ) {
      this.stepper = new Stepper(document.querySelector('#stepper1'), {
        linear: false,
        animation: true,
      });
    } else if (this.props.appointmentStatus === 'InProgress') {
      this.stepper = new Stepper(document.querySelector('#stepper1'), {
        linear: false,
        animation: true,
      });
      this.setState({
        step: 2,
      });
      if (this.props.steppervalue !== 2) this.stepper.next();
    } else {
      this.stepper = new Stepper(document.querySelector('#stepper1'), {
        linear: false,
        animation: true,
      });

      if (this.props.steppervalue == 2) {
        this.stepper.to(2);
        this.setState({
          step: 2,
        });
      } else if (this.props.steppervalue == 1) {
        if (this.props.appointmentStatus === 'Encounter-Summary-Preparation') {
          this.stepper.to(3);
          this.setState({
            step: 3,
          });
        } else {
          this.stepper.to(1);
          this.setState({
            step: 1,
          });
        }
      } else {
        this.stepper.to(3);
        this.setState({
          step: 3,
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.steppervalue !== this.props.steppervalue) {
      if (this.props.steppervalue !== 1) this.stepper.next();
    }
  }

  endGraceTimer = (timer) => {
    if (timer === this.state.graceTimer) {
      // console.log('time -  ', timer);
      this.state.graceTimerEnds = true;
    } else {
      this.state.graceTimerStart = true;
      (this.state.counter_title = 'Grace Period'), (this.state.visibility_flag = 'hidden');
    }
    this.forceUpdate();
  };
  toBeImplemented = (timer) => {
    console.log(`consultation evaluation time of ${timer} has come to an end`);
  };

  render() {
    return (
      <div className="telekardia">
        <div className="w-100">
          <div id="stepper1" className="bs-stepper">
            <div className="col-12 p-0">
              <div className="kardiaprotitle">
                <b>Kardia</b>Complete
              </div>
              <div className="bs-stepper-header col-12 col-md-6 col-lg-8 col-xl-5 text-center d-flex ml-md-auto mr-md-auto mr-lg-auto m-xl-auto">
                <div className="step" data-target="#test-l-1">
                  <button className="step-trigger d-flex flex-column">
                    <span className="bs-stepper-circle">1</span>
                    <span className="bs-stepper-label font_title">Step 1</span>
                    <span className="bs-stepper-label font_med font_grey m-0">
                      Review Member Documents
                    </span>
                  </button>
                </div>
                <div className="line"></div>
                <div className="step" data-target="#test-l-2">
                  <button className="step-trigger  d-flex flex-column">
                    <span className="bs-stepper-circle">2</span>
                    <span className="bs-stepper-label font_title">Step 2</span>
                    <span className="bs-stepper-label font_med font_grey m-0">
                      Consultation in progress
                    </span>
                  </button>
                </div>
                <div className="line"></div>
                <div className="step" data-target="#test-l-3">
                  <button className="step-trigger  d-flex flex-column">
                    <span className="bs-stepper-circle">3</span>
                    <span className="bs-stepper-label font_title">Step 3</span>
                    <span className="bs-stepper-label font_med font_grey m-0">
                      Post Consultation
                    </span>
                  </button>
                </div>
              </div>
              <div className="limelimit">
                <p className="text-uppercase mb-0 text-center f600 f14">
                  {this.state.counter_title}
                </p>
                {this.props.steppervalue === 1 && this.state.step === 1 ? (
                  <h5 className="d-flex flex-row">
                    <CountdownTimer
                      count={120}
                      key={0}
                      endTimer={this.props.endtimer}
                      color={'#e6c029'}
                    />
                  </h5>
                ) : this.props.steppervalue === 2 || this.state.step == 2 ? (
                  <>
                    <h5 className="d-flex flex-row">
                      {this.state.graceTimerStart && (
                        <CountdownTimer
                          count={this.state.graceTimer}
                          key={1}
                          color={'#CC3D3F'}
                          endTimer={this.endGraceTimer}
                        />
                      )}
                    </h5>
                    <h5
                      className="d-flex flex-row"
                      style={{ visibility: `${this.state.visibility_flag}` }}
                    >
                      <CountdownTimer
                        count={600}
                        key={1}
                        color={'#e6c029'}
                        endTimer={this.endGraceTimer}
                      />
                    </h5>
                  </>
                ) : (
                  <h5 className="d-flex flex-row">
                    <CountdownTimer
                      count={180}
                      key={2}
                      color={'#e6c029'}
                      endTimer={this.toBeImplemented}
                    />
                  </h5>
                )}
              </div>
            </div>

            <div className="bs-stepper-content">
              <div id="test-l-1" className="content overflow_modal pt-1 mt-4">
                {/*<div className="kardiaprotitle">KardiaPro</div>*/}
                {/*<div className="limelimit">*/}
                {/*  <p className="text-uppercase mb-0 text-center f600 f14">*/}
                {/*    time remaining*/}
                {/*  </p>*/}
                {/*  <h5 className="d-flex flex-row">*/}
                {/*    {(this.props.appointmentStatus === "Physician-Review" ||*/}
                {/*      this.props.appointmentStatus === "Scheduled") && (*/}
                {/*      <CountdownTimer count={120} />*/}
                {/*    )}*/}
                {/*  </h5>*/}
                {/*</div>*/}
                {this.props.steppervalue === 1 && this.state.step === 1 && (
                  <DoctorDetails
                    {...this.props}
                    step={1}
                    onSubmit={this.props.onSubmit}
                    disable={this.props.disable}
                    startcalldisable={this.props.startcalldisable}
                    steppervalue={this.props.steppervalue}
                    status={this.props.status}
                    clicked={this.props.clicked}
                    setConsultationNote={this.props.setConsultationNote}
                    consultationNote={this.props.consultationNote}
                    updateoverReadReport={this.props.updateoverReadReport}
                    handleClose={this.props.handleClose}
                    tabvalue={this.props.tabvalue}
                    changetabvalue={this.props.changetabvalue}
                  />
                )}
              </div>
              <div id="test-l-2" className="content overflow_modal pt-1 mt-4">
                {/*<div className="kardiaprotitle">KardiaPro</div>*/}
                {/*<div className="limelimit">*/}
                {/*  <p className="text-uppercase mb-0 text-center f600 f14">*/}
                {/*    time remaining*/}
                {/*  </p>*/}
                {/*  <h5 className="d-flex flex-row">*/}
                {/*    {(this.props.appointmentStatus === "InProgress" ||*/}
                {/*      this.state.step === 2 ||*/}
                {/*      this.props.steppervalue === 2) && (*/}
                {/*      <CountdownTimer count={600} />*/}
                {/*    )}*/}
                {/*  </h5>*/}
                {/*</div>*/}
                {(this.props.steppervalue === 2 || this.state.step == 2) && (
                  <DoctorDetails
                    {...this.props}
                    step={2}
                    onSubmit={this.props.onSubmit}
                    disable={this.props.disable}
                    startcalldisable={this.props.startcalldisable}
                    status={this.props.status}
                    clicked={this.props.clicked}
                    setConsultationNote={this.props.setConsultationNote}
                    consultationNote={this.props.consultationNote}
                    handleClose={this.props.handleClose}
                    steppervalue={
                      this.props.steppervalue === 2 ? this.props.steppervalue : this.state.step
                    }
                    tabvalue={this.props.tabvalue}
                    changetabvalue={this.props.changetabvalue}
                    graceTimerEnds={this.state.graceTimerEnds}
                  />
                )}
              </div>
              <div id="test-l-3" className="content overflow_modal pt-1 mt-4">
                {/*<div className="kardiaprotitle">KardiaPro</div>*/}
                {/*<div className="limelimit">*/}
                {/*  <p className="text-uppercase mb-0 text-center f600 f14">*/}
                {/*    time remaining*/}
                {/*  </p>*/}
                {/*  <h5 className="d-flex flex-row">*/}
                {/*    {(this.props.appointmentStatus ===*/}
                {/*      "Encounter-Summary-Preparation" ||*/}
                {/*      this.state.step === 3 ||*/}
                {/*      this.props.steppervalue === 3) && (*/}
                {/*      <CountdownTimer count={180} />*/}
                {/*    )}*/}
                {/*  </h5>*/}
                {/*</div>*/}
                {(this.props.steppervalue === 3 || this.state.step === 3) && (
                  <DoctorDetails
                    step={3}
                    clicked={this.props.clicked}
                    disable={this.props.disable}
                    handleClose={this.props.handleClose}
                    startcalldisable={this.props.startcalldisable}
                    onSubmit={this.props.onSubmit}
                    setConsultationNote={this.props.setConsultationNote}
                    consultationNote={this.props.consultationNote}
                    updateoverReadReport={this.props.updateoverReadReport}
                    overReadReport={this.props.overReadReport}
                    steppervalue={
                      this.props.steppervalue === 3 ? this.props.steppervalue : this.state.step
                    }
                    status={this.props.status}
                    tabvalue={this.props.tabvalue}
                    changetabvalue={this.props.changetabvalue}
                    {...this.props}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
