import { getTimezoneName } from 'utilities/Utilities';
import { getToken } from 'auth/AuthUtilities';
import { EscalationAPI, TeleKardiaAPI } from 'Axios/axios';
import * as actionTypes from './actionTypes';

export const totalAlertCount = (data) => ({
  type: actionTypes.TOTAL_ALERT,
  payload: data,
});

export const dashboardKpiCount = (teamId, clinicianId) => (dispatch) => {
  return EscalationAPI.get(
    `api/v1/teams/${teamId}/clinicians/${clinicianId}/alerts/count?offsetInMinutes=${new Date().getTimezoneOffset()}&timeZone=${getTimezoneName()}`,
    {
      headers: {
        Authorization: ` Bearer ${getToken()}`,
      },
    },
  )
    .then((res) => {
      let count = Object.values(res.data.result).reduce((acc, cur) => {
        return acc + cur;
      });
      dispatch(totalAlertCount(count));
      return res.data.result;
    })
    .catch((err) => {
      return err;
    });
};

export const alertsTableInfo = async (
  teamId,
  profileId,
  page,
  pageSize,
  patientData = '',
  alertSubType = '',
) => {
  return EscalationAPI.get(
    `api/v1/teams/${teamId}/clinicians/${profileId}/alerts?offsetInMinutes=${new Date().getTimezoneOffset()}&timeZone=${getTimezoneName()}&pageNumber=${page}&pageSize=${pageSize}${patientData}${alertSubType}`,
    {
      headers: {
        Authorization: ` Bearer ${getToken()}`,
      },
    },
  )
    .then((res) => {
      return res.data.result;
    })
    .catch((err) => {
      return err;
    });
};

export const deleteAlert = (teamId, profileId, params) => {
  return EscalationAPI.delete(
    `api/v1/teams/${teamId}/clinicians/${profileId}/patients/${params.patientId}/alerts/${
      params.id
    }/dismiss?offsetInMinutes=${new Date().getTimezoneOffset()}&timeZone=${getTimezoneName()}`,
    {
      headers: {
        Authorization: ` Bearer ${getToken()}`,
      },
    },
  )
    .then((res) => {
      return res.data.result;
    })
    .catch((err) => {
      return err;
    });
};

export const getChatUserId = (profileId, memberId) => {
  return TeleKardiaAPI.get(`api/v1/chat/userChatInfo/${profileId}?publicId=${memberId}`, {
    headers: {
      Authorization: ` Bearer ${getToken()}`,
    },
  })
    .then((res) => {
      return res.data.result;
    })
    .catch((err) => {
      return err;
    });
};

export const alertStatusChange = (teamId, profileId, alertId) => {
  return EscalationAPI.put(
    `api/v1/teams/${teamId}/clinicians/${profileId}/alerts/${alertId}`,
    {
      status: 2,
    },
    {
      headers: {
        Authorization: ` Bearer ${getToken()}`,
      },
    },
  )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};
