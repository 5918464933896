import React, { useEffect, useState } from 'react';
import { FormControl, Grid, Select as MuiSelect, MenuItem, Modal } from '@material-ui/core';
import { Box, Typography, Tabs, Tab } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import styles from '../../memberProfile.module.css';
import classnames from 'classnames';

import { commonStyles, navStyles } from '../../styles';
import EditIcon from '@material-ui/icons/Edit';

import { GoAPI, TeleKardiaAPI } from '../../../../../Axios/axios';
import { getToken } from 'auth/AuthUtilities';
import TeamAlert from 'components/Shared/Alert/TeamAlert';
import Loading from 'components/Shared/EcgLoader';
import ProgramProgress from './ProgramProgress';
import { programStyles } from './styles';
import ProgramFocus from './ProgramFocus';
import { programMap } from 'utilities/Constants';
import { callNextSessionAPI } from 'store/actions/member';
import { AUTH_TYPE } from 'constants/app';
import { startAdhocCall } from 'store/actions';

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box div={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const Program = (props) => {
  const dispatch = useDispatch();
  const { memberId } = useParams();
  const { t } = useTranslation();

  const [coachList, setCoachList] = useState([]);
  const [isCoachEditing, setIsCoachEditing] = useState(false);
  const [isRiskEditing, setIsRiskEditing] = useState(false);
  const [isStatusEditing, setIsStatusEditing] = useState(false);

  const coachId = useSelector((state) => state.user.profile.id);
  const teamId = useSelector((state) => state.user.team.id);
  const userType = useSelector((state) => state.user.chatProfile.userType);
  const me = useSelector((state) => state.user.profile);
  const { programData: data, loading } = useSelector((state) => state.memberProfile);
  const isAdmin = me.permissions ? me.permissions.isAdmin : false;
  const isCoach = me.permissions ? me.permissions.isPhysician : false;
  const { programProgress } = useSelector((state) => state.memberProfile);
  const isAdhocCallWindow = useSelector((state) => state.adhocCall.isFloatingWindow);

  //importing styles
  const classes = programStyles();
  const navClasses = navStyles();
  const commonClasses = commonStyles();
  const [value, setValue] = useState(0);
  const [memberStatus, setMemberStatus] = useState(null);
  const [risk, setRisk] = useState('');
  const [nextSession, setNextSession] = useState('');
  const [newCoach, setNewCoach] = useState('');
  //values stored in local state to send PUT requests
  useEffect(() => {
    if (data) {
      data.risk && setRisk(data.risk);
      data.status && setMemberStatus(data.status);
      data.coachId &&
        setNewCoach({ id: data.coachId, name: `${data.coachLastName}, ${data.coachFirstName}` });
    }
    return () => {
      setNewCoach('');
      setRisk('');
      setMemberStatus('');
    };
  }, [data]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const fetchCoachList = async () => {
    GoAPI.get(`/i/v1/kcc/coach`, {
      headers: { Authorization: ` Bearer ${getToken()}` },
    })
      .then((res) => {
        let resultData = res.data;
        // console.log(resultData);
        let options = resultData
          .filter((d) => d.coachID !== coachId)
          .map((d) => {
            return {
              value: d.coachID,
              label: `${d.coachLastName}, ${d.coachFirstName}`,
            };
          });
        setCoachList(options);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [showAlert, setShowAlert] = useState({ data: '', error: false });
  const reassignCoachAsync = (val) => {
    GoAPI.post(
      '/i/v1/kcc/reassignMember',
      { coachID: val, participantID: memberId },
      { headers: { Authorization: `Bearer ${getToken()}` } },
    )
      .then((res) => {
        TeleKardiaAPI.put(
          `/api/v1/teams/${teamId}/admin/${coachId}/reassign`,
          { oldCoachId: data.coachId, coachId: val, memberId: memberId },
          { headers: { Authorization: `Bearer ${getToken()}` } },
        )
          .then((res) => {
            switch (res.status) {
              case 200: {
                setShowAlert({ data: 'Coach has been updated successfully', error: false });
                const cindex = coachList.findIndex((c) => c.value === val);
                if (cindex > -1) {
                  let c = coachList[cindex];
                  setNewCoach({ id: c.coachId, name: c.label });
                  setTimeout(() => {
                    setIsCoachEditing(false);
                    setShowAlert({ data: '', type: null });
                  }, 4000);
                } else {
                  throw Error('Coach not present in list');
                }
                break;
              }

              default: {
                setShowAlert({ data: 'Something went wrong', error: true });

                break;
              }
            }
          })
          .catch((err) => {
            throw err;
          });
      })
      .catch((err) => {
        throw err;
      });
  };

  //Edit Risk API
  const editRiskAsync = (val) => {
    setRisk(val);
    GoAPI.put(
      `/i/v1/kcc/${memberId}/risk/${val}`,
      {},
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      },
    )
      .then((res) => {
        switch (res.status) {
          case 200: {
            setIsRiskEditing(false);
            setShowAlert({
              data: 'Member’s risk level has been updated successfully',
              error: false,
            });
            setTimeout(() => {
              setShowAlert({ data: '', type: null });
            }, 4000);
            break;
          }
          default:
            setShowAlert({ data: 'Something went wrong', error: true });
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  //Edit Member Status API
  const editMemberStatusAsync = (val) => {
    setMemberStatus(val);
    GoAPI.put(
      `/i/v1/kcc/${memberId}/status/${val}`,
      {},
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      },
    )
      .then((res) => {
        switch (res.status) {
          case 200: {
            setIsStatusEditing(false);
            setShowAlert({ data: 'Member’s status has been updated successfully', error: false });
            setTimeout(() => {
              setShowAlert({ data: '', type: null });
            }, 4000);
            break;
          }
          default:
            setShowAlert({ data: 'Something went wrong', error: true });
        }
      })
      .catch((err) => {
        throw err;
      });
  };
  //Next Session GET API
  const nextCoachingSessionAsync = async () => {
    const res = await callNextSessionAPI(teamId, coachId, [memberId]);
    setNextSession(res.data.result[memberId]);
  };
  //Trigger Next session API
  useEffect(() => {
    nextCoachingSessionAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId, coachId]);
  //get coaches list on edit
  useEffect(() => {
    if (isCoachEditing) {
      fetchCoachList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCoachEditing]);
  const statusColor = {
    inactive: '#CC3D3F',
    noncompliant: '#FDC246',
    active: '#2D9F86',
    onHold: 'black',
    completed: 'maroon',
    terminated: '#BABFBD',
  };

  // adhoc phone call modal flow
  const adhocClick = () => {
    if (isAdhocCallWindow || data.ParticipantPhoneNumber.length == 0) {
      return;
    }
    dispatch(startAdhocCall('Call Member', memberId));
  };

  const phoneNumberFormat = (countryCode = null, phoneNumber = null) => {
    const formatedPhoneNumber =
      phoneNumber !== null
        ? `${phoneNumber.substring(0, 3)}-${phoneNumber.substring(3, 6)}-${phoneNumber.substring(
            6,
            10,
          )}`
        : '--';
    return `${countryCode !== null ? `${countryCode}-` : ''}${formatedPhoneNumber}`;
  };
  return (
    <div className={classes.root}>
      {loading && <Loading />}
      {data && !loading && (
        <>
          <Box style={{ marginTop: '10px' }}>
            {showAlert.data !== '' && <TeamAlert title={showAlert.data} error={showAlert.error} />}
          </Box>
          <Box
            className={classnames(commonClasses.divWrapper, classes.divWrapperOverride)}
          >
            <Box className={classes.divHeaderWrapper}>
              <Typography variant="h5">Member Demographics</Typography>
            </Box>
            <Box className={commonClasses.divContentWrapper}>
              <Grid container className={styles.tableRowHeading}>
                <Grid item lg={4} md={4} className={classes.callTypeSection}>
                  <PhoneIcon
                    style={{
                      color: '#067F6F',
                      cursor: 'pointer',
                    }}
                    onClick={() => data.ParticipantPhoneNumberVerified && adhocClick()}
                  />
                  <div className={classes.distanceFromIcon}>
                    <Typography variant="body2">Phone</Typography>
                    <Typography
                      variant="body1"
                      // style={{ color: data.ParticipantPhoneNumberVerified ? '#DCDCDC' : '#067F6F' }}
                      style={{ color: '#067F6F' }}
                    >
                      {phoneNumberFormat(data.ParticipantCountryCode, data.ParticipantPhoneNumber)}
                    </Typography>
                  </div>
                </Grid>
                <Grid item lg={4} md={4}>
                  <Typography variant="body2">Company name</Typography>
                  <Typography variant="body1">{data.sponsor}</Typography>
                </Grid>
                <Grid item lg={4} md={4}>
                  <Typography variant="body2">Email</Typography>
                  <Typography variant="body1">{data.participantEmail}</Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box className={classnames(commonClasses.divWrapper, classes.divWrapperOverride)}>
            <Box className={classes.divHeaderWrapper}>
              <Typography variant="h5">Program Overview</Typography>
              <Box className={classes.divHeaderWrapper_status}>
                {/* cannot find icon for this, color is subject to change */}
                {memberStatus && (
                  <div
                    style={{
                      background: statusColor[memberStatus],
                      width: '20px',
                      height: '20px',
                      borderRadius: '50%',
                    }}
                  />
                )}

                {isStatusEditing ? (
                  <FormControl
                    variant="outlined"
                    style={{ marginLeft: '10px' }}
                    className={classes.formControl}
                  >
                    <MuiSelect
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={memberStatus}
                      onChange={(e) => editMemberStatusAsync(e.target.value)}
                      placeholder="Select"
                    >
                      <MenuItem value={'active'}>Active</MenuItem>
                      <MenuItem value={'inactive'}>Inactive</MenuItem>
                      <MenuItem value={'noncompliant'}>Noncomplaint</MenuItem>
                      <MenuItem value={'terminated'}>Terminated</MenuItem>
                      <MenuItem value={'onHold'}>On Hold</MenuItem>
                    </MuiSelect>
                  </FormControl>
                ) : (
                  <Typography variant="body2" onClick={() => setIsStatusEditing((t) => !t)}>
                    {memberStatus && memberStatus[0].toUpperCase() + memberStatus.substr(1)}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box className={commonClasses.divContentWrapper}>
              <Grid container className={styles.tableRowHeading}>
                <Grid item lg={4} md={4}>
                  <Typography variant="body2">Program</Typography>
                  <Typography variant="body1">
                    {data.program ? (
                      programMap[data.program]
                    ) : (
                      <span style={{ color: 'grey' }}>No data</span>
                    )}
                  </Typography>
                </Grid>
                {isCoach && !isAdmin && (
                  <Grid item lg={4} md={4}>
                    <Typography variant="body2">Current week</Typography>
                    <Typography variant="body1">
                      {' '}
                      {data['currentWeek'] ? `Week ${data.currentWeek}` : '-'}
                    </Typography>
                  </Grid>
                )}
                {isAdmin && (
                  <Grid item lg={4} md={4}>
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="body2">Coach</Typography>
                      <EditIcon
                        style={{ marginLeft: '15px', fontSize: 15 }}
                        onClick={() => setIsCoachEditing(true)}
                      />
                    </Box>
                    {isCoachEditing ? (
                      <FormControl className={classes.formControl} variant="outlined">
                        <MuiSelect
                          id="demo-simple-select2"
                          defaultValue={newCoach.id}
                          // value={data.coachId}
                          onChange={(e) => reassignCoachAsync(e.target.value)}
                          placeholder="Select Coach"
                        >
                          {coachList.map((c, index) => (
                            <MenuItem key={index} value={c.value}>
                              {c.label}
                            </MenuItem>
                          ))}
                        </MuiSelect>
                      </FormControl>
                    ) : (
                      <Typography variant="body1">
                        {newCoach ? newCoach.name : 'No Coach assigned'}
                      </Typography>
                    )}
                  </Grid>
                )}
                <Grid item lg={4} md={4}>
                  <Box style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <Typography variant="body2">Risk</Typography>
                    <EditIcon
                      style={{ marginLeft: '15px', fontSize: 15 }}
                      onClick={() => setIsRiskEditing(true)}
                    />
                  </Box>
                  {/* @todo 
                1. add icon for risk
                add input select 
                 */}
                  {isRiskEditing ? (
                    <FormControl variant="outlined" className={classes.formControl}>
                      <MuiSelect
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={risk}
                        onChange={(e) => editRiskAsync(e.target.value)}
                        placeholder="Select"
                      >
                        <MenuItem value={'Low'}>Low</MenuItem>
                        <MenuItem value={'Medium'}>Medium</MenuItem>
                        <MenuItem value={'High'}>High</MenuItem>
                      </MuiSelect>
                    </FormControl>
                  ) : (
                    <Typography variant="body1">{risk ? risk : '-'}</Typography>
                  )}
                </Grid>
                {isAdmin && (
                  <Grid item lg={4} md={4}>
                    <Typography variant="body2">Current Week</Typography>
                    <Typography variant="body1">
                      {data['currentWeek'] ? `Week ${data.currentWeek}` : '-'}
                    </Typography>
                  </Grid>
                )}
                <Grid item lg={4} md={4}>
                  <Typography variant="body2">Enrolled on</Typography>
                  <Typography variant="body1">
                    {(programProgress &&
                      programProgress.data &&
                      programProgress?.data?.programStartTime.split('T')[0]) ||
                      '--'}
                  </Typography>
                </Grid>
                {isCoach && !isAdmin && (
                  <Grid item lg={4} md={4}>
                    <Typography variant="body2">Last login</Typography>
                    <Typography variant="body1">
                      {moment(data.lastLogin).format('MM/DD/YYYY hh:mm A')}
                    </Typography>
                  </Grid>
                )}
                <Grid item lg={4} md={4}>
                  <Typography variant="body2">Next coaching session</Typography>
                  <Typography variant="body1">
                    {nextSession
                      ? moment(nextSession).format('MM-DD-YYYY, HH:mm A')
                      : 'Unscheduled'}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
          {/* display program focus and heart habit components only for userType COACH */}
          {userType === AUTH_TYPE.COACH && <ProgramFocus />}

          <Box className={classnames(commonClasses.divWrapper, classes.divWrapperOverride)}>
            <Box className={classes.flexWrapper}>
              <Typography variant="h5">Program Progress</Typography>

              <Tabs
                classes={{
                  root: navClasses.customTabRoot,
                  indicator: navClasses.customTabIndicator,
                }}
                value={value}
                onChange={handleChange}
                scrollButtons="auto"
                // centered
                variant="fullWidth"
              >
                <Tab label={t('Weekly')} classes={{ root: navClasses.tabRoot }} {...a11yProps(0)} />
                <Tab
                  label={t('Overall')}
                  classes={{ root: navClasses.tabRoot }}
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
              <ProgramProgress />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div style={{ minHeight: '300px', position: 'relative' }}>
                <div className={commonClasses.overlay}>Coming Soon!</div>
              </div>
            </TabPanel>
          </Box>
        </>
      )}
    </div>
  );
};

export default Program;
