import React from 'react';
// import _get from 'lodash.get';
import { Grid, makeStyles, Typography } from '@material-ui/core';
// import { useSelector, useDispatch } from 'react-redux';
import { fontSizes, fonts, colors } from '../../../style/styleSettings';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTypography-h5': {
      fontFamily: `${fonts.SESSION} !important`,
      fontWeight: '600 !important',
      fontSize: `${fontSizes.HEADING}`,
    },
  },
}));

const PatientDetailsComponent = (props) => {
  const { patientDetails } = props;
  const { patient } = patientDetails;
  const classes = useStyles();

  // const patientData = useSelector((state) => state.session.patientsData);

  return (
    <Grid container className={classes.root} rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={5}>
        <Typography variant="h5">
          Member: {`${patient?.lastName} ${patient?.firstName}` || ' -- '}
        </Typography>
        <Typography variant="h5">
          Sponsor: <span style={{ color: `${colors.SPRUCE}` }}>{patient?.company || ' -- '}</span>
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography variant="h5">
          Plan: {(patient?.program && patient?.program.join(', ')) || ' -- '}
        </Typography>
        <Typography variant="h5">Risk Level: {patient?.risk_level || ' -- '}</Typography>
      </Grid>
    </Grid>
  );
};

export default PatientDetailsComponent;
