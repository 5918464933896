/* eslint-disable */
import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import _get from 'lodash.get';
import * as API from '../../../Services/API/actions';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { calcRowSeconds } from '../../Utilities';
import * as Lib from 'constants/RecordingConstants';
import axios from 'axios';
import { streamToPromise, getTimezoneName } from 'utilities/Utilities';
import apiservice from 'api/services/apiservice.service';
import { GoAPI } from '../../../Axios/axios';
import { getToken, renewAuth } from 'auth/AuthUtilities';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import 'assets/styles/bootstrap.scss';
import Details_tab from '../../Dashboard/details_tab';
import { useTranslation } from 'react-i18next';
import Chat from './Chat';
import VideoCall from './VideoCall';
import { Grid, Drawer, SvgIcon, Box, TextareaAutosize } from '@material-ui/core';
import KeyboardArrowLeftOutlinedIcon from '@material-ui/icons/KeyboardArrowLeftOutlined';
import clsx from 'clsx';
import PreviewReportModal from '../../AppointmentDetails/PreviewReportModal';
import useResizeDetector from 'use-resize-observer/polyfilled';
import debounce from 'lodash.debounce';
import Authorize from '../../Authorize';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import VonageCall from './VonageCall';
// import { ExampleComponent } from '@alivecor/vonage-api'

const useStyles = makeStyles((theme) => ({
  root: {},
  verticalgrid: {
    minHeight: 600,
  },
  cardHeader: {
    fontWeight: '600',
  },
  blackGrid: {
    fontWeight: '600',
    alignItems: 'center',
    height: '55px',
    padding: theme.spacing(1),
    background: '#E2E7E3',
    color: '#000000',
    lineHeight: '40px',
  },
  gridPadding: {
    padding: theme.spacing(1),
  },
  boldFont: {
    fontWeight: '600',
  },
  bolderFont: {
    fontWeight: '700',
  },
  cardlayout: {
    minHeight: 600,
    background: '#F1F1F1',
  },
  cardlayoutmini: {
    minHeight: 280,
    background: '#F1F1F1',
  },
  recordingInfo: {
    zIndex: 1,
    padding: '8px 10px',
    backgroundColor: '#fff',
    opacity: 0.8,
    width: 'fit-content',
    display: '-webkit-box' && '-moz-box' && '-ms-flexbox' && '-webkit-flex' && 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    position: 'absolute',
    right: '10px',
    margin: '20px',
  },
  recordingInfospan: {
    // width: "30px",
    paddingRight: '20px',
    color: '#000',
  },
  recordingInfospanfirstChild: {
    width: '30px',
    paddingLeft: '0px',
  },
  stickyvideocall: {
    position: '-webkit-sticky' && 'sticky',
    top: 0,
    zIndex: 5000,
    // margin:"20px"
  },
  drawer: {
    height: '75%',
    flexShrink: 0,
    marginLeft: '40px',
    marginRight: '40px',
    marginBottom: '40px',
    boxShadow:
      '0px 3px 6px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 1px 12px rgba(0, 0, 0, 0.04) !important',
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    height: 'calc(100vh - 345px)',
    marginLeft: '40px',
    marginRight: '40px',
    marginBottom: '100px',
    boxShadow: '0 0 15px -2px #444444 !important',
    transition: theme.transitions.create('height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    marginLeft: '40px',
    marginRight: '40px',
    marginBottom: '100px',
    boxShadow: '0 0 15px -2px #444444 !important',
    transition: theme.transitions.create('height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    // height: theme.spacing(7) + 1,
    height: '15%',
    // [theme.breakpoints.up('sm')]: {
    //  height: theme.spacing(9) + 1,
    // },
  },
  paddeddrawertitle: {
    padding: theme.spacing(1),
    minHeight: '80px',
  },
}));

function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}
const Details = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const mediawidth = useWidth();
  const appointmentId = props.appointmentId;
  const heartHealthData = useSelector((state) => state.session.heartHabit);
  const clinicianId = useSelector((state) => state.user.profile.id);
  const teamId = useSelector((state) => state.user.team.id);
  const [consultationNote, setconsultationNote] = useState('');
  const [myNote, setMyNotes] = useState('');
  const [previewReportModalOpen, setpreviewReportModalOpen] = useState(false);

  const loadReport = () => {
    if (props.id !== 0) {
      setpreviewReportModalOpen(true);
      props.setisError(false);
    } else {
      props.setError('Please select the EKG interpretation to proceed to the next step.');
      props.setisError(true);
    }
  };

  const handlePreviewReportModalClose = () => {
    setpreviewReportModalOpen(false);
  };

  const [hearthabitData, sethearthabitData] = useState([]);
  useEffect(() => {
    sethearthabitData(heartHealthData);
    // console.log(hearthabitData);
  }, [heartHealthData]);

  const [draweropen, setdraweropen] = React.useState(false);
  let actions = null;
  if (
    props.steppervalue === 2 &&
    (props.status === 'Scheduled' || props.status === 'Coach-Review')
  ) {
    actions = (
      <>
        <Authorize>
          <button
            type="button"
            className="btn px-4 btn-outline-primary text-uppercase h40"
            onClick={props.onSubmit}
            disabled={props.disable}
          >
            {t('REASSIGN SESSION')}
          </button>
        </Authorize>
        <button
          type="button"
          className="btn px-4 btn_teal text-uppercase h40 ml-3"
          onClick={props.clicked}
        >
          {/* START CALL */}
          {_get(patientDetails, 'patientAppointment.appointmentChannelTypeId') === 4
            ? 'START CHAT'
            : 'START CALL'}
        </button>
      </>
    );
  } else if (props.steppervalue === 3) {
    actions = (
      <>
        {/* <button
          type="button"
          className="btn px-4 btn-outline-primary text-uppercase h40 ml-3"
          onClick={loadReport}
        >
          Preview REPORT
        </button> */}
        <button
          type="button"
          disabled={!props.consultationNote}
          className="btn px-4 btn_teal text-uppercase h40 ml-3"
          onClick={props.handleClose}
        >
          {/* SEND REPORT */}
          {t('DONE')}
        </button>
      </>
    );
  } else if (
    props.steppervalue === 1 ||
    props.status === 'Encounter-Summary-Preparation' ||
    props.status === 'Closed' ||
    props.status === 'Canceled'
  ) {
    actions = (
      <>
        {/* <button type="button" className="btn px-4 btn-outline-primary text-uppercase h40" onClick={props.onSubmit}>
              Cancel Appoinment
              </button> */}

        <button
          type="button"
          className="btn px-4 btn-outline-secondary text-uppercase h40 ml-3"
          onClick={(e) => {
            props.clicked(e);
          }}
          disabled={(props.startcalldisable && !props.rejoinCallFlag) || consultationNote === ''}
        >
          {t('Complete Session')}
        </button>
      </>
    );
  } else if (props.component === 'popover') {
    actions = (
      <>
        <button
          type="button"
          className="btn px-4 btn-outline-primary text-uppercase h40 f28_icon"
          onClick={props.handleClose}
        >
          <KeyboardArrowLeftOutlinedIcon /> {t('Back')}
        </button>

        {/* {(props['isfuturescheduledappintment'] == undefined ||
          props.isfuturescheduledappintment === true) && (
          <Authorize>
            <button
              type="button"
              className="btn px-4 btn-outline-secondary text-uppercase h40 ml-3"
              onClick={props.onSubmit}
              disabled={props.disable}
            >
              REASSIGN SESSION
            </button>
          </Authorize>
        )} */}
      </>
    );
  }

  // => (event)
  const toggleDrawer = () => {
    // if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    //   return;
    // }
    setdraweropen(!draweropen);
    // setState({ ...state, [anchor]: open });
  };

  const [recording, setRecording] = useState({});
  const [toggleRecordingInvert, setoggleRecordingInvert] = useState(false);
  const [appNotes, setappNotes] = useState('');
  const [notesheight, setnotesheight] = useState(1000);
  const [comunicationheight, setcomunicationheight] = useState(1000);
  const viewportRef2 = useRef(null);
  const communicationref = useRef(null);
  const [key, setKey] = useState('AddNotes');

  function handleResize({ height }) {
    if (notesheight !== height) {
      setnotesheight(height);
    }
  }

  const delay = 30;

  useResizeDetector({
    ref: viewportRef2,
    onResize: debounce(handleResize, delay, { maxWait: 1000 }),
  });

  function handlecommunicationResize({ height }) {
    if (comunicationheight !== height) {
      setcomunicationheight(height);
    }
  }

  useResizeDetector({
    ref: communicationref,
    onResize: debounce(handlecommunicationResize, delay, { maxWait: 1000 }),
  });

  const patientDetailsntwrk = useSelector((state) => state.appointments.patientDetails);
  const recordingntwrk = useSelector((state) => state.ekg.recording);
  const [loading, setloading] = useState(false);
  const [alertOn, setAlertOn] = useState(false);

  const MIN_SECONDS = 1;
  const mainContentWidth = 902;
  const rowSeconds =
    calcRowSeconds(mainContentWidth) > 0 ? calcRowSeconds(mainContentWidth) : MIN_SECONDS;
  const ekgWidth = rowSeconds * Lib.PX_PER_SECOND + 18;

  let subscription = '';
  const loadAction = useCallback(async () => {
    setloading(true);
    let offsetInMinutes = new Date().getTimezoneOffset();
    const appointmentRequest = streamToPromise(
      apiservice.getapiservice({
        baseUrl: `api/v1.1/teams/${teamId}/clinicians/${clinicianId}/appointments/${appointmentId}/patient-detail?timeZoneOffset=${offsetInMinutes}&timeZone=${getTimezoneName()}`,
      }),
    );
    const appointmentdetailsdata = await appointmentRequest;
    setPatientDetails(appointmentdetailsdata.data);
    setloading(false);
  }, []);
  const [patientDetails, setPatientDetails] = useState([]);
  //=======renew jwt every 10 mins====
  const dispatch = useDispatch();
  const renewRef = useRef(null);
  const renewAuthDuringApp = useCallback(() => {
    renewRef.current = setInterval(() => {
      dispatch(renewAuth());
    }, 600000);
  });
  useEffect(() => {
    if (!renewRef.current) {
      renewAuthDuringApp();
    }
    return () => clearInterval(renewRef.current);
  }, []);
  useEffect(() => {
    if (props.patientDetails && Object.keys(props.patientDetails).length) {
      setPatientDetails(props.patientDetails);
    } else loadAction();
  }, [appointmentId, props.patientDetails]);

  useEffect(() => {
    if (props.recording && Object.keys(props.recording).length) {
      setRecording(props.recording);
    } else if (
      Object.keys(patientDetails).length > 0 &&
      patientDetails.ekgResults &&
      patientDetails.ekgResults.length > 0
    ) {
      //loadEkgAction(patientDetails.ekgResults[0].ekgRecordId);
    }
  }, [patientDetails, props.recording]);
  useEffect(() => {
    if (
      (props.steppervalue && patientDetailsntwrk && patientDetailsntwrk.appointmentId) ||
      props.component === 'popover'
    ) {
      subscription = API.getAppointmentNotes(teamId, clinicianId, appointmentId).subscribe(
        (res) => {
          // if (res.data.appointmentNote) setappNotes(decodeURI(res.data.appointmentNote));
          // else setappNotes('');
          if (res.data.appointmentNote && res.data.appointmentNote != null) {
            setappNotes(res.data.appointmentNote);
            setconsultationNote(res.data.appointmentNote);

            // setappNotes(decodeURI(res.data.appointmentNote));
            // setconsultationNote(decodeURI(res.data.appointmentNote));

            // } else if (res.data.appointmentNote != null) {
            //   setappNotes('');
            //   setconsultationNote(decodeURI(res.data.appointmentNote));
          } else {
            setconsultationNote('');
          }
        },
        (error) => {
          // log error
          console.log(error);
        },
      );
    }
    return () => {
      setloading(false);
      if (subscription && subscription !== '') subscription.unsubscribe();
    };
  }, [props.steppervalue]);

  useEffect(() => {
    setconsultationNote(props.consultationNote);
  }, [props.consultationNote]);

  const [statistics, setstatistics] = React.useState([]);
  const [connectionflag, setconnectionflag] = React.useState(false);
  const [reconnectionflag, setreconnectionflag] = React.useState(false);
  const [finalcounttimer, setfinalcounttimer] = useState(0);
  const [phoneCall, choosePhoneCall] = useState(false);
  const settoggleRecordingInvert = (prps) => {
    setoggleRecordingInvert(prps);
  };

  const establishnewconnection = (props) => {
    setfinalcounttimer(props);
    setreconnectionflag(true);
    setconnectionflag(!connectionflag);
  };
  const timer = useRef(null);
  const saveMyNotesAsync = (note) => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      props.setConsultationNote(note);
    }, 2000);
  };
  return (
    <div className="telekardia">
      <div className={loading || props.consultationloader ? 'filtering' : null}>
        <div className={loading || props.consultationloader ? 'spinner-border' : null}></div>
        <div className="m-4">{props.alert}</div>
        <div className="container-fluid">
          {previewReportModalOpen && (
            <PreviewReportModal
              open={previewReportModalOpen}
              memberId={clinicianId}
              patientId={patientDetails.patient.patientId}
              // "2iik80h13leb5q8osgsyvesvz"
              appointmentId={appointmentId}
              handlePreviewReportModalClose={handlePreviewReportModalClose}
            />
          )}
          <div className="row m-0" style={{ display: '-ms-flexbox' }}>
            <div className="col-12 col-xl-7">
              {mediawidth !== 'xl' && mediawidth !== 'lg' ? (
                <Drawer
                  variant="permanent"
                  PaperProps={{
                    style: { borderRadius: '24px 24px 0px 0px', padding: '0 30px' },
                  }}
                  // className={clsx(classes.drawer, {
                  //   [classes.drawerOpen]: draweropen,
                  //   [classes.drawerClose]: !draweropen,
                  // })}
                  classes={{
                    paper: clsx({
                      [classes.drawerOpen]: draweropen,
                      [classes.drawerClose]: !draweropen,
                    }),
                  }}
                  anchor="bottom"
                  BackdropProps={{ invisible: true }}
                  open={draweropen}
                >
                  <Grid
                    alignItems="center"
                    container
                    justify="space-between"
                    className={classes.paddeddrawertitle}
                  >
                    <Grid item>
                      <h5 className="text_head mt-4"></h5>
                    </Grid>
                    <Grid item>
                      <button
                        type="button"
                        className="btn text-uppercase h40 btn_green_light min-width163 position-relative"
                        onClick={toggleDrawer}
                      >
                        {draweropen ? (
                          <>
                            <SvgIcon
                              viewBox="0 0 12 8"
                              style={{ fontSize: 8 }}
                              className="left_15 f30"
                            >
                              <path
                                d="M1.41 -7.62939e-08L6 4.59L10.59 -7.62939e-08L12 1.42L6 7.42L0 1.42L1.41 -7.62939e-08Z"
                                fill="#067F6F"
                              />
                            </SvgIcon>
                            {t('Hide')}
                          </>
                        ) : (
                          <>
                            <SvgIcon
                              viewBox="0 0 12 8"
                              style={{ fontSize: 8 }}
                              className="left_15 f30"
                            >
                              <path
                                d="M1.41 7.41L6 2.83L10.59 7.41L12 6L6 0L0 6L1.41 7.41Z"
                                fill="#067F6F"
                              />
                            </SvgIcon>
                            {t('Show')}
                          </>
                        )}
                      </button>
                    </Grid>
                  </Grid>

                  <Details_tab
                    {...props}
                    value={props.tabvalue}
                    patientDetails={patientDetails}
                    appointmentId={appointmentId}
                    stepper={props.step}
                    handleChange={props.changetabvalue}
                    setoggleRecordingInvert={settoggleRecordingInvert}
                    stats={statistics}
                    toggleRecordingInvert={toggleRecordingInvert}
                    recording={recording}
                    alertOn={alertOn}
                    appNotes={appNotes}
                  />
                </Drawer>
              ) : (
                <div ref={viewportRef2}>
                  <Details_tab
                    {...props}
                    value={props.tabvalue}
                    patientDetails={patientDetails}
                    appointmentId={appointmentId}
                    stepper={props.step}
                    handleChange={props.changetabvalue}
                    setoggleRecordingInvert={settoggleRecordingInvert}
                    stats={statistics}
                    toggleRecordingInvert={toggleRecordingInvert}
                    recording={recording}
                    alertOn={alertOn}
                    appNotes={appNotes}
                  />
                </div>
              )}
              {/* My Notes step3 */}
            </div>

            <div
              className={
                mediawidth === 'xl' || mediawidth === 'lg'
                  ? 'col-12 col-xl-5 p-md-0 p-lg-0 px-xl-3'
                  : 'col-12 col-xl-5 p-md-0 p-lg-0 px-xl-3 order-first'
              }
            >
              {props.step === 1 && (
                <>
                  {/* please dont remove below line only comment */}
                  {
                    // (mediawidth === "xl" || mediawidth === "lg") &&
                    (_get(patientDetails, 'patientAppointment.appointmentChannelTypeId') === 2 ||
                      _get(patientDetails, 'patientAppointment.appointmentChannelTypeId') === 3) &&
                      !phoneCall && (
                        <div ref={communicationref}>
                          <VideoCall
                            finalcounttimer={finalcounttimer}
                            consultationNote={consultationNote}
                            reconnectionflag={reconnectionflag}
                            setConsultationNotevideocall={setconsultationNote}
                            saveMyNotesAsync={saveMyNotesAsync}
                            appointmentId={appointmentId}
                            clinicianId={clinicianId}
                            teamId={teamId}
                            appointmentChannelTypeId={_get(
                              patientDetails,
                              'patientAppointment.appointmentChannelTypeId',
                            )}
                            establishnewconnection={establishnewconnection}
                            key={connectionflag}
                            connectionflag={connectionflag}
                            patientName={
                              (_get(patientDetails, 'patient.lastName') || '-') +
                              ' ' +
                              (_get(patientDetails, 'patient.firstName') || '-')
                            }
                            choosePhoneCall={choosePhoneCall}
                            {...props}
                          />
                          {/* <ExampleComponent 
                            text="vonage api" 
                            teamId={teamId}
                            clinicianId={clinicianId}
                            appointmentId={appointmentId}
                            patientName={
                              (_get(patientDetails, 'patient.lastName') || '-') +
                              ' ' +
                              (_get(patientDetails, 'patient.firstName') || '-')
                            }
                            baseUrl= {process.env.REACT_APP_TELEKARDIA_API_BASE}
                            authToken= {getToken()}
                          /> */}
                        </div>
                      )
                  }
                  {/* {console.log('phone number - ', _get(patientDetails, "patient.phone").replace(/[^\w\s]/gi, ''))} */}
                  {phoneCall && (
                    <VonageCall
                      {...props}
                      patientName={
                        _get(patientDetails, 'patient.firstName') +
                          ' ' +
                          _get(patientDetails, 'patient.lastName') || '-'
                      }
                      mobile={_get(patientDetails, 'patient.phone').replace(/[^\w\s]/gi, '') || '-'}
                    />
                  )}
                </>
              )}

              {/* member's vision */}
              {/* <div className="row no-gutters" style={{backgroundColor: '#9DD3DE', padding: '25px', fontFamily: 'Work Sans', marginBottom: '20px', fontSize: '16px'}}>
                  <Typography variant={'body2'}>
                    Vision: I want to be healthy and strong enough to swim, dance, and play with my granchildren. 
                  </Typography>
              </div> */}

              {/*Consultation Notes*/}
              {
                <div className="row no-gutters">
                  <div className="col-12 bg_white p-4">
                    <Tabs
                      id="controlled-tab-example"
                      className="customtabs_sm"
                      activeKey={key}
                      onSelect={(k) => setKey(k)}
                    >
                      <Tab eventKey="AddNotes" title={t('My Notes')}>
                        <TextareaAutosize
                          onChange={(e) => {
                            setconsultationNote(e.target.value); //saving to local state
                            saveMyNotesAsync(e.target.value); //saving to api on a delay
                          }}
                          className="form-control bg_grey border-0"
                          className={
                            (props.step === 1 ||
                              props.step === 2 ||
                              props.step === 3 ||
                              props.component === 'popover') &&
                            (mediawidth === 'xl' || mediawidth === 'lg')
                              ? 'form-control bg_grey border-0'
                              : 'form-control bg_grey height_230 border-0'
                          }
                          style={{
                            overflow: 'scroll',
                            overflowX: 'hidden',
                            height:
                              props.component === 'popover'
                                ? notesheight - 77
                                : props.step === 1 && (mediawidth === 'xl' || mediawidth === 'lg')
                                ? '480px'
                                : props.step === 2 && (mediawidth === 'xl' || mediawidth === 'lg')
                                ? _get(
                                    patientDetails,
                                    'patientAppointment.appointmentChannelTypeId',
                                  ) === 1
                                  ? notesheight - 156
                                  : notesheight - 550
                                : props.step === 3 &&
                                  (mediawidth === 'xl' || mediawidth === 'lg') &&
                                  notesheight,
                          }}
                          placeholder="Write your session notes here. They will not be shared with the member."
                          value={consultationNote}
                        />
                      </Tab>
                      {/* {props.step === 2 && currentAppointmentUser && (
                        <Tab eventKey="chat" title="Chat">
                          chat user id comes here from redux
                          <SessionChat chatUserId={currentAppointmentUser} />
                        </Tab>
                      )} */}
                    </Tabs>
                  </div>
                </div>
              }
            </div>
            <div
              className={mediawidth === 'xl' || mediawidth === 'lg' ? 'col-12' : 'col-12 order-2'}
            >
              <div className="telekardia">
                <div className="d-flex justify-content-end py-3 px-0">{actions}</div>
                {mediawidth !== 'xl' && mediawidth !== 'lg' && <Box mb={30} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Details;
